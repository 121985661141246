import notification from "@/api/notification";

// initial state
const state = {
    toasts: [],
    unreadNotifications: []
};

// getters
const getters = {
    toasts: state => {
        return state.toasts;
    },
    unreadNotifications: state => {
        return state.unreadNotifications;
    }
};

// actions
const actions = {
    addToast({ commit }, toast) {
        commit("PUSH", toast);
    },

    removeToast({ commit }, toastToRemove) {
        commit("DELETE", toastToRemove);
    },

    getUnreadNotifications({ commit }) {
        return notification
            .get({
                filter: "unread"
            })
            .then(res => {
                commit("SET_UNREAD_NOTIFICATIONS", res.data);
                return res;
            })
            .catch(error => {
                commit("SET_UNREAD_NOTIFICATIONS", []);
                return error;
            });
    },

    markNotificationAsRead({ commit }, data) {
        return notification
            .markAsRead(data.id)
            .then(res => {
                commit("MARK_NOTIFICATION_AS_READ", res.data);
                return res;
            })
            .catch(error => {
                return error;
            });
    }
};

// mutations

let nextId = 1;

const mutations = {
    PUSH(state, toast) {
        state.toasts.push({
            ...toast,
            id: nextId++
        });
    },

    DELETE(state, toastToRemove) {
        state.toasts = state.toasts.filter(toast => toast.id !== toastToRemove.id);
    },

    SET_UNREAD_NOTIFICATIONS(state, notifications) {
        state.unreadNotifications = notifications;
    },

    MARK_NOTIFICATION_AS_READ(state, data) {
        let index = state.unreadNotifications.findIndex(n => n.id === data.id);
        if (index > -1) {
            state.unreadNotifications.splice(index, 1);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
