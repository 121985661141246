export default {
    data() {
        return {
            sidebarOpen: false,
            open: false,
            showFilters: true
        };
    },
    methods: {
        updateSidebarOpen(bool) {
            this.sidebarOpen = bool;
        },
    },
    computed: {
        bgColorClass() {
            return "bg-gray-100";
            // return this.$route.meta.bgClass ? this.$route.meta.bgClass : "bg-gray-100";
        }
    }
};
