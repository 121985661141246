import axios from "axios";

export default {
    getThreads(params) {
        return axios.get("threads", {params});
    },
    getThread(id) {
        return axios.get(`threads/${id}`);
    },
    getOne(promotionId, id) {
        return axios.get(`promotions/${promotionId}/messages/${id}`);
    },
    createThread(data){
        return axios.post("/threads", data);
    },
    replyToThread(params, threadId) {
        return axios.post(`/threads/${threadId}/reply`, params);
    },
    markAsRead(params, threadId) {
        return axios.put(`/threads/${threadId}/markAsRead`, params);
    },
};
