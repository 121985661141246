<template>
    <default-modal
        v-if="open"
        @close="close"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
            v-if="targetType != 'lot'"
        >Ajouter une tâche pour {{entityName}}</h3>
        <div slot="body" v-if="loaded">
            <div
                class="mt-4 text-left"
                id="title_group"
            >
                <label
                    for="title"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >Titre *</label>
                <input
                    type="text"
                    id="title"
                    v-model="title"
                    class="form-input w-full"
                />
            </div>
            <div
                class="mt-4 text-left"
                id="detail_group"
            >
                <label
                    for="detail"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >Détail</label>
                <textarea
                    id="detail"
                    v-model="detail"
                    class="form-input resize-none"
                ></textarea>
            </div>
            <div
                class="mt-2 text-left relative"
                id="deadline_group"
            >
                <div class="grid grid-cols-6 gap-4">
                    <div class="mt-1 sm:mt-0 col-span-2">
                        <label
                            for="lot"
                            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Date *</label>
                        <datetime 
                            v-model="end_at"
                            type="datetime"
                            value-zone="UTC+2"
                            zone="UTC+2"
                            input-id="promotion_start_at"
                            input-class="form-input"
                            :minute-step="15"
                        >
                        </datetime>
                    </div>
                    <div class="mt-1 ml-6 sm:mt-0 col-span-3">
                        <div class="block text-sm font-medium leading-5 text-gray-700 mb-2">Type *</div>
                        <div class="inline-block ml-0 mt-1">
                            <input
                                id="radio1"
                                type="radio"
                                class="hidden"
                                :value="0"
                                v-model="type"
                            />
                            <label
                                for="radio1"
                                class="flex items-center cursor-pointer text-xl text-gray-500 hover:text-gray-700"
                            >
                                <span class="w-4 h-4 inline-block mr-2 rounded-full border flex-no-shrink"></span>
                                Deadline
                            </label>
                        </div>

                        <div class="inline-block m-4 mt-1">
                            <input
                                id="radio2"
                                type="radio"
                                class="hidden"
                                :value="1"
                                v-model="type"
                            />
                            <label
                                for="radio2"
                                class="flex items-center cursor-pointer text-xl text-gray-500 hover:text-gray-700"
                            >
                                <span class="w-4 h-4 inline-block mr-2 rounded-full border flex-no-shrink"></span>
                                Evènement
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="mt-4 text-left"
                id="attribution_group"
            >
                <span class="block text-sm font-medium leading-5 text-gray-700 mb-2">Attribuer à *</span>
                <picture-select
                    :collection="currentPromotion.brokers"
                    textProp="full_name"
                    imgProp="profile_picture"
                    defaultText="Sélectionner"
                    valueProp="id"
                    :value="broker_id"
                    @change="(val) => {updateSelectedBroker(val)}"
                />
            </div>
        </div>
        <div
            slot="footer"
            class="mt-12 text-right"
        >
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >Valider</button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Fermer</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import { Datetime } from "vue-datetime";

import PictureSelect from "@/components/Forms/PictureSelect";
import TaskModal from "@/mixins/TaskModal";
export default {
    components: { DefaultModal, Datetime, PictureSelect },
    mixins: [TaskModal],
    data() {
        return {
            type: 0,
            end_at: null,
            detail: "",
            title: "",
            broker_id: null, //assignedTo() morph
            isDisabled: false,
        };
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapGetters("auth", ["currentPromotion", "preferences"]),
        open(){
            return this.currentActionType === "addTask" && this.targetType != null;
        },
        isValid() {
            if(this.isDisabled)
                return false;
            return this.title.length;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
    },
    methods: {
        fetchData(){
            
        },
        updateSelectedBroker(val) {
            this.broker_id = val;
        },
        submit() {
            if (!this.isValid)
                return;

            this.isDisabled = true;
            // this.$store.commit("loader/SET_LOADING", true);
            let body = {
                promotion_id : this.preferences.selected_promotion,
                broker_id: this.broker_id,
                type: this.type,
                detail: this.detail,
                end_at: this.end_at,
                title: this.title
            };

            this.$store.dispatch("app/executeCommand", {
                name : "createAction",
                params : {
                    entity : this.targetType,
                    entity_id : this.target.id,
                    additionnal_fields : body,
                }
            }).then(() => {
                this.close();
                this.$emit("createdTask");
            }).finally(()=>{
                this.isDisabled = false;
            });
            
        },
    },
};
</script>

<style lang="sass">
#vdp-datepicker .vdp-datepicker
    position: fixed
    display: block

#deadline_group .vdp-datepicker__calendar
    display: block

input[type="radio"] + label span
    transition: all .2s
    transform .2s

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span
    transform: scale(1.2)

input[type="radio"]:checked + label span
    background-color: rgb(42,67,101)
    box-shadow: 0px 0px 0px 2px white inset

input[type="radio"]:checked + label
    color: rgb(42,67,101)

.display-time
  border-radius : 6px
  border-color : rgb(226, 232, 240) !important

.time-picker li.active
  background-color : rgb(42,67,101) !important
</style>
