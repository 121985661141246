<template>
    <div
        class="inline-block mr-5"
        v-on-clickaway="close"
    >
        <div class="relative">
            <button
                @click.prevent="toggle"
                class="rounded-lg shadow inline-flex items-center bg-white hover:text-gray-700 focus:outline-none focus:shadow-outline text-gray-500 py-2 px-2 md:px-4 text-sm"
            >
                <span
                    v-if="selected"
                    class="rounded-full h-6 w-6 inline-block"
                    :style="`background-color : ${selected}`"
                >
                </span>
                <span v-else>
                    Couleur
                </span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 ml-1"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        stroke="none"
                    />
                    <polyline points="6 9 12 15 18 9" />
                </svg>
            </button>
            <div
                v-show="open"
                class="z-40 absolute top-0 left-0 w-40 h-56 bg-white rounded-lg shadow-lg mt-12 block overflow-hidden"
            >
                <div
                    @click="select(null)"
                    class="px-3 py-2 font-semibold hover:bg-gray-200 cursor-pointer"
                >Aucune couleur</div>
                <template v-for="(item, index) in collection">
                    <div
                        class="hover:bg-gray-200 h-10 w-10 cursor-pointer justify-center align-center inline-block"
                        :key="index"
                        @click="select(item)"
                    >
                        <span
                            class="rounded-full h-6 w-6 inline-block m-2"
                            :style="`background-color : ${item};`"
                        >
                        </span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
    mixins: [clickaway],
    props: {
        label: [String, Number],
        selectedColor: String,
        collection: {
            required: true
        }
    },
    data() {
        return {
            open: false,
            selected: null
        };
    },
    mounted() {
        if (this.selectedColor) this.selected = this.selectedColor;
    },
    methods: {
        close() {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        },
        emitChange() {
            const selection = this.collection.filter((item, index) =>
                this.selected.includes(index)
            );

            this.$emit("change", selection);
        },
        select(item) {
            this.selected = item;
            this.close();
            this.$emit("change", this.selected);
        }
    },
    watch: {
        selectedColor(newval) {
            this.selected = newval;
        }
    }
};
</script>
