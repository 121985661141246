<template v-if="user">
    <index-candidate v-if="isCandidate || isInvestor" />
    <index-auth v-else />
</template>

<script>
import { mapState } from "vuex";

import IndexAuth from "./IndexAuth";
import IndexCandidate from "./IndexCandidate";
import Auth from "@/mixins/Auth";

export default {
    mixins : [Auth],
    components: {
        IndexAuth,
        IndexCandidate,
    },
    computed: {
        ...mapState("auth", ["user"]),
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Avancement";
            }
        },
    },     
};
</script>
