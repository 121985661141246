import modals from "../partials/modals";
import axios from "axios";

const state = {
    ...modals.state,
    targetType : null,
    target : null,
};

const getters = {
    ...modals.getters,
    target : (state) => state.target,
    targetType : (state) => state.targetType,
    entityName : (state) => {
        if(state.target){
            switch(state.targetType){
                case "candidacy" : 
                    return state.target.candidate.full_name;
                case "application" : 
                    return state.target.candidacy1.candidate.full_name;
                case "lot" : 
                    return state.target.title;
                default : 
                    return null; 
            }
        }
        return null;
    },
    entityApiName(){
        return {
            application : "applications",
            lot : "lots",
            candidacy : "candidacies"
        };
    }
};

const actions = {
    openAddTask({commit}, data){
        commit("SET_CURRENT_ACTION_TYPE", "addTask");
        commit("SET_CURRENT_ACTION_TARGET", null);
        commit("SET_TARGET_TYPE", data.targetType);
        commit("SET_TARGET", data.target);
    }, 
    closeModals({commit}){
        commit("SET_CURRENT_ACTION_TARGET", null);
        commit("SET_CURRENT_ACTION_TYPE", null);
        commit("SET_TARGET_TYPE", null);
        commit("SET_TARGET", null);
    },
    getTarget({commit, getters}, target){
        return axios.get(`/${getters.entityApiName[target.type]}/${target.id}`).then((res)=>{
            commit("SET_TARGET", res.data);
        }); 
    }
};

const mutations = {
    ...modals.mutations,
    SET_TARGET_TYPE(state, value){
        state.targetType = value;
    },
    SET_TARGET(state, value){
        state.target = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
