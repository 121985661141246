import axios from "axios";

export default {
    login(body) {
        return axios.post("login", body);
    },
    user(params) {
        return axios.get("user", {params});
    },
    askDemo(body) {
        return axios.post("askdemo",body);
    },
    update(body) {
        return axios.post("user/update", body);
    },
    updatePassword(body) {
        return axios.put("user/password", body);
    },
    createResetPassword(body) {
        return axios.post("password/create", body);
    },
    checkPasswordToken(token) {
        return axios.get(`password/find/${token}`);
    },
    resetPassword(body) {
        return axios.post("password/reset", body);
    },
    getPreferences(){
        return axios.get("user/preferences");
    },
    setCurrentPromotion(promotionId){
        return axios.put("user/promotion", {promotion : promotionId});
    },
    getCurrentPromotion(){
        return axios.get("user/promotion");
    }
};
