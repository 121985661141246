<template>
    <div>
        
        <div v-for="candidacy in candidate.candidacies" :key="candidacy.id">
            <h2
            class="font-semibold text-center bg-blue-400 text-white shadow py-1"
        >
            Commentaires {{candidacy.promotion.title}}
        </h2>
            <div class="grid grid-rows px-2">
                <comments-list
                    :candidacy="candidacy"
                    @addComment="emitReload"
                ></comments-list>
            </div>
        </div>
    </div>
</template>

<script>
import CommentsList from "@/components/Candidates/CommentsList";

export default {
    components: {
        CommentsList,
    },
    methods: {
        emitReload() {
            this.$emit("needReload");
        },
    },
    computed: {
        candidate() {
            return this.$parent.candidate;
        },
    },
};
</script>

<style>
</style>