<template>
    <div class="bg-white shadow sm:rounded-md">
        <div class="flex" style="min-height : 120px;">
            <div class="w-full">
                <div class="align-middle min-w-full shadow sm:rounded-lg border-b border-gray-200 h-full">
                    <div class="flex">
                        <div class="grid sm:grid-cols-1 lg:grid-cols-3 gap-2 mr-2">
                            <div class="pl-2 py-2">
                                <!-- Recherche -->
                                <div class="inline-block mr-4">
                                    <div class="mt-1 flex rounded-md shadow-sm w-64">
                                        <div class="relative flex-grow focus-within:z-10">
                                            <input
                                                v-model="search"
                                                id="search"
                                                :class="{ 'rounded-r-md': !search }"
                                                class="px-3 py-2 border border-gray-300 block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5 appearance-none focus:outline-none focus:border-blue-400"
                                                placeholder="Recherche"
                                            />
                                        </div>
                                        <button
                                            v-if="search"
                                            @click="clearSearch"
                                            class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 rounded-r-md text-sm leading-5 font-medium text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-400 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                                        >
                                            <svg
                                                class="h-5 w-5 text-gray-400"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clip-rule="evenodd"
                                                    fill-rule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>                            
                            </div>
                            <div class="px-2 py-2 mt-1">
                                <datepicker
                                    :disabled-dates="startDisabledDates"
                                    v-model="start_at"
                                    @selected="updateStartAt"
                                    input-class="form-input"
                                    placeholder="Débute après"
                                    :language="fr"
                                ></datepicker> 
                            </div>
                            <div class="px-2 py-2 mt-1">
                                <datepicker
                                    :disabled-dates="endDisabledDates"
                                    v-model="end_at"
                                    @selected="updateEndAt"
                                    input-class="form-input"
                                    placeholder="Termine avant"
                                    :language="fr"
                                ></datepicker>
                            </div>                          
                        </div>
                        <div class="flex items-center">
                            <div
                                class="inline-block p-2 h-10 rounded shadow align-bottom text-gray-700 cursor-pointer hover:shadow-lg transition-all select-none"
                                @click="resetFilters"
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    class="fill-current"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.662 23l-5.369-5.365c-.195-.195-.293-.45-.293-.707 0-.256.098-.512.293-.707l14.929-14.928c.195-.194.451-.293.707-.293.255 0 .512.099.707.293l7.071 7.073c.196.195.293.451.293.708 0 .256-.097.511-.293.707l-11.216 11.219h5.514v2h-12.343zm3.657-2l-5.486-5.486-1.419 1.414 4.076 4.072h2.829zm6.605-17.581l-10.677 10.68 5.658 5.659 10.676-10.682-5.657-5.657z"
                                    />
                                </svg>
                            </div>
                        </div>  
                    </div>
                    <div class="overflow-x-auto">
                        <table class="min-w-full main--table">
                            <thead class="bg-gray-200">
                                <tr>
                                    <sortable-th v-if="currentPromotion.multi_agency" column="agency_id" class="text-left">Agence</sortable-th>
                                    <sortable-th column="title" class="text-left">Titre</sortable-th>
                                    <sortable-th column="method">Plateforme</sortable-th>
                                    <sortable-th column="start_at">
                                        Début
                                    </sortable-th>
                                    <sortable-th column="end_at">
                                        Fin
                                    </sortable-th>
                                    <sortable-th column="price">
                                        Coût
                                    </sortable-th>
                                    <th>
                                        Fichier
                                    </th>
                                    <th>
                                        Commentaire
                                    </th>
                                    <th class="w-6"></th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                <campaign-row
                                    v-for="campaign in currentCampaigns"
                                    :key="campaign.id"
                                    :campaign="campaign"
                                ></campaign-row>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <the-pagination
            v-if="campaigns"
            :lastPage="campaigns.last_page"
            :currentPage="campaigns.current_page"
            :from="campaigns.from"
            :to="campaigns.to"
            :total="campaigns.total"
        ></the-pagination>
    </div>
</template>

<script>
import _ from "lodash";
import { fr } from "vuejs-datepicker/dist/locale";
import CampaignRow from "@/components/Campaigns/CampaignRow";
import Sortable from "@/mixins/Sortable";
import SortableTh from "@/components/Tables/SortableTh";
import Datepicker from "vuejs-datepicker";
import ThePagination from "@/components/global/ThePagination";
import { resetQuery } from "@/helpers/router";
import { mapGetters } from "vuex";

export default {
    mixins: [Sortable],
    components: {
        CampaignRow,
        SortableTh,
        Datepicker,
        ThePagination
    },
    data() {
        return {
            start_at: null,
            end_at: null,
            fr,
        };
    },
    props: {
        campaigns: {
            type: Object,
            required: true,
        },
    },
    methods: {
        resetQuery,
        resetFilters(){
            this.search = null;
            this.start_at = null;
            this.updateStartAt();
            this.end_at = null;
            this.updateEndAt();
            this.resetQuery();
        },
        setFilter(key, val) {
            this.$store.commit("app/SET_FILTER", {
                key,
                value: val,
            });
        },
        updateStartAt(){
            this.$nextTick(
                () => this.setFilter("start_at",this.start_at)
            );
        },
        updateEndAt(){
            this.$nextTick(
                () => this.setFilter("end_at",this.end_at)
            );
        },
        clearSearch() {
            this.search = null;
        }
    },
    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
        ...mapGetters("app", ["searchCampaign"]),
        search: {
            get() { 
                return this.searchCampaign;
            },
            set: _.debounce(function(value) {
                this.setFilter("searchCampaign", value);
            }, 300)
        },
        startDisabledDates() {
            if (this.end_at) {
                return {
                    from: this.end_at
                };
            }
            return {
                to: null
            };
        },
        endDisabledDates() {
            if (this.start_at) {
                return {
                    to: this.start_at
                };
            }
            return {
                to: null
            };
        },
        hasCampaigns() {
            return this.campaigns.length;
        },
        currentCampaigns(){
            if(this.campaigns){
                if(this.campaigns.data)
                    return this.campaigns.data;
            } 
            return [];
        }
    }
};
</script>
