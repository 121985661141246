import axios from "axios";

export default {
    get(promotionId) {
        return axios.get("actions", {params : { promotion: promotionId }});
    },
    notaryGet(promotionId) {
        return axios.get(`promotions/${promotionId}/notary/actions`);
    },
    applicationPdf(applicationPublicId){
        return axios.get(`applications/${applicationPublicId}/pdf`);
    }
};
