import axios from "axios";

export default {
    get(body) {
        return axios.get("promotions", body);
    },
    getOne(id) {
        return axios.get(`promotions/${id}`);
    },
    create(body) {
        return axios.post("promotions", body);
    },
    /*candidateGet() {
        return axios.get("candidate/promotions");
    },
    candidateGetOne(id) {
        return axios.get(`candidate/promotions/${id}`);
    },*/
    notaryGet() {
        return axios.get("notaries/promotions");
    },
    notaryGetOne(id) {
        return axios.get(`notaries/promotions/${id}`);
    },
    createCampaign(body, promotion_id) {
        return axios.post(`promotions/${promotion_id}/campaigns`, body);
    },

    getPhotoGroups(params) {
        return axios.get("/photoGroups", { params });
    },

    addPhoto(body, promotion_id) {
        return axios.post(`promotions/${promotion_id}/photos`, body);
    },
    addPhotoGroup(body, promotion_id) {
        return axios.post(`promotions/${promotion_id}/photoGroups`, body);
    },
    editPhotoGroup(body, promotion_id, photo_group_id) {
        return axios.put(
            `promotions/${promotion_id}/photoGroups/${photo_group_id}`,
            body
        );
    },
    removePhoto(promotion_id, photo_id) {
        return axios.delete(`promotions/${promotion_id}/photos/${photo_id}`);
    },
    removePhotoGroup(promotion_id, photo_group_id) {
        return axios.delete(
            `promotions/${promotion_id}/photoGroups/${photo_group_id}`
        );
    },
    createFile(body, promotion_id) {
        return axios.post(`promotions/${promotion_id}/files`, body);
    },
    updateFile(body, promotion_id, file_id) {
        return axios.post(`promotions/${promotion_id}/files/${file_id}`, body);
    },
    deleteFile(promotion_id, file_id) {
        return axios.delete(`promotions/${promotion_id}/files/${file_id}`);
    },
    addBroker(promotion_id, broker_id) {
        return axios.post(`promotions/${promotion_id}/add-broker/${broker_id}`);
    },
    addPromoter(promotion_id, promoter_id) {
        return axios.post(`promotions/${promotion_id}/add-promoter/${promoter_id}`);
    },
    addBanker(promotion_id, banker_id) {
        return axios.post(`promotions/${promotion_id}/add-banker/${banker_id}`);
    },
    addArchitect(promotion_id, architect_id) {
        return axios.post(`promotions/${promotion_id}/add-architect/${architect_id}`);
    },
    removeBroker(promotion_id, broker_id) {
        return axios.post(`promotions/${promotion_id}/remove-broker/${broker_id}`);
    },
    removePromoter(promotion_id, promoter_id) {
        return axios.post(`promotions/${promotion_id}/remove-promoter/${promoter_id}`);
    },
    removeBanker(promotion_id, banker_id) {
        return axios.post(`promotions/${promotion_id}/remove-banker/${banker_id}`);
    },
    removeArchitect(promotion_id, architect_id) {
        return axios.post(`promotions/${promotion_id}/remove-architect/${architect_id}`);
    },
    updateSettings(promotion_id, settings) {
        return axios.post(`promotions/${promotion_id}/settings`, settings);
    },
    createBuilding(promotion_id, body) {
        return axios.post(`promotions/${promotion_id}/buildings`, body);
    },
    updateBuilding(promotion_id, building_id, body) {
        return axios.post(`promotions/${promotion_id}/buildings/${building_id}`, body);
    },
    createLot(promotion_id, building_id, body) {
        return axios.post(`promotions/${promotion_id}/buildings/${building_id}/lots`, body);
    },
    updateLot(promotion_id, building_id, lot_id, body) {
        return axios.put(`promotions/${promotion_id}/buildings/${building_id}/lots/${lot_id}`, body);
    },
    deleteLot(promotion_id, building_id, lot_id, body=null) {
        return axios.delete(`promotions/${promotion_id}/buildings/${building_id}/lots/${lot_id}`, body);
    },
    createBroker(promotion_id, body) {
        return axios.post(`promotions/${promotion_id}/brokers`, body);
    },
    syncCrm(promotion_id){
        return axios.put(`promotions/${promotion_id}/send-to-crm`);
    }
};
