<template>
    <div
        class="z-40 h-screen w-screen fixed bottom-0 inset-x-0 px-3 pb-3 sm:inset-0 sm:p-0 flex items-center justify-center"
    >
        <div
            class="fixed inset-0 transition-opacity"
        >
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div
            class="bg-white rounded-lg p-3 shadow-xl transform transition-all sm:p-6"
            :class="maxWidthClass"
        >
            <div class="absolute top-0 right-0 pt-2 pr-2">
                <button
                    @click="close"
                    type="button"
                    class="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600 transition ease-in-out duration-150"
                >
                    <svg
                        class="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </button>
            </div>
            
            <div class="text-center">
                <slot name="header"></slot>
                <div class="max-h-80vh">
                    <slot name="body"></slot>
                </div>
            </div>
        
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        maxWidthClass: {
            type: String,
            default: ""
        }
    },
    methods: {
        close() {
            this.$emit("close");
        }
    },
};
</script>
