<template>
    <div
        class="inline-flex items-center px-2 py-1 rounded-full h-6 w-6 text-sm font-medium leading-5 relative cursor-pointer select-none border border-gray-700"
        :class="applicationPriority.color"
        @mouseover="e => show(e, application)"
        @mouseleave="hide"
        @click="openSalesTunnel"
    >
        {{ applicationPriority.value }}
        <!-- <div
            class="rounded text-sm bg-white border border-gray-200 absolute p-2 text-left"
            :class="applicationPriority.color"
            v-show="showTooltip"
            style="top: calc(100%); right : 15px;"
        >
            <div v-if="application.main_candidate">
                {{ applicationPriority.label }} :
                {{ application.main_candidate.full_name }}
            </div>
            <span class="text-xs text-blue-700">
                Cliquer pour voir dans le tunnel de vente
            </span>
        </div> -->
    </div>
</template>

<script>
import PriorityBadge from "@/mixins/PriorityBadge";
import { mapState } from "vuex";

export default {
    mixins: [PriorityBadge],
    props: {
        application: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            showTooltip: false
        };
    },
    computed : {
        ...mapState("application", ["priorities"]),
        applicationPriority(){
            return this.priorities.find(
                x => x.value === this.application.priority
            );
        }   
    },
    methods: {
        show(event, application){
            this.$store.commit("application/SET_TOOLTIP_APPLICATION", application);
            this.$store.commit("application/SET_TOOLTIP_EVENT", event);
        },
        hide(){
            this.$store.commit("application/SET_TOOLTIP_APPLICATION", null);
            this.$store.commit("application/SET_TOOLTIP_EVENT", null);
        },
        openSalesTunnel() {
            this.hide();
            this.$router.push({
                name: "tunnel-de-vente",
                query: { application: this.application.id }
            });
        }
    }
};
</script>

<style></style>
