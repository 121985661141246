<template>
    <span
        class="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium leading-5"
        :class="color"
    >
        {{ status }}
    </span>
</template>

<script>
export default {
    data() {
        return {
            colors: {
                "Non retenu" : ["text-gray-500"],
                "Non retenu CP": ["text-gray-500"],
                "Prospect" : ["bg-gray-500", "text-white"],
                "Client" : ["bg-orange-300", "text-white"],
                "Client acquéreur" : ["bg-orange-400", "text-white"],
                "Promettant acquéreur" : ["bg-green-300", "text-white"],
                "Acquéreur" : ["bg-green-600", "text-white"]
            }
        };
    },
    props: {
        status: {
            type: String,
            default: null
        }
    },
    computed: {
        color() {
            return this.colors[this.status];
        }
    }
};
</script>

<style></style>
