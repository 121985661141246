<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-gray-800 pb-5"
        >
            Modifier l'album
            <span class="font-bold text-blue-900">{{ currentActionTarget.name }}</span>
        </h3>
        <div
            slot="body"
            class="text-left"
        >
            <div
                class="mt-4 text-left"
                id="title_group"
            >
                <label
                    for="title-edit"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >Nom</label>
                <input
                    type="text"
                    id="name"
                    v-model="body.name"
                    class="form-input w-full"
                />
            </div>
            <div class="mt-4 text-left" v-if="!isPilot">
                <label
                    for="lot"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >Lot</label>
                <select-lot
                    @change="handleSelectLot"
                    :initialValue="currentActionTarget.lot_id"
                ></select-lot>
            </div>
            <div class="mt-4">
                <label
                    for="body"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >Commentaire</label>
                <div class="mt-1 rounded-md shadow-sm">
                    <textarea
                        v-model="body.comment"
                        id="body"
                        rows="3"
                        class="border p-2 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:outline-none focus:border-blue-400 rounded"
                    ></textarea>
                </div>
            </div>
            <div class="mt-4 grid grid-cols-2 text-left gap-4">
                <div>
                    <label
                        for="date"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Date</label>
                    <datepicker
                        v-model="body.date"
                        input-class="form-input"
                        :language="fr"
                    ></datepicker>
                </div>
            </div>
            <div class="mt-4 text-left gap-6">
                <label
                    for="hidden"
                    class="text-sm font-medium leading-5 text-gray-700 mb-2"
                >Caché</label>
                <input
                    :checked="body.hidden"
                    v-model="body.hidden"
                    id="hidden" 
                    type="checkbox" 
                    class="ml-4 form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                />
            </div>
            <div class="mt-4 text-left gap-6">
                <label
                    for="hidden"
                    class="text-sm font-medium leading-5 text-gray-700 mb-2"
                >Affiché sur la homepage</label>
                <input
                    :checked="body.home_page"
                    v-model="body.home_page"
                    id="hidden" 
                    type="checkbox" 
                    class="ml-4 form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                />
            </div>
        </div>
        <div
            slot="footer"
            class="mt-12 text-right"
        >
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >Valider</button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Fermer</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import { fr } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import SelectLot from "@/components/Lots/SelectLot";
import Auth from "@/mixins/Auth";

export default {
    components: {
        DefaultModal,
        Datepicker,
        SelectLot
    },
    mixins: [Auth],
    data() {
        return {
            fr: fr,
            body: {
                name: "",
                lot_id: null,
                date: null,
                comment: null,
                hidden: false,
                home_page: false
            },
            isDisabled: false,
        };
    },
    computed: {
        ...mapGetters("photo", ["currentActionType", "currentActionTarget"]),
        ...mapGetters("auth", ["currentPromotion"]),
        open(){
            return this.currentActionType === "editAlbum" && this.currentActionTarget != null;
        },
        isValid() {
            if(this.isDisabled)
                return false;
            const fields = ["name", "date"];
            for (var i in fields) {
                if (this.body[fields[i]] == "" || this.body[fields[i]] == null)
                    return false;
            }
            return true;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        }
    },
    methods: {
        handleSelectLot(value) {
            this.body.lot_id = value;
        },
        close() {
            this.$store.dispatch("photo/closeModals");
        },
        init(){
            this.body.name = this.currentActionTarget.name;
            this.body.lot_id = this.currentActionTarget.lot_id;
            this.body.date = this.currentActionTarget.date;
            this.body.comment = this.currentActionTarget.comment;
            this.body.hidden = this.currentActionTarget.hidden;
            this.body.home_page = this.currentActionTarget.home_page;
        },
        resetValues() {
            this.body = {
                name: "",
                lot_id: null,
                date: null
            };
        },
        submit() {
            const data = {
                ...this.body,
                date: this.$options.filters.date_ymd(this.body.date),
                promotion_id: this.currentPromotion.id,
                id: this.currentActionTarget.id
            };

            if (this.isValid) {
                this.isDisabled = true;
                this.$store.commit("loader/SET_LOADING", true);
                this.$store
                    .dispatch("promotion/editPhotoGroup", data)
                    .then(() => {
                        this.close();
                        this.resetValues();
                        this.$store.commit("loader/SET_LOADING", false);
                        this.$emit("update");
                    })
                    .catch(() => {
                        this.$toast.open({
                            message: "Une erreur est survenue",
                            type: "error"
                        });
                        this.$store.commit("loader/SET_LOADING", false);
                    })
                    .finally(() => {
                        this.isDisabled = false;
                    });
            }
        }
    },

    watch: {
        open(val) {
            if (val) {
                this.init();
            }
        }
    }
};
</script>
