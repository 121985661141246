<template>
    <main>
        <div class="grid grid-cols-1 lg:grid-cols-2 py-5 mx-auto sm:px-6 lg:px-8 items-start gap-3">
            <div
                v-if="showForcePassword"
                class="bg-yellow-100 border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md mb-6"
                role="alert"
            >
                <div class="flex">
                    <div class="py-1">
                        <svg
                            class="fill-current h-6 w-6 text-yellow-500 mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>
                    <div>
                        <p class="font-bold">Changement de mot de passe</p>
                        <p class="text-sm">
                            Veuillez modifier votre mot de passe avant d'utiliser NAEF e-promotion.
                        </p>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow overflow-hidden sm:rounded-md p-6">
                <form @submit.prevent="submit">
                    <div>
                        <div class="border-gray-200">
                            <div>
                                <h3
                                    class="text-lg leading-6 font-medium text-blue-900"
                                >
                                    Profil
                                </h3>
                                <p
                                    class="mt-1 max-w-2xl text-sm leading-5 text-gray-500"
                                >
                                    Vous pouvez ici modifier les informations
                                    relatives à votre compte.
                                </p>
                            </div>
                            <div class="mt-6 sm:mt-5">
                                <div
                                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                                >
                                    <label
                                        for="first_name"
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Prénom
                                    </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div
                                            class="max-w-xs rounded-md shadow-sm"
                                        >
                                            <input
                                                v-model="editUser.first_name"
                                                id="first_name"
                                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                                >
                                    <label
                                        for="last_name"
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Nom
                                    </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div
                                            class="max-w-xs rounded-md shadow-sm"
                                        >
                                            <input
                                                v-model="editUser.last_name"
                                                id="last_name"
                                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                                >
                                    <label
                                        for="email"
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Adresse email
                                    </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div
                                            class="max-w-lg rounded-md shadow-sm"
                                        >
                                            <input
                                                v-model="editUser.email"
                                                id="email"
                                                type="email"
                                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
                                >
                                    <label
                                        for="photo"
                                        class="block text-sm leading-5 font-medium text-gray-700"
                                    >
                                        Photo
                                    </label>
                                    <div class="mt-2 sm:mt-0 sm:col-span-2">
                                        <div v-if="avatar.open">
                                            <my-upload
                                                field="img"
                                                @crop-success="cropSuccess"
                                                v-model="avatar.open"
                                                :width="150"
                                                :height="150"
                                                lang-type="fr"
                                                img-format="png"
                                            ></my-upload>
                                        </div>
                                        <div class="flex items-center">
                                            <img
                                                class="h-12 w-12 rounded-full"
                                                v-if="avatar.base64"
                                                :src="avatar.base64"
                                            />
                                            <img
                                                class="h-12 w-12 rounded-full"
                                                v-else-if="
                                                    editUser.profile_picture
                                                "
                                                :src="editUser.profile_picture"
                                            />
                                            <span
                                                v-else
                                                class="h-12 w-12 rounded-full overflow-hidden bg-gray-100"
                                            >
                                                <svg
                                                    class="h-full w-full text-gray-300"
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                                    />
                                                </svg>
                                            </span>
                                            <span
                                                class="ml-5 rounded-md shadow-sm"
                                            >
                                                <button
                                                    @click="openUpload"
                                                    type="button"
                                                    class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                                                >
                                                    Modifier
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-8 border-t border-gray-200 pt-5">
                        <div class="flex justify-end">
                            <span class="ml-3 inline-flex rounded-md shadow-sm">
                                <button
                                    type="submit"
                                    class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                                >
                                    Enregistrer
                                </button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>

            <div class="bg-white shadow overflow-hidden sm:rounded-md p-6">
                <form @submit.prevent="submitPassword">
                    <div>
                        <div class="border-gray-200">
                            <div>
                                <h3
                                    class="text-lg leading-6 font-medium text-blue-900"
                                >
                                    Mot de passe
                                </h3>
                                <p
                                    class="mt-1 max-w-2xl text-sm leading-5 text-gray-500"
                                >
                                    Vous pouvez ici modifier votre mot de passe.
                                </p>
                            </div>
                            <div class="mt-6 sm:mt-5">
                                <div
                                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                                >
                                    <label
                                        for="first_name"
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Nouveau mot de passe
                                    </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div
                                            class="max-w-xs rounded-md shadow-sm"
                                        >
                                            <input
                                                v-model="password.password"
                                                type="password"
                                                id="password"
                                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                                >
                                    <label
                                        for="last_name"
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Confirmation du nouveau mot de passe
                                    </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                                        <div
                                            class="max-w-xs rounded-md shadow-sm"
                                        >
                                            <input
                                                v-model="
                                                    password.password_confirmation
                                                "
                                                type="password"
                                                id="password_confirmation"
                                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-8 border-t border-gray-200 pt-5">
                        <div class="flex justify-end">
                            <span class="ml-3 inline-flex rounded-md shadow-sm">
                                <button
                                    type="submit"
                                    class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                                >
                                    Enregistrer
                                </button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="sm:px-6 lg:px-8" v-if="false">
            <div class="bg-white rounded-md p-6 shadow" v-if="isBroker && currentPromotion">
                <BrokerAssistants :promotion="currentPromotion"></BrokerAssistants>
            </div>
        </div>
    </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MyUpload from "vue-image-crop-upload";
import { dataURLtoFile } from "@/helpers/file";
import Redirect from "@/mixins/Redirect";
import BrokerAssistants from "@/views/Account/Brokers/Assistants";
import Auth from "@/mixins/Auth";


export default {
    mixins: [Redirect, Auth],
    components: { MyUpload, BrokerAssistants },
    data() {
        return {
            editUser: null,
            password: {
                password: "",
                password_confirmation: "",
            },
            avatar: {
                open: false,
                base64: null,
            },
            showForcePassword: false,
        };
    },
    mounted(){
        this.init();
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapGetters("auth", ["currentPromotion"]),
    },
    methods: {
        dataURLtoFile,
        init(){
            this.editUser = {...this.user};
        },
        submit() {
            const data = new FormData();

            for (var key in this.editUser) {
                if (key !== "avatar") {
                    data.append(key, this.editUser[key]);
                }
            }

            if (this.avatar.base64) {
                data.append(
                    "avatar_file",
                    this.dataURLtoFile(this.avatar.base64, "x")
                );
            }

            this.$store
                .dispatch("auth/update", data)
                .then((res) => {
                    if (res.code === "200") {
                        //this.editUser = res;
                        this.$toast.open({
                            message: "Changements enregistrés",
                            type: "success",
                        });
                    } 
                })
                .catch(() => {
                    this.$toast.open({
                        message: "Une erreur est survenue",
                        type: "error",
                    });
                });
        },
        submitPassword() {
            this.$store
                .dispatch("auth/updatePassword", this.password)
                .then((res) => {
                    if (res.code === "200") {
                        this.password = {
                            password: "",
                            password_confirmation: "",
                        };
                        this.$toast.open({
                            message: "Mot de passe modifié",
                            type: "success",
                        });
                        //si premiere connexion
                        if (this.showForcePassword) {
                            this.showForcePassword = false;
                            this.redirectHome();
                        }
                    } 
                })
                .catch(() => {
                    this.$toast.open({
                        message: "Une erreur est survenue",
                        type: "error",
                    });
                });
        },
        cropSuccess(avatar) {
            this.avatar.base64 = avatar;
        },
        openUpload() {
            this.avatar.open = true;
        },
    },
    watch: {
        user() {
            this.editUser = Object.assign({}, this.user);
        },
        "$route.query.password"(value) {
            if (value) {
                this.showForcePassword = true;
            }
        },
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Mon compte";
            }
        },        
    },
    created() {
        this.editUser = Object.assign({}, this.user);
    },
};
</script>

<style>
.vue-image-crop-upload .vicp-wrap {
    max-width : 100%;
}
</style>