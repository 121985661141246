import broker from "@/api/brokers";

const state = {
    brokers: null
};

const getters = {
    brokers: state => {
        return state.brokers;
    }
};

const actions = {
    get({ commit }) {
        return broker
            .get()
            .then(response => {
                if (response.status === 200) {
                    commit("SET_BROKERS", response.data.data);
                }
                return response;
            })
            .catch(error => {
                return error;
            });
    }
};

const mutations = {
    SET_BROKERS(state, value) {
        state.brokers = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
