<template>
    <main>
        <div class="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="flex items-center">
                <div class="mb-2">
                    <!-- Recherche -->
                    <div class="inline-block mr-4">
                        <div class="mt-1 flex rounded-md shadow-sm w-64">
                            <div class="relative flex-grow focus-within:z-10">
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="Recherche"
                                    :value="search"
                                    @input="debounceSearch"
                                >
                            </div>
                            <button
                                v-if="search"
                                @click="clearSearch"
                                class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 rounded-r-md text-sm leading-5 font-medium text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-400 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                            >
                                <svg
                                    class="h-5 w-5 text-gray-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                        fill-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>     
                </div>   
                <div class="text-right mb-2 flex-grow">
                    <button
                        class="pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                        @click="openAddThreadModal"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4 inline-block fill-current align-middle"
                            viewBox="0 0 20 20"
                        >
                            <path
                                d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                            />
                        </svg>
                        <span class="align-middle ml-2"
                            >Nouveau sujet de discussion</span
                        >
                    </button>
                </div>
            </div>
            <div v-if="candidate" class="py-3">
                <button class="text-blue-600 underline text-sm cursor-pointer" @click="resetFilter">
                    <span v-if="candidate">ATTENTION, vous ne voyez que les messages de {{ candidate.full_name }}. </span>
                    Cliquez pour revenir à tous les messages.
                </button>
            </div>
            <div v-if="(!threads || !threads.data.length) && isCandidate">
                <div
                    class="border-l-2 border-blue-400 bg-blue-100 text-blue-600 px-4 py-3"
                >
                    Bienvenue dans votre espace de messagerie ! Il vous
                    permettra de contacter votre courtier privilégié à tout
                    moment durant ce projet. Vous avez une question ?
                    Rendez-vous dans la rubrique "FAQ", ou posez simplement
                    votre question
                    <span
                        class="underline cursor-pointer"
                        @click="openAddThreadModal"
                        >en commençant une conversation</span
                    >.
                </div>
            </div>
            <div
                class="border-l-2 border-blue-400 bg-blue-100 text-blue-600 px-4 py-3 my-3"
                v-if="threads && !threads.data.length && !isCandidate"
            >
                Aucun message : Sélectionnez
                <span
                    class="underline cursor-pointer"
                    @click="openAddThreadModal"
                    >"nouveau sujet de discussion"</span
                >
                pour contacter un candidat, un notaire, ou un courtier
            </div>

            <div class="mb-8 space-y-2">
                <div v-for="summary in displayPromotionsUnreadSummary" :key="summary.promotion_id" class="bg-white px-2 py-2 shadow-sm rounded w-100 hover:border-blue-200 border cursor-pointer w-full" @click="$store.dispatch('auth/changePromotion', summary.promotion_id);">
                    <div class="flex justify-between">
                        <div class="flex self-center">
                            <img
                                :src="summary.promotion.logo_full_path"
                                class="rounded-full w-8 h-8"
                            />
                            <div class="flex self-center pl-3 text-md font-semibold text-blue-600">
                                {{ summary.promotion.title }}
                            </div>
                        </div>
                        <div class="flex self-center flex-col">
                            <span
                                class="bg-red-500 rounded-full px-2 py-0 text-center object-right-top text-white text-xs ml-1"
                            >
                                {{ summary.unread_count }} messages non lus dans la promotion
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <threads-table :threads="threads"></threads-table>
            <add-thread-modal @update="getThreads"></add-thread-modal>
        </div>
    </main>
</template>

<script>
import ThreadsTable from "@/components/Messages/ThreadsTable";
import AddThreadModal from "@/components/Messages/modal/AddThreadModal";
import _ from "underscore";
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import Auth from "@/mixins/Auth";

export default {
    mixins: [Auth],
    components: {
        ThreadsTable,
        AddThreadModal,
    },
    data(){
        return {
            threads: null,
            search : "",
            promotionsUnreadSummary: {},
        };
    },
    async mounted() {
        await this.getThreads();
        this.$watch("params", () => this.getThreads());
    },
    computed: {
        ...mapGetters("app", ["formattedFilters"]),
        ...mapGetters("auth", ["currentPromotion", "user"]),
        ...mapGetters("message", ["currentActionTarget"]),
        ...mapState("loader", ["loading"]),

        params() {
            return { 
                ...this.formattedFilters,

                search: this.search,
                candidate_id: this.candidate?this.candidate.id:null,
            };
        },

        displayPromotionsUnreadSummary() {
            let summary = [];

            for (let promotion_id in this.promotionsUnreadSummary) {
                if (promotion_id == this.currentPromotion.id) continue;
                if (! this.promotionsUnreadSummary[promotion_id]) continue;

                let promotion = this.user.promotions.find((promotion) => promotion.id == promotion_id);

                // Cela ne devrait jamais arriver normalement.
                if (! promotion) continue;

                summary.push({
                    promotion_id,
                    promotion: promotion,
                    unread_count: this.promotionsUnreadSummary[promotion_id],
                });
            }

            return summary;
        },

        candidate(){
            if(this.currentActionTarget){
                return this.currentActionTarget.candidate;
            }
            return null;
        }
    },
    methods: {
        debounceSearch: _.debounce(function (e) {
            this.search = e.target.value;
        }, 300),

        openAddThreadModal() {
            this.$store.commit("message/SET_CURRENT_ACTION_TYPE", "addThread");
        },

        async getThreads() {
            this.$store.commit("loader/SET_LOADING", true);

            axios.get("/threads/promotions-unread-summary").then(({ data }) => {
                this.promotionsUnreadSummary = data;
            });

            let response = await axios.get("/threads", { params: this.params });

            this.threads = response.data;
            this.$store.commit("loader/SET_LOADING", false);
        },

        clearSearch() {
            this.search = null;
        },

        resetFilter(){
            this.$store.commit("message/SET_CURRENT_ACTION_TARGET", null);
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Messages";
            }
        },
    },     
};
</script>