<template>
    <div
        class="rounded bg-white border border-gray-300 px-4 py-2 mt-4 cursor-pointer"
    >
        <div class="flex justify-between hover:underline" @click="toggleAnswer">
            <div>
                <span
                    @click.stop="deleteQuestion(question)"
                    href="#"
                    class="mr-3 cursor-pointer"
                    v-if="isBroker"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 fill-current text-gray-700 hover:text-red-600 inline-block "
                        viewBox="0 0 20 20"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </span>
                <div class="inline-block align-middle cursor-pointer">
                    <span class="badge bg-red-500 px-2 text-white triangle-right mr-3">{{
            index
          }}</span>
                    <div class="inline-block text-blue-900 my-2">
                        {{ question.question }}
                    </div>
                </div>
            </div>
            <div class="my-auto">
                <button>
                    <svg
                        v-if="!isOpen"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 fill-current text-gray-700 hover:text-red-600 inline-block "
                        viewBox="0 0 20 20"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                    <svg
                        v-if="isOpen"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 fill-current text-gray-700 hover:text-red-600 inline-block "
                        viewBox="0 0 20 20"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
        <div v-show="isOpen">
            <p class="px-1 text-gray-700">
                {{ question.answer }}
            </p>
            <a
                class="px-1 underline text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                v-if="question.file"
                :href="question.file"
                target="_blank"
            >Voir le fichier</a>
        </div>
    </div>
</template>

<script>
import Auth from "@/mixins/Auth";
export default {
    mixins: [Auth],
    data() {
        return {
            isOpen: false,
        };
    },
    props: {
        question: {
            type: Object,
            default: null,
        },
    },
    computed: {
        index() {
            return this.$vnode.key + 1;
        },
    },
    methods: {
        emitClick() {
            this.$emit("click", this.question);
        },
        toggleAnswer() {
            this.isOpen = !this.isOpen;
        },
        deleteQuestion(question) {
            if (!confirm("Voulez-vous vraiment supprimer cette Question ?")){
                return false;
            }
            else if(this.question.global){    
                if(!confirm("Attention : Si vous supprimez cette question, elle sera supprimée pour toutes les autres promotions"))
                    return false;
            }

            this.$store.commit("loader/SET_LOADING", true);
            this.$store
                .dispatch("question/delete", question.id)
                .then((res) => {
                    this.$store.commit("loader/SET_LOADING", false);
                    if (res.status === 200) {
                        this.$emit("delete");
                    }
                })
                .catch((err) => {
                    this.$store.commit("loader/SET_LOADING", false);
                    this.$toast.open({
                        type: "error",
                        message: "Une erreur est survenue",
                    });
                    throw err;
                });
        },
    },
};
</script>

<style></style>
