<template>
    <tr
        @contextmenu.prevent="e => openApplicationContextMenu(e)"
        :class="{ 
            'bg-gray-200': application.hidden,
            'bg-blue-100' : isActive
        }"
    >
        <td style="padding: 0 0 0 5px !important">
            <div>
                <img
                    v-if="broker"
                    class="h-8 w-8 rounded-full inline-block max-w-none"
                    :src="broker.profile_picture"
                    :title="broker.full_name"
                />
            </div>
        </td>
        <td
            style="padding: 0 0 0 1px !important"
            class="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500"
        >
            <application-priority-badge
                v-if="application"
                :application="application"
                :tooltip="true"
                :click="false"
            />
        </td>
        <td
            class="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 underline"
            style="padding : 5px 3px !important"
        >
            <div class="flex items-center">
                <div class="text-left">
                    <candidate-badge :candidacy="application.candidacy1"></candidate-badge>
                    <candidate-badge
                        v-if="application.candidacy2"
                        :candidacy="application.candidacy2"
                    ></candidate-badge>
                </div>
            </div>
        </td>
        <td
            style="padding-left : 2px !important; padding-right : 10px !important;"
            class="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 font-medium"
        >
            <status-badge
                :status="application.status"
                :statusText="application.status_text"
            ></status-badge>
        </td>
        <td
            style="padding-left : 2px !important; padding-right : 2px !important;"
            class="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 font-medium"
        >
            {{ application.lot.building.title }}
        </td>
        <td
            @click="viewLot"
            class="cursor-pointer whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 font-medium underline"
            title="Voir le lot"
        >
            {{ application.lot.reference }}
        </td>
        <td
            class="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 font-medium"
        >
            {{ application.lot.nb_rooms }}
        </td>
        <td
            class="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 font-medium"
        >
            {{ application.lot.area_hab }}
        </td>
        <td
            class="whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500 font-medium text-right"
        >
            <span class="text-xs">{{ application.lot.floor.title }}</span>
        </td>
        <td
            class="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 font-medium text-right"
        >
            <div 
                :class="{'line-through' : application.negociated_price}"
            >
                {{ formatCHF(application.lot.price) }}
            </div>
            <div v-if="application.negociated_price" class="text-red-500" v-tooltip="'Prix négocié'">
                {{ formatCHF(application.negociated_price)}}
            </div>
        </td>
        <template v-if="!simpleView">

            <td class="border" style="padding-left : 3px !important; padding-right: 3px !important;">
                <span class="text-xs" :class="{ 'text-green-400': application.status_FR_checked }">
                    {{ application.status_FR }}
                </span>
            </td>
            <td class="border" style="padding-left : 3px !important; padding-right: 3px !important;">
                <span class="text-xs" :class="{ 'text-green-400': application.status_AC_checked }">
                    {{ application.status_AC }}
                </span>
            </td>
            <td class="border" style="padding-left : 3px !important; padding-right: 3px !important;">
                <span
                    class="text-xs"
                    :class="{
                        'text-green-400': application.status_FIN_checked,
                        'text-red-400': !application.status_FIN_checked,
                    }"
                >
                    {{ application.status_FIN }}
                </span>
            </td>
            <td class="border" style="padding-left : 3px !important; padding-right: 3px !important;">
                <span
                    class="text-xs"
                    :class="{
                        'text-green-400': application.status_SIGNPDV_checked,
                    }"
                >
                    {{ application.status_SIGNPDV }}
                </span>
            </td>
            <td class="border" style="padding-left : 3px !important; padding-right: 3px !important;">
                <span
                    class="text-xs"
                    :class="{ 'text-green-400': application.status_SIGN_checked }"
                >
                    {{ application.status_SIGN }}
                </span>
            </td>
        </template>
        <!-- a faire -->
        <td
            class="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 font-medium"
            style="padding : 2px !important;"
        >
            <div
                class="grid gap-1"
                :class="`grid-rows-${application.actions.length}`"
            >
                <action
                    v-for="task in application.actions"
                    :key="task.id"
                    :task="task"
                    taskTargetType="application"
                    @update="emitUpdate"
                ></action>
            </div>
        </td>
    </tr>
</template>

<script>
import StatusBadge from "@/components/SalesTunnel/StatusBadge";
import Action from "@/components/Actions/Action";
import { formatFloor, formatCHF } from "@/helpers/formatter";
import ApplicationPriorityBadge from "@/components/Candidates/ApplicationPriorityBadge";
import { mapState } from "vuex";
import Phone from "@/mixins/Phone";
import CandidateBadge from "@/components/Candidates/CandidateBadge";
import {VTooltip} from "v-tooltip";

export default {
    mixins: [Phone],
    directives: {
        tooltip: VTooltip,
    },
    data() {
        return {
            openContextMenu: false,
            contextMenuX: null,
            contextMenuY: null,
        };
    },
    components: {
        StatusBadge,
        Action,
        ApplicationPriorityBadge,
        CandidateBadge,
    },
    props: {
        application: {
            type: Object,
            required: true,
        },
        simpleView: {
            type : Boolean,
            default : false
        }
    },
    computed: {
        ...mapState("candidate", ["candidates"]),
        candidate() {
            return this.application.candidacy1.candidate;
        },
        broker(){
            return this.application.candidacy1.broker;
        },
        secondCandidate() {
            if(this.application.candidacy2)
                return this.application.candidacy2.candidate;
            return null;
        },
        isActive(){
            return this.$store.getters["application/currentActionTarget"] == this.application;
        }
    },
    methods: {
        formatCHF,
        formatFloor,
        openApplicationContextMenu(event) {
            if(!this.application.hidden){
                this.$store.commit("application/SET_CURRENT_ACTION_TYPE", "context");
                this.$store.commit("application/SET_CURRENT_ACTION_TARGET", this.application);
                this.$store.commit("application/SET_CONTEXT_EVENT", event);
            }else{
                this.closeContextMenu();
            }
        },
        closeContextMenu() {
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
            this.$store.commit("application/SET_CURRENT_ACTION_TARGET", null);
            this.$store.commit("application/SET_CONTEXT_EVENT", null);
        },
        viewLot() {
            this.$router.push({
                name: "lots",
                query: { lot: this.application.lot.public_id },
            });
        },
        emitUpdate(data){
            this.$emit("update", data);
        }
    },
};
</script>
