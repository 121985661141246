<template>
  <default-modal
    :open="open"
    @close="close"
    maxWidthClass="max-w-3xl"
    v-if="lot !== null"
  >
    <h3
      slot="header"
      class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
    >
      Historique du lot {{ lot.title }}
    </h3>
    <div slot="body">
      <div class="mt-2" v-if="hasHistory">
        <p class="text-sm leading-5 text-gray-700 text-left">
          Modifications réalisées sur le lot
          <span class="font-semibold">{{ lot.title }}</span> :
        </p>
        <div
          class="mt-6 align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  Par
                </th>
                <th
                  class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  Cible
                </th>
                <th
                  class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  De
                </th>
                <th
                  class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  A
                </th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white"
                v-for="action in lot.action_lots"
                :key="action.id"
              >
                <td
                  class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                >
                  {{ action.created_at | date_dmyhms }}
                </td>
                <td
                  class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                >
                  {{ action.user.email }}
                </td>
                <td
                  class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                >
                  {{ action.column }}
                </td>
                <td
                  class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                >
                  {{ action.from }}
                </td>
                <td
                  class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                >
                  {{ action.to }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-2" v-else>
        <p class="text-sm leading-5 text-gray-700 text-left">
          Aucune action n'a encore été réalisée sur le lot
          <span class="font-semibold">{{ lot.title }}</span
          >.
        </p>
      </div>
    </div>
    <div slot="footer" class="mt-12 text-right">
      <button
        @click="close"
        type="button"
        class="inline-flex justify-center w-48 rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
      >
        Fermer
      </button>
    </div>
  </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
export default {
    components: { DefaultModal },
    data() {
        return {};
    },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        lot: {
            type: Object,
            required: false
        }
    },
    computed: {
        hasHistory() {
            return this.lot.action_lots.length > 0;
        }
    },
    methods: {
        close() {
            this.$emit("close");
        }
    }
};
</script>
