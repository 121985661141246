<template>
    <div class="text-left mx-1" v-on-clickaway="close">
        <div class="inline-block w-full">
            <span class="rounded-md shadow-sm">
                <button
                    @click="open"
                    type="button"
                    class="text-left overflow-hidden inline-block w-full shadow rounded bg-gray-100 text-gray-700 text-xs font-semibold transition ease-in-out duration-150"
                >
                    <div
                        class="whitespace-no-wrap text-base shadow px-2 py-1 text-white truncate"
                        :class="backgroundColor"
                    >
                        <img
                            v-if="task.assigned_to"
                            class="mr-2 inline-block rounded-full align-top"
                            style="height : 30px"
                            :src="task.assigned_to.profile_picture"
                            :title="task.assigned_to.full_name"
                        />
                        <div
                            class="float-right rounded-full bg-white inline-flex justify-center items-center mr-2 text-gray-700"
                            style="height : 30px; width : 30px"
                            :title="target"
                        >
                            <svg
                                v-if="target == 'lot'"
                                class="h-4 w-4 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M8 20H3V10H0L10 0l10 10h-3v10h-5v-6H8v6z"
                                />
                            </svg>
                            <svg
                                v-if="target == 'candidate'"
                                class="h-4 w-4 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z"
                                />
                            </svg>
                            <svg
                                v-if="target == 'application'"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4 fill-current"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M4 18h12V6h-4V2H4v16zm-2 1V0h12l4 4v16H2v-1z"
                                />
                            </svg>
                        </div>
                        <span class="align-middle">{{ task.title }}</span>
                    </div>
                    <div
                        class="grid grid-cols-1 grid-flow-rows gap-1 p-2 border"
                    >
                        <div v-if="task.detail">
                            <span
                                class="h6-w-6 rounded-full bg-white inline-block mr-2"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    class="h-4 w-4 fill-current"
                                >
                                    <path
                                        d="M10 15l-4 4v-4H2a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-8zM5 7v2h2V7H5zm4 0v2h2V7H9zm4 0v2h2V7h-2z"
                                    />
                                </svg>
                            </span>
                            <div class="inline-block align-top">
                                {{ task.detail }}
                            </div>
                        </div>
                        <hr />
                        <div class="grid grid-cols-2">
                            <div>
                                <div class="inline-block align-top text-sm">
                                    <span v-if="target == 'lot'">{{
                                        "Lot n° " + task.target.reference
                                    }}</span>
                                    <span v-if="target == 'application'">{{
                                        mainCandidateFullName
                                    }}</span>
                                    <span
                                        v-if="
                                            target == 'candidate' && task.target
                                        "
                                        >{{ task.target.full_name }}</span
                                    >
                                </div>
                            </div>
                            <div
                                v-if="task.end_at"
                                class="text-right"
                            >
                                <span class="flex justify-end">
                                    <svg
                                        class="w-4 h-4 fill-current inline-block"
                                        v-if="task.type == 1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                                        />
                                    </svg>
                                    <svg
                                        class="w-4 h-4 fill-current inline-block"
                                        v-else
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            d="M16.32 7.1A8 8 0 1 1 9 4.06V2h2v2.06c1.46.18 2.8.76 3.9 1.62l1.46-1.46 1.42 1.42-1.46 1.45zM10 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12zM7 0h6v2H7V0zm5.12 8.46l1.42 1.42L10 13.4 8.59 12l3.53-3.54z"
                                        />
                                    </svg>
                                    <span class="pl-2">
                                        {{ task.end_at | date_dmyhm }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </button>
            </span>
        </div>
        <div
            v-show="isOpen"
            class="origin-top-right absolute mt-2 w-56 rounded-md shadow-lg z-10"
        >
            <div class="rounded-md bg-white shadow-xs">
                <div class="py-1">
                    <a
                        v-for="(subAction, i) in subActions"
                        :key="i"
                        @click.prevent="selectSubAction(subAction.action)"
                        href="#"
                        class="block pl-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-blue-900 focus:outline-none focus:bg-gray-100 focus:text-blue-900"
                        :class="subAction.color"
                    >
                        <svg
                            v-if="subAction.icon"
                            v-html="subAction.icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            class="h-4 w-4 fill-current mr-3 inline-block"
                        >
                            {{ subAction.icon }}
                        </svg>
                        {{ subAction.label }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import Task from "@/mixins/Task";
import { mapState } from "vuex";

export default {
    mixins: [clickaway, Task],
    computed: {
        ...mapState("candidate", ["candidates"]),
        mainCandidateFullName() {
            if (this.target !== "application") return null;

            return this.task.target.candidacy1.candidate.full_name;
        }
    }
};
</script>
