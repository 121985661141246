export function formatFloor(floor, short=false) {
    if (floor == 0) return "RDC";
    else if(floor == -1) return short ? "RDJ" : "Rez-de-jardin";
    else if(floor == -2) return short ? "Rez sup." : "Rez supérieur";
    else if(floor == -3) return short ? "Att." : "Attique";
    else return floor > 1 ? floor + "ème" : floor + "er";
}

export function formatCHF(val, float = false) {
    if (!val) {
        return 0;
    }
    if (!float) {
        val = parseInt(val);
    }

    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

export function concat(strings = []){
    var ret = "";
    for(let i in strings){
        if(Array.isArray(strings[i])){
            for(let j in strings[i]){
                ret += strings[i][j] + " ";
            }
        }
        else{
            ret += strings[i] + " ";
        }
    }

    return ret;
}
