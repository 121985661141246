import noUiSlider from "nouislider";
import { mapGetters } from "vuex";

export default {
    data(){
        return {
            isSliderInit: false,
            slider: {
                step: 10,
                current: {
                    min: 0,
                    max: 0,
                    displayedMin: 0,
                    displayedMax: 0,
                },
            },
        };
    },
    computed : {
        ...mapGetters("app", ["filters", "filtersCollections"])
    },
    methods : {
        //just reset values
        clearSlider(){
            this.resetSlider();
        },
        resetSlider() {
            if(this.sliderInstance.noUiSlider)
                this.sliderInstance.noUiSlider.destroy();
            
            this.createSlider();
        },
        createSlider() {
            this.$nextTick().then(() => {
                noUiSlider.create(this.$refs.slider, {
                    start: [
                        this.filtersCollections.price[0],
                        this.filtersCollections.price[1],
                    ],
                    step: this.slider.step,
                    connect: true,
                    range: {
                        min: this.filtersCollections.price[0],
                        max: this.filtersCollections.price[1]
                    }
                });

                this.$store.commit("app/SET_FILTER", {
                    key : "price",
                    value : this.filtersCollections.price
                });
                
                const slider = {
                    current : {
                        min : parseInt(this.filtersCollections.price[0]),
                        max : parseInt(this.filtersCollections.price[1]),
                        displayedMin : parseInt(this.filtersCollections.price[0]),
                        displayedMax : parseInt(this.filtersCollections.price[1]),
                    }
                };
                Object.assign(this.slider, slider);
                
                // on move cursor
                this.sliderInstance.noUiSlider.on(
                    "update",
                    this.updateSliderDisplayedValues
                );
                // on release cursor
                this.sliderInstance.noUiSlider.on(
                    "change",
                    this.applySliderFilters
                );

                this.isSliderInit = true;
            });
        },

        applySliderFilters() {
            const price = [
                parseInt(this.sliderInstance.noUiSlider.get()[0]),
                parseInt(this.sliderInstance.noUiSlider.get()[1])
            ];

            this.$store.commit("app/SET_FILTER", {
                key : "price",
                value : price
            });
        },
        updateSliderDisplayedValues(values, handle, unencoded) {
            if(unencoded){
                this.slider.current.displayedMin = parseInt(unencoded[0]);
                this.slider.current.displayedMax = parseInt(unencoded[1]);
            }
        },
    }
};