<template>
    <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="p-3">
            <div class="flex">
                <div
                    class="rounded-md p-3 shadow text-white icon-badge"
                    :class="bgColor"
                    style="height: 48px"
                >
                    <slot></slot>
                </div>
                <div class="ml-3 w-0 flex-1">
                    <dl>
                        <dt
                            class="text-left font-medium truncate"
                            :class="textColor"
                        >
                            <div class="flex justify-between">
                                <span class="leading-8 text-2xl">{{ text }}</span>
                                <span class="text-xl">{{ nb }} Lots</span>
                            </div>
                        </dt>
                        <dd class="text-right">
                            <div
                                class="text-xl leading-8 font-semibold"
                                :class="textColor"
                            >
                                {{ chf }} CHF
                            </div>
                            <div class="text-sm leading-5 font-semibold text-gray-600">
                                ({{ percentage }}%)
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["color", "text", "nb", "chf", "percentage"],
    computed: {
        textColor() {
            return this.color ? "text-" + this.color : "text-gray-500";
        },
        bgColor() {
            return this.color ? "bg-" + this.color : "bg-gray-500";
        }
    }
};
</script>
