<template>
    <div
        class="step relative text-center text-white font-medium text-md h-7 leading-7 cursor-pointer select-none hover:border-red-600 hover:bg-red-600 transition ease-in duration-100"
        :class="customClass"
        @click="setSearchStatus"
    >
        <div class="white-inset-arrow absolute w-0 h-0 inset-y-0 left-0"></div>
        <slot></slot>
        <div class="right-arrow absolute z-10 w-0 h-0 inset-y-0"></div>
    </div>
</template>

<script>
export default {
    props: {
        background: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number],
            required: true,
        },
        type: {
            type: String
        }
    },
    computed: {
        customClass() {
            if (!this.isActive)
                return "bg-" + this.background + " border-" + this.background;
            return "bg-red-600 border-red-600 text-white";
        },
        isActive() {        
            return this.$store.getters["app/filters"]["status_"+this.type] == this.value;
        },
    },
    methods: {
        setSearchStatus() {
            this.$store.dispatch("app/setStatus",{
                value: this.value, 
                type: "status_"+this.type
            });
        },
    },
};
</script>

<style scoped>
.step:first-child .white-inset-arrow {
    display: none !important;
}
.white-inset-arrow {
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-left: 12px solid #fff;
}
.right-arrow {
    right: -12px;
    border-left-width: 12px;
    border-style: solid;
    border-color: inherit;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
}
</style>
