<template>
    <div class="rounded shadow bg-white p-3">
        <div>
            <img :src="currentPromotion.logo_full_path" alt="Logo promotion" />
        </div>
        <div>
            <h3 class="text-xl leading-6 text-blue-900 mb-3">
                {{ currentPromotion.title }}
            </h3>
            <a :href="currentPromotion.url" target="_blank">
                <button class="btn shadow bg-blue-400 py-2 px-3 text-white">
                    Site de la promotion
                </button>
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters("auth", ["currentPromotion"])
    }
};
</script>

<style>
</style>