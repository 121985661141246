<template>
    <main>
        
        <div class="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="text-right mb-2">
                <button
                    v-if="isBroker"
                    @click="openAddCampaign"
                    class="pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 inline-block fill-current align-middle"
                        viewBox="0 0 20 20"
                    >
                        <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                    </svg>
                    <span class="align-middle ml-2">Ajouter une campagne</span>
                </button>
            </div>
            <campaign-table v-if="campaigns" :campaigns="campaigns"></campaign-table>
        </div>

        <ReportsTable />

        <add-campaign-modal
            @createdCampaign="fetchData"
        ></add-campaign-modal>
        <edit-campaign-modal
            
            @update="fetchData"
        ></edit-campaign-modal>
        <delete-campaign-modal
            @update="fetchData"
        ></delete-campaign-modal>
        <FilesList
            @update="fetchData"
        ></FilesList>
        
    </main>
</template>

<script>
import CampaignTable from "@/components/Campaigns/CampaignsTable";
import AddCampaignModal from "@/components/Campaigns/modals/AddCampaignModal";
import EditCampaignModal from "@/components/Campaigns/modals/EditCampaignModal";
import DeleteCampaignModal from "@/components/Campaigns/modals/DeleteCampaignModal";
import ReportsTable from "@/views/Reports/ReportsTable";
import { mapGetters } from "vuex";
import campaign from "@/api/campaign";
import FilesList from "@/components/Campaigns/modals/FilesList";

import Auth from "@/mixins/Auth";
import View from "@/mixins/View";

export default {
    mixins : [Auth, View],
    components: {
        CampaignTable,
        AddCampaignModal,
        EditCampaignModal,
        DeleteCampaignModal,
        FilesList,
        ReportsTable,
    },
    data() {
        return {
            campaigns : null,
        };
    },
    computed: {
        ...mapGetters("app", ["formattedFilters"]),
    },
    methods : {
        openAddCampaign(){
            this.$store.commit("campaign/SET_CURRENT_ACTION_TYPE", "addCampaign");
        },
        fetchData(){
            campaign.get(this.formattedFilters).then(res => {
                this.campaigns = res.data.data;
            });
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Marketing";
            }
        },
    },     
};
</script>

<style>
</style>