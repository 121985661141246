export default {
    computed : {
        phoneFields() {
            return ["phone", "phone_private", "phone_pro"];
        },
        mainCandidatePhone() {
            for (var phoneField of this.phoneFields) {
                if (this.candidate[phoneField])
                    return this.candidate[phoneField];
            }
            return "";
        },
        secondCandidatePhone() {
            if (this.secondCandidate) {
                for (var phoneField of this.phoneFields) {
                    if (this.secondCandidate[phoneField])
                        return this.secondCandidate[phoneField];
                }
            }
            return "";
        },
    }
};