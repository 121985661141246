<template>
  <div class="w-56 mr-4 mb-4" @click="toggleOpen">
    <video v-if="photo.is_video" controls>
        <source :src="photo.url_video">
    </video>
    <img v-else :src="photo.url_160h" height="160" />
    <div
      class="absolute w-56 rounded-md shadow-lg z-20"
      v-on-clickaway="toggleOpen"
      v-if="isOpen"
    >
      <div class="rounded-md bg-white shadow-xs">
        <div class="py-1">
          <a
            @click="removePhoto"
            href="#"
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-600 hover:bg-gray-100 hover:text-blue-900 focus:outline-none focus:bg-gray-100 focus:text-blue-900"
          >
            Retirer la {{ photo.is_video ? 'vidéo' : 'photo'}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    mixins: [clickaway],

    data() {
        return {
            isOpen: false
        };
    },

    components: {},

    props: {
        photo: {
            type: Object,
            required: true
        }
    },

    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen;
        },
        removePhoto() {
            this.$store
                .dispatch("promotion/removePhoto", this.photo)
                .then(() => {
                    this.$store.commit("loader/SET_LOADING", false);
                    this.$emit("update");
                })
                .catch(() => {
                    this.$store.commit("loader/SET_LOADING", false);
                });
        }
    }
};
</script>
