<template>
    <div class="bg-white rounded-sm shadow w-full">
        <informations v-if="currentView == 'informations'" />
        <candidatures v-if="currentView == 'candidatures'" />
        <commentaires
            v-if="currentView == 'commentaires'"
            @needReload="reload"
        />
        <messages v-if="currentView == 'messages'" @needReload="reload"/>
        <files v-if="currentView == 'documents'" @needReload="reload" />
    </div>
</template>

<script>
import Informations from "@/components/Candidates/container/Informations";
import Candidatures from "@/components/Candidates/container/Candidatures";
import Commentaires from "@/components/Candidates/container/Commentaires";
import Messages from "@/components/Candidates/container/Messages";
import Files from "@/components/Candidates/container/Files";

export default {
    components: {
        Informations,
        Candidatures,
        Commentaires,
        Messages,
        Files,
    },
    props: {
        currentView: {
            required: true,
            default: "informations",
        },
        candidate: {
            required: true,
            type: Object,
        },
    },
    methods: {
        reload() {
            this.$parent.init();
        },
    },
};
</script>

<style>
</style>