import { mapState } from "vuex";
import { fr } from "vuejs-datepicker/dist/locale";
import DefaultModal from "@/components/global/modals/DefaultModal";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        DefaultModal,
        Datepicker
    },
    data() {
        return {
            fr: fr,
            alreadySent: false,
            file: null,
            body: {
                comment : "",
                color : null,
                title: "",
                format: "",
                end_at: null,
                start_at: null,
                url: "",
                price: 0,
            },
            isDisabled: false,
        };
    },
    computed: {
        ...mapState("color", ["colors"]),
        startDisabledDates() {
            if (this.end_at) {
                return {
                    from: this.end_at
                };
            }
            return {
                to: null
            };
        },
        endDisabledDates() {
            if (this.body.start_at) {
                return {
                    to: this.body.start_at
                };
            }
            return {
                to: null
            };
        },
        isValid() {
            if(this.isDisabled)
                return false;
            const fields = ["title"];
            for (var i in fields) {
                if (this.body[fields[i]] == "" || this.body[fields[i]] == null)
                    return false;
            }
            return true;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        }
    },
    methods: {
        resetValues() {
            this.body.title = "";
            this.body.color = null;
            this.body.url = "";
            this.body.start_at = null;
            this.body.end_at = null;
            this.body.method = "";
            this.body.comment = "";
            this.body.price = 0;
            this.alreadySent = false;
        },
        changeFile(event) {
            this.file = event.target.files[0];
        }
    },
};

// (https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})
