import app from "@/api/app";
import commandApi from "@/api/command";

const state = {
    filters: null,
    // filters : {
    //     promotion : null,
    //     page: 0,
    //     orderBy: null,
    //     order: "desc",
    //     price : null,
    //     nb_rooms: null,
    //     floors: null,
    //     status: null,
    //     search: null,
    //     buildings: null,
    //     toKCHF: false,
    //     application: null,
    //     lot: null,
    //     candidat: null
    // },
    commands : [],
    filtersCollections : null,
    loading : true,
};

const getters = {
    ready(state){
        return state.filtersCollections && !state.loading;
    },
    filters(state){
        return state.filters;
    },
    formattedFilters(state){

        const nb_rooms = state.filters.nb_rooms && state.filters.nb_rooms.length != state.filtersCollections.nb_rooms.length ? 
            state.filters.nb_rooms.map(item => item.value) : null;

        const floors = state.filters.floors && state.filters.floors.length != state.filtersCollections.floors.length ? 
            state.filters.floors.map(item => item.value) : null;

        const buildings = state.filters.buildings && state.filters.buildings.length != state.filtersCollections.buildings.length ? 
            state.filters.buildings.map(item => item.value) : null;

        const toKCHF = state.filters.toKCHF ? 1 : null;

        const price = state.filters.price && 
            JSON.stringify(state.filters.price) != JSON.stringify(state.filtersCollections.price) ? 
            state.filters.price.map(val => val * 1000) : null;
        
        let filters =  {
            ...state.filters,
            nb_rooms,
            floors,
            buildings,
            "page[number]" : state.filters.page,
            toKCHF,
            price
        };

        if (filters.onlyLateTasks) {
            filters.onlyLateTasks = 1;
        } else {
            delete filters.onlyLateTasks;
        }

        for (var propName in filters) {
            if (filters[propName] === null || filters[propName] === undefined) {
                delete filters[propName];
            }
        }
        return filters;
    },
    searchCampaign(state){
        return state.filters.searchCampaign;
    }
};

const actions = {
    filters({ commit, rootGetters }) {
        commit("SET_LOADING", true);
        return app
            .getFilters({
                promotion: rootGetters["auth/preferences"].selected_promotion
            })
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_FILTERS", response.data);
                    commit("SET_FILTERS_COLLECTIONS", response.data);
                    commit("SET_LOADING", false);
                }
                return response;
            });
    },
    setOrderBy({commit, state}, column){
        if(column == state.filters.orderBy && state.filters.orderBy !== null){
            const order = state.filters.order == "desc" ? "asc" : "desc";
            commit("SET_FILTER", {
                key : "order",
                value : order
            });
        }
        else{
            commit("SET_FILTER", {
                key : "orderBy",
                value : column
            });
            commit("SET_FILTER", {
                key : "order",
                value : "desc"
            });
        }
    },
    setStatus({commit, dispatch}, objectStatus){
        let newStatus = null;
        if(objectStatus.value != state.filters[objectStatus.type] || state.filters[objectStatus.type] === null)
            newStatus = objectStatus.value;        
        commit("SET_FILTER", {
            key : objectStatus.type,
            value : newStatus
        });
        dispatch("setPage", null);
    },
    setPage({commit, state}, page){
        if(page !== state.filters.page){
            commit("SET_FILTER", {
                key : "page",
                value : page
            });
        }
    },
    resetViewFilters({commit, state}){
        commit("SET_LOADING", true);
        commit("SET_FILTERS", state.filtersCollections);
        commit("SET_LOADING", false);
    },
    executeCommand({commit}, command){
        commit("ADD_COMMAND", command.name);
        return commandApi.execute(command.name, command.params)
            .then( (res) => res);
    },
};

const mutations = {
    CLEAR_QUERY_FILTERS(state){
        state.filters = {...state.filters, lot : null, application : null, candidat : null, candidacy : null,};
    },
    SET_FILTERS_COLLECTIONS(state, value) {
        state.filtersCollections = value;
    },
    // all filters
    SET_FILTERS(state, value) {
        state.filters = value;
    },
    // only one filter 
    SET_FILTER(state, item) {
        state.filters = { ...state.filters, [item.key]: item.value };

        // Si nous changons un filtre qui n'est pas la page
        // nous voulons retourner à la première page.
        if (item.key !== "page") {
            state.filters.page = 1;
        }
    },
    SET_LOADING(state, value){
        state.loading = value;
    },
    ADD_COMMAND(state, value){
        state.commands = [...state.commands, value];
    },
};

export default{
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};