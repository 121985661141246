const state = {
    globalSearch: null,
};

const getters = {
    globalSearch: (state) => {
        return state.globalSearch;
    },
};

const actions = {};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
