<template>
    <default-modal
        v-if="open"
        @close="close"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Attribuer un lot à {{ currentActionTarget.candidate.full_name }}
        </h3>
        <div slot="body">
            <div class="mt-2 text-left">
                <label
                    for="lot"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Lot</label
                >
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <div class="inline-block relative w-36 mr-2">
                        <select-lot
                            defaultText="Sélectionner"
                            :lots="lotsList"
                            :initialValue="lotToAttribute"
                            @change="handleSelectLot"
                        ></select-lot>
                    </div>
                </div>
            </div>
            <div class="my-4 text-gray-800 text-sm flex items-center" v-if="currentPromotion.multi_agency && lot">
                <div v-if="lot.best_available_priority">
                    <span>Ce candidat sera en priorité</span>
                    <application-priority-badge
                        :priority="lot.best_available_priority"
                        :tooltip="false"
                        :click="false"
                    />
                    <span>sur ce lot</span>
                </div>
                <div v-if="! lot.best_available_priority">
                    Impossible d'attribuer ce lot, déjà 3 candidats sont positionnés.
                </div>
            </div>
            <div class="mt-2 text-left" v-if="!currentPromotion.multi_agency">
                <label
                    for="ecm-priority"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Priorité *</label
                >
                <badge-select
                    :collection="priorities"
                    badgeTextProp="value"
                    textProp="label"
                    defaultText="Sélectionner"
                    valueProp="value"
                    :value="priority"
                    @change="handlePriorityChange"
                ></badge-select>
            </div>
            <div class="mt-4 text-left">
                <label class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Ajouter un partenaire</label
                >
                <picture-select
                    ref="candidacySelect"
                    :disabled="!(all_candidacies && all_candidacies.length > 0)"
                    :searchProps="['candidate.full_name']"
                    :collection="filteredCandidacies"
                    textProp="candidate.full_name"
                    imgProp="candidate.profile_picture"
                    defaultText="Candidats"
                    valueProp="id"
                    @change="
                    val => {
                        updateSelectedCandidate(val);                        
                    }
                    "
                />
            </div>
        </div>
        
        <div slot="footer" class="mt-12 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-transparent px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5 text-white"
                        :class="submitButtonClass"
                        :disabled="! isValid"
                    >
                        Valider
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import SelectLot from "@/components/Lots/SelectLot";
import PictureSelect from "@/components/Forms/PictureSelect";
import BadgeSelect from "@/components/Forms/BadgeSelect";
import ApplicationPriorityBadge from "@/components/Candidates/ApplicationPriorityBadge";

export default {
    components: {
        DefaultModal, 
        SelectLot, 
        BadgeSelect, 
        PictureSelect,
        ApplicationPriorityBadge, 
    },
    data() {
        return {
            lotToAttribute: null,
            sendForm: false,
            priority: 9,
            candidacy_2_id : null,
            isDisabled: false,
        };
    },

    computed: {
        ...mapState("candidacy", ["currentActionTarget", "currentActionType","all_candidacies"]),
        ...mapState("application", ["priorities"]),
        ...mapGetters("auth", ["user","preferences", "currentPromotion"]),
        ...mapGetters("lot", ["allLots"]),

        open(){
            return this.currentActionTarget && (this.currentActionType === "attributeLot");
        },
        candidateHasApplications() {
            return (
                this.currentActionTarget.applications &&
                this.currentActionTarget.applications.length != 0
            );
        },
        isValid() {
            if(this.isDisabled)
                return false;
            if (this.currentPromotion.multi_agency) {
                return this.lot && this.lot.best_available_priority;
            } else {
                return this.lot;
            }
        },
        lot() {
            return this.allLots.find((lot) => lot.id === this.lotToAttribute);
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
        filteredCandidacies() {
            if(this.all_candidacies)
                return this.all_candidacies.filter((x) => (x.id != this.currentActionTarget.id && (this.user.agency.id != 1 || x.crm_validated || this.currentPromotion.crm_updated_at == null)));
            return [];
        },
        lotsList() {
            let clonedLots = [...this.allLots];
            if(this.currentActionTarget){
                let currentLots = this.currentActionTarget.applications.map(y => y.lot);
                //On masque les lots où il y a déjà une application du candidat pour "attribuer lot"
                if(currentLots){
                    let indexList = [];
                    currentLots.forEach(function(currentLot) {
                        clonedLots.forEach(function(clonedLot,i) {
                            if(clonedLot.id == currentLot.id)
                                indexList.push(i);
                        });
                    });
                        
                    if(indexList.length){
                        for (var i = clonedLots.length -1; i >= 0; i--){
                            if(indexList.includes(i))
                                clonedLots.splice(i,1);
                        }
                    }
                }
            }
            return clonedLots;
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        handleSelectLot(val) {
            this.lotToAttribute = val;
        },
        handlePriorityChange(val) {
            this.priority = val;
        },
        init() {
            if (this.currentActionTarget) {
                this.priority = this.currentActionTarget.priority;
            }
        },
        resetValues() {
            this.lotToAttribute = null;
            this.priority = 9;
            this.sendForm = false;
        },
        close() {
            this.resetValues();
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TYPE", null);
        },
        getData() {
            this.$store.dispatch("candidacy/getAll");
        },
        updateSelectedCandidate(val) {
            this.candidacy_2_id= val;
        },
        submit(){
            this.isDisabled = true;
            const command = {
                name : "attributeLot", 
                params : {
                    entity : "candidacy",
                    entity_id : this.currentActionTarget.id,
                    additionnal_fields : {
                        lot_id : this.lotToAttribute,
                        promotion_id : this.preferences.selected_promotion,
                        priority : this.priority,
                        candidacy_2_id : this.candidacy_2_id
                    }
                }};

            this.$store.dispatch("app/executeCommand",command).then(() =>{
                this.close();
                this.$emit("update");
            }).finally(() => {
                this.isDisabled = false;
            });
        }
    },
    watch: {
        currentActionTarget(newVal, oldVal) {
            if (newVal !== null) {
                if (newVal !== oldVal) {
                    this.init();
                }
            } else {
                this.resetValues();
            }
        },
        open(){
            this.getData();
        }
    },
};
</script>
