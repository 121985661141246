<template>
    <th
        class="sortable"
        :class="[classes, propsClass]"
        @click="setOrderBy"
    >
        <slot></slot>
    </th>
</template>

<script>
export default {
    props: {
        method: {
            required: false
        },
        column: {
            type: String,
            required: true
        },
        propsClass: {
            type: String,
            required: false
        }
    },
    methods: {
        setOrderBy() {
            this.$store.dispatch("app/setOrderBy", this.column);
        },
        getColumn(row) {
            return parseFloat(row[this.column]) || row[this.column];
        }
    },
    computed: {
        classes() {
            return {
                "sorted-asc": this.isActive && this.asc,
                "sorted-desc": this.isActive && !this.asc,
                underline: this.isActive
            };
        },
        isActive() {
            return this.$store.getters["app/filters"].orderBy === this.column;
        },
        asc(){
            return this.$store.getters["app/filters"].order === "asc";
        }
    }
};
</script>

<style scoped>
.sortable {
    background-image: url("/img/sortable.png");
    cursor: pointer;
    user-select: none;
    background-position: center right 5px;
    background-repeat: no-repeat;
}
.sortable.sorted-desc {
    color: rgb(42,67,101);
    font-weight: bold;
    background-image: url("/img/sorted-desc.png");
}
.sortable.sorted-asc {
    color: rgb(42,67,101);
    font-weight: bold;
    background-image: url("/img/sorted-asc.png");
}
</style>
