<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <div slot="header" class="text-left">
            <h3 class="text-xl leading-6 font-medium text-blue-900 mb-3">
                Envoyer « {{ currentActionTarget.candidate.full_name }} » vers une autre agence
            </h3>
            <div class="p-3 mb-3 bg-red-200 text-red-600 border-l-4 border-red-600">
                Attention ! Ce candidat sera déplacé dans la nouvelle agence et vous n'aurez plus la main sur son compte.
            </div>
        </div>
        <div slot="body" class="text-left">
            <base-select
                :collection="agencies"
                label-attribute="name"
                v-model="selectedAgency"
            />
            <div class="mt-10 sm:mt-12 sm:flex sm:flex-row-reverse">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="{
                            'bg-red-600 hover:bg-red-700 text-white cursor-pointer': isValid,
                            'bg-gray-300 text-gray-700 cursor-not-allowed': ! isValid,
                        }"
                    >
                        Envoyer vers la nouvelle agence
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Annuler
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import { mapGetters, mapState } from "vuex";
import BaseSelect from "@/components/Forms/BaseSelect";

export default {
    components: {
        DefaultModal,
        BaseSelect,
    },
    data() {
        return {
            selectedAgency: null,
            isDisabled: false,
        };
    },
    computed: {
        ...mapState("candidacy", ["currentActionTarget", "currentActionType"]),
        ...mapGetters("auth", ["currentPromotion", "user"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "changeAgency";
        },
        isValid() {
            if(this.isDisabled)
                return false;
            return this.selectedAgency != null;
        },
        agencies() {
            return this.currentPromotion.agencies.filter(agency => {
                return agency.id !== this.currentActionTarget.agency_id;
            });
        }
    },
    methods: {
        close() {
            this.selectedAgency = null;
            this.$emit("close");

            this.$store.commit("candidacy/SET_CURRENT_ACTION_TARGET", null);
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TYPE", null);
        },
        async submit() {
            this.isDisabled = true;
            await this.$store.dispatch("app/executeCommand", {
                name : "moveCandidacyToOtherAgency",
                params : {
                    entity : "candidacy",
                    entity_id : this.currentActionTarget.id,
                    additionnal_fields : {
                        agency_id: this.selectedAgency.id,
                    },
                },
            });

            this.$emit("update");
            this.close();
            this.isDisabled = false;
        }
    }
};
</script>

<style>
</style>