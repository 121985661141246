<template>
  <main class="px-4 bg-white">
    <div class="py-8 max-w-screen-xl mx-auto">
      <form class="space-y-8 divide-y divide-gray-200" @submit.prevent="submit">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Informations principales
              </h3>
            </div>

            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="title"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Nom
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="promotion.title"
                    type="text"
                    name="title"
                    id="title"
                    class="max-w-lg block w-full shadow-sm form-input"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="url"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  URL
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="promotion.url"
                    type="text"
                    name="url"
                    id="url"
                    class="max-w-lg block w-full shadow-sm form-input"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="url"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Courtier principal
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2" v-if="brokers.length">
                  <picture-select
                    ref="brokerSelect"
                    :searchProps="['full_name']"
                    :collection="brokers"
                    textProp="full_name"
                    imgProp="profile_picture"
                    defaultText="Courtier"
                    valueProp="id"
                    :value="promotion.broker_id"
                    @change="
                      val => {
                        updateSelectedMainBroker(val);
                      }
                    "
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="photo"
                  class="block text-sm font-medium text-gray-700"
                >
                  Image
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div class="flex items-center">
                    <div
                      class="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                    >
                      <div class="text-center">
                        <svg
                          class="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div
                          class="mt-1 overflow-hidden relative w-64 mt-4 mb-0"
                        >
                          <label
                            class="font-medium text-blue-secondary hover:text-blue-primary focus:outline-none focus:underline transition duration-150 ease-in-out"
                          >
                            <span class="text-sm cursor-pointer"
                              >Ajouter un fichier</span
                            >
                            <input
                              ref="logo"
                              type="file"
                              class="hidden"
                              name="logo"
                              @change="updateLogo"
                            />
                          </label>
                          <p v-if="promotion.logo">{{ promotion.logo.name }}</p>
                        </div>
                        <p class="mt-1 text-xs text-gray-500">
                          PNG, JPG jusqu'à 10MB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <button
              @click="$router.go(-1)"
              type="button"
              class="bg-white py-2 px-8 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Annuler
            </button>
            <button
              type="submit"
              :class="submitButtonClass"
              class="ml-3 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
// import promotion from "@/api/promotion";
import broker from "@/api/brokers";

import PictureSelect from "@/components/Forms/PictureSelect";

export default {
    components: {
        PictureSelect
    },

    data() {
        return {
            promotion: {
                title: "",
                url: "",
                broker_id: null
            },
            logo: null,
            brokers: [],
            isDisabled: false,
        };
    },

    mounted() {
        broker.get().then(({ data }) => {
            this.brokers = data.data;
        });
    },

    computed: {
        isValid() {
            if(this.isDisabled)
                return false;
            const fields = ["title", "url", "broker_id"];
            for (const field of fields) {
                if ([null, ""].includes(this.promotion[field])) {
                    return false;
                }
            }
            return true;
        },

        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        }
    },

    methods: {
        async submit() {
            let data = new FormData();

            for (var key in this.promotion) {
                if (this.promotion[key] !== null) {
                    data.append(key, this.promotion[key]);
                }
            }

            if (this.logo) {
                data.append("logo", this.logo);
            }

            if (this.isValid) {
                this.isDisabled = true;
                this.$store.commit("loader/SET_LOADING", true);

                try {
                    await this.$store.dispatch("promotion/create", data);

                    // La vue promotions/index utilise user.promotions, nous devons 
                    // donc recharger l'utilisateur pour obtenir la nouvelle promotion.
                    await this.$store.dispatch("auth/user");

                    this.$store.commit("loader/SET_LOADING", false);
                    this.$router.push({name : "promotions"});
                } catch (error) {
                    this.$store.commit("loader/SET_LOADING", false);
                    this.$toast.open({
                        message: "Une erreur est survenue",
                        type: "error"
                    });
                }
                this.isDisabled = false;
            }
        },

        updateSelectedMainBroker(val) {
            this.promotion.broker_id = val;
        },

        updateLogo(event) {
            this.promotion.logo = event.target.files[0];
        }
    }
};
</script>