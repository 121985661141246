<template>
  <div class="rounded-md h-full w-full overflow-y-auto border divide-y">
    <div
      class="
        bg-white
        px-2
        py-2
        shadow-sm
        flex
        items-center
        space-x-3
        hover:bg-gray-100
      "
      v-for="architect in architects"
      :key="architect.id"
    >
      <div class="flex flex-grow items-center">
        <img
          class="h-10 w-10 rounded-full"
          :src="architect.profile_picture"
          alt=""
        />
        <div class="flex-1 min-w-0 p-2">
          <p class="text-sm font-medium text-gray-900">
            {{ architect.full_name }}
          </p>
          <p class="text-xs font-semibold text-gray-600">
            {{ architect.email }}
          </p>
        </div>
        <div
          v-if="editMode && user.admin"
          class="
            flex
            items-center
            justify-center
            h-8
            w-8
            bg-red-500
            text-white
            rounded-full
            hover:shadow-lg
            cursor-pointer
            transition-all
            duration-300
          "
          v-tooltip="'Supprimer'"
          @click="removeArchitect(architect)"
        >
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VTooltip } from "v-tooltip";
import "v-tooltip/dist/v-tooltip.css";
import promotion from "@/api/promotion";
import { mapGetters } from "vuex";

export default {
    props: {
        architects: {
            type: Array,
            required: true,
        },
        promotion: {
            type: Object,
            required: false,
            default: null,
        },
        edit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters("auth", ["user"]),
        editMode() {
            return this.edit && this.promotion;
        },
    },
    directives: {
        tooltip: VTooltip,
    },
    methods: {
        removeArchitect(architect) {
            if (this.editMode) {
                this.$store.commit("loader/SET_LOADING", true);
                promotion.removeArchitect(this.promotion.id, architect.id).then(() => {
                    this.$emit("remove");
                    this.$store.commit("loader/SET_LOADING", false);
                });
            }
        },
    },
};
</script>

<style>
</style>