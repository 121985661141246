import lot from "@/api/lot";
import modals from "../partials/modals";

const state = {
    ...modals.state,
    lots: null,
    currentTask: null,
    filters: null,
    filtersCollections: null,
    allLots : [],
    floors: [],
    types: []
};

const getters = {
    ...modals.getters,
    lots: (state) => {
        return state.lots;
    },
    allLots: (state) => {
        return state.allLots;
    },
    currentTask: (state) => {
        return state.currentTask;
    },
    floors: (state) =>{
        return state.floors;
    },
    types: (state) =>{
        return state.types;
    }
};

const actions = {
    get({ commit, rootGetters }) {
        commit("loader/SET_LOADING", true, { root: true });

        return lot
            .get(rootGetters["app/formattedFilters"])
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_LOTS", response.data.data);
                }
                commit("loader/SET_LOADING", false, { root: true });
                return response;
            });
    },
    getAll({ commit, rootGetters }) {
        return lot
            .get({
                ...rootGetters["app/formattedFilters"],
                all : true
            })
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_ALL_LOTS", response.data.data);
                }
                return response;
            });
    },
    getFloors({ commit }) {
        return lot
            .getFloors()
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_FLOORS", response.data);
                }
                return response;
            });
    },
    getTypes({ commit }) {
        return lot
            .getTypes()
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_TYPES", response.data);
                }
                return response;
            });
    },
    candidateGet({ commit, rootGetters }, params) {
        return lot
            .candidateGet({...params, promotion: rootGetters["auth/currentPromotion"].id})
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_LOTS", response.data.data);
                }
                return response;
            });
    },
    notaryGet({ commit, rootGetters }) {
        return lot
            .notaryGet(rootGetters["auth/currentPromotion"].id)
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_LOTS", response.data.data);
                }
                return response;
            });
    },
    update(root, body) {
        return lot.update(body.id, body);
    },
    addAction({ commit, rootGetters }, body) {
        return lot
            .createAction(rootGetters["auth/currentPromotion"].id, body)
            .then((response) => {
                if (response.status === 200) {
                    commit("ADD_LOT_ACTION", response.data.data);
                }
                return response;
            });
    },
    updateAction({ commit, rootGetters }, body) {
        return lot
            .updateAction(
                rootGetters,
                body.action_id,
                body
            )
            .then((response) => {
                if (response.status === 200) {
                    commit("UPDATE_LOT_ACTION", response.data.data);
                }
                return response;
            });
    },
};

const mutations = {
    ...modals.mutations,
    
    SET_LOTS(state, value) {
        state.lots = value;
    },

    SET_ALL_LOTS(state, value) {
        state.allLots = value;
    },
    SET_FLOORS(state, value) {
        state.floors = value;
    },
    SET_TYPES(state, value) {
        state.types = value;
    },
    SET_CURRENT_TASK(state, value) {
        state.currentTask = value;
    },
    UPDATE_LOT_ACTION(state, value) {
        if (state.lots) {
            state.lots.forEach((lot, i) => {
                if (lot.id == value.target_id) {
                    state.lots[i].actions = [
                        ...state.lots[i].actions.filter(
                            (action) => action.id !== value.id
                        ),
                        value,
                    ];
                }
            });
        }
    },

    RESET(state) {
        state.lots = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
