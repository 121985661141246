<template>
  <main>
    <div class="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div>
            <div
                class="border-l-2 border-blue-400 bg-blue-100 text-blue-600 px-4 py-3"
            >
                Vous retrouverez ici les différents albums photos de la promotion
            </div>
            <span v-if="!photoGroups">
                <div
                class="border-l-2 border-blue-400 bg-blue-100 text-blue-600 px-4 py-3"
            >
                Aucune photo n'a été ajoutée pour cette promotion
            </div>
            </span>
            <candidate-progression-table
                v-if="photoGroups"
                :photoGroups="photoGroups"
                @openSlider="openSlider"
            ></candidate-progression-table>
            <slide-photo-modal
                @close="toggleSlidePhoto"
            ></slide-photo-modal>
        </div>
    </div>
    <div v-if="isDemo">
        <DemoVideo></DemoVideo>
    </div>
  </main>
</template>

<script>

import CandidateProgressionTable from "@/components/Progression/CandidateProgressionTable";
import SlidePhotoModal from "@/components/Progression/modals/SlidePhotoModal";
import DemoVideo from "@/components/Progression/DemoVideo";
import Auth from "@/mixins/Auth";
import View from "@/mixins/View";
//import { mapGetters } from "vuex";
import photo from "@/api/photos";

export default {
    mixins: [Auth, View],
    components: {
        CandidateProgressionTable,
        SlidePhotoModal,
        DemoVideo
    },

    data() {
        return {
            modals: {
                slidePhoto: false
            },
            photoGroups: null
        };
    },
    /*computed: {
        ...mapGetters("photo",["photoGroups"]),
    },*/
    methods: {
        toggleSlidePhoto() {
            this.modals.slidePhoto = !this.modals.slidePhoto;
        },
        
        openSlider(photogroup) {
            this.$store.commit("photo/SET_CURRENT_ACTION_TARGET", photogroup);
            this.$store.commit("photo/SET_CURRENT_ACTION_TYPE", "slidePhoto");
        },
        fetchData(){
            //this.$store.dispatch("photo/getPhotoGroups");
            photo.getPhotoGroups(this.formattedFilters).then(res => {
                this.photoGroups = res.data.data;
            });
        }
    }
};
</script>