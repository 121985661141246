<template>
    <div
        class="inline-flex items-center justify-center align-middle"
        @click="toggle"
    >
        <span
            role="checkbox"
            tabindex="0"
            aria-checked="false"
            class="group relative inline-flex items-center justify-center flex-shrink-0 h-5 w-12 cursor-pointer focus:outline-none"
        >
            <!-- On: "bg-indigo-600", Off: "bg-gray-200" -->
            <span
                aria-hidden="true"
                class="absolute inline-block h-4 w-12 mx-auto rounded-full transition-colors ease-in-out duration-300 border shadow"
                :class="firstSpanClass"
            ></span>
            <!-- On: "translate-x-5", Off: "translate-x-0" -->
            <span
                aria-hidden="true"
                class="absolute left-0 flex h-5 w-5 border border-gray-500 rounded-full bg-white shadow transform group-focus:shadow-outline group-focus:border-blue-400 transition-transform ease-in-out duration-300 items-center justify-center"
                :class="secondSpanClass"
            >
				<svg class="w-6 h-6" v-if="value" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
				<svg class="w-4 h-4" v-else fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</span>
        </span>
    </div>
</template>

<script>
export default {
    props: ["onColor", "offColor", "value"],
    methods: {
        toggle() {
            this.$emit("input", ! this.value);
        }
    },
    computed: {
        startTextClass() {
            return this.value ? "text-gray-500" : "text-gray-700 underline";
        },
        endTextClass() {
            return this.value ? "text-gray-700 underline" : "text-gray-500";
        },
        firstSpanClass() {
            return this.value ? "bg-"+this.onColor : "bg-"+this.offColor;
        },
        secondSpanClass() {
            return this.value ? "translate-x-8 text-"+this.onColor : "translate-x-0 text-"+this.offColor;
        }
    }
};
</script>
