export default {
    data() {
        return {
            sort: {
                column: null,
                subColumn: null,
                method: null,
                desc: true
            }
        };
    },
    methods: {
        setSortOptions(column, method, subColumn) {
            if (subColumn)
                this.sort.desc =
          column === this.sort.column &&
          subColumn === this.sort.subColumn &&
          !this.sort.desc;
            else this.sort.desc = column === this.sort.column && !this.sort.desc;
            this.sort.method = method;
            this.sort.column = column;
            this.sort.subColumn = subColumn;
        }
    }
};
