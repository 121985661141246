<template>
    <default-modal
        v-if="open"
        @close="close"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Retirer un candidat :
        </h3>
        <div slot="body">
            
            <div class="mt-4 text-left">
                <picture-select
                    ref="candidacySelect"
                    :disabled="!(this.application.candidacy1 !== null && this.application.candidacy2 !== null)"
                    :searchProps="['candidate.full_name']"
                    :collection="filteredCandidacies"
                    textProp="candidate.full_name"
                    imgProp="candidate.profile_picture"
                    defaultText="Candidats"
                    valueProp="id"
                    @change="
                    val => {
                        updateSelectedCandidate(val);                        
                    }
                    "
                />
            </div>
        </div>
        
        <div slot="footer" class="mt-12 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        :disabled="!isValid"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5 text-white cursor-pointer"
                        :class="submitButtonClass"
                    >
						<svg class="w-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
						Oui, retirer le candidat
					</button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Annuler</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import PictureSelect from "@/components/Forms/PictureSelect";

export default {
    components: {
        DefaultModal, 
        PictureSelect,
    },
    data() {
        return {
            application : null,
            removedPartner : null,
            isDisabled: false,
        };
    },

    computed: {
        ...mapState("application", ["currentActionTarget", "currentActionType"]),
        ...mapGetters("auth", ["preferences", "currentPromotion"]),

        open(){
            return this.currentActionTarget && (this.currentActionType === "removePartner");
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-red-500 hover:bg-red-600 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
        filteredCandidacies() {
            if(this.application.candidacy1 !== null && this.application.candidacy2 !== null)
                return [this.application.candidacy1,this.application.candidacy2];
            return [];
        },
        isValid(){
            if(this.isDisabled)
                return false;
            return this.removedPartner !== null ? true : false;
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.application = this.currentActionTarget;
        },
        close() {
            this.removedPartner = null;
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },
        getData() {
            this.$store.dispatch("candidacy/getAll");
        },
        updateSelectedCandidate(val) {
            this.removedPartner = val;
        },
        submit(){
            this.isDisabled = true;
            const command = {
                name : "detachCandidacy", 
                params : {
                    entity : "application",
                    entity_id : this.currentActionTarget.id,
                    additionnal_fields : {
                        promotion_id : this.preferences.selected_promotion,
                        candidacy_id : this.removedPartner
                    }
                }};

            this.$store.dispatch("app/executeCommand",command).then(() =>{
                this.close();
                this.$emit("update");
            }).finally(() => {
                this.isDisabled = false;
            });
        }
    },
    watch: {
        currentActionTarget(newVal, oldVal) {
            if (newVal !== null) {
                if (newVal !== oldVal) {
                    this.init();
                }
            } 
        },
        open(){
            this.getData();
        }
    },
};
</script>
