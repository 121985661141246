export default {
    props: {
        isOpen: {
            type: Boolean,
            required: true
        },
        x: {
            type: Number,
            default: 0
        },
        y: {
            type: Number,
            default: 0
        }
    },
    computed: {
        position() {
            const maxWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            const maxHeight = (window.innerHeight > 0) ? window.innerHeight : screen.height;
            const menuInRem = 14;
            const menuInPx =
                menuInRem *
                parseFloat(getComputedStyle(document.documentElement).fontSize);
            const menuHeightInPx = 36 * (this.actionsLength ? this.actionsLength : this.actions.length) + 8;

            let x = this.x;
            let y = this.y;

            if (menuInPx + x > maxWidth) {
                x = maxWidth - menuInPx;
            }

            if (menuHeightInPx + y > maxHeight) {
                y -= menuHeightInPx;
            }

            return `top: ${y}px; left: ${x}px;`;
        }
    }
};
