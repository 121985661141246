export default{
    created(){
        for(let i in this.$route.query){
            this.$store.commit("app/SET_FILTER", {
                key : i,
                value : this.$route.query[i]
            });
        }
        this.fetchData();
    },
    computed : {
        formattedFilters(){
            return this.$store.getters["app/formattedFilters"];
        }   
    },
    watch : {
        ready : {
            handler(newVal){
                if(newVal)
                    this.fetchData();
            },
            deep : true,
        },
        formattedFilters : {
            handler(newV, oldV){
                // si on ne stringify pas, les values sont toujours !=, et on trigger le reload pour rien
                if(JSON.stringify(newV) != JSON.stringify(oldV)){
                    this.fetchData();
                }
            },
            deep : true,
        },
    }
};