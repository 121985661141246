import question from "@/api/question";
import modals from "../partials/modals";

const state = {
    ...modals.state,
    questions: [],
};

const getters = {
    ...modals.getters,
    questions : state => {
        return state.questions;
    },
};

const actions = {
    get({ commit, rootGetters }) {
        return question
            .get(rootGetters["auth/currentPromotion"].id)
            .then(response => {
                if (response.status === 200) {
                    commit("SET_QUESTIONS", response.data.data);
                }
                return response;
            })
            .catch(error => {
                throw error;
            });
    },
    create({commit, rootGetters}, data){
        return question
            .create(rootGetters["auth/currentPromotion"].id, data).then(response => {
                if (response.status === 200) {
                    commit("ADD_QUESTION", response.data.data);
                }
                return response;
            })
            .catch(error => {
                throw error;
            });
    },
    delete({commit, rootGetters}, id){
        return question.delete(rootGetters["auth/currentPromotion"].id, id)
            .then(response => {
                if(response.status === 200){
                    const id = response.data.data;
                    commit("DELETE_QUESTION", id);
                }
                return response;
            })
            .catch(error => {
                throw error;
            });
    },
    closeModals({commit}){
        commit("SET_CURRENT_ACTION_TYPE", null);
        commit("SET_CURRENT_ACTION_TARGET", null);
    }
};

const mutations = {
    ...modals.mutations,
    SET_QUESTIONS(state, value) {
        state.questions = value;
    },
    ADD_QUESTION(state ,value){
        state.questions.push(value);
    },
    DELETE_QUESTION(state, value){
        state.questions = state.questions.filter(x => x.id != value);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
