<template>
  <main class="py-8 max-w-screen-xl mx-auto px-4">
    <div class="flex flex-row-reverse justify-between mb-2">
      <div class="flex">
        <router-link
          :to="{name: 'promotion.add'}"
          class="pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            class="h-4 w-4 inline-block fill-current align-middle mr-2"
          >
            <path
              d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
            ></path></svg
          ><span class="align-middle">Ajouter une promotion</span>
        </router-link>
      </div>
      <!---->
    </div>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
      <div
        v-for="promotion in this.user.promotions"
        :key="promotion.id"
        class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
      >
        <div class="flex-shrink-0">
          <img
            class="h-10 w-10 rounded-full"
            :src="promotion.logo_full_path"
            alt=""
          />
        </div>
        <div class="flex-1 min-w-0">
          <a
            
            @click="changePromotion(promotion.id)"
            class="focus:outline-none"
          >
            <span class="absolute inset-0" aria-hidden="true"></span>
            <p class="text-sm font-medium text-gray-900">
              {{ promotion.title }}
            </p>
            <!-- <p class="text-sm text-gray-500 truncate">
              {{ promotion.brokers.length }}
              {{ promotion.brokers.length > 1 ? "courtiers" : "courtier" }}
            </p> -->
            <div class="flex flex-row">
              <p class="text-sm text-gray-500 truncate" v-for="agency in promotion.agencies" :key="agency.id">
                {{ agency.name }}<span v-if="promotion.agencies.length > 1">,</span> 
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex";
import Auth from "@/mixins/Auth";

export default {
    mixins: [Auth],

    components: {},

    computed: {
        ...mapState("auth", ["user", "preferences"]),
    },

    methods: {
        changePromotion(val){
            this.$store.dispatch("auth/changePromotion", val);
            this.$router.push({name : "promotion.view"});
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Mes promotions";
            }
        },
    },       
};
</script>