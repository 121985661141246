<template>
    <div
        class="bg-white px-4 py-3 items-center justify-between border-t border-gray-200 sm:px-6"
    >
        <div
            class="flex sm:items-center sm:justify-between"
        >
            <div>
                <p class="text-sm leading-5 text-gray-700">
                    <span v-if="from && to">
                        Affichage de
                        <span class="font-medium">{{ from }}</span>
                        à
                        <span class="font-medium">{{ to }}</span>
                        des
                    </span>
                        <span class="font-medium">{{ total }}</span>
                        résultats
                </p>
            </div>
            <div>
                <nav class="relative z-0 inline-flex shadow-sm">
                    <button
                        type="button"
                        class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                        aria-label="Previous"
                        @click="setPage(currentPage - 1)"
                    >
                        <svg
                            class="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                    <button
                        @click="setPage(index)"
                        v-for="index in paginationSteps"
                        :key="index"
                        type="button"
                        class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                        :class="{ 'bg-gray-100': index === currentPage }"
                    >
                        {{ index }}
                    </button>
                    <button
                        type="button"
                        class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                        aria-label="Next"
                        @click="setPage(currentPage + 1)"
                    >
                        <svg
                            class="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lastPage: {
            type: Number,
            require: true,
        },
        from: {
            type: Number,
            require: true,
        },
        to: {
            type: Number,
            require: true,
        },
        total: {
            type: Number,
            require: true,
        },
    },
    computed: {
        currentPage(){
            return this.$store.getters["app/filters"].page ? this.$store.getters["app/filters"].page : 1;
        },
        paginationSteps() {
            if (this.lastPage < 8) {
                
                return Array.from({ length: this.lastPage }, (v, k) => k + 1);
            }
            if (
                (this.currentPage === 1 && this.lastPage >= 8) ||
                this.currentPage + 3 > this.lastPage
            ) {
                return [
                    1,
                    2,
                    3,
                    "...",
                    this.lastPage - 2,
                    this.lastPage - 1,
                    this.lastPage,
                ];
            }
            if (this.currentPage <= this.lastPage / 2 && this.lastPage >= 8) {
                return [
                    this.currentPage - 1,
                    this.currentPage,
                    this.currentPage + 1,
                    "...",
                    this.lastPage - 2,
                    this.lastPage - 1,
                    this.lastPage,
                ];
            }
            if (this.currentPage > this.lastPage / 2 && this.lastPage >= 8) {
                return [
                    1,
                    2,
                    3,
                    "...",
                    this.currentPage - 1,
                    this.currentPage,
                    this.currentPage + 1,
                ];
            }
            return [];
        },
    },
    methods: {
        setPage(page) {
            if(this.isValidPage(page))
                this.$store.dispatch("app/setPage", page);
        },
        isValidPage(page){
            if(page <= this.lastPage && page >= 1){
                return true;
            }
            return false;
        }
    },
};
</script>
