<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >Supprimer la campagne <span class="text-gray-700">{{currentActionTarget.title}}</span> ?</h3>
        <div slot="body">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5 bg-blue-400 hover:bg-blue-600 text-white cursor-pointer"
                    >Oui</button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Non</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";

export default {
    components: {
        DefaultModal
    },
    computed: {
        ...mapGetters("campaign", ["currentActionType", "currentActionTarget"]),
        open(){
            return this.currentActionType === "deleteCampaign" && this.currentActionTarget != null;
        }
    },
    methods: {
        close() {
            this.$store.dispatch("campaign/closeModals");
        },
        submit() {
            const body = {
                id : this.currentActionTarget.id,
            };

            this.$store.commit("loader/SET_LOADING", true);
            this.$store
                .dispatch("campaign/delete", body)
                .then((res) => {
                    this.close();
                    this.$store.commit("loader/SET_LOADING", false);
                    if(res.status === 200){
                        this.$emit("update");
                    }
                    else{
                        this.$toast.open({
                            message: res.data.error,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$store.commit("loader/SET_LOADING", false);
                    this.$toast.open({
                        message: err,
                        type: "error"
                    });
                });
        }
    }
};
</script>