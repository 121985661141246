<template>
  <default-modal
    v-if="open"
    @close="close"
  >
    <h3
      slot="header"
      class="text-left text-xl leading-6 font-medium text-gray-800 pb-5"
    >
      Ajouter une question
    </h3>
    <div slot="body" class="text-left w-90vw md:w-60vw">
      <div class="mt-4 text-left gap-6" v-if="!isDemo">
        <label
            for="aqm-global"
            class="text-sm font-medium leading-5 text-gray-700 mb-2"
        >Ajouter pour toutes les promotions</label>
        <input
            type="checkbox"
            id="aqm-global"
            :checked="body.global"
            v-model="body.global"
            class="ml-4 form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
        />
    </div>
      <div class="mt-4">
        <label
          for="aqm-question"
          class="block text-sm font-medium leading-5 text-gray-700 mb-2"
          >Question</label
        >
        <input
          type="text"
          id="aqm-question"
          class="form-input w-full"
          v-model="body.question"
        />
      </div>
      <div class="mt-4">
        <label
          for="aqm-answer"
          class="block text-sm font-medium leading-5 text-gray-700 mb-2"
          >Réponse</label
        >
        <textarea
          name="answer"
          id="aqm-answer"
          v-model="body.answer"
          rows="10"
          class="border border-gray-300 form-input resize-none"
        >
        </textarea>
      </div>
      <div class="text-left mt-4" v-if="!isDemo">
        <label
          for="file"
          class="block text-sm font-medium leading-5 text-gray-700 mb-2"
          >Fichier</label
        >
        <label
          for="file"
          class="w-48 h-8 bg-gray-500 text-white rounded border-gray-500 border px-4 flex items-center cursor-pointer"
        >
          <span v-if="!body.file">Ajouter un fichier...</span>
          <span class="truncate" v-else>{{ body.file.name }}</span>
        </label>
        <input type="file" id="file" ref="file" @change="changeFile" class="hidden" />
      </div>
    </div>
    <div slot="footer" class="mt-12 text-right">
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            @click="submit"
            type="button"
            :disabled="!isValid"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            :class="submitButtonClass"
          >
            Valider
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            @click="close"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Fermer
          </button>
        </span>
      </div>
    </div>
  </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import { mapGetters } from "vuex";
import Auth from "@/mixins/Auth";

export default {
    mixins: [Auth],
    components: {
        DefaultModal
    },
    data() {
        return {
            body: {
                file: null,
                question: "",
                answer: "",
                global: false
            },
            isDisabled: false,
        };
    },
    computed: {
        ...mapGetters("question", ["currentActionType"]),
        open(){
            return this.currentActionType === "addQuestion";
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
        isValid() {
            if(this.isDisabled)
                return false;
            return this.body.question.length && this.body.answer.length;
        }
    },
    methods: {
        initialData(){
            return {
                body: {
                    file: null,
                    question: "",
                    answer: "",
                    global: false
                },
            };
        },
        resetValues() {
            Object.assign(this.$data, this.initialData());
        },
        changeFile(event) {
            this.body.file = event.target.files[0];
        },
        submit() {
            this.isDisabled = true;
            let data = new FormData();
            for (var key in this.body) {
                data.append(key, this.body[key]);
            }

            this.$store
                .dispatch("question/create", data)
                .then(() => {
                    this.close();
                    this.$emit("create");
                    this.resetValues();
                })
                .catch(() => {
                    this.$toast.open({
                        type: "error",
                        message: "Une erreur est survenue"
                    });
                })
                .finally(() => {
                    this.isDisabled = false;
                });
        },
        close() {
            this.$store.dispatch("question/closeModals");
        },
        reset() {
            this.body.question = "";
            this.body.answer = "";
            this.body.file = null;
            this.$refs.file.value = "";
        }
    }
};
</script>

<style>
</style>