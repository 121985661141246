<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Modifier une campagne
        </h3>
        <div slot="body">
            <div class="mt-4 text-left">
                <label
                    for="title"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >Titre *</label>
                <input
                    type="text"
                    v-model="body.title"
                    class="form-input w-full"
                />
            </div>
            <div class="mt-4 text-left">
                <label
                    for="ecm-type"
                    class="text-sm font-medium leading-5 text-gray-700"
                >Plateforme *</label>
                <div class="grid grid-cols-5 mt-2 text-left">
                    <div class="col-span-4">
                        <input
                            type="text"
                            id="ecm-type"
                            v-model="body.method"
                            class="form-input inline-block"
                        />
                    </div>
                    <div class="col-span-1 pl-4">
                        <color-select
                            :selectedColor="body.color"
                            :collection="colors"
                            @change="
                color => {
                  this.body.color = color;
                }
              "
                        ></color-select>
                    </div>
                </div>
            </div>
            <div
                class="mt-4 grid grid-cols-3 text-left gap-4"
            >
                <div>
                    <label
                        for="lot"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Début</label>
                    <datepicker
                        :disabled-dates="startDisabledDates"
                        v-model="body.start_at"
                        input-class="form-input"
                        :language="fr"
                    ></datepicker>
                </div>
                <div>
                    <label
                        for="lot"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Fin</label>
                    <datepicker
                        :disabled-dates="endDisabledDates"
                        v-model="body.end_at"
                        input-class="form-input"
                        :language="fr"
                    ></datepicker>
                </div>
                <div>
                    <label
                        for="title"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Prix (CHF)</label>
                    <input
                        type="number"
                        v-model="body.price"
                        class="form-input w-full"
                    />
                </div>                
            </div>
            <div class="mt-4 text-left">
                <label
                    for="title"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >URL (lien)</label>
                <input
                    type="text"
                    v-model="body.url"
                    class="form-input w-full"
                />
            </div>
            <div class="mt-2 text-left cursor-pointer">
                <a @click.prevent="openShowFiles" class="text-blue-600 text-sm underline">
                    <svg 
                        class="fill-current h-5 w-5 inline-block"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd"></path>
                    </svg>
                    Voir / modifier documents ({{currentActionTarget.files.length}})
                </a>
            </div>
            <div class="text-left mt-4">
                <label
                    for="comment"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >   
                    <p>Commentaire :</p>
                    <textarea v-model="body.comment" id="comment" class="full-width form-input"></textarea>
                </label>
            </div>
            <div class="mt-8 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >
                        Valider
                    </button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import Datepicker from "vuejs-datepicker";
import Vue from "vue";
import CampaignModal from "@/mixins/CampaignModal";
import { mapGetters } from "vuex";
import ColorSelect from "@/components/Forms/ColorSelect";

export default {
    mixins: [CampaignModal],
    components: {
        DefaultModal,
        Datepicker,
        ColorSelect
    },
    computed: {
        ...mapGetters("campaign", ["currentActionTarget", "currentActionType"]),
        open(){
            return this.currentActionTarget != null && this.currentActionType === "editCampaign";
        }
    },
    methods: {
        openShowFiles(){
            this.$store.commit("campaign/SET_CURRENT_ACTION_TYPE", "showFiles");
        },
        close() {
            this.resetValues();
            this.$store.commit("campaign/SET_CURRENT_ACTION_TARGET", null);
        },
        init(){
            if (this.currentActionTarget) {
                this.body.title = this.currentActionTarget.title;
                if(this.currentActionTarget.start_at != null){
                    this.body.start_at = new Date(
                        Date.parse(this.currentActionTarget.start_at)
                    );
                }
                if(this.currentActionTarget.end_at != null){
                    this.body.end_at = new Date(
                        Date.parse(this.currentActionTarget.end_at)
                    );
                }
                this.body.url = this.currentActionTarget.url;
                this.body.price = this.currentActionTarget.price;
                this.body.method = this.currentActionTarget.method;
                this.body.color = this.currentActionTarget.color;
                this.body.comment = this.currentActionTarget.comment;
            }
        },
        submit() {
            if (this.isValid) {
                this.isDisabled = true;
                this.$store.commit("loader/SET_LOADING", true);
                this.$store
                    .dispatch("campaign/update", {
                        id: this.currentActionTarget.id,
                        title: this.body.title,
                        start_at: this.body.start_at !== null ? this.$options.filters.date_ymd(this.body.start_at) : null,
                        end_at: this.body.end_at !== null ? this.$options.filters.date_ymd(this.body.end_at) : null,
                        url: this.body.url,
                        method: this.body.method,
                        color: this.body.color,
                        price: this.body.price,
                        comment: this.body.comment,
                    })
                    .then(() => {
                        this.resetValues();
                        this.close();

                        this.$store.commit("loader/SET_LOADING", false);
                        this.$emit("update");
                    })
                    .catch(() => {

                        this.$store.commit("loader/SET_LOADING", false);
                        Vue.$toast.open({
                            message: "Une erreur est survenue",
                            type: "error"
                        });
                    })
                    .finally(() => {
                        this.isDisabled = false;
                    });
            }
        }
    },
    watch: {
        open(val) {
            if(val){
                this.init();
            }
        }
    }
};
</script>

<style>
</style>
