import axios from "axios";

export default {
    get(params) {
        return axios.get("notifications", {
            params: params
        });
    },

    count() {
        return axios.get("notifications/count");
    },

    markAsRead(id) {
        return axios.put(`notifications/${id}/read`);
    }
};
