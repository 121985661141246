<template>
	<div
		class="bg-white shadow overflow-hidden sm:rounded-lg border border-blue-900"
	>
		<div class="p-4 border-b border-gray-200 sm:px-6">
			<h3 class="text-lg leading-6 font-medium text-blue-900">Informations du lot {{lot.reference}}</h3>
			<p
				class="mt-1 max-w-2xl text-sm leading-5 text-gray-500"
			>Rappel des informations concernant votre lot.</p>
		</div>
		<div class="px-4 py-5 sm:p-0">
			<dl class="grid grid-cols-2">
				<div class="p-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
					<dt class="text-sm leading-5 font-medium text-gray-500">Promotion</dt>
					<dd
						class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
					>{{ lot.building.promotion.title }}</dd>
				</div>
				<div class="p-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
					<dt class="text-sm leading-5 font-medium text-gray-500">Immeuble</dt>
					<dd
						class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
					>{{ lot.building.title }}</dd>
				</div>
				<div class="p-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
					<dt
						class="text-sm leading-5 font-medium text-gray-500"
					>Nombre de lots associés (parking,cave,...)</dt>
					<dd
						class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
					>{{ lot.children.length }}</dd>
				</div>
				<div class="p-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
					<dt class="text-sm leading-5 font-medium text-gray-500">Superficie</dt>
					<dd
						class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
					>{{ lot.area_hab }}m²</dd>
				</div>
				<div class="p-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
					<dt
						class="text-sm leading-5 font-medium text-gray-500"
					>Nombre de pièces</dt>
					<dd
						class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
					>{{ lot.nb_rooms }}</dd>
				</div>

		

				<div
					class="p-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
				>
					<dt class="text-sm leading-5 font-medium text-gray-500">Prix</dt>
					<dd
						class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
						:class="{'line-through' : application.negociated_price}">
						{{ formatCHF(lot.total_price, false) }} CHF
					</dd>
					<dt v-if="application.negociated_price" class="text-sm leading-5 font-medium text-gray-500">Prix négocié</dt>
					<dd v-if="application.negociated_price" class="text-red-500" v-tooltip="'Prix négocié'">{{ formatCHF(application.negociated_price,false)}} CHF</dd>
				</div>
			</dl>
		</div>
	</div>
</template>

<script>
import { formatFloor, formatCHF } from "@/helpers/formatter";
export default {
    props : {
        lot : {
            type : Object,
            required : true,
            default : null,
        },
        application:{
            type : Object,
            required : true,
            default: null
        }
    },
    methods : {
        formatFloor,
        formatCHF,
    },
};
</script>

<style>

</style>