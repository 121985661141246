<template>
	<div class="p-2 border rounded-md fixed z-50 flex flex-col border shadow-md rounded-md overflow-hidden bg-white w-72" v-if="open" :style="position">
		<!-- Noms -->
		<div class="pb-1 border-b mb-1">
			<div class="text-sm text-gray-700 font-medium">
                {{main_candidate.full_name}}
                <vip-badge
                    v-if="main_candidate.vip"
                    class="ml-1"
                    :candidate="main_candidate"
                ></vip-badge>

            </div>
			<div v-if="second_candidate" class="text-xs text-gray-500">
                {{second_candidate.full_name}}
                <vip-badge
                    v-if="second_candidate.vip"
                    class="ml-1"
                    :candidate="second_candidate"
                ></vip-badge>
            </div>
		</div>
        <div v-if="main_candidate.comment" class="text-sm text-gray-500 flex">
            <div class="mr-1">
               <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            </div>
            <p>
                {{main_candidate.comment}}
            </p>
        </div>
        <div v-if="comments.length > 0" class="text-sm text-gray-500 py-1">
            <!-- <div class="text-sm underline mr-1">
               <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
            </div> -->
            <div v-for="(comment, index) in comments" :key="index" class="flex">
                <div class="flex w-5 items-start justify-center mr-1">
                    <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                </div>
                <div class="flex items-start align-top">
                    <p>{{comment.comment}}</p>
                </div>
            </div>
        </div>
        <!-- TODO REFACTO WITH CANDIDATURES (candidate / view) -->
        
        <div class="py-1 border-t">
            <div
                class="grid grid-cols-3 md:grid-cols-5 text-center gap-2"
            >
                <div>
                    <div
                        class="font-bold text-sm"
                        :class="{
                            'text-green-400':
                                tooltipApplication.status_FR_checked,
                            'text-gray-400': !tooltipApplication.status_FR_checked,
                        }"
                    >
                        FR
                    </div>

                    <div
                        class="rounded-full mx-auto h-5 w-5 text-white flex justify-center items-center"
                        :class="getBadgeClass(tooltipApplication.status_FR_checked)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-3 w-3"
                        >
                            <path
                                v-if="tooltipApplication.status_FR_checked"
                                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                            />
                            <path
                                v-else
                                d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
                            />
                        </svg>
                    </div>
                </div>
                <div>
                    <div
                        class="font-bold text-sm"
                        :class="{
                            'text-green-400':
                                tooltipApplication.status_AC_checked,
                            'text-gray-400': !tooltipApplication.status_AC_checked,
                        }"
                    >
                        AC
                    </div>
                    <div
                        class="rounded-full mx-auto h-5 w-5 text-white flex justify-center items-center"
                        :class="getBadgeClass(tooltipApplication.status_AC_checked)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-3 w-3"
                        >
                            <path
                                v-if="tooltipApplication.status_AC_checked"
                                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                            />
                            <path
                                v-else
                                d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
                            />
                        </svg>
                    </div>
                </div>
                <div>
                    <div
                        class="font-bold text-sm"
                        :class="{
                            'text-green-400':
                                tooltipApplication.status_FIN_checked,
                            'text-gray-400': !tooltipApplication.status_FIN_checked,
                        }"
                    >
                        FIN
                    </div>
                    <div
                        class="rounded-full mx-auto h-5 w-5 text-white flex justify-center items-center"
                        :class="getBadgeClass(tooltipApplication.status_FIN_checked)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-3 w-3"
                        >
                            <path
                                v-if="
                                    tooltipApplication.status_FIN_checked
                                "
                                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                            />
                            <path
                                v-else
                                d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
                            />
                        </svg>
                    </div>
                </div>
                <div>
                    <div
                        class="font-bold text-sm"
                        :class="{
                            'text-green-400':
                                tooltipApplication.status_SIGNPDV_checked,
                            'text-gray-400': !tooltipApplication.status_SIGNPDV_checked,
                        }"
                    >
                        PDV
                    </div>
                    <div
                        class="rounded-full mx-auto h-5 w-5 text-white flex justify-center items-center"
                        :class="getBadgeClass(tooltipApplication.status_SIGNPDV_checked)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-3 w-3"
                        >
                            <path
                                v-if="
                                    tooltipApplication.status_SIGNPDV_checked
                                "
                                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                            />
                            <path
                                v-else
                                d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
                            />
                        </svg>
                    </div>
                </div>
                <div>
                    <div
                        class="font-bold text-sm"
                        :class="{
                            'text-green-400':
                                tooltipApplication.status_SIGN_checked,
                            'text-gray-400': !tooltipApplication.status_SIGN_checked,
                        }"
                    >
                        SIGN
                    </div>
                    <div
                        class="rounded-full mx-auto h-5 w-5 text-white flex justify-center items-center"
                        :class="getBadgeClass(tooltipApplication.status_SIGN_checked)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-3 w-3"
                        >
                            <path
                                v-if="
                                    tooltipApplication.status_SIGN_checked
                                "
                                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                            />
                            <path
                                v-else
                                d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-xs text-blue-600 text-center"
                >Cliquer pour voir dans le tunnel de vente
        </div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import VIPBadge from "@/components/Candidates/VIPBadge";

export default {
    components : {"vip-badge" : VIPBadge},
    computed : {
        ...mapState("application", ["tooltipApplication", "tooltipEvent"]),
        open(){
            return this.tooltipApplication != null && this.tooltipEvent != null;
        },
        comments(){
            let comm = this.tooltipApplication.user_comments || [];
            if(this.tooltipApplication.second_candidate){
                let commSecond = this.tooltipApplication.second_candidate.user_comments || [];
                comm = [...comm, ...commSecond];
            }
            return comm;
        },
        // REFACTO WITH CONTEXT
        position(){
            let position, transform = {};
            transform.transform = "";
            
            if(this.tooltipEvent){
                position = {
                    top : `${this.tooltipEvent.clientY}px`,
                    left : `${this.tooltipEvent.clientX}px`,
                };
                if(this.tooltipEvent.clientX > window.innerWidth/2){
                    transform.transform = "translateX(-100%)";
                }
                if(this.tooltipEvent.clientY > window.innerHeight/2){
                    transform.transform += "translateY(-100%)";
                }
            }
            else{
                position = {
                    top : 0,
                    left : 0,
                };
            }
            return {
                ...position,
                ...transform,
            };
        },
        main_candidate(){
            return this.tooltipApplication.candidacy1.candidate;
        },
        second_candidate(){
            if(this.tooltipApplication.candidacy2)
                return this.tooltipApplication.candidacy2.candidate;
            return null;
        }
    },
    methods : {
        getBadgeClass(isChecked) {
            return isChecked ? "bg-green-400" : "bg-gray-400";
        },        
    }
};
</script>

<style>

</style>