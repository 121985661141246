<template>
    <form ref="form" @submit.prevent="submit">
        <default-modal
            v-if="open"
            @close="close"
            maxWidthClass="max-w-3xl overflow-visible"
        >
            <h3 slot="header" class="text-left text-xl leading-6 font-medium text-gray-800 pb-5">
                <div v-if="file">
                    Modifier le fichier
                    <span class="font-bold text-blue-900">{{ file.name }}</span>
                </div>

                <div v-if="! file">
                    Ajouter un fichier
                </div>
            </h3>
            <div slot="body" class="text-left space-y-4">
                <div class="flex space-x-4">
                    <div class="w-full space-y-2">
                        <label class="block text-sm font-medium leading-5 text-gray-700">Dossier</label>
                        <base-select
                            :collection="filesFolders"
                            v-model="form.folder"
                            defaultText="Sans dossier"
                            keyAttribute="id"
                            labelAttribute="name"
                            name="folder_id"
                            class="w-full"
                        />
                    </div>
                    <div class="w-full space-y-2">
                        <label class="block text-sm font-medium leading-5 text-gray-700">Type de fichier</label>
                        <base-select
                            :collection="filesTypes"
                            v-model="form.type"
                            defaultText="Sélectionner…"
                            keyAttribute="id"
                            labelAttribute="name"
                            name="type_id"
                            class="w-full"
                        />
                    </div>
                </div>

                <div>
                    <label for="name" class="block text-sm font-medium leading-5 text-gray-700 mb-2">Nom</label>
                    <input type="text" id="name" name="name" v-model="form.name" class="form-input w-full" />
                </div>
                <div class="space-y-2">
                    <label for="file" class="block text-sm leading-5 font-medium text-gray-700">
                        <span v-show="file">Modifier le fichier (laisser vide pour ne pas changer)</span>
                        <span v-show="! file">Fichier</span>
                    </label>
                    <input type="file" id="file" name="file" :required="! file" />
                </div>
                
                <div>
                    <div
                        class="text-left"
                        id="access_group"
                    >
                        <div class="grid grid-cols-2">
                            <div>
                                <div class="text-sm font-medium leading-5 text-gray-700 mb-2">Droits d'accès au fichier :</div>
                                <ul class="pl-2 text-gray-800">
                                    <li v-for="(label, accessType) in {
                                        'access_candidate': 'Candidat',
                                        'access_promoter': 'Promoteur',
                                        'access_notary': 'Notaire',
                                        'access_investor':'Investisseur',
                                    }" :key="accessType">
                                        <input
                                            type="checkbox"
                                            :name="accessType"
                                            :id="accessType"
                                            class="mr-3 form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                            v-model="form[accessType]"
                                        />
                                        <label :for="accessType">{{ label }}</label>
                                    </li>
                                </ul>
                            </div>
                            <div :class="{ 'opacity-0': ! form.access_candidate }" class="transition-opacity">
                                <div>
                                    <label
                                        for="ecm-status"
                                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                                    >Statut minimum requis (vide= Prospect)</label>
                                    <badge-select
                                        :collection="candidateStatuses"
                                        :value="min_status"
                                        defaultText="Sélectionner"
                                        valueProp="value"
                                        name="candidate_min_status"
                                        v-model="min_status"
                                        @change="handleStatusMin"
                                    ></badge-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                slot="footer"
                class="mt-12 text-right"
            >
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                        <button type="submit"
                            :disabled="!isValid"
                            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                            :class="{
                                'bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer': isValid,
                                'bg-gray-300 text-gray-700 cursor-not-allowed': ! isValid,
                            }"
                        >Valider</button>
                    </span>
                    <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button
                            @click="$emit('change', false)"
                            type="button"
                            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >Fermer</button>
                    </span>
                </div>
            </div>
        </default-modal>
    </form>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import { fr } from "vuejs-datepicker/dist/locale";
import BadgeSelect from "@/components/Forms/BadgeSelect";
import BaseSelect from "@/components/Forms/BaseSelect";
import Auth from "@/mixins/Auth";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
    mixins: [ Auth ],
    
    components: {
        DefaultModal,
        BadgeSelect,
        BaseSelect,
    },

    model: {
        prop: "open",
        event: "change",
    },
    
    props: ["open", "file", "filesTypes", "filesFolders"],
    
    data() {
        return {
            fr: fr,
            form: {
                name: "",
                type: null,
                folder: null,
                access_candidate: false,
                access_promoter: false,
                access_notary: false,
                access_investor: false,
                candidate_min_status: null,
            },
            min_status: null,
            isDisabled: false,
        };
    },

    mounted() {
        this.$watch("open", () => this.init());
    },

    computed: {
        ...mapGetters("auth", ["currentPromotion"]),

        candidateStatuses() {
            return this.$store.getters["candidate/statuses"];
        },
        isValid() {
            if(this.isDisabled) return false;
            return this.form.name && this.form.type;
        },

    },

    methods: {
        close() {
            this.$emit("change", false);
        },

        handleStatusMin(val) {
            this.min_status = val;
        },

        init() {
            //console.log("init", this.file);
            this.form.name = this.file ? this.file.name : "";
            this.form.type = this.file ? this.file.type : null;
            this.form.folder = this.file ? this.file.folder : null;
            this.form.access_candidate = this.file ? this.file.access_candidate : false;
            this.form.access_promoter = this.file ? this.file.access_promoter : false;
            this.form.access_notary = this.file ? this.file.access_notary : false;
            this.form.access_investor = this.file ? this.file.access_investor : false;
            //this.form.candidate_min_status = this.file ? this.file.candidate_min_status : 0;
            this.min_status = this.file ? this.file.candidate_min_status : 0;
        },

        async submit() {
            let data = new FormData(this.$refs.form);
            data.append("candidate_min_status", this.min_status); //obligatoire sinon bug car form.candidate_min_status = toujours 0

            this.isDisabled = true;
            this.$store.commit("loader/SET_LOADING", true);

            try {
                if (this.file) {
                    let response = await axios.post(`/promotions/${this.currentPromotion.id}/files/${this.file.id}`, data);
                    this.$emit("modified", response.data);
                } else {
                    let response = await axios.post(`/promotions/${this.currentPromotion.id}/files`, data);
                    this.$emit("added", response.data);
                }
                this.close();
            } finally {
                this.isDisabled = false;
                this.$store.commit("loader/SET_LOADING", false);
            }
        },
    },
};
</script>
