<template>
  <div class="ml-3 relative" v-on-clickaway="closeNotificationDropdown">
    <div>
      <button
        @click="open = !open"
        type="button"
        class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        id="user-menu-button"
        aria-expanded="false"
        aria-haspopup="true"
      >
        <span class="sr-only">Open user menu</span>
        <svg
          class="text-gray-600 w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          ></path>
        </svg>
      </button>
      <span
        v-if="hasUnreadNotifications"
        class="top-0 right-0 absolute px-1 py-1 -mr-1 text-2xs font-bold leading-none text-red-100 bg-red-600 rounded-full"
        >{{ unreadNotifications.length }}</span
      >
    </div>

    <div
      v-show="open"
      class="z-50 absolute top-0 right-0 w-100 bg-white rounded-lg shadow-lg mt-12 -mr-1 block py-1 overflow-hidden"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu-button"
      tabindex="-1"
    >
      <div
        class="mx-4 py-3 border-b border-gray-300 flex justify-between items-center"
      >
        <p class="text-lg text-gray-600">Notifications</p>
        <p class="text-sm text-gray-600">{{ unreadNotifications.length }}</p>
      </div>
      <a
        v-for="notification in unreadNotifications"
        :key="`notification-${notification.id}`"
        href="#"
        class="block py-2 hover:bg-gray-100"
      >
        <div
          class="flex justify-between items-center mx-4 border-b border-gray-100"
        >
          <div class="flex flex-col">
            <p class="text-base text-gray-700">{{ notification.title }}</p>
            <p class="text-sm text-gray-600">{{ notification.description }}</p>
            <p class="text-xs text-gray-500">
              {{ notification.created_at | date_dmyhm }}
            </p>
          </div>
          <div>
            <button
              @click="markAsRead(notification)"
              class="text-gray-500 hover:text-gray-700 rounded-full border border-gray-200 hover:border-gray-400 p-1 focus:outline-none"
            >
              <svg
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin as clickaway } from "vue-clickaway";

export default {
    mixins: [clickaway],
    data() {
        return {
            open: false
        };
    },

    mounted() {
        this.getNotifications();
        // get notifications every 60 seconds, can be replace by websocket (pusher)
        window.setInterval(() => {
            this.getNotifications();
        }, 60000);
    },

    computed: {
        ...mapState("notification", ["unreadNotifications"]),

        hasUnreadNotifications() {
            return this.unreadNotifications.length > 0;
        }
    },

    methods: {
        getNotifications() {
            this.$store.dispatch("notification/getUnreadNotifications");
        },

        markAsRead(notification) {
            this.$store.dispatch("notification/markNotificationAsRead", notification);
        },

        closeNotificationDropdown() {
            this.open = false;
        },
    }
};
</script>