<template>
    <div class="bg-white shadow overflow-hidden sm:rounded-md p-4 mb-4">
        <p>
            {{ photoGroup.name }} <span class="text-sm text-gray-600">({{ photoGroup.date | date_ymd }})</span>
            <span class="text-sm" v-if="photoGroup.lot"> - {{ photoGroup.lot.full_title }}</span>
        </p>
        <div class="flex flex-wrap mt-8">
            <candidate-photo-row
                v-for="photo in photoGroup.photos"
                :key="photo.id"
                :photo="photo"
                @openSlider="openSlider"
            ></candidate-photo-row>
        </div>
    </div>
</template>

<script>
import CandidatePhotoRow from "@/components/Progression/CandidatePhotoRow";

export default {
    data() {
        return {};
    },
    components: {
        CandidatePhotoRow
    },
    props: {
        photoGroup: {
            type: Object,
            required: true
        }
    },
    computed: {},
    methods: {
        openSlider() {
            this.$emit("openSlider", this.photoGroup);
        }
    }
};
</script>
