<template>
    <div class="flex flex-row space-x-2" v-if="!isDemo">
        <div
            class="flex w-80 flex-col bg-white border-2 border-gray-300 border-dashed rounded-md px-4 py-2"
            v-cloak
            @drop.prevent="addFilesFromDrop"
            @dragover.prevent
        >
            <label
                class="flex w-full flex-col justify-center items-center text-gray-500 cursor-pointer"
            >
                <h2 class="text-sm font-medium mb-2 inline">Déposez un ou plusieurs fichier(s)</h2>
                <svg
                    class="w-12 h-12"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                    />
                </svg>
                <input type="file" class="hidden" :multiple="multiple" @change="addFilesFromClick" />
            </label>
        </div>
        <div>
            <ul class="flex flex-col w-full flex-wrap space-y-1 bg-white">
                <li
                    class="shadow"
                    
                    :key="getFileName(file)"
                    v-for="(file) in files"
                    :focus="true"
                    :class="{
                        'px-3 py-2' : !isEditingFile(file),
                        'px-1 py-1 border border-blue-400' : isEditingFile(file)
                    }"
                >
                    <div class="flex">
                        <div class="flex-grow">
                            <span
                                class="w-full text-sm text-gray-700"
                                v-if="!isEditingFile(file)"
                            >{{ getFileName(file) }}</span>
                            <span v-else class="w-full text-sm text-gray-700">
                                <input
                                    type="text"
                                    @keypress="(e)=>{
                                    if(e.which == 13)
                                        edit(file)
                                    }"
                                    class="form-control border outline-none"
                                    :value="getFileName(file)"
                                    @input="(e)=> editValue = e.target.value"
                                    :style="{
                                        'width' : ((getFileName(file).length + 1) * 7) + 'px'
                                    }"
                                />
                            </span>
                            <span
                                class="ml-1 bg-blue-100 text-blue-600 rounded-full text-xs px-1"
                            >{{ formatBytes(file.size) }}</span>
                        </div>
                        <div class="pl-2">
                            <div class="flex flex-row space-x-1" v-if="isEditingFile(file)">
                                <button
                                    @click="edit(file)"
                                    class="flex justify-center items-center h-6 w-6 rounded-full bg-green-500 hover:bg-green-700 transition-colors duration-200 text-white focus:outline-none"
                                    title="Confirmer"
                                >
                                    <svg
                                        class="w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                </button>
                                <button
                                    @click="closeEditMode"
                                    class="flex justify-center items-center h-6 w-6 rounded-full bg-red-500 hover:bg-red-700 transition-colors duration-200 text-white focus:outline-none"
                                    title="Annuler"
                                >
                                    <svg
                                        class="w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div class="flex flex-row space-x-1" v-else>
                                <button
                                    @click="(e) => openEditMode(file)"
                                    title="Edit"
                                    class="flex justify-center items-center h-6 w-6 rounded-full bg-blue-900 text-white focus:outline-none"
                                >
                                    <svg
                                        class="w-4 h-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                        />
                                    </svg>
                                </button>
                                <button
                                    @click="removeFile(file)"
                                    title="Remove"
                                    class="flex justify-center items-center h-6 w-6 rounded-full bg-red-500 text-white hover:bg-red-600 transition-colors duration-200 focus:outline-none"
                                >
                                    <svg
                                        class="w-4 h-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Auth from "@/mixins/Auth";
export default {
    mixins: [Auth],
    props: {
        multiple: {
            default: false
        }
    },
    data: function() {
        return {
            files: [],
            renames: {},
            editKey: null,
            editMode: false,
            editValue: ""
        };
    },
    computed: {
        hasFiles() {
            return this.files.length === 0;
        }
    },
    methods: {
        openEditMode(file) {
            this.editMode = true;
            this.editKey = file.name;
            this.editValue = this.getFileName(file);
            this.$emit("openEdit");
        },
        closeEditMode() {
            this.editKey = null;
            this.editValue = "";
            this.editMode = false;
            this.$emit("closeEdit");
        },
        edit(file) {
            this.renames[file.name] = this.editValue;
            this.closeEditMode();
        },
        addFilesFromClick(e) {
            let selectedFiles = e.target.files;
            if (!selectedFiles) return;
            this.addFiles(selectedFiles);
            e.target.value = "";
        },
        addFilesFromDrop(e) {
            let droppedFiles = e.dataTransfer.files;
            if (!droppedFiles) return;

            this.addFiles(droppedFiles);
        },
        addFiles(files) {
            [...files].forEach(f => {
                if (this.files.findIndex(file => file.name === f.name) < 0) {
                    this.files.push(f);
                    this.renames[f.name] = f.name;
                } else {
                    this.$toast.open({
                        type: "warning",
                        message: "Doublon ignoré (" + f.name + ")"
                    });
                }
            });
            this.$emit("change", this.files);
        },
        removeFile(file) {
            this.files = this.files.filter(f => {
                return f != file;
            });
            this.$emit("change", this.files);
        },
        formatBytes(a, b = 2) {
            if (0 === a) return "0 Bytes";
            const c = 0 > b ? 0 : b,
                d = Math.floor(Math.log(a) / Math.log(1024));
            return (
                parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
                " " +
                ["Bytes", "ko", "mo", "Go", "TB", "PB", "EB", "ZB", "YB"][d]
            );
        },
        getFileName(file) {
            return this.renames[file.name] || file.name;
        },
        isEditingFile(file) {
            return this.editMode && this.editKey === file.name;
        },
        getRenames() {
            return this.renames;
        },
        getRenamesAsArray() {
            return Object.values(this.renames);
        },
        appendToFormData(fd){
            const files = this.files;
            for (var i in files) {
                fd.append("files[]", files[i]);
            }
            const renames = this.getRenamesAsArray();
            for(var j in renames){
                fd.append("renames[]", renames[j]);
            }
        },
        resetValues() {
            this.files = [];
            this.renames = {};
        }
    }
};
</script>

<style>
</style>