<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Dates importantes pour la candidature de {{ currentActionTarget.candidacy1.candidate.full_name }} sur le lot {{currentActionTarget.lot.reference}}
        </h3>
        <div slot="body">
            <div class="mt-6 align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table class="min-w-full">
                    <thead>
                        <tr>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Titre
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600">
                                Enregistrement sur la plateforme
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600">
                                {{currentActionTarget.candidacy1.created_at | date_dmyhm }}
                            </td>
                        </tr>
                        <template v-for="(date, index) in dates">
                            <tr
                                class="bg-white"
                                :key="index"
                            >

                                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600">
                                    {{date.title }}
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600">
                                    <template v-if="currentActionTarget[date.key]">
                                        {{ currentActionTarget[date.key] | date_dmyhm }}
                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import { mapGetters } from "vuex";
export default {
    components: {
        DefaultModal,
    },
    data() {
        return {
            dates: [
                {
                    title: "Envoi fiche réservation",
                    key: "reservation_sent_at",
                },
                {
                    title: "Première validation fiche réservation",
                    key: "first_reservation_at",
                },
                {
                    title: "Validation fiche réservation par courtier",
                    key: "reservation_confirmed_at",
                },
                {
                    title: "Acompte réservation reçu",
                    key: "reservation_deposit_confirmed_at",
                },
                // {
                //     title: "Signature acte de vente prévue le",
                //     key: "act_of_purchase_at",
                // },
                {
                    title: "Signature promesse de vente",
                    key: "promise_signed_at",
                },
                {
                    title: "Signature acte de vente def.",
                    key: "act_of_purchase_signed_at",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("application", ["currentActionTarget", "currentActionType"]),
        open(){
            return this.currentActionTarget && this.currentActionType == "applicationDates";
        }
    },
    methods: {
        close() {
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },
    },
};
</script>

<style>
</style>