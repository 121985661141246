<template>
  <index-candidate v-if="user.type === 'candidate'"/>
  <index-auth v-else-if="user"/>
</template>

<script>
import { mapState } from "vuex";

import IndexAuth from "./IndexAuth";
import IndexCandidate from "./IndexCandidate";

export default {
    name: "Dashboard",
    components: {
        IndexAuth,
        IndexCandidate,
    },
    computed: {
        ...mapState("auth", ["user"])
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Dashboard";
            }
        },
    }    
};
</script>
