<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >Le projet d'acte a été signé</h3>
        <div slot="body">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        :disabled="isDisabled"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5 bg-blue-600 hover:bg-blue-400 text-white cursor-pointer"
                    >Oui</button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Non</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import _YesNoModal from "@/mixins/YesNoModal";
import {mapGetters} from "vuex";

export default {
    mixins: [_YesNoModal],
    data() {
        return {
            isDisabled: false,
        };
    },
    computed:{
        ...mapGetters("lot", ["currentActionType", "currentActionTarget"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "signAct";
        }
    },
    methods: {
        submit() {
            this.isDisabled=true; 
            this.$store
                .dispatch("application/sign-act", {
                    id: this.currentActionTarget.id
                })
                .then(() => {
                    this.$toast.open({
                        type: "success",
                        message: "Projet d'acte signé"
                    });
                    this.$emit("update");
                })
                .catch(() => {
                    this.$toast.open({
                        type: "error",
                        message: "Une erreur est survenue"
                    });
                }).finally(()=>{
                    this.isDisabled = false;
                });
            this.close();
        }
    }
};
</script>

<style>
</style>