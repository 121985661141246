<template>
    <default-modal 
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Demander la correction de la candidature {{ mainCandidate.full_name }}
            <span v-if="secondCandidate">
                et {{secondCandidate.full_name}}
            </span>

        </h3>
        <div slot="body">
            <div class="mt-4 sm:col-span-6 text-left text-sm text-gray-700">
                <div class="text-left">
                    <div class="flex">
                        <div class="m-1 block flex-initial">
                            <label
                                for="sre-email"
                                class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                                >Email du candidat 1</label
                            >
                            <span>
                                {{mainCandidate.email}}
                            </span>
                        </div>
                        <div v-if="secondCandidate" class="m-1 block flex-initial">
                            <label
                                for="sre-email"
                                class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                                >Email du candidat 2</label
                            >
                            <span>
                                {{secondCandidate.email}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="text-left mt-4">
                    <label
                        for="sni-subject"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Sujet de l'email</label>
                    <input
                        type="text"
                        id="sni-subject"
                        v-model="body.subject"
                        class="form-input w-full"
                    />
                </div>
                <div class="mt-4">
                    <label
                        for="body"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >Contenu</label>
                    <div class="mt-1 rounded-md shadow-sm ">
                        <textarea
                            v-model="body.body"
                            id="body"
                            rows="12"
                            class="border p-2 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:outline-none focus:border-blue-400 rounded"
                        ></textarea>
                    </div>
                    <p
                        class="mt-2 text-sm text-gray-500"
                    >Ce message sera envoyé par email au(x) candidat(s).</p>
                </div>
            </div>
        </div>
        <div slot="footer" class="mt-12 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-transparent px-8 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Valider</button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Fermer</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import Email from "@/mixins/Email";

export default {
    mixins : [Email],

    components: {
        DefaultModal
    },
    data() {
        return {
            body: {
                emails: "",
                subject: "E-Promotion - Formulaire d'inscription incomplet",
                body:
                    "Votre formulaire de réservation est incomplet, veuillez cliquer ici pour le compélter :"
            }
        };
    },
    computed: {
        ...mapGetters("application", ["currentActionTarget", "currentActionType"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "sendNotificationIncomplete";
        },
        mainCandidate(){
            return this.currentActionTarget.candidacy1.candidate;
        },
        secondCandidate(){
            return this.hasSecondCandidate ? this.currentActionTarget.candidacy2.candidate : false;
        },
        hasSecondCandidate() {
            return this.currentActionTarget.candidacy2 ? true : false;
        }
    },
    methods: {
        renderBody() {
            let text = "";
            text +=
                "Nous vous remercions d’avoir renseigné vos coordonnées dans le cadre de votre réservation d’un lot dans le projet cité en titre. Malheureusement certaines informations manquent encore et nous vous prions de bien vouloir les compléter. Ces informations sont essentielles pour permettre au notaire de procéder à toutes les formalités nécessaires à votre achat et son financement. \n \n";
            text +=
                "Nous vous remercions d’avance de bien vouloir répondre dans les meilleurs délais et restons tout naturellement à votre entière disposition pour toute question. \n\n";
            this.body.body = text;
        },
        renderSubject() {
            this.body.subject = `Votre acquisition du lot ${this.currentActionTarget.lot.reference} - Informations manquantes`;
        },
        close() {
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },
        submit() {
            this.body.body = this.body.body.replace(/\n\r?/g, "<br />");
            
            const command = {
                name : "unvalidateReservationForm",
                params : {
                    entity : "application",
                    entity_id : this.currentActionTarget.id,
                    additionnal_fields : this.body
                }
            };
            this.$store.commit("loader/SET_LOADING", true);
            this.$store.dispatch("app/executeCommand", command)
                .then(() => {
                    this.$emit("update");
                    this.close();
                    this.$store.commit("loader/SET_LOADING", false);
                });
        }
    },

    watch: {
        open(val) {
            if (val) {
                this.render();
            }
        }
    }
};
</script>
