const state = {
    colors: ["#142459", "#176BA0", "#19AADE", "#1AC9E6", "#1DE4BD", "#6DFD02", "#7D3AC1", "#AF4BCE", "#DB4CB2", "#EE548C", "#EA7369", "#F0A58F", "#DE542F", "#EF73E2", "#EABD38"],
};

const getters = {
    colors: state => {
        return state.colors;
    },
};

const mutations = {
    SET_COLORS(state, value) {
        state.colors = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
};
