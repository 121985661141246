<template>
	<div class="flex flex-col flex-1">
		<div v-if="title" class="flex items-center justify-center bg-blue-400 px-2 py-1 text-white text-center font-medium">
			<span role="title" class="truncate">{{title}}</span>
		</div>
		<div class="divide-y">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
    props : {
        title : {
            type : String,
            required : false,
            default : null
        },
    }
};
</script>
