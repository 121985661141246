<template>
  <default-modal
    v-if="open"
    @close="close"
    maxWidthClass="max-w-3xl overflow-visible"
  >
    <h3
      slot="header"
      class="text-left text-xl leading-6 font-medium text-gray-800 pb-5 px-4"
    >
      {{ "Associer un lot existant au lot " + editLot.title }}
    </h3>
        <div slot="body">
            
            <div class="mt-4 text-left">
                <p class="font-medium mt-4 mb-2 text-blue-900">Associer le lot :</p>
                <div class="flex items-center">
                    <select-lot
                    @change="updateSelectedChildLotParent"
                    :lots="allPromotionLots"
                    ></select-lot>
                </div>
            </div>
        </div>

    <div slot="footer" class="mt-12 text-right px-4">
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            @click="submit"
            type="button"
            :disabled="!isValid"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            :class="submitButtonClass"
          >
            Valider
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            @click="close"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Fermer
          </button>
        </span>
      </div>
    </div>
  </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import {mapGetters} from "vuex";
import SelectLot from "@/components/Lots/SelectLot";
export default {
    components: {
        DefaultModal,
        SelectLot,
    },

    props: {
        open: {
            type: Boolean,
            required: true
        },

        promotion: {
            type: Object,
            required: true
        },

        building: {
            type: Object,
            required: true
        },

        editLot: {
            type: Object,
            required: false
        },

        parentLot: {
            type: Object,
            required: false
        },
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters("lot", ["floors","types"]),
        isValid() {
            if(this.isDisabled)
                return false;
            return this.selectedChildLotParent ? true : false;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
        allPromotionLots() {
            return this.promotion.buildings
                .map(b => b.lots)
                .flat()
                .filter(l => !l.parent_id && l.id != this.lot.id && !l.children.length);
        },        
    },

    methods: {
        initialState(){
            return {
                isDisabled: false,
                selectedChildLotParent: null,
            };
        },
        updateSelectedChildLotParent(val) {
            this.selectedChildLotParent = val;
        } ,      
        close() {
            this.$emit("close");
        },
        reset(){
            Object.assign(this.$data, this.initialState());
        },
        submit() {
            this.isDisabled = true;
            this.$emit("submit", this.selectedChildLotParent);
            this.isDisabled = false;
            this.reset();
        }
    },

    watch: {
        open(val) {
            if (val) {
                if (this.editLot) {
                    this.lot = { 
                        ...this.editLot,
                    };
                }
                if (this.parentLot) {
                    this.lot.parent_id = this.parentLot.id;
                }
            }
        }
    }
};
</script>
