<template>
  <transition>
    <div
      class="fixed z-50 flex flex-row bg-white border shadow-md divide-x rounded-md overflow-hidden"
      :style="position"
      ref="ctx"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
    props : {
        active : {
            type : Boolean
        },
        targetType : {
            type : String,
            required : true,
        },
    },
    created(){
        this.createClickEventListener();
    },
    beforeDestroy(){
        document.removeEventListener("click", this.handleClick);
    },
    methods: {
        calculatePosition(clickEvent) {
            this.top = clickEvent.offsetY;
            this.left = clickEvent.offsetX;
        },
        createClickEventListener(){
            this.$nextTick(() => {
                document.addEventListener("click", this.handleClick);
            });
        },
        handleClick(evt){
            if(this.active){
                // clicked element
                let targetElement = evt.target;  
                do {
                    // click inside
                    if (targetElement == this.$refs.ctx) {
                        return;
                    }                    
                    targetElement = targetElement.parentNode;
                } while (targetElement);

                this.$emit("close");
            }
        }
    },
    computed : {
        event(){
            return this.$store.getters[`${this.targetType}/contextEvent`];
        },
        position(){
            let position, transform = {};
            
            transform.transform = "";
            if(this.event.clientX > window.innerWidth/2){
                transform.transform = "translateX(-100%)";
            }
            if(this.event.clientY > window.innerHeight/2){
                transform.transform += "translateY(-100%)";
            }
            
            if(this.event){
                position = {
                    top : `${this.event.clientY}px`,
                    left : `${this.event.clientX}px`,
                };
            }
            else{

                position = {
                    top : 0,
                    left : 0,
                };
            }
            return {
                ...position,
                ...transform,
            };
        }
    }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
