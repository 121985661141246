<template>
  <default-modal
    v-if="open"
    @close="close"
  >
    <h3
      slot="header"
      class="text-left text-xl leading-6 font-medium text-gray-800 pb-5"
    >
      Nouveau sujet de discussion
    </h3>
    <div slot="body" class="text-left w-90vw md:w-60vw">
      <div class="mt-4 text-left grid md:grid-cols-3" v-if="!isCandidate && !isPilot && !isInvestor">
        <div>
          <span class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Envoyer à</span
          >
          <picture-select
            ref="candidacySelect"
            :disabled="!(all_candidacies && all_candidacies.length > 0)"
            :searchProps="['candidate.full_name']"
            :collection="all_candidacies"
            textProp="candidate.full_name"
            imgProp="candidate.profile_picture"
            defaultText="Candidats"
            valueProp="candidate.id"
            :value="currentActionTarget?currentActionTarget.candidate.id:null"
            @change="
              val => {
                updateSelectedCandidate(val);
              }
            "
          />
        </div>
        <div v-if="!isBroker">
          <span class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Envoyer à</span
          >
          <picture-select
            ref="brokerSelect"
            :searchProps="['full_name']"
            :collection="currentPromotion.brokers"
            textProp="full_name"
            imgProp="profile_picture"
            defaultText="Courtiers"
            valueProp="id"
            @change="
              val => {
                updateSelectedBroker(val);
              }
            "
          />
        </div>
        <div v-if="!isNotary">
          <span class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Envoyer à</span
          >
          <picture-select
            ref="notarySelect"
            :searchProps="['full_name']"
            :collection="currentPromotion.notaries"
            textProp="full_name"
            imgProp="profile_picture"
            defaultText="Notaires"
            valueProp="id"
            @change="
              val => {
                updateSelectedNotary(val);
              }
            "
          />
        </div>
      </div>
      <div v-if="isInvestor">
        <span class="block text-sm font-medium leading-5 text-gray-700 mb-2"
          >Envoyer à</span
        >
        <picture-select
          ref="pilotSelect"
          :searchProps="['full_name']"
          :collection="pilots"
          textProp="full_name"
          imgProp="profile_picture"
          defaultText="Pilotes"
          valueProp="id"
          @change="
            val => {
              updateSelectedPilot(val);
            }
          "
        />
      </div>
      <div v-if="isPilot">
        <span class="block text-sm font-medium leading-5 text-gray-700 mb-2"
          >Envoyer à</span
        >
        <picture-select
          ref="investorSelect"
          :searchProps="['full_name']"
          :collection="investors"
          textProp="full_name"
          imgProp="profile_picture"
          defaultText="Investisseurs"
          valueProp="id"
          @change="
            val => {
              updateSelectedInvestor(val);
            }
          "
        />
      </div>
      <div class="mt-4 text-left">
        <label
          for="title"
          class="block text-sm font-medium leading-5 text-gray-700 mb-2"
          >Titre</label
        >
        <input
          type="text"
          id="title"
          v-model="body.title"
          class="form-input w-full"
        />
      </div>
      <div class="mt-4">
        <label
          for="content"
          class="block text-sm font-medium leading-5 text-gray-700"
          >Message</label
        >
        <div class="mt-1 rounded-md shadow-sm">
          <textarea
            v-model="body.content"
            id="body"
            rows="3"
            class="border p-2 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:outline-none focus:border-blue-400 rounded"
          ></textarea>
        </div>
      </div>
      <div class="mt-4">
        <label
          for="file"
          class="block text-sm leading-5 font-medium text-gray-700"
        >
          Fichier(s)
        </label>
        <div class="mt-2">
          <FileDragger
            ref="fileDragger"
            @change="updateFiles"
            :multiple="true"
            @openEdit="editingFiles = true"
            @closeEdit="editingFiles = false"
          >
          </FileDragger>
        </div>
      </div>
    </div>
    <div slot="footer" class="mt-12 text-right">
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            @click="submit"
            type="button"
            :disabled="!isValid"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            :class="submitButtonClass"
          >
            Valider
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            @click="close"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Fermer
          </button>
        </span>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import PictureSelect from "@/components/Forms/PictureSelect";
import Auth from "@/mixins/Auth";
import FileDragger from "@/components/global/FileDragger";

export default {
    mixins: [Auth],
    components: {
        DefaultModal,
        PictureSelect,
        FileDragger
    },

    data() {
        return this.initialData();
    },
  
    beforeMount() {
        this.getData();
    },

    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
        ...mapState("candidacy", ["all_candidacies"]),
        ...mapState("investor", ["investors"]),
        ...mapState("pilot", ["pilots"]),
        ...mapGetters("message", ["currentActionType","currentActionTarget"]),
        open(){
            return this.currentActionType === "addThread";
        },
        
        isValid() {
            if(this.isDisabled)
                return false;
            let fields = ["content", "title"];
            if (!this.isCandidate) fields.push("to_id");
            for (var i in fields) {
                if (this.body[fields[i]] == "" || this.body[fields[i]] == null)
                    return false;
            }
            return true;
        },

        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        }        
    },

    methods: {
        initialData(){
            return {
                body: {
                    title: "",
                    content: "",
                    to_id: null,
                    to_base_class: null
                },
                isDisabled: false,
                files: []
            };
        },
        resetValues() {
            Object.assign(this.$data, this.initialData());
        },
        getData() {
            if(!this.isCandidate && !this.isInvestor && !this.isPilot){
                this.$store.dispatch("candidacy/getAll").then(() => {                 
                });
            }
            if(this.isPilot){
                this.$store.dispatch("investor/getAll").then(() => {                 
                });
            }
            if(this.isInvestor){
                this.$store.dispatch("pilot/getAll").then(() => {                 
                });
            }
        },

        handleSelectLot(value) {
            this.body.lot_id = value;
        },
        close(returnBack = true) {
            this.$store.dispatch("message/closeModals");
            if(this.$route.query.candidate && returnBack){
                this.$router.go(-1);
            }
        },
        updateFiles(files){
            this.files = files;
        },
        submit() {
            if (this.isValid) {
                this.isDisabled = true;
                let data = new FormData();

                data.append("title", this.body.title);
                data.append("content", this.body.content);
                data.append("to_id", this.body.to_id);
                data.append("to_base_class", this.body.to_base_class);
                data.append("promotion", this.currentPromotion.id);
                if (this.$refs.fileDragger) {
                    this.$refs.fileDragger.appendToFormData(data);
                }

                this.$store
                    .dispatch("message/createThread", data)
                    .then(() => {
                        this.$emit("update");
                        this.close(false);
                        this.resetValues();
                    })
                    .finally(() => {
                        this.isDisabled = false;
                    });
                
                this.resetValues();
            }
        },

        updateSelectedBroker(val) {
            if(this.$refs.candidacySelect)
                this.$refs.candidacySelect.selected = null;

            if(this.$refs.notarySelect)
                this.$refs.notarySelect.selected = null;
            this.body.to_id = val;
            this.body.to_base_class = "Broker";
        },

        updateSelectedCandidate(val) {
            if(this.$refs.brokerSelect)
                this.$refs.brokerSelect.selected = null;
            if(this.$refs.notarySelect)
                this.$refs.notarySelect.selected = null;
            this.body.to_id = val;
            this.body.to_base_class = "Candidate";
        },

        updateSelectedNotary(val) {            
            if(this.$refs.candidacySelect)
                this.$refs.candidacySelect.selected = null;
            if(this.$refs.brokerSelect)
                this.$refs.brokerSelect.selected = null;
            this.body.to_id = val;
            this.body.to_base_class = "Notary";
        },

        updateSelectedPilot(val) {
            this.body.to_id = val;
            this.body.to_base_class = "Pilot";
        },

        updateSelectedInvestor(val) {
            this.body.to_id = val;
            this.body.to_base_class = "Investor";
        },

        changeFile(event) {
            this.files = event.target.files;
        },
    },
};
</script>