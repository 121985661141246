<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Valider la fiche de réservation de {{ currentActionTarget.candidacy1.candidate.full_name }} sur le lot {{currentActionTarget.lot.reference}}
        </h3>
        <div slot="body">
            <div class="mt-6 flex items-center justify-between">
                <div class="flex items-center">
                    <input
                        v-model="knowDate"
                        id="knowDate"
                        type="checkbox"
                        class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <label
                        for="knowDate"
                        class="ml-2 block text-sm leading-5 text-blue-900"
                    >
                        Date de versement de l'acompte connue ?
                    </label>
                </div>
            </div>
            <div
                class="mt-6"
                v-if="knowDate"
            >
                <label
                    for="date"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2 text-left"
                >
                    Date
                </label>
                <div class="flex items-center">
                    <datetime 
						@input="(val) => date = val"
						type="date"
						value-zone="UTC+2"
						zone="UTC+2"
						input
						input-class="form-input"
					>
					</datetime>
                    <time-picker
                        v-model="time"
                        minute-interval="15"
                        hour-label="Heure"
                        minute-label="Minute"
                    >
                    </time-picker>
                </div>
            </div>
        </div>
        <div
            slot="footer"
            class="mt-12 text-right"
        >
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        :disabled="isDisabled"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-transparent px-8 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Valider
                    </button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";

import TimePicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";


export default {
    components: {
        DefaultModal,
        TimePicker,
        Datetime
    },
    data() {
        return {
            knowDate: false,
            date: null,
            time: {
                HH: "10",
                mm: "00"
            },
            isDisabled: false
        };
    },
    computed: {
        ...mapGetters("application", ["currentActionTarget", "currentActionType"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "reservationComplete";
        }
    },
    methods: {
        close() {
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },
        submit() {
            this.isDisabled = true;
            const command = {
                name : "validateReservationForm",
                params : {
                    entity : "application",
                    entity_id : this.currentActionTarget.id,
                    additionnal_fields : this.$data
                }
            };
            this.$store
                .dispatch("app/executeCommand", command)
                .then(() => {
                    this.close();
                    this.$emit("update");
                }).finally(()=>{
                    this.isDisabled = false;
                });
        }
    }
};
</script>
