import axios from "axios";

export default {
    get(params){
        return axios.get("/campaigns", {params});
    },
    update(campaign_id, body) {
        return axios.put(`/campaigns/${campaign_id}`, body);
    },
    delete(campaign_id) {
        return axios.delete(`/campaigns/${campaign_id}`);
    },
    addFiles(campaign_id, body){
        return axios.post(`/campaigns/${campaign_id}/files`, body);
    },
    removeFile(campaign_id, file_id){
        return axios.delete(`/campaigns/${campaign_id}/files/${file_id}`);
    }
};
