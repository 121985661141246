<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 p-2 gap-2">
    <div class="p-2 rounded-md flex flex-col space-y-3" v-if="user.admin">
      <div class="font-semibold text-sm text-gray-700">Ajouter un banquier</div>
      <div>
        <picture-select
          ref="bankerselect"
          :searchProps="['full_name']"
          :collection="availableBankers"
          :value="form.banker_id"
          textProp="full_name"
          imgProp="profile_picture"
          defaultText="Sélectionner un banquier"
          valueProp="id"
          @change="
            (val) => {
              handleBankerChange(val);
            }
          "
        />
      </div>
      <div class="">
        <button
          class="
            bg-blue-400
            hover:bg-blue-600
            text-white
            px-2
            py-1
            font-medium font-semibold
            transition-all
            duration-300
            hover:shadow
            focus:outline-none
          "
          @click="addBanker"
        >
          Ajouter
        </button>
      </div>
      <button
        @click="openCreateBanker = true"
        class="
          pb-2
          pt-1
          px-4
          border border-transparent
          text-sm
          font-medium
          rounded-sm
          text-white
          bg-blue-400
          hover:bg-blue-600
          focus:outline-none focus:border-green-700
          transition
          duration-150
          ease-in-out
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          class="h-4 w-4 inline-block fill-current align-middle mr-2"
        >
          <path
            d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
          ></path></svg
        ><span class="align-middle">Créer un banquier</span>
      </button>
    </div>
    <div>
      <bankers-list
        :bankers="promotion.bankers"
        :promotion="promotion"
        :edit="true"
        @remove="emitUpdate"
      ></bankers-list>
    </div>
    <add-banker-modal
      :open="openCreateBanker"
      :promotion="promotion"
      @close="openCreateBanker = false"
      @submit="createBanker"
    />
  </div>
</template>

<script>
import axios from "axios";

import BankersList from "@/components/Bankers/BankersList";
import PictureSelect from "@/components/Forms/PictureSelect";
import bankers from "@/api/bankers";
import { pluck } from "underscore";
import promotion from "@/api/promotion";
import { mapGetters } from "vuex";

import AddBankerModal from "@/components/Promotion/modals/AddBankerModal";

export default {
    components: {
        BankersList,
        PictureSelect,
        AddBankerModal,
    },
    props: {
        promotion: {
            type: Object,
            required: true,
        },
    },
    data() {
        return this.initialState();
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters("auth", ["user"]),
        availableBankers() {
            let promotionBankerIds = pluck(this.promotion.bankers, "id");
            return this.bankers.filter((el) => {
                return promotionBankerIds.indexOf(el.id) < 0;
            });
        },
    },
    methods: {
        initialState() {
            return {
                form: {
                    banker_id: null,
                },
                bankers: [],
                openCreateBanker: false,
            };
        },
        init() {
            this.$store.commit("loader/SET_LOADING", true);
            bankers.get().then((res) => {
                this.bankers = res.data.data;
                this.$store.commit("loader/SET_LOADING", false);
            });
        },
        handleBankerChange(val) {
            this.banker_id = val;
        },
        emitUpdate() {
            this.$emit("update");
        },
        addBanker() {
            this.$store.commit("loader/SET_LOADING", true);
            promotion.addBanker(this.promotion.id, this.banker_id).then(() => {
                this.$store.commit("loader/SET_LOADING", false);
                this.emitUpdate();
            });
        },
        async createBanker(banker) {
            const data = await axios.post(
                `/promotions/${this.promotion.id}/bankers`,
                banker
            );
            this.bankers.push(data.data);

            this.openCreateBanker = false;
            this.$emit("update");
        },
    },
};
</script>

<style>
</style>