<template>
    <div class="inline-flex shadow rounded-lg mr-5" v-on-clickaway="close">
        <div class="relative">
            <button
                @click.prevent="toggle"
                class="rounded-lg inline-flex items-center bg-white hover:text-gray-700 focus:outline-none focus:shadow-outline text-gray-500 py-2 px-2 text-sm"
                :class="behaviourClass"
            >
                <div
                    v-if="selected"
                    class="flex"
                    style="min-width: 105px"
                >
                    <img
                        :src="getImg(selected)"
                        class="rounded-full mr-2 h-6 w-6 inline-block"
                    />
                    <span class="w-30 truncate">{{ selectedText }}</span>
                </div>
                <span v-else class="inline-block" style="min-width: 105px">{{
                    defaultText
                }}</span>
                <svg
                    v-if="!disabled"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 ml-1"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <rect x="0" y="0" width="24" height="24" stroke="none" />
                    <polyline points="6 9 12 15 18 9" />
                </svg>
            </button>
            <div
                v-show="isOpen && !disabled"
                class="z-40 absolute top-0 left-0 w-56 bg-white rounded-lg shadow-lg mt-12 -mr-1 block py-1 overflow-hidden"
            >
                <div v-if="searchProps">
                    <input
                        type="text"
                        class="w-full p-2"
                        placeholder="Recherche"
                        v-model="search"
                        ref="search"
                    />
                </div>
                <div class="overflow-auto h-56">
                    <label
                        v-if="selected != null && defaultText"
                        class="flex justify-start items-center text-truncate hover:bg-gray-100 px-4 py-2 cursor-pointer"
                        @click="select(null)"
                    >
                        <div
                            class="select-none text-gray-700 text-sm font-semibold"
                        >
                            {{ defaultText }}
                        </div>
                    </label>
                    <template v-for="(item, index) in filteredCollection">
                        <label
                            v-if="item != selected"
                            class="flex justify-between items-center px-4 py-2 hover:bg-gray-100"
                            :key="index"
                            @click="select(item)"
                        >
                            <div class="flex items-center text-truncate cursor-pointer">
                                <div class="text-teal-600 mr-3">
                                <img
                                    :src="getImg(item)"
                                    alt
                                    :class="imgClass"
                                />
                            </div>
                            <div
                                class="select-none text-gray-700 text-sm inline-block"
                            >
                                {{ getItemText(item) }}
                            </div>
                            </div>
                            <span
                                v-if="badgeProp"
                                class="bg-red-500 rounded-full px-2 py-0 text-center object-right-top text-white text-2xs ml-1"
                                >{{ getBadgeText(item) }}</span
                            >
                        </label>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomSelect from "@/mixins/CustomSelect";
import { mixin as clickaway } from "vue-clickaway";
export default {
    mixins: [clickaway, CustomSelect],
    props: {
        imgProp: {
            type: String,
            required: true,
        },
        imgClass: {
            type: String,
            default: "rounded-full h-6 w-6 inline-block",
        },
        searchProps: {
            type: Array,
            default: null,
        },
        badgeProp: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            search: "",
        };
    },
    methods : {
        getImg(item){      
            let imgProps = this.imgProp.split(".");
            let selected = item;
            if(this.imgProp){
                for(var ip of imgProps){
                    selected = selected[ip];
                }
            }
            return selected;
        },
        getBadgeText(item){
            let badgeProps = this.badgeProp.split(".");
            let selected = item;
            if(this.badgeProp && this.selected){
                for(var bp of badgeProps){
                    selected = selected[bp];
                }
            }
            return selected;
        }
    },
    mounted(){
        this.init();
    },
};
</script>