<template>
    <main class="p-4">
        <div
            class="grid auto-rows-auto grid-cols-3 md:grid-cols-6 lg:grid-cols-10 gap-3"
            v-if="candidate"
        >
            <div class="col-span-3 md:col-span-2">
                <candidate-card
                    :candidate="candidate"
                    :initialMenu="currentView"
                    @selectMenu="handleSelectMenu"
                />
            </div>
            <candidate-container
                :candidate="candidate"
                :currentView="currentView"
                class="col-span-3 md:col-span-4 lg:col-span-8"
            ></candidate-container>
        </div>
    </main>
</template>

<script>
import CandidateCard from "@/components/Candidates/Card";
import CandidateContainer from "@/components/Candidates/Container";

import candidate from "@/api/candidate";
export default {
    components: {
        CandidateCard,
        CandidateContainer,
    },
    data() {
        return {
            candidate: null,
            currentView: "informations",
        };
    },
    mounted() {
        // fetch user
        this.init();
    },
    beforeDestroy() {
        this.$store.commit("candidate/SET_SELECTED_MENU", "informations");
    },
    computed:{
        selectedMenu(){
            return this.$store.getters["candidate/selectedMenu"];
        }
    },
    methods: {
        init() {
            if(localStorage.getItem("candidateSelectedMenu"))
            {
                this.handleSelectMenu(localStorage.getItem("candidateSelectedMenu"));
            }
            candidate
                .getOne(this.$route.params.id)
                .then((res) => {
                    if(res.data.code == 400){
                        this.$toast.open({
                            message: res.data.error,
                            type: "error",
                        });
                    }
                    this.candidate = res.data.data;
                    if (this.selectedMenu) {
                        this.selectMenu(this.selectedMenu);
                    }
                })
                .catch((err) => {
                    this.$toast.open({
                        message: err,
                        type: "error",
                    });
                });
        },
        handleSelectMenu(menu) {
            this.$store.commit("candidate/SET_SELECTED_MENU", menu);
            this.selectMenu(menu);
        },
        selectMenu(menu) {
            this.currentView = menu;
        },
    },
};
// AFFICHER PROMOTION DU CANDIDAT
</script>

<style>
</style>
