<template>
    <Ctx ref="ctx" v-if="active" :active="active" @close="close" targetType="candidacy">
        <Column :candidacy="currentActionTarget"></Column>
    </Ctx>
</template>

<script>
import Ctx from "@/components/global/ContextMenu";
import Column from "./Column";
import {mapState} from "vuex";

export default {
    components : {
        Ctx,
        Column
    },
    computed: {
        ...mapState("candidacy", ["currentActionTarget", "currentActionType", "contextEvent"]),
        active(){
            return this.currentActionTarget != null && this.currentActionType == "context" && this.contextEvent != null;
        }
    },
    methods : {
        close(){
            this.$store.commit("candidacy/SET_CONTEXT_EVENT", null);
        }
    }
};
</script>

<style></style>
