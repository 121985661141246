import router from "@/router";
import store from "@/store";
import auth from "@/mixins/Auth";

export default{
    fiche_reservation : {
        label : () => "Fiche Réservation",
        show : (candidate) => {
            return candidate.applications && candidate.applications.length == 1 && 
				candidate.applications[0] != null && 
				candidate.applications[0].reservation_sent_at != null;
        },
        onclick(candidate){
            let routeData = router.resolve({
                name: "candidate-form",
                params: { id: candidate.applications[0].public_id },
                target: "_blank"
            });
            window.open(routeData.href, "_blank");
        }
    },
    attribute_lot : {
        label : () => "Attribuer un lot",
        onclick(candidacy){
            store.commit("candidacy/SET_CURRENT_ACTION_TYPE", "attributeLot");
            store.commit("candidacy/SET_CURRENT_ACTION_TARGET", candidacy);
        },
        show : (candidacy) => {
            return candidacy.agency_id != 1 || store.getters["auth/currentPromotion"].multi_agency == true || store.getters["auth/currentPromotion"].crm_updated_at == null || candidacy.crm_validated == true;
        }
    },
    add_task : {
        label : () => "Ajouter une tâche",
        show : () => {
            return auth.computed.isBroker();
        },
        onclick(candidacy){
            store.dispatch("task/openAddTask", {
                targetType : "candidacy", 
                target : candidacy
            });
        }
    },
    // task_history : {
    //     label : () => "Historique des tâches",
    //     show : () => {
    //         return auth.computed.isBroker();
    //     },
    //     onclick(candidacy){
    //         store.commit("candidacy/SET_CURRENT_ACTION_TYPE", "taskHistory");
    //         store.commit("candidacy/SET_CURRENT_ACTION_TARGET", candidacy);
    //     }
    // },
    logs: {
        label: () => "Historique",
        show: () => {
            return auth.computed.isBroker();
        },
        onclick(candidacy) {
            router.push({ name: "logs", query: {
                candidacy_id: candidacy.id,
            } });
        }
    },
    edit_candidate : {
        label : () => {
            return auth.computed.isBroker() ? "Voir / Modifier candidat" : "Voir candidat";
        },
        onclick(candidacy){
            store.commit("candidacy/SET_CURRENT_ACTION_TYPE", "edit");
            store.commit("candidacy/SET_CURRENT_ACTION_TARGET", candidacy);
        }
    },
    show_files : {
        label : () => "Documents",
        onclick(candidacy){
            store.commit("candidacy/SET_CURRENT_ACTION_TYPE", "showFiles");
            store.commit("candidacy/SET_CURRENT_ACTION_TARGET", candidacy);
            
        },
    },
    show_comments : {
        label : () => "Commentaires",
        show : () => {
            return auth.computed.isBroker() || auth.computed.isNotary() ||auth.computed.isBanker() || auth.computed.isPromoter();
        },
        onclick(candidacy){
            store.commit("candidacy/SET_CURRENT_ACTION_TYPE", "showComments");
            store.commit("candidacy/SET_CURRENT_ACTION_TARGET", candidacy);
            
        },
    },
    contact : {
        label : () => "Contacter",
        onclick(candidacy){
            store.commit("message/SET_CURRENT_ACTION_TYPE", "addThread");
            store.commit("message/SET_CURRENT_ACTION_TARGET", candidacy);
        },
    },
    show_messages : {
        label : () => "Voir les messages",
        onclick(candidacy){ 
            store.commit("message/SET_CURRENT_ACTION_TARGET", candidacy);
            router.push({
                name: "message",
            });
        },
    },
    change_agency : {
        label : () => "Envoyer vers une autre agence",
        color : "red-500",
        show : () => {
            return auth.computed.isBroker();
        },
        onclick(candidacy){  
            store.commit("candidacy/SET_CURRENT_ACTION_TYPE", "changeAgency");
            store.commit("candidacy/SET_CURRENT_ACTION_TARGET", candidacy);
        },
    },
};