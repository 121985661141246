<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-gray-800 pb-5"
        >
            Ajouter une photo/vidéo à l'album <span class="font-bold text-blue-900">{{ currentActionTarget.name }}</span>
        </h3>
        <div
            slot="body"
            class="text-left"
        >
            <div class="mt-6">
                <label
                    for="photo"
                    class="block text-sm leading-5 font-medium text-gray-700"
                >
                    Photo
                </label>
                <div class="mt-2">
                    <input
                        type="file"
                        id="photo"
                        name="photo"
                        @change="changePhoto"
                        multiple
                    >
                </div>
            </div>
        </div>
        <div
            slot="footer"
            class="mt-12 text-right"
        >
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >
                        Valider
                    </button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";

export default {
    components: {
        DefaultModal
    },
    data() {
        return {
            body: {},
            photos: null,
            isDisabled: false,
        };
    },
    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
        ...mapGetters("photo", ["currentActionTarget", "currentActionType"]),
        open(){
            return this.currentActionTarget != null && this.currentActionType === "addPhoto";
        },
        isValid() {
            if(this.isDisabled)
                return false;
            if (!this.photos) {
                false;
            }
            return true;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        }
    },
    methods: {
        close() {
            this.$store.dispatch("photo/closeModals");
        },
        resetValues() {
            this.body = {
                photo: null
            };
        },
        changePhoto(event) {
            this.photos = event.target.files;
        },
        submit() {
            const data = new FormData();
            if (this.isValid) {
                this.isDisabled = true;
                for (var key in this.body) {
                    data.append(key, this.body[key]);
                }

                if (this.photos) {
                    for (var i = 0; i < this.photos.length; i++) {
                        data.append("photos[]", this.photos[i]);
                    }
                }

                data.append("promotion_id", this.currentPromotion.id);
                data.append("photo_group_id", this.currentActionTarget.id);

                this.$store.commit("loader/SET_LOADING", true);
                this.$store
                    .dispatch("promotion/addPhoto", data)
                    .then(() => {
                        this.close();
                        this.resetValues();
                        this.$store.commit("loader/SET_LOADING", false);
                        this.$emit("update");
                    })
                    .catch(() => {
                        this.$store.commit("loader/SET_LOADING", false);
                    })
                    .finally(() => {
                        this.isDisabled = false;
                    });
            }
        }
    }
};
</script>
