<template>
    <div>
        <default-modal
            v-if="open"
            @close="close"
        >
            <h3
                slot="header"
                class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"            
                >
                Liste des commentaires reliés à {{ currentActionTarget.candidate.full_name }}
            </h3>
            <div slot="body">
                <comments-list 
                    :candidacy="currentActionTarget"
                    @close="close"
                    :showCloseButton="true"
                    @addComment="addComment"
                    >
                </comments-list>
            </div>
        </default-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import CommentsList from "@/components/Candidates/CommentsList";

export default {
    components: { DefaultModal, CommentsList },
    computed: {
        ...mapState("candidacy", ["currentActionTarget", "currentActionType"]),
        candidate(){
            return this.currentActionTarget.candidate;
        },
        open(){
            return this.currentActionTarget && this.currentActionType === "showComments";
        }
    },
    methods: {
        close() {
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TYPE", null);
        },
        addComment(){
            this.$emit("update");
        }
    },
};
</script>
