<template>
    <default-modal
        v-if="open"
        @close="close"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Versement appel de fonds : {{ currentActionTarget.candidacy1.candidate.full_name }} sur le lot {{currentActionTarget.lot.reference}}
        </h3>
        <div slot="body">
            <div class="mt-6">
                <label
                    for="date"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2 text-left"
                >Date</label>
                <div class="grid grid-cols-2">
                    <div class="flex items-center">
                        <datetime 
                            v-model="body.date"
                            type="datetime"
                            value-zone="Europe/paris"
                            :minute-step="15"
                            format="dd/MM/yyyy HH:mm"
                            zone="Europe/paris"
                            input
                            input-class="form-input"
                        >
                        </datetime>
                    </div>
                </div>
            </div>
        </div>
        <div
            slot="footer"
            class="mt-12 text-right"
        >
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-transparent px-8 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >Valider</button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Fermer</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import "vue2-timepicker/dist/VueTimepicker.css";

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
    components: {
        DefaultModal,
        Datetime,
    },
    data() {
        return {
            body : {
                date: null,
            },
            isDisabled: false,
        };
    },
    computed: {
        ...mapGetters("application", ["currentActionTarget", "currentActionType"]),
        ...mapState("auth", ["user"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "scheduleAgreement";
        },
        isValid(){
            if(this.isDisabled)
                return false;
            return this.body.date != null && this.body.date != "";
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
    },
    methods: {
        close() {
            this.body.date = null;
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },
        submit() {
            if(this.isValid){
                this.isDisabled = true;
                const command = {
                    name : "scheduleAgreement",
                    params : {
                        entity : "application",
                        entity_id : this.currentActionTarget.id,
                        additionnal_fields : this.body
                    }
                };
    
                this.$store.dispatch("app/executeCommand", command)
                    .then(() => {
                        this.close();
                        this.$emit("update");
                    })
                    .finally(() => {
                        this.isDisabled = false;
                    });
            }
        }
    }
};
</script>
