<template>
    <div class="bg-white shadow overflow-hidden sm:rounded-md p-4 mb-4">
        <div class="flex justify-between items-start">
            <div>
                <p>
                    {{ photoGroup.name }} <span class="text-sm text-gray-600">({{ photoGroup.date | date_ymd }})</span>
                    <span class="text-sm" v-if="photoGroup.lot"> - {{ photoGroup.lot.full_title }}</span>
                </p>
                <div class="text-sm text-gray-600">
                    <p>Visible: {{ photoGroup.hidden ? "non" : "oui"}}</p>
                    <p>Homepage: {{ photoGroup.home_page ? "oui" : "non"}}</p>
                </div>
            </div>
            <div class="flex">
                <button
                    @click="openAddPhoto"
                    v-if="!isDemo"
                    type="button"
                    class="py-1 px-2 border border-transparent text-xs font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 inline-block fill-current align-middle"
                        viewBox="0 0 20 20"
                    >
                        <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                    </svg>
                </button>
                <button
                    @click="openEditAlbum"
                    type="button"
                    class="ml-2 py-1 px-2 border border-transparent text-xs font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:border-gray-700 transition duration-150 ease-in-out"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-4 inline-block fill-current align-middle"
                        viewBox="0 0 20 20"
                    >
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                        <path
                            fill-rule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </button>
                <button
                    @click="openDeleteAlbum"
                    type="button"
                    title="Supprimer l'album"
                    class="ml-2 py-1 px-2 border border-transparent text-xs font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:border-red-700 transition duration-150 ease-in-out"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-4 inline-block fill-current align-middle"
                        viewBox="0 0 20 20"
                    >
                        <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z"/>
                    </svg>
                </button>
            </div>
        </div>
        <div class="flex flex-wrap mt-4" v-if="photoGroup.photos">
            <photo-row
                v-for="photo in photoGroup.photos"
                :key="photo.id"
                :photo="photo"
                @update="emitUpdate"
            ></photo-row>
        </div>
    </div>
</template>

<script>
import PhotoRow from "@/components/Progression/PhotoRow";
import Auth from "@/mixins/Auth";

export default {
    mixins:[Auth],
    data() {
        return {};
    },

    components: {
        PhotoRow
    },

    props: {
        photoGroup: {
            type: Object,
            required: true
        }
    },

    computed: {},

    methods: {
        openAddPhoto() {
            this.$store.commit("photo/SET_CURRENT_ACTION_TARGET", this.photoGroup);
            this.$store.commit("photo/SET_CURRENT_ACTION_TYPE", "addPhoto");
        },
        openEditAlbum() {
            this.$store.commit("photo/SET_CURRENT_ACTION_TARGET", this.photoGroup);
            this.$store.commit("photo/SET_CURRENT_ACTION_TYPE", "editAlbum");
        },
        openDeleteAlbum() {
            this.$store.commit("photo/SET_CURRENT_ACTION_TARGET", this.photoGroup);
            this.$store.commit("photo/SET_CURRENT_ACTION_TYPE", "deleteAlbum");
        },
        emitUpdate(){
            this.$emit("update");
        }
    }
};
</script>
