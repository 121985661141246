import axios from "axios";

export default {
    get(params) {
        return axios.get("/candidates", {
            params,
        });
    },
    getOne(id) {
        return axios.get(
            "/candidates/" +
                id +
                "?includes=threadsTo,threadsBy,files,actions,candidacies.files,candidacies.applications.lot,candidacies.userComments,candidacies.promotion"
        );
    }
};
