import { mapGetters } from "vuex";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters("task", ["currentActionType", "target", "targetType", "entityName", "currentActionTarget"]),
        loaded(){
            return this.open && this.target;
        },
    },
    methods: {
        close() {
            this.$store.dispatch("task/closeModals");
            this.resetValues();
        },
        resetValues() {
            Object.assign(this.$data, this.$options.data());
        },
    },
    watch: {
        open(val){
            if(val && !this.target){
                this.fetchData();
            }
        }
    }
};

