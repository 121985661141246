<template>
    <div class="shadow rounded-lg inline-block mr-5" v-on-clickaway="close">
        <div class="relative">
            <button
                @click.prevent="toggle"
                class="rounded-lg inline-flex items-center bg-white hover:text-gray-700 focus:outline-none focus:shadow-outline py-2 px-2 md:px-4 text-sm"
                :class="{
                    'bg-red-100 text-red-800': selected.length < minCount,
                    'text-gray-500': selected.length >= minCount,
                }"
            >
                <span class="inline-block">
                    {{ title }}
                    <span
                        class="inline-flex items-center px-2 py-0.5 rounded-md text-sm font-medium leading-5 ml-2"
                        :class="{
                            'bg-red-100 text-red-800':
                                selected.length < collection.length,
                            'bg-blue-100 text-blue-700':
                                selected.length >= collection.length,
                        }"
                        >{{ selected.length }}/{{ collection.length }}</span
                    >
                </span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 ml-1"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <rect x="0" y="0" width="24" height="24" stroke="none" />
                    <polyline points="6 9 12 15 18 9" />
                </svg>
            </button>

            <div
                v-show="open"
                class="z-40 absolute top-0 left-0 w-40 bg-white rounded-lg shadow-lg mt-12 -mr-1 block pt-1 overflow-hidden"
            >
                <template v-for="(item, index) in collection">
                    <label
                        class="flex justify-start items-center text-truncate hover:bg-gray-100 px-4 py-2 cursor-pointer"
                        :key="index"
                    >
                        <div class="text-teal-600 mr-3">
                            <input
                                type="checkbox"
                                class="form-checkbox focus:outline-none focus:shadow-outline"
                                :checked="valueIsSelected(item.value)"
                                :value="item"
                                @click="handleClick(item)"
                            />
                        </div>
                        <div class="select-none text-gray-700 text-sm">
                            {{ item.title }}
                        </div>
                    </label>
                </template>
                <label
                    class="flex justify-start items-center text-truncate text-white px-4 py-2 cursor-pointer text-center select-none"
                    :class="{
                        'bg-red-100 text-red-800': !noneSelected,
                        'bg-blue-100 text-naef-blue': noneSelected,
                    }"
                    @click="handleSelectAllClick"
                >
                    <div v-if="noneSelected" class="text-blue-700">
                        Tout Sélectionner
                    </div>
                    <div v-else>Désélectionner</div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
// import _ from "underscore";

export default {
    mixins: [clickaway],
    props: {
        title: String,
        label: [String, Number],
        valueField: String,
        collection: {
            required: true,
        },
        minCount: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            open: false,
            selected: [],
        };
    },
    mounted() {
        this.selectAll();
    },
    computed: {
        allSelected() {
            return this.selected.length == this.collection.length;
        },
        noneSelected() {
            return this.selected.length == 0;
        },
    },
    methods: {
        close() {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        },
        valueIsSelected(value) {
            return this.selected.map((x) => x.value).includes(value);
        },
        handleClick(item) {
            if (!this.valueIsSelected(item.value)) {
                let selectedCopy = JSON.parse(JSON.stringify(this.selected));
                selectedCopy.push(item);
                this.selected = selectedCopy;
            } else {
                this.selected = this.selected.filter(
                    (x) => x.value != item.value
                );
                if (this.selected.length == 0) {
                    this.$toast.open({
                        message:
                            "Veuillez sélectionner au moins 1 " + this.title,
                        type: "warning",
                    });
                }
            }
            this.emitChange();
        },
        emitChange() {
            this.$emit("change", this.selected);
        },
        handleSelectAllClick() {
            if (!this.noneSelected) {
                this.selected = [];
                this.$toast.open({
                    message: "Veuillez sélectionner au moins 1 " + this.title,
                    type: "warning",
                });
            } else {
                this.selectAll();
            }
        },
        selectAll() {
            this.selected = this.collection;
            this.emitChange();
        },
    },
    watch: {
        collection(newVal) {
            if (newVal) {
                this.selectAll();
            }
        },
    },
};
</script>

<style>
.form-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    color: currentColor;
    background-color: #fff;
    border-color: #e2e8f0;
    border-width: 1px;
    border-radius: 0.25rem;
    height: 1.2em;
    width: 1.2em;
}

.form-checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: rgb(42, 67, 101);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
