<template>
    <div
        class="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium leading-5 relative select-none  mx-1 h-6 w-6"
        :class="customClass"
        @mouseover="show"
        @mouseleave="hide"
        @click="openSalesTunnel"
    >
        {{ applicationPriority.value }}
        <div
            class="rounded text-sm border border-gray-200 absolute z-50 p-2 text-left"
            :class="customClass"
            v-show="tooltip && showTooltip"
            style="bottom: calc(100% + 0.5rem); left: calc(50% - 3rem)"
        >
            <div>
                {{ applicationPriority.label }} 
                <span v-if="lot">sur le lot {{ lot ? lot.reference : "-" }}</span>
            </div>
            <div v-if="application && applicationPriority.value === 1"
                :class="{
                    'font-bold text-red-700': application.deadline_is_in_less_than_one_day || this.application.is_over_deadline,
                }">
                <span v-if="application.deadline">Réservé jusqu'au {{ application.deadline | date_dmy }}.</span>
                <span v-if="application.deadline_is_in_less_than_one_day && !this.application.is_over_deadline">La réservation expire dans moins de 24h.</span>
                <span v-if="this.application.is_over_deadline">La réservation est expirée, la priorité peut-être supprimée à tout moment.</span>
            </div>
            <span class="text-xs text-blue-600" v-if="click">
                Cliquer pour voir dans le tunnel de vente
            </span>
        </div>
    </div>
</template>

<script>
import PriorityBadge from "@/mixins/PriorityBadge";
import { mapState } from "vuex";

//showTooltip ==> lors du hover
//showTip : forcé via une prop

export default {
    mixins: [PriorityBadge],
    props: {
        application: {
            type: Object,
            required: false,
        },
        lot: {
            type: Object,
            required: false,
        },
        click: {
            type: Boolean,
            required: true,
        },
        tooltip: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            showTooltip: false,
        };
    },
    methods: {
        openSalesTunnel() {
            if (this.click && this.application) {
                this.$router.push({
                    name: "tunnel-de-vente",
                    query: { application: this.application.id },
                });
            }
        },
        show() {
            this.showTooltip = true;
        },
        hide() {
            this.showTooltip = false;
        },
    },
    computed: {
        ...mapState("application", ["priorities"]),
        applicationPriority() {
            return this.priorities.find(
                (x) => x.value === (this.priority || this.application.priority)
            );
        },
        customClass() {
            return {
                [this.applicationPriority.color]: true,
                "border-gray-700": this.click,
                "cursor-pointer": this.click,
                "border-red-700 border-2": !this.priority && (this.application.deadline_is_in_less_than_one_day || this.is_over_deadline),
                "border-dashed": !this.priority && (this.application.deadline_is_in_less_than_one_day && !this.application.is_over_deadline),
                "border": this.priority || ! this.application.deadline_is_in_less_than_one_day,
            };
        },
    },
};
</script>
