var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{on:{"contextmenu":function($event){$event.preventDefault();return (function (e) { return _vm.openCandidateContextMenu(e); }).apply(null, arguments)}}},[(_vm.user.agency.id == 1)?_c('td',[_vm._v(" "+_vm._s(_vm.candidacy.crm_validated ? "Oui" : "")+" ")]):_vm._e(),_c('td',{staticStyle:{"padding":"0 0 0 2px !important"}},[_c('div',[(_vm.candidacy.broker)?_c('img',{staticClass:"h-8 w-8 rounded-full inline-block max-w-none",attrs:{"src":_vm.candidacy.broker.profile_picture,"title":_vm.candidacy.broker.full_name}}):_vm._e()])]),_c('td',{staticStyle:{"padding":"5px 3px !important"}},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"text-left"},[_c('candidate-badge',{attrs:{"candidacy":_vm.candidacy,"canClick":false}})],1)])]),_c('td',{staticStyle:{"padding-left":"3px !important","padding-right":"3px !important"}},[_c('priority-badge',{attrs:{"priority":parseInt(_vm.candidacy.priority)}})],1),_c('td',{staticStyle:{"padding-left":"3px !important","padding-right":"3px !important"}},[_c('status-badge',{attrs:{"status":_vm.candidacy.status_text}})],1),_c('td',{staticStyle:{"padding-left":"3px !important","padding-right":"3px !important"}},[_c('div',{class:{
                'grid-cols-2 grid': _vm.candidacy.applications.length > 1,
            }},_vm._l((_vm.candidacy.applications),function(application,index){return _c('div',{key:index,staticClass:"text-center py-1"},[_c('application-priority-badge',{attrs:{"application":application,"lot":application.lot,"tooltip":true,"click":true}})],1)}),0)]),_c('td',{staticStyle:{"padding-left":"3px !important","padding-right":"3px !important"}},[_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm._f("date_dmyhm")(_vm.candidacy.created_at))+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.candidacy.budget ? _vm.formatCHF(_vm.candidacy.budget) : "-")+" ")]),_c('td',{staticStyle:{"padding-left":"3px !important","padding-right":"3px !important"}},[(_vm.candidacy.candidate.email)?_c('div',{staticClass:"text-xs leading-5 text-gray-500"},[_c('a',{attrs:{"href":("mailto:" + (_vm.candidacy.candidate.email))}},[_vm._v(_vm._s(_vm.candidacy.candidate.email))])]):_vm._e()]),_c('td',{staticStyle:{"padding-left":"3px !important","padding-right":"3px !important"}},[_c('div',[_c('a',{staticClass:"text-xs",attrs:{"href":("tel:" + (_vm.candidacy.candidate.phone))}},[_vm._v(_vm._s(_vm.candidacy.candidate.phone))])])]),_c('td',{staticClass:"whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 font-medium",staticStyle:{"padding":"2px !important"}},[_c('div',{staticClass:"grid gap-1",class:("grid-rows-" + (_vm.actions.length))},[_vm._l((_vm.actions),function(task){return _c('action',{key:task.id,attrs:{"task":task,"taskTargetType":"candidacy"},on:{"update":_vm.emitUpdate}})}),(_vm.applicationTasks && _vm.applicationTasks.length)?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Voir dans le tunnel de vente'),expression:"'Voir dans le tunnel de vente'"}],staticClass:"flex items-start m-auto",attrs:{"to":{
                    name: 'tunnel-de-vente',
                    query: { candidacy: _vm.candidacy.id },
                }}},[_c('div',{staticClass:"badge text-blue-600 bg-white py-1 px-2 rounded shadow mx-1"},[_c('div',{staticClass:"h-6 inline-block align-top"},[_vm._v(" +"+_vm._s(_vm.applicationTasks.length)+" "+_vm._s(_vm.applicationTasks.length > 1 ? "tâches" : "tâche")+" candidature ")])])]):_vm._e()],2)]),_c('td',{staticStyle:{"padding":"5px 3px !important"}},[_c('div',{staticClass:"flex"},[(_vm.hasComments)?_c('span',{attrs:{"title":((_vm.candidacy.user_comments.length) + " " + (_vm.candidacy.user_comments.length > 1
                        ? 'commentaires'
                        : 'commentaire'))}},[_c('svg',{staticClass:"w-6 h-6 fill-current mx-1 text-yellow-400 hover:text-yellow-500 cursor-pointer",attrs:{"fill":"currentColor","viewBox":"0 0 20 20"},on:{"click":function($event){return _vm.clickAction('showComments')}}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z","clip-rule":"evenodd"}})])]):_vm._e(),(_vm.candidacy.files && _vm.candidacy.files.length)?_c('span',{attrs:{"title":((_vm.candidacy.files.length) + " " + (_vm.candidacy.files.length > 1 ? 'documents' : 'document'))}},[_c('svg',{staticClass:"w-6 h-6 fill-current mx-1 text-gray-400 hover:text-gray-600 cursor-pointer",attrs:{"fill":"currentColor","viewBox":"0 0 20 20"},on:{"click":function($event){return _vm.clickAction('showFiles')}}},[_c('path',{attrs:{"d":"M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"}}),_c('path',{attrs:{"d":"M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"}})])]):_vm._e(),(
                    _vm.candidacy.unread_messages_by &&
                    _vm.candidacy.unread_messages_by.length
                )?_c('span',{attrs:{"title":((_vm.candidacy.unread_messages_by.length) + " message(s) non lu(s)")}},[_c('router-link',{attrs:{"to":{
                        name: 'message',
                        query: { candidacy: _vm.candidacy.id },
                    }}},[_c('svg',{staticClass:"w-5 h-5 fill-current mx-1 text-red-500 hover:text-red-600 cursor-pointer",attrs:{"fill":"currentColor","viewBox":"0 0 20 20"},on:{"click":function($event){return _vm.clickAction('showFiles')}}},[_c('path',{attrs:{"d":"M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z"}})])])],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }