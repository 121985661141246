import axios from "axios";

export default {
    create(promotionId, data) {
        return axios.post(`/promotions/${promotionId}/question`, data);
    },
    delete(promotionId, id) {
        return axios.delete(`/promotions/${promotionId}/questions/${id}`);
    },
    get(promotionId){
        return axios.get(`/promotions/${promotionId}/questions`);
    }
};
