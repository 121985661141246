import application from "@/api/application";

const candidateInitialState = () => {
    return {
        first_name: null,
        maiden_name: null,
        last_name: null,
        gender: "M",
        father_first_name: null,
        mother_first_name: null,
        mother_maiden_name: null,
        birthday: null,
        birthday_city: null,
        place_origin: null,
        establishment_permit: null,
        job: null,
        civil_status: null,
        matrimonial_property: null,
        address: null,
        city: null,
        zip: null,
        phone_private: null,
        phone_pro: null,
        phone: null,
        email: null,
        avs: null
    };
};

const financialInitialState = () => {
    return {
        mortgage_bank_name: null,
        mortgage_contact_name: null,
        mortgage_amount: null,
        second_pillar_bank_name: null,
        second_pillar_contact_name: null,
        second_pillar_amount: null,
        third_pillar_bank_name: null,
        third_pillar_contact_name: null,
        third_pillar_amount: null,
    };
};

const initialState = () => {
    return {
        candidacy1 : {
            candidate : candidateInitialState(),
            ...financialInitialState(),
        },
        candidacy2 : {
            candidate : candidateInitialState(),
            ...financialInitialState(),
        },
        application : null,
        feedback : {
            type : null,
            message : null,
        },
        step : 1,
        nbSteps : 5,
    };
};

const state = initialState();

const getters = {
    mainCandidate : (state) => state.candidacy1.candidate,
    secondCandidate : (state) => state.candidacy2.candidate,
    candidacy1 : (state) => state.candidacy1,
    candidacy2 : (state) => state.candidacy2,
    application : (state) => state.application,
    feedback : (state) => state.feedback,
    step : (state) => state.step,
    nbSteps : (state) => state.nbSteps,
};

const actions = {
    reset({commit}){
        commit("RESET");
    },
    loadApplication({commit}, id){
        application
            .getOnePublic(id)
            .then((response) => {
                if (response.status === 200) {
                    const application = response.data.data;
                    
                    commit("SET_APPLICATION", application);
                    commit("SET_CANDIDACY_1", application.candidacy1);
                    commit("SET_CANDIDACY_2", application.candidacy2);
                } else {
                    this.application = null;
                    this.feedback.type = "error";
                    this.feedback.message = "Lien invalide";
                }
            })
            .catch(() => {
                this.application = null;
                this.feedback.type = "error";
                this.feedback.message = "Lien invalide";
            });
      
    },
    next({commit, state}){
        if(state.step < state.nbSteps){
            commit("INCREMENT_STEP");
        }
    },
    prev({commit, state}){
        if(state.step > 1){
            commit("DECREMENT_STEP");
        }
    },
    goTo({commit, state}, step){
        if(step > 0 && step <= state.nbSteps){
            commit("SET_STEP", step);
        }
    },
};

const mutations = {
    RESET(state){
        Object.assign(state, initialState());
    },
    SET_CANDIDACY_1(state, candidacy1){
        if(candidacy1)
            state.candidacy1 = candidacy1;
    },
    SET_CANDIDACY_2(state, candidacy2){
        if(candidacy2)
            state.candidacy2 = candidacy2;
    },
    SET_MAIN_CANDIDATE_DATA(state, data){
        // data = key => value
        let newValue = {};
        newValue[data.key] = data.value;
        state.candidacy1 = {
            ...state.candidacy1,
            candidate : {
                ...state.candidacy1.candidate,
                ...newValue
            }
        };
    },
    SET_CANDIDACY_1_DATA(state, data){
        // data = key => value
        let newValue = {};
        newValue[data.key] = data.value;
        state.candidacy1 = {
            ...state.candidacy1,
            ...newValue
        };
    },
    SET_SECOND_CANDIDATE_DATA(state, data){
        // data = key => value
        let newValue = {};
        newValue[data.key] = data.value;
        state.candidacy2 = {
            ...state.candidacy2,
            candidate : {
                ...state.candidacy2.candidate,
                ...newValue
            }
        };
    },
    SET_CANDIDACY_2_DATA(state, data){
        // data = key => value
        let newValue = {};
        newValue[data.key] = data.value;
        state.candidacy2 = {
            ...state.candidacy2,
            ...newValue
        };
    },
    SET_APPLICATION(state, application){
        state.application = {...application};
    },
    SET_FEEDBACK(state, value){
        state.feedback = value;
    }, 
    SET_STEP(state, step){
        state.step = step;
    },
    INCREMENT_STEP(state, steps=1){
        state.step += steps;
    },
    DECREMENT_STEP(state, steps=1){
        state.step -= steps;
    }
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations
};
