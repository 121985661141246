<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 p-2 gap-2">
    <div class="p-2 rounded-md flex flex-col space-y-3" v-if="user.admin">
      <div class="font-semibold text-sm text-gray-700">Ajouter un architecte</div>
      <div>
        <picture-select
          ref="bankerselect"
          :searchProps="['full_name']"
          :collection="availableArchitects"
          :value="form.architect_id"
          textProp="full_name"
          imgProp="profile_picture"
          defaultText="Sélectionner un architecte"
          valueProp="id"
          @change="
            (val) => {
              handleArchitectChange(val);
            }
          "
        />
      </div>
      <div class="">
        <button
          class="
            bg-blue-400
            hover:bg-blue-600
            text-white
            px-2
            py-1
            font-medium font-semibold
            transition-all
            duration-300
            hover:shadow
            focus:outline-none
          "
          @click="addBanker"
        >
          Ajouter
        </button>
      </div>
      <button
        @click="openCreateArchitect = true"
        class="
          pb-2
          pt-1
          px-4
          border border-transparent
          text-sm
          font-medium
          rounded-sm
          text-white
          bg-blue-400
          hover:bg-blue-600
          focus:outline-none focus:border-green-700
          transition
          duration-150
          ease-in-out
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          class="h-4 w-4 inline-block fill-current align-middle mr-2"
        >
          <path
            d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
          ></path></svg
        ><span class="align-middle">Créer un architecte</span>
      </button>
    </div>
    <div>
      <architects-list
        :architects="promotion.architects"
        :promotion="promotion"
        :edit="true"
        @remove="emitUpdate"
      ></architects-list>
    </div>
    <add-architect-modal
      :open="openCreateArchitect"
      :promotion="promotion"
      @close="openCreateArchitect = false"
      @submit="createArchitect"
    />
  </div>
</template>

<script>
import axios from "axios";

import ArchitectsList from "@/components/Architects/ArchitectsList";
import PictureSelect from "@/components/Forms/PictureSelect";
import architects from "@/api/architects";
import { pluck } from "underscore";
import promotion from "@/api/promotion";
import { mapGetters } from "vuex";

import AddArchitectModal from "@/components/Promotion/modals/AddArchitectModal";

export default {
    components: {
        ArchitectsList,
        PictureSelect,
        AddArchitectModal,
    },
    props: {
        promotion: {
            type: Object,
            required: true,
        },
    },
    data() {
        return this.initialState();
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters("auth", ["user"]),
        availableArchitects() {
            let promotionArchitectsIds = pluck(this.promotion.architects, "id");
            return this.architects.filter((el) => {
                return promotionArchitectsIds.indexOf(el.id) < 0;
            });
        },
    },
    methods: {
        initialState() {
            return {
                form: {
                    architect_id: null,
                },
                architects: [],
                openCreateArchitect: false,
            };
        },
        init() {
            this.$store.commit("loader/SET_LOADING", true);
            architects.get().then((res) => {
                this.architects = res.data.data;
                this.$store.commit("loader/SET_LOADING", false);
            });
        },
        handleArchitectChange(val) {
            this.architect_id = val;
        },
        emitUpdate() {
            this.$emit("update");
        },
        addBanker() {
            this.$store.commit("loader/SET_LOADING", true);
            promotion.addArchitect(this.promotion.id, this.architect_id).then(() => {
                this.$store.commit("loader/SET_LOADING", false);
                this.emitUpdate();
            });
        },
        async createArchitect(banker) {
            const data = await axios.post(
                `/promotions/${this.promotion.id}/architects`,
                banker
            );
            this.architects.push(data.data);

            this.openCreateArchitect = false;
            this.$emit("update");
        },
    },
};
</script>

<style>
</style>