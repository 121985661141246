<template>
  <main>
    <p>candidate dashboard - todo</p>
  </main>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    methods: {},
    computed: {}
};
</script>
