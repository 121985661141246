import candidate from "@/api/candidate";
import modals from "../partials/modals";
import contextMenu from "../partials/contextMenu";
const state = {
    ...contextMenu.state,
    ...modals.state,
    candidates: [],
    currentTask: null,
    selectedMenu : null,
    
    statuses: [
        {
            value: 0,
            label: "Prospect",
            color: "bg-gray-500 text-white",
        },
        {
            value: 1,
            label: "Non retenu",
            color: "bg-white text-gray-500",
        },
        {
            value: 2,
            label: "Non retenu CP",
            color: "bg-white text-gray-500",
        },
        {
            value: 3,
            label: "Client",
            color: "bg-orange-300 text-white",
        },
        {
            value: 4,
            label: "Client acquéreur",
            color: "bg-orange-400 text-white",
        },
        {
            value: 5,
            label: "Promettant acquéreur",
            color: "bg-green-300 text-white",
        },
        {
            value: 6,
            label: "Acquéreur",
            color: "bg-green-600 text-white",
        },
    ],
    priorities: [
        {
            value: 1,
            label: "A",
            full_label: "Note attribuée : A",
            color: "bg-green-300 text-white",
        },
        {
            value: 2,
            label: "B",
            full_label: "Note attribuée : B",
            color: "bg-orange-300 text-white",
        },
        {
            value: 3,
            label: "C",
            full_label: "Note attribuée : C",
            color: "bg-gray-300 text-white",
        },
        {
            value: 9,
            label: "NP",
            full_label: "Non prioritaire",
            color: "bg-red-300 text-white",
        },
    ],
};

const getters = {
    ...contextMenu.getters,
    ...modals.getters,
    selectedMenu: (state)=>{
        return state.selectedMenu;
    },
    candidates: (state) => {
        return state.candidates;
    },
    // parentCandidates : state => {
    //     return state.pa
    // },
    allCandidates: (state) => {
        return state.candidates;
    },
    statuses: (state) => {
        return state.statuses;
    },
    priorities: (state) => {
        return state.priorities;
    },
    currentTask: (state) => {
        return state.currentTask;
    },
};

const actions = {
    get({ commit, rootGetters }) {
        return candidate
            .get({
                "promotion": rootGetters["auth/currentPromotion"].id,
                "all": true
            })
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_CANDIDATES", response.data.data);
                }
                return response;
            });
    },

    create({ commit, rootGetters }, body) {
        const promotionId = rootGetters["auth/currentPromotion"].id;
        return candidate
            .create(body, promotionId)
            .then((response) => {
                if (response.status === 200) {
                    commit("ADD_CANDIDATE", response.data.data);
                }
                return response;
            });
    },

    update({ rootGetters }, body) {
        return candidate
            .update(body.id, {
                ...body,
                promotion_id: rootGetters["auth/currentPromotion"].id,
            })
            .then((response) => {
                return response;
            });
    },

    updateComment(ctx, body) {
        return candidate
            .updateComment(body.id, body)
            .then((response) => {
                return response;
            });
    },

    addAction({ commit, rootGetters }, body) {
        return candidate
            .createAction(rootGetters["auth/currentPromotion"].id, body)
            .then((response) => {
                if (response.status === 200) {
                    commit("ADD_CANDIDATE_ACTION", response.data.data);
                }
                return response;
            });
    },

    updateAction({ commit, rootGetters }, body) {
        return candidate
            .updateAction(
                rootGetters["auth/currentPromotion"].id,
                body.action_id,
                body
            )
            .then((response) => {
                if (response.status === 200) {
                    commit("UPDATE_CANDIDATE_ACTION", response.data.data);
                }
                return response;
            });
    },

    addComment({ commit }, body) {
        return candidate
            .createComment(body)
            .then((response) => {
                if (response.status === 200) {
                    commit("ADD_CANDIDATE_COMMENT", response.data.data);
                }
                return response;
            });
    },
    closeModals({commit}){
        commit("SET_CURRENT_ACTION_TARGET", null);
        commit("SET_CURRENT_ACTION_TYPE", null);
    }
};

const mutations = {
    ...contextMenu.mutations,
    ...modals.mutations,

    SET_CANDIDATES(state, value) {
        state.candidates = value;
    },

    SET_CURRENT_TASK(state, value) {
        state.currentTask = value;
    },

    //in candidate.view
    SET_SELECTED_MENU(state, value){
        state.selectedMenu = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
