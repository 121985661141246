<template>
    <div class="flex flex-col" v-if="photoGroups && photoGroups.data.length">
        <album-row
            v-for="photoGroup in photoGroups.data"
            :key="photoGroup.id"
            :photoGroup="photoGroup"
            @addPhoto="addPhoto"
            @edit="openEditModal"
            @delete="openDeleteModal"
            @update="emitUpdate"
        ></album-row>
        <the-pagination
            v-if="photoGroups"
            :lastPage="photoGroups.last_page"
            :currentPage="photoGroups.current_page"
            :from="photoGroups.from"
            :to="photoGroups.to"
            :total="photoGroups.total"
        ></the-pagination>
    </div>
</template>

<script>
import AlbumRow from "@/components/Progression/AlbumRow";
import ThePagination from "@/components/global/ThePagination";

export default {
    components: {
        AlbumRow,
        ThePagination
    },
    methods: {
        addPhoto(photoGroup) {
            this.$emit("addPhoto", photoGroup);
        },
        openEditModal(photoGroup) {
            this.$emit("openEditModal", photoGroup);
        },
        openDeleteModal(photoGroup) {
            this.$emit("openDeleteModal", photoGroup);
        },
        emitUpdate(){
            this.$emit("update");
        }
    },
    props: {
        photoGroups: {
            type: Object,
            required: true,
        },
    }
};
</script>
