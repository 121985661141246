<template>
    <main>
        <div class="py-5 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="flex items-center justify-between pb-2">
                <div>
                    <reset-query-link
                        v-if="$route.query.candidacy || $route.query.application || appFilter || candidacyFilter"
                    ></reset-query-link>
                </div>
                <div class="flex items-center space-x-4">
                    <div class="flex items-center">
                        <span class="mr-2 text-sm font-medium text-gray-500">Tâches en retard :</span>
                        <!-- :PurgeTailwindCSS -->
                        <OnOffSimple
                            onColor="red-400" 
                            offColor="gray-400"
                            :value="$store.getters['app/filters'].onlyLateTasks"
                            @input="$store.commit('app/SET_FILTER', {
                                key : 'onlyLateTasks',
                                value : $event
                            })"
                        />
                    </div>
                    <div class="flex items-center">
                        <span class="mr-2 text-sm font-medium text-gray-500">Vue simplifiée :</span>
                        <!-- :PurgeTailwindCSS -->
                        <OnOffSimple
                            onColor="green-400"
                            offColor="gray-400"
                            v-model="simpleView"
                        />
                    </div>
                    <button
                    v-if="isBroker"
                        @click="downloadApplications"
                        class="ml-3 pb-2 pt-1 px-3 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                    >
                        <svg class="inline-block w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path 
                                fill-rule="evenodd"
                                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                                clip-rule="evenodd"
                            >
                            </path
                        ></svg>
                        <span class="align-middle ml-2"
                            >Exporter</span
                        >
                    </button>                    
                </div>
            </div>
            <sales-tunnel-table
                :simple-view="simpleView"
                :applications="applications"
                :count="count"
                @update="fetchData"
            ></sales-tunnel-table>
        </div>
        
        <mark-as-complete-modal
            @update="fetchData"
        ></mark-as-complete-modal>
        <send-notification-incomplete-modal
            @update="fetchData"
        ></send-notification-incomplete-modal>
        <send-reservation-email-modal
            @update="fetchData"
        ></send-reservation-email-modal>
        <schedule-agreement-modal
            @update="fetchData"
        ></schedule-agreement-modal>
        <plan-act-of-purchase-modal
            @update="fetchData"
        ></plan-act-of-purchase-modal>
        <update-priority-modal
            @update="fetchData"
        ></update-priority-modal>
        <show-task-modal
            :targetOverride="currentTaskTarget"
            @update="fetchData"
        ></show-task-modal>
        <edit-task-modal
            v-if="!isBanker"
            :targetOverride="currentTaskTarget"
            @update="fetchData"
        ></edit-task-modal>
        <add-task-modal
            @createdTask="fetchData"
        ></add-task-modal>
        <delete-application-modal
            @update="fetchData"
        ></delete-application-modal>
        <agreement-paid-modal
            @update="fetchData"
        ></agreement-paid-modal>
        <reservation-deposit-paid-modal
            @update="fetchData"
        ></reservation-deposit-paid-modal>
        <act-of-purchase-signed
            @update="fetchData"
        ></act-of-purchase-signed>
        <add-candidacy-modal @update="fetchData"></add-candidacy-modal>
        <remove-partner-modal @update="fetchData"></remove-partner-modal>
        <client-solvent  @update="fetchData"> </client-solvent>
        <client-non-solvent
            @update="fetchData"
        ></client-non-solvent>
        <confirm-received-agreement-funds
            @update="fetchData"
        ></confirm-received-agreement-funds>
        <send-act  @update="fetchData"></send-act>
        <sign-act  @update="fetchData"></sign-act>
        <send-call-for-funds
            @update="fetchData"
        ></send-call-for-funds>
        <application-dates ></application-dates>
        <update-statuses
            v-if="!isBanker"
            @update="fetchData"
        ></update-statuses>
        <edit-candidate-modal
            @update="fetchData"
        ></edit-candidate-modal>
        <update-negociated-modal
            v-if="currentActionTarget"
            @update="fetchData"
        ></update-negociated-modal>
        <add-thread-modal></add-thread-modal>
        <DeleteCandidateModal @delete="fetchData"></DeleteCandidateModal>
        <SalesTunnelContextMenu></SalesTunnelContextMenu>
        <confirm-send-access/>
        <!-- candidate modals -->
        <comments-list-modal
                @update="fetchData"
        ></comments-list-modal>
        <tasks-history-modal/>
        <files-list-modal @update="fetchData"/>
    </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import application from "@/api/application";

import SalesTunnelTable from "@/components/SalesTunnel/SalesTunnelTable";
import MarkAsCompleteModal from "@/components/SalesTunnel/modals/MarkAsCompleteModal";
import SendNotificationIncompleteModal from "@/components/SalesTunnel/modals/SendNotificationIncompleteModal";
import SendReservationEmailModal from "@/components/SalesTunnel/modals/SendReservationEmailModal";
import ScheduleAgreementModal from "@/components/SalesTunnel/modals/ScheduleAgreementModal";
import UpdatePriorityModal from "@/components/SalesTunnel/modals/UpdatePriorityModal";
import ShowTaskModal from "@/components/Candidates/modals/ShowTaskModal";
import EditTaskModal from "@/components/Candidates/modals/EditTaskModal";
import AddTaskModal from "@/components/Candidates/modals/AddTaskModal";
import PlanActOfPurchaseModal from "@/components/SalesTunnel/modals/PlanActOfPurchaseModal";
import DeleteApplicationModal from "@/components/Candidates/modals/DeleteApplicationModal";
import AgreementPaidModal from "@/components/SalesTunnel/modals/AgreementPaidModal";
import ReservationDepositPaidModal from "@/components/SalesTunnel/modals/ReservationDepositPaidModal";
import ActOfPurchaseSigned from "@/components/SalesTunnel/modals/ActOfPurchaseSigned";
import ClientSolvent from "@/components/SalesTunnel/modals/ClientSolvent";
import ClientNonSolvent from "@/components/SalesTunnel/modals/ClientNonSolvent";
import ConfirmReceivedAgreementFunds from "@/components/SalesTunnel/modals/ConfirmReceivedAgreementFunds";
import SendAct from "@/components/SalesTunnel/modals/SendAct";
import SignAct from "@/components/SalesTunnel/modals/SignAct";
import SendCallForFunds from "@/components/SalesTunnel/modals/SendCallForFunds";
import ApplicationDates from "@/components/SalesTunnel/modals/ApplicationDates";
import UpdateStatuses from "@/components/SalesTunnel/modals/UpdateStatuses";
import ResetQueryLink from "@/components/global/ResetQueryLink";
import EditCandidateModal from "@/components/Candidates/modals/EditModal";
import UpdateNegociatedModal from "@/components/SalesTunnel/modals/UpdateNegociated";
import OnOffSimple from "@/components/Forms/OnOffSimple";
import DeleteCandidateModal from "@/components/Candidates/modals/DeleteModal";
import SalesTunnelContextMenu from "@/components/SalesTunnel/contextMenu/Menu";
import CommentsListModal from "@/components/Candidates/modals/CommentsListModal";
import TasksHistoryModal from "@/components/Candidates/modals/TasksHistoryModal";
import FilesListModal from "@/components/Candidates/modals/FilesListModal";
import AddCandidacyModal from "@/components/SalesTunnel/modals/AddCandidacyModal";
import RemovePartnerModal from "@/components/SalesTunnel/modals/RemovePartnerModal";
import AddThreadModal from "@/components/Messages/modal/AddThreadModal";
import ConfirmSendAccess from "@/components/SalesTunnel/modals/ConfirmSendAccess";

import Auth from "@/mixins/Auth";
import View from "@/mixins/View";

export default {
    mixins: [Auth, View],

    name: "tunnel-de-vente",
    components: {
        SalesTunnelTable,
        MarkAsCompleteModal,
        SendNotificationIncompleteModal,
        SendReservationEmailModal,
        ScheduleAgreementModal,
        UpdatePriorityModal,
        ShowTaskModal,
        EditTaskModal,
        AddTaskModal,
        ResetQueryLink,
        DeleteApplicationModal,
        ReservationDepositPaidModal,
        AgreementPaidModal,
        PlanActOfPurchaseModal,
        ActOfPurchaseSigned,
        ClientSolvent,
        ClientNonSolvent,
        ConfirmReceivedAgreementFunds,
        SendAct,
        SignAct,
        SendCallForFunds,
        ApplicationDates,
        UpdateStatuses,
        EditCandidateModal,
        UpdateNegociatedModal,
        OnOffSimple,
        DeleteCandidateModal,
        SalesTunnelContextMenu,
        CommentsListModal,
        TasksHistoryModal,
        FilesListModal,
        AddCandidacyModal,
        RemovePartnerModal,
        ConfirmSendAccess,
        AddThreadModal,
    },
    data() {
        return {
            currentTaskTarget: "application",
            simpleView: false,
            onlyLateTasks: false,
        };
    },
    computed: {
        ...mapState("application", [
            "currentActionTarget",
            "currentActionType",
            "applications",
            "count"
        ]),
        ...mapGetters("application",["applications"]),
        ...mapGetters("auth", ["preferences"]),
        currentActionTypeCandidate() {
            return this.$store.getters["candidate/currentActionType"];
        },
        currentActionTargetCandidate() {
            return this.$store.getters["candidate/currentActionTarget"];
        },
        appFilter(){
            return this.$store.state.app.filters.application;
        },
        candidacyFilter(){
            return this.$store.state.app.filters.candidacy;
        }
    },

    methods: {
        fetchData() {
            this.$store.dispatch("application/get");
        },
        closeModals() {
            for (var i in this.modals) {
                this.modals[i] = false;
            }
        },
        downloadApplications() {
            application.download({
                promotion : this.preferences.selected_promotion
            })
                .then((res) => {
                    window.open(res.data.url, "_blank");
                });
        },        
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Tunnel de vente";
            }
        },
    },     
};
</script>
