import promotion from "@/api/promotion";

function initialState(){
    return {
        promotions: null,
    };
}

const state = initialState();

const getters = {
    promotions: (state) => {
        return state.promotions;
    }
};

const actions = {
    get({ commit }) {
        return promotion
            .get()
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_PROMOTIONS", response.data.data);
                }
                return response;
            })
            .catch((error) => {
                return error;
            });
    },

    /*candidateGet({ commit }) {
        return promotion
            .candidateGet()
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_PROMOTIONS", [response.data.data]);
                }
                return response;
            })
            .catch((error) => {
                return error;
            });
    },*/
    // notaryGet({ commit }) {
    //     return promotion
    //         .notaryGet()
    //         .then((response) => {
    //             if (response.status === 200) {
    //                 commit("SET_PROMOTIONS", response.data.data);
    //             }
    //             return response;
    //         })
    //         .catch((error) => {
    //             return error;
    //         });
    // },

    getOne({ commit }, id) {
        return promotion
            .getOne(id)
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_CURRENT_PROMOTION", response.data.data);
                }
                return response;
            })
            .catch((error) => {
                return error;
            });
    },

    /*candidateGetOne({ commit }, id) {
        return promotion
            .candidateGetOne(id)
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_CURRENT_PROMOTION", response.data.data);
                }
                return response;
            })
            .catch((error) => {
                return error;
            });
    },*/

    // notaryGetOne({ commit }, id) {
    //     return promotion
    //         .notaryGetOne(id)
    //         .then((response) => {
    //             if (response.status === 200) {
    //                 commit("SET_CURRENT_PROMOTION", response.data.data);
    //             }
    //             return response;
    //         })
    //         .catch((error) => {
    //             return error;
    //         });
    // },

    addCampaign({ rootGetters }, body) {
        return promotion
            .createCampaign(body, rootGetters["auth/currentPromotion"].id);
    },

    addPhoto(root, body) {
        return promotion.addPhoto(body, body.get("promotion_id"));
    },

    addPhotoGroup(root, body) {
        return promotion.addPhotoGroup(body, body.promotion_id);
    },

    editPhotoGroup(root, body) {
        return promotion.editPhotoGroup(body, body.promotion_id, body.id);
    },

    removePhoto({rootGetters}, body) {
        return promotion.removePhoto(rootGetters["auth/currentPromotion"].id, body.id);
    },

    removePhotoGroup({rootGetters}, body) {
        return promotion.removePhotoGroup(rootGetters["auth/currentPromotion"].id, body.id);
    },

    addFile({ rootGetters}, body) {
        return promotion.createFile(body, rootGetters["auth/currentPromotion"].id);
    },

    updateFile({ rootGetters }, body) {
        return promotion.updateFile(body, rootGetters["auth/currentPromotion"].id, body.get("file_id"));
    },

    deleteFile({ rootGetters }, fileId) {
        return promotion
            .deleteFile(rootGetters["auth/currentPromotion"].id, fileId);
    },

    create({commit}, data){
        return promotion
            .create(data)
            .then((res) => {
                commit("CREATE", res.data.data);
                return res.data;
            });
    },

    clear({commit}){
        commit("CLEAR");
    }
};

const mutations = {
    SET_PROMOTIONS(state, value) {
        state.promotions = value;
    },

    SET_CURRENT_PROMOTION(state, value) {
        state.currentPromotion = value;
    },

    ADD_PROMOTION_CAMPAIGN(state, value) {
        state.currentPromotion.campaigns.push(value);
    },

    ADD_PROMOTION_PHOTO(state, value) {
        const index = state.currentPromotion.photo_groups.findIndex(
            (photo_group) => photo_group.id === value.id
        );
        state.currentPromotion.photo_groups.splice(index, 1, value);
    },

    ADD_PROMOTION_FILE(state, value) {
        if (!state.currentPromotion.promotion_files) {
            state.currentPromotion.promotion_files = [];
        }
        state.currentPromotion.promotion_files.push(value);
    },

    UPDATE_PROMOTION_FILE(state, value) {
        let index = state.currentPromotion.promotion_files.findIndex(
            (file) => file.id === value.id
        );
        if (index > -1) {
            state.currentPromotion.promotion_files.splice(index, 1, value);
        }
    },

    DELETE_PROMOTION_FILE(state, value) {
        let index = state.currentPromotion.promotion_files.findIndex(
            (file) => file.id === value
        );
        if (index > -1) {
            state.currentPromotion.promotion_files.splice(index, 1);
        }
    },

    ADD_PROMOTION_PHOTO_GROUP(state, value) {
        state.currentPromotion.photo_groups.push(value);
    },

    EDIT_PROMOTION_PHOTO_GROUP(state, value) {
        let index = state.currentPromotion.photo_groups.findIndex(
            (photoGroup) => photoGroup.id === value.id
        );
        state.currentPromotion.photo_groups.splice(index, 1, value);
    },

    REMOVE_PROMOTION_PHOTO(state, value) {
        state.currentPromotion.photo_groups.forEach((group, i) => {
            if (group.photos) {
                let index = group.photos.findIndex(
                    (photo) => photo.id === value.id
                );
                if (index > -1) {
                    state.currentPromotion.photo_groups[i].photos.splice(
                        index,
                        1
                    );
                }
            }
        });
    },

    REMOVE_PROMOTION_PHOTO_GROUP(state, value) {
        let index = state.currentPromotion.photo_groups.findIndex(
            (pg) => pg.id === value.id
        );
        if (index > -1) {
            state.currentPromotion.photo_groups.splice(index, 1);
        }
    },

    CREATE(state, data){
        if(state.promotions){
            state.promotions = [...state.promotions, data];
        }else{
            state.promotions = data;
        }
    },

    CLEAR(state){
        Object.assign(state, initialState());
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
