<template>
  <div
      class="step flex justify-between h-10 lg:h-16 cursor-pointer select-none"
      :class="{active : stepNumber === step}"
      @click="goToStep"
  >
      <!-- <div v-if="stepNumber > 1" class="flex-shrink inset-arrow z-10 w-0 h-0 inset-y-0 hidden lg:flex"></div> -->
      <div class="flex-grow flex items-center font-medium text-sm">
		<div class="flex flex-shrink items-center pl-3">
			<span class="w-6 h-6 text-xs lg:text-sm lg:h-10 lg:w-10" :class="circleClass">
				{{stepNumber}}
			</span>
		</div>
		<div class="flex-grow pr-3 text-center">
			{{title}}
		</div>
      </div>
      <!-- <div class="flex-shrink right-arrow z-10 w-0 h-0 inset-y-0 hidden lg:flex" v-if="stepNumber < nbSteps"></div> -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    props : {
        stepNumber : {
            type : Number,
            required  : true
        },
        title : {
            type : String,
            required : true
        },
        description : {
            type : String,
            required : false,
            default : ""
        }
    },
    computed : {
        ...mapGetters("reservationForm", ["step", "nbSteps"]),
        circleClass(){
            if(this.step === this.stepNumber){
                return "border-2 border-white rounded-full flex items-center justify-center";
            }
            return "border-2 border-blue-900 rounded-full flex items-center justify-center";
        }
    },
    methods : {
        goToStep(){
            this.$store.dispatch("reservationForm/goTo", this.stepNumber);
        }
    }
};
</script>

<style scoped>
.step{
	color : #09014c;
	background-color: white;
}
.right-arrow{
  border-color: transparent;
  border-left-color: white;
}
.step.active{
	color : white;
	background-color: #09014c;
}
.step.active .right-arrow{
  border-left-color: #09014c;
}
.right-arrow {
    right: -10px;
    border-left-width: 10px;
    border-style: solid;
    border-color: white;
    border-top: 33px solid #edf2f7;
    border-bottom: 33px solid #edf2f7;
}
.inset-arrow {
    border-left-width: 10px;
    border-left-color: #edf2f7 !important;
    border-style: solid;
    border-top: 33px solid white;
    border-bottom: 33px solid white;
}
.step.active .inset-arrow{
  border-color: #09014c;
  border-left-color: #edf2f7 !important;
}
</style>