<template>
    <div class="p-4">
        <div class="text-lg font-semibold text-blue-600 mb-2">Informations</div>

        <div class="mr-4 mb-4 flex items-center">
            <div class="font-semibold text-sm text-gray-800">ID promotion</div>
            <div class="text-gray-700 px-2">
                {{ this.promotion.id }}
            </div>
            <div class="font-semibold text-sm text-gray-800 pr-2">Titre</div>
            <div class="text-gray-700 w-full">
                <input
                    type="text"
                    class="form-input"
                    v-model="form.title"
                    @input="setChanged"
                />
            </div>
        </div>

        <!-- Prestige -->
            <span class="font-semibold text-sm text-gray-800"
                >Prestige :
            </span>
            <!-- On ne peut pas utiliser de v-model ici, je ne sais pas trop pourquoi… -->
            <!-- Dans la version précédente, il y avait un commentaire "force deep update" -->
            <!-- Ça serait bien de vérifier également si le 0/1 est obligatoire ou si il -->
            <!-- est possible d'utiliser directement true/false -->
            <on-off-simple
                onColor="green-400"
                offColor="gray-500"
                :value="form.prestige"
                @input="
                    form = {
                        ...form,
                        prestige: $event ? 1 : 0,
                    }
                "
        />
        
        
        <div class="flex items-center pt-4">
            <!-- Logo de la promotion -->
            <label
            for="photo"
            class="block text-sm leading-5 font-medium text-gray-700"
            >
            Logo
            </label>
            <div class="mt-2 sm:mt-0 sm:col-span-2">
                <div v-if="logo.open">
                    <my-upload
                        field="img"
                        @crop-success="cropSuccess"
                        v-model="logo.open"
                        :width="150"
                        :height="150"
                        lang-type="fr"
                        img-format="png"
                        ></my-upload>
                    </div>
                <div class="flex items-center">
                    <img
                        class="h-12 w-12 rounded-full"
                        v-if="logo.base64"
                        :src="logo.base64"
                        />
                        <img
                        class="h-12 w-12 rounded-full"
                        v-else-if="
                            promotion.logo_full_path
                        "
                        :src="promotion.logo_full_path"
                    />
                    <span
                    v-else
                        class="h-12 w-12 rounded-full overflow-hidden bg-gray-100"
                        >
                        <svg
                            class="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            >
                            <path
                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                        </svg>
                    </span>
                    <span
                        class="ml-5 rounded-md shadow-sm"
                        >
                        <button
                            @click="openUpload"
                            v-if="user.admin"
                            type="button"
                            class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                            >
                            Modifier
                        </button>
                    </span>
                </div>
            </div>
            
            
        </div>
        <!-- Lien du formulaire de la promotion -->
        <div v-if="!isDemo" class="py-2">
            <label for=""> Lien du formulaire d'inscription : </label>
            <a class="text-xs text-blue-600 underline" :href="iFrameLink">
                {{ iFrameLink }}
            </a>
        </div>

        <div class="font-semibold text-sm text-gray-800 pt-4">
            Url
            <span class="text-xs">(ex : http://www.naef.ch)</span>
        </div>
        <div class="text-gray-700 flex flex-row space-x-2 items-center">
            <input
                type="text"
                class="form-input"
                v-model="form.url"
                @input="setChanged"
            />
            <a
                v-if="form.url"
                :href="hrefValue"
                target="_blank"
                class="
                    button
                    rounded-sm
                    text-white
                    transition-colors
                    duration-300
                    font-semibold
                    py-1
                    px-3
                    bg-blue-400
                    hover:bg-blue-600
                    cursor-pointer
                "
            >
                Tester
            </a>
        </div>
        
        <div class="py-2 pt-4">
            <div>
                <div class="font-semibold text-sm text-gray-800">
                    Looker Studio URL iframe
                    <span class="text-xs"></span>
                </div>
            </div>
            <div class="text-gray-800 flex flex-row space-x-2 items-center">
                <input
                type="text"
                class="form-input"
                v-model="form.external_report_url"
                @input="setChanged"
                />
            </div>
        </div>
        
        <div class="py-2">
            <div>
                <div class="font-semibold text-sm text-gray-800">
                    Google tag manager
                    <span class="text-xs">(ex : GTM-1A3B5C6)</span>
                </div>
            </div>
            <div class="text-gray-800 flex flex-row space-x-2 items-center">
                <input
                type="text"
                class="form-input"
                v-model="form.gtm"
                @input="setChanged"
                />
            </div>
        </div>
        
        <!-- Immeubles -->
        <div>
            <div class="font-semibold text-sm text-gray-800">Immeubles</div>
            <div class="py-2 flex flex-row space-x-2">
                <div
                class="
                rounded-md
                overflow-hidden
                border-2 border-blue-400
                shadow-sm
                "
                v-for="building in promotion.buildings"
                :key="building.id"
                >
                    <div
                    class="
                    flex
                    text-md
                    font-semibold
                            justify-center
                            text-white
                            bg-blue-400
                        "
                    >
                        {{ building.title }}
                    </div>
                    <div class="p-1">
                        <p class="text-gray-500">
                            {{ building.city }} {{ building.zip }}
                        </p>
                        <p class="text-gray-500">{{ building.street }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="select-none">
            <div class="mt-8 text-lg font-semibold text-blue-600">
                Paramètres
            </div>
            <div class="border-b py-4">
                <span class="font-semibold text-sm text-gray-800"
                    >Inclure le prix des lots associés dans le prix affiché :
                </span>
                <!-- On ne peut pas utiliser de v-model ici, je ne sais pas trop pourquoi… -->
                <!-- Dans la version précédente, il y avait un commentaire "force deep update" -->
                <!-- Ça serait bien de vérifier également si le 0/1 est obligatoire ou si il -->
                <!-- est possible d'utiliser directement true/false -->
                <on-off-simple
                    class="mr-4"
                    onColor="green-400"
                    offColor="red-500"
                    :value="form.lot_and_parking_price"
                    @input="
                        form = {
                            ...form,
                            lot_and_parking_price: $event ? 1 : 0,
                        }
                    "
                />
            </div>
            <div class="border-b py-4" v-if="promotion.multi_agency">
                <span class="font-semibold text-sm text-gray-800"
                    >Attribuer les nouveaux candidats 50/50 :
                </span>
                <on-off-simple
                    class="mr-4"
                    onColor="green-400"
                    offColor="red-500"
                    :value="form.share_new_candidates"
                    @input="
                        form = {
                            ...form,
                            share_new_candidates: $event ? 1 : 0,
                        }
                    "
                />
            </div>
            <div class="border-b py-4" v-if="promotion.multi_agency">
                <div
                    class="
                        grid grid-cols-2
                        font-semibold
                        text-sm text-gray-800
                        gap-4
                    "
                >
                    <span>Nb. max jours pour remplir la FR</span>
                </div>
                <div class="grid grid-cols-2 gap-4">
                    <input
                        v-model="form.reservation_timeout"
                        @input="setChanged"
                        type="number"
                        class="form-input"
                    />
                </div>
            </div>
            <div class="border-b py-4">
                <div
                    class="
                        grid grid-cols-2
                        font-semibold
                        text-sm text-gray-800
                        gap-4
                    "
                >
                    <span>Début commercialisation</span>
                    <span>Fin commercialisation</span>
                </div>
                <div class="grid grid-cols-2 gap-4">
                    <datetime
                        v-model="form.start_at"
                        @input="setChanged"
                        type="date"
                        value-zone="UTC+2"
                        zone="UTC+2"
                        input-id="promotion_start_at"
                        input-class="form-input"
                    ></datetime>
                    <datetime
                        v-model="form.end_at"
                        @input="setChanged"
                        type="date"
                        value-zone="UTC+2"
                        zone="UTC+2"
                        input-id="promotion_end_at"
                        input-class="form-input"
                    ></datetime>
                </div>
            </div>
            <div class="border-b py-4 grid grid-cols-2">
                <div class="flex flex-col">
                    <span class="font-semibold text-sm text-gray-800 mr-4"
                        >Notaire attitré</span
                    >
                    <div>
                        <picture-select
                            ref="notarySelect"
                            :searchProps="['full_name']"
                            :collection="promotion.notaries"
                            :value="form.notary_id"
                            textProp="full_name"
                            imgProp="profile_picture"
                            defaultText="Aucun"
                            valueProp="id"
                            @change="
                                (val) => {
                                    handleNotaryChange(val);
                                }
                            "
                        />
                    </div>
                </div>
                <div class="flex flex-col">
                    <span class="font-semibold text-sm text-gray-800 mr-4"
                        >Courtier responsable</span
                    >
                    <div>
                        <picture-select
                            ref="brokerSelect"
                            :searchProps="['full_name']"
                            :collection="promotion.brokers"
                            :value="form.broker_id"
                            textProp="full_name"
                            imgProp="profile_picture"
                            defaultText="Aucun"
                            valueProp="id"
                            @change="
                                (val) => {
                                    handleBrokerChange(val);
                                }
                            "
                        />
                    </div>
                </div>
            </div>
            <div class="flex justify-end">
                <button
                    class="
                        button
                        rounded-sm
                        text-white
                        transition-colors
                        duration-300
                        font-semibold
                        py-1
                        px-3
                        mt-3
                        focus:outline-none
                    "
                    :class="submitButtonClass"
                    :disabled="!changed"
                    @click="submit"
                    v-if="user.admin"
                >
                    Enregistrer
                </button>
            </div>
            <div class="pt-2 flex justify-end">
                <button :disabled="isDisabled" class="bg-blue-400 hover:bg-blue-600 text-white px-2 py-1 font-semibold transition-all duration-300 hover:shadow focus:outline-none" @click="syncCRM">Envoyer sur Salesforce</button>
            </div>
            <div class="flex justify-end text-sm text-gray-600 italic">{{ formatDate(promotion.crm_updated_at) }}</div>
            <div class="flex justify-end text-sm ">
                Pensez à Enregistrer&nbsp;<strong>avant</strong>&nbsp;d'envoyer sur Salesforce
            </div>
        </div>
    </div>
</template>

<script>
import OnOffSimple from "@/components/Forms/OnOffSimple";
import MyUpload from "vue-image-crop-upload";
import { Datetime } from "vue-datetime";
import { dataURLtoFile } from "@/helpers/file";
import promotion from "@/api/promotion";
import PictureSelect from "@/components/Forms/PictureSelect";
import { mapGetters } from "vuex";
import Auth from "@/mixins/Auth";
import {format, parseISO } from "date-fns";

export default {
    mixins: [Auth],
    components: { MyUpload, OnOffSimple, Datetime, PictureSelect },
    props: {
        promotion: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            form: {
                start_at: this.promotion.start_at,
                title: this.promotion.title,
                url: this.promotion.url,
                gtm: this.promotion.gtm,
                external_report_url: this.promotion.external_report_url,
                end_at: this.promotion.end_at,
                lot_and_parking_price: this.promotion.lot_and_parking_price,
                share_new_candidates: this.promotion.share_new_candidates,
                notary_id: this.promotion.main_notary
                    ? this.promotion.main_notary.id
                    : null,
                broker_id: this.promotion.main_broker
                    ? this.promotion.main_broker.id
                    : null,
                reservation_timeout: this.promotion.reservation_timeout,
                prestige: this.promotion.prestige
            },
            changed: false,
            logo: {
                open: false,
                base64: null,
            },
            isDisabled: false
        };
    },
    computed: {
        ...mapGetters("auth", ["user"]),
        submitButtonClass() {
            return this.changed
                ? "bg-blue-400 hover:bg-blue-600 cursor-pointer"
                : "bg-gray-300 cursor-not-allowed";
        },
        iFrameLink() {
            let url = process.env.VUE_APP_API_URL;
            url += url.endsWith("/") ? "" : "/";
            return `${url}promotion/${this.promotion.token}/${this.user.agency.token}`;
        },
        hrefValue() {
            return this.form.url.includes("http://", 0) ||
                this.form.url.includes("https://", 0)
                ? this.form.url
                : "//" + this.form.url;
        },
    },
    methods: {
        dataURLtoFile,
        setChanged() {
            this.changed = true;
        },
        submit() {
            if (! this.changed) return;

            const data = new FormData();

            for (var key in this.form) {
                if (key !== "logo_full_path") {
                    if(this.form[key] !== null)
                        data.append(key, this.form[key]);
                }
            }

            if (this.logo.base64) {
                data.append(
                    "logo_full_path",
                    this.dataURLtoFile(this.logo.base64, "x")
                );
            }

            promotion
                .updateSettings(this.promotion.id, data)
                .then((res) => {
                    if (res.status == 200) {
                        this.changed = false;                    
                        this.$emit("update");
                    }
                });
        },
        syncCRM(){
            this.isDisabled = true;
            this.$store.commit("loader/SET_LOADING", true);
            promotion.syncCrm(this.promotion.id)
                .then((res) => {
                    if (res.status == 200) {
                        this.$toast.open({
                            message: "Promotion bien envoyée",
                            type: "success"
                        });
                        this.$emit("update");
                    } else {
                        this.$toast.open({
                            message: "Impossible d'envoyer la promotion à Salesforce\n"+res,
                            type: "error"
                        });
                    }
                })
                .catch((res) => {
                    this.$toast.open({
                        message: "Impossible d'envoyer la promotion à Salesforce\n"+res,
                        type: "error"
                    });
                })
                .finally(() => {
                    this.$store.commit("loader/SET_LOADING", false);
                    this.isDisabled = false;
                });
        },
        handleNotaryChange(val) {
            this.form = { ...this.form, notary_id: val };
            this.setChanged();
        },
        handleBrokerChange(val) {
            this.form = { ...this.form, broker_id: val };
            this.setChanged();
        },
        openUpload() {
            this.logo.open = true;
        },  
        cropSuccess(logo) {
            this.logo.base64 = logo;
        },
        formatDate(dateString) {
            if (!dateString) return "Pas encore sur Salesforce";
        
            return "Dernière mise à jour "+format(parseISO(dateString), "dd/MM/yyyy HH:mm:ss");
        }
    },
    watch: {
        form: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) this.setChanged();
            },
            deep: true,
        },
    },
};
</script>

<style>
</style>