<template>
    <div
        class="text-left"
        @click="toggleOpen"
        v-on-clickaway="close"
    >
        <div>
            <button class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600">
                <svg
                    class="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
            </button>
        </div>

        <div
            class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-10 bg-white cursor-pointer"
            v-if="isOpen"
        >
            <div class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-600 hover:bg-gray-100 hover:text-blue-900 focus:outline-none focus:bg-gray-100 focus:text-blue-900"
                @click="openUpdateModal"
            >
                <svg
                    class="fill-current mr-3 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
                </svg>
                Modifier
            </div>
            <div class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-600 hover:bg-gray-100 hover:text-blue-900 focus:outline-none focus:bg-gray-100 focus:text-blue-900"
                @click="openFilesModal"
            >
                <svg 
                    class="fill-current mr-3 h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd"></path>
                </svg>
                Documents
            </div>
            <a
                v-if="campaign.url"
                :href="hrefValue"
                target="_blank"
                class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-600 hover:bg-gray-100 hover:text-blue-900 focus:outline-none focus:bg-gray-100 focus:text-blue-900"
            >
                <svg
                    class="fill-current mr-3 h-5 w-5"
                    xlmns="https://www.w3.org/2000/svg"
                    fill="currentcolor"
                    viewBox="0 0 20 20"
                >
                    <path d="M9.26 13a2 2 0 0 1 .01-2.01A3 3 0 0 0 9 5H5a3 3 0 0 0 0 6h.08a6.06 6.06 0 0 0 0 2H5A5 5 0 0 1 5 3h4a5 5 0 0 1 .26 10zm1.48-6a2 2 0 0 1-.01 2.01A3 3 0 0 0 11 15h4a3 3 0 0 0 0-6h-.08a6.06 6.06 0 0 0 0-2H15a5 5 0 0 1 0 10h-4a5 5 0 0 1-.26-10z" />
                </svg>
                Visiter
            </a>
            <div class="group flex items-center px-4 py-2 text-sm leading-5 text-red-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                @click="openDeleteModal"
            >
                <svg
                    class="fill-current mr-3 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM11.4 10l2.83-2.83-1.41-1.41L10 8.59 7.17 5.76 5.76 7.17 8.59 10l-2.83 2.83 1.41 1.41L10 11.41l2.83 2.83 1.41-1.41L11.41 10z"/>
                </svg>
                Supprimer
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
    mixins: [clickaway],
    data() {
        return {
            isOpen: false
        };
    },
    props: {
        campaign: {
            type: Object,
            required: true
        }
    },
    computed:{
        hrefValue ()
        {
            return this.campaign.url.includes("http://",0) || this.campaign.url.includes("https://",0) ? this.campaign.url : "//" + this.campaign.url;
        }
    },
    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen;
        },
        close() {
            this.isOpen = false;
        },
        openUpdateModal() {
            this.$store.commit(
                "campaign/SET_CURRENT_ACTION_TARGET",
                this.campaign
            );
            this.$store.commit(
                "campaign/SET_CURRENT_ACTION_TYPE",
                "editCampaign"
            );
        },
        openDeleteModal() {
            this.$store.commit(
                "campaign/SET_CURRENT_ACTION_TARGET",
                this.campaign
            );
            this.$store.commit(
                "campaign/SET_CURRENT_ACTION_TYPE",
                "deleteCampaign"
            );
        },
        openFilesModal() {
            this.$store.commit(
                "campaign/SET_CURRENT_ACTION_TARGET",
                this.campaign
            );
            this.$store.commit(
                "campaign/SET_CURRENT_ACTION_TYPE",
                "showFiles"
            );
        },
    }
};
</script>

<style></style>
