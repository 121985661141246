<template>
  <div class="bg-white rounded-sm shadow w-full mb-64">
    <informations v-if="currentView == 'informations'" :promotion="promotion" @update="reload" />
    <lots v-if="currentView == 'lots'" :promotion="promotion" @update="reload" />
    <notaries v-if="currentView == 'notaries'" :promotion="promotion" @update="reload" />
    <brokers v-if="currentView == 'brokers'" :promotion="promotion" @update="reload"/>
    <promoters v-if="currentView == 'promoters'" :promotion="promotion" @update="reload"/>
    <bankers v-if="currentView == 'bankers'" :promotion="promotion" @update="reload"/>
    <architects v-if="currentView == 'architects'" :promotion="promotion" @update="reload"/>
  </div>
</template>

<script>
import Informations from "@/components/Promotion/container/Informations";
import Lots from "@/components/Promotion/container/Lots";
import Notaries from "@/components/Promotion/container/Notaries";
import Brokers from "@/components/Promotion/container/Brokers";
import Promoters from "@/components/Promotion/container/Promoters";
import Bankers from "@/components/Promotion/container/Bankers";
import Architects from "@/components/Promotion/container/Architects";

export default {
    components: {
        Informations,
        Lots,
        Notaries,
        Brokers,
        Promoters,
        Bankers,
        Architects
    },
    props: {
        currentView: {
            required: true,
            default: "informations"
        },
        promotion: {
            required: true,
            type: Object
        }
    },
    methods: {
        reload() {
            this.$parent.init();
        }
    }
};
</script>

<style>
</style>