import axios from "axios";

export default{
    download(file){
        var is_ios = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
        var is_safari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
        
        if ( is_ios || is_safari ) {
            var windowReference = window.open();
            axios.get(`file-url/${file.uuid}`).then(response => {
                windowReference.location = response.data;
            });
        } else {
            axios.get(`file-url/${file.uuid}`).then(response => {
                window.open(response.data, "_blank");
            });
        }
    },
    get(params){
        return axios.get("/files", {params});
    }
};