<template>
    <tr
        :class="{ 'bg-gray-200': openContextMenu }"
    >
        <td v-if="currentPromotion.multi_agency">
            {{campaign.agency.name}}
        </td>
        <td :style="`border-left : 15px solid ${color}; max-width : 200px; white-space: normal;`" class="text-left">
            {{campaign.title}}
        </td>
        <td>
            {{campaign.method}}
        </td>
        <td>
            <span v-if="campaign.start_at">
                {{campaign.start_at | date_dmy}}
            </span>
        </td>
        <td>
            <span v-if="campaign.end_at">
                {{campaign.end_at | date_dmy}}
            </span>
        </td>
        <td>
            {{formatCHF(campaign.price)}}
        </td>
        <td>
            <div class="flex flex-col cursor-pointer">
                <a 
                    v-for="file in campaign.files"
                    :key="file.url"
                    class="font-medium text-blue-600 hover:text-blue-700 hover:underline focus:outline-none transition ease-in-out duration-150"
                    @click.prevent="download(file)"
                    target="_blank"
                >{{file.name}}</a>
            </div>
        </td>
        <td>{{campaign.comment}}</td>
        <td>
            <campaign-row-actions :campaign="campaign"/>
        </td>
    </tr>
</template>

<script>
import CampaignRowActions from "@/components/Campaigns/CampaignRowActions";
import { formatCHF } from "@/helpers/formatter";
import files from "@/api/files";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            openContextMenu: false,
            contextMenuX: null,
            contextMenuY: null
        };
    },
    components: {
        CampaignRowActions
    },
    props: {
        campaign: {
            type: Object,
            required: true
        }
    },

    computed : {
        ...mapGetters("auth", ["currentPromotion"]),
        color(){
            if(this.campaign){
                if(this.campaign.color)
                    return this.campaign.color;
            }
            return "white";
        }
    },
    methods: {
        download(file){
            files.download(file);
        },
        formatCHF,
    }
};
</script>
