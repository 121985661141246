import Dashboard from "../views/Dashboard/Index";
import Candidats from "../views/Candidats/Index";
import Lots from "@/views/Lots/Index";
import Tasks from "@/views/Tasks/Index";
import Attractivite from "@/views/Attractivite/Index";
import TunnelDeVente from "@/views/TunnelDeVente/Index";
import Settings from "@/views/Account/Settings";
import Campagnes from "@/views/Campagnes/Index";
import Progression from "@/views/Progression/Index";
import Documents from "@/views/Documents/Index";
import PromotionIndex from "@/views/Promotion/Index";
import PlanningIndex from "@/views/Planning/Index";
import FinanceIndex from "@/views/Finances/Index";
import Message from "@/views/Message/Index";
import MessageOne from "@/views/Message/Show";
import HomeCandidate from "@/views/Home/HomeCandidate";
//import CandidateLots from "@/views/Lots/candidate/Index";
import CandidateView from "@/views/Candidats/View";
import PromotionView from "@/views/Promotion/View";
import PromotionAdd from "@/views/Promotion/Add";
import FAQ from "@/views/FAQ/Index";
import Logs from "@/views/Logs/Index";
import RedirectToHome from "@/views/Redirection/RedirectToHome";
import FormFinishRegister from "@/views/Candidats/public/FormFinishRegister";


export default [
    {
        path: "/home",
        name: "candidate-home",
        component: HomeCandidate,
        meta: {
            guard: ["candidate", "notary", "investor"],
            filters: false,
            isMultiAgency: false,
        }
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            guard: ["broker", "notary", "promoter", "banker", "architect","pilot","investor"],
            nb_kchf: true,
            showSearch: false,
            isMultiAgency: true,
        }
    },
    {
        path: "/candidats",
        name: "candidats",
        component: Candidats,
        meta: {
            guard: ["broker"],
            showNonRetainedCheckbox: true,
            showPriceSlider: false,
            showLotFilters: false,
            isMultiAgency: false,
        }
    },
    {
        path: "/lots",
        name: "lots",
        component: Lots,
        meta: {
            guard: ["broker", "notary", "promoter", "banker", "architect"],
            isMultiAgency: false,
        }
    },
    {
        path: "/grille-des-prix",
        name: "candidate-lots",
        component: Lots,
        meta: {
            guard: ["candidate"],
            isMultiAgency: false,
        }
    },
    {
        path: "/attractivite",
        name: "attractivite",
        component: Attractivite,
        meta: {
            filters: false
        }
    },
    {
        path: "/tunnel-de-vente",
        name: "tunnel-de-vente",
        component: TunnelDeVente,
        meta: {
            guard: ["broker", "promoter", "notary", "banker", "architect"],
            isMultiAgency: true,
        }
    },
    {
        path: "/tasks",
        name: "tasks",
        component: Tasks,
        meta: {
            guard: ["broker", "notary"],
            brokersFilter: true,
        }
    },
    {
        path: "/logs",
        name: "logs",
        component: Logs,
        meta: {
            guard: ["broker"],
            filters: false,
        },
    },
    {
        path: "/marketing",
        name: "marketing",
        component: Campagnes,
        meta: {
            guard: ["broker", "promoter"],
            filters: false
        }
    },
    {
        path: "/messages",
        name: "message",
        component: Message,
        meta: {
            guard: ["broker", "promoter", "notary", "candidate", "banker", "architect","pilot","investor"],
            filters: false
        }
    },
    {
        path: "/messages/:id",
        name: "message-one",
        component: MessageOne,
        meta: {
            guard: ["broker", "promoter", "notary", "candidate", "banker", "architect","pilot","investor"],
            filters: false
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: {
            guard: ["broker", "promoter", "notary", "candidate", "banker", "architect","pilot","investor"],
            filters: false
        }
    },
    {
        path: "/progression",
        name: "progression",
        component: Progression,
        meta: {
            guard: ["broker", "promoter", "promoter", "candidate", "architect","pilot","investor"],
            filters: false
        }
    },
    {
        path: "/documents",
        name: "documents",
        component: Documents,
        meta: {
            guard: ["candidate", "broker", "notary", "promoter", "banker", "architect","pilot","investor"],
            filters: false
        }
    },
    {
        path: "/promotions",
        name: "promotions",
        component: PromotionIndex,
        meta: {
            guard: ["broker"],
            filters: false
        }
    },
    {
        path: "/planning",
        name: "planning",
        component: PlanningIndex,
        meta: {
            guard: ["broker","pilot","investor"],
            filters: false
        }
    },
    {
        path: "/finances",
        name: "finances",
        component: FinanceIndex,
        meta: {
            guard: ["broker","pilot","investor"],
            filters: false
        }
    },
    {
        path: "/FAQ",
        name: "FAQ",
        component: FAQ,
        meta: {
            guard: ["candidate", "broker", "banker", "promoter", "notary", "architect"],
            filters: false
        }
    },
    {
        path: "/candidate/:id",
        name: "candidate.view",
        component: CandidateView,
        meta: {
            guard: ["broker"],
            filters: false
        }
    },
    {
        path: "/candidate/form/:id",
        name: "candidate-form",
        component: FormFinishRegister,
        meta: { layout: "auth", guard: ["candidate", "broker", "banker", "promoter", "notary"], }
    },
    {
        path: "/promotion",
        name: "promotion.view",
        component: PromotionView,
        meta: {
            guard: ["broker"],
            filters: false
        }
    },
    {
        path: "/promotions/add",
        name: "promotion.add",
        component: PromotionAdd,
        meta: {
            guard: ["broker"],
            filters: false
        }
    },
    {
        path: "/",
        component: RedirectToHome,
    },
];