<template>
    <div class="bg-white shadow overflow-x-hidden sm:rounded-md">
        <div class="flex flex-col">
            <div
                class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                id="table"
            >
                <div
                    class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                >
                    <div v-if="!isCandidate" class="grid grid-flow-col grid-cols-4 gap-2">
                        <!-- double-demi-arrow -->
                        <div class="grid grid-flow-col grid-cols-2 gap-2">
                            <status-arrow
                                background="gray-300"
                                value="0"
                                type="lot"
                                >Disponible</status-arrow
                            >
                            <status-arrow
                                background="gray-500"
                                value="1"
                                type="lot"
                                >PRS</status-arrow
                            >
                        </div>
                        <status-arrow
                            background="orange-300"
                            value="2"
                            type="lot"
                            >Optionné</status-arrow
                        >
                        <status-arrow
                            background="orange-400"
                            value="3"
                            type="lot"
                            >Réservé</status-arrow
                        >
                        <status-arrow
                            background="green-400"
                            value="4"
                            type="lot"
                            >Signé</status-arrow
                        >
                    </div>
                    <table class="min-w-full main--table">
                        <thead class="bg-gray-200">
                            <tr>
                                <sortable-th
                                    column="buildings.title"
                                    propsClass="text-left"
                                    >Immeuble</sortable-th
                                >
                                <sortable-th column="reference">Ref.</sortable-th>
                                <sortable-th column="lot_ppe" v-if="!isCandidate">Lot PPE</sortable-th>
                                <sortable-th column="feuillet_ppe" v-if="!isCandidate">Feuillet</sortable-th>
                                <sortable-th column="status" v-if="!isCandidate">PRS</sortable-th>
                                <th column="status">Statut</th>
                                <sortable-th column="lot_types.order">Type</sortable-th>
                                <sortable-th column="nb_rooms">Pièces</sortable-th>
                                <sortable-th column="floors.order">étage</sortable-th>
                                <sortable-th column="price">Prix de vente</sortable-th>
                                <th v-tooltip="'Exposition'">Expo.</th>
                                <th v-tooltip="'Surface PPE'">PPE</th>
                                <th v-tooltip="'Surface habitable'">Hab.</th>
                                <th v-tooltip="'Surface jardin'">Jar.</th>
                                <th v-tooltip="'Surface balcon'">Bal.</th>
                                <th v-tooltip="'Surface terrain'">Ter.</th>
                                <th>Lots associés</th>
                                <th v-if="!isCandidate">Candidats</th>
                                <th v-if="!isCandidate">A faire</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white" v-if="hasCurrentLots">
                            <lot-row
                                v-for="lot in lots.data"
                                :key="lot.id"
                                :lot="lot"
                                :building="lot.building"
                                @open-history="openHistory"
                                @open-children="openChildren"
                                @update="emitUpdate"
                            ></lot-row>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <the-pagination
            v-if="lots"
            :lastPage="lots.last_page"
            :currentPage="lots.current_page"
            :from="lots.from"
            :to="lots.to"
            :total="lots.total"
        ></the-pagination>
        <history-modal
            :open="historyModal.open"
            :lot="historyModal.lot"
            @close="closeHistory"
        ></history-modal>
        <children-lots-modal
            :open="childrenModal.open"
            :lot="childrenModal.lot"
            @close="closeChildren"
        ></children-lots-modal>
    </div>
</template>

<script>
import SortableTh from "@/components/Tables/SortableTh";
import { mapState, mapGetters } from "vuex";
import LotRow from "@/components/Lots/LotRow";
import HistoryModal from "@/components/Lots/modals/HistoryModal";
import ChildrenLotsModal from "@/components/Lots/modals/ChildrenLotsModal";
import Sortable from "@/mixins/Sortable";
import StatusArrow from "@/components/Filters/StatusArrow";
import ThePagination from "@/components/global/ThePagination";
import {VTooltip} from "v-tooltip";
import "v-tooltip/dist/v-tooltip.css";
import Auth from "@/mixins/Auth";

export default {
    mixins: [Sortable, Auth],
    components: {
        LotRow,
        HistoryModal,
        ChildrenLotsModal,
        SortableTh,
        StatusArrow,
        ThePagination,
    },
    directives: {
        tooltip: VTooltip,
    },
    props: {
        lots: {
            type: Object,
            required: false,
            nullable: true,
        },
        // params: {
        //     type: Object,
        //     required: true,
        // },
    },
    data() {
        return {
            buildingIndex: "",
            s_lot: "",
            s_candidat: "",
            s_status: null,
            sortByDesc: false,
            historyModal: {
                open: false,
                lot: null,
            },
            childrenModal: {
                open: false,
                lot: null,
            },
        };
    },
    methods: {
        openHistory(lot) {
            this.historyModal.open = true;
            this.historyModal.lot = lot;
        },
        openChildren(lot) {
            this.childrenModal.open = true;
            this.childrenModal.lot = lot;
        },
        closeHistory() {
            this.historyModal.open = false;
        },
        closeChildren() {
            this.childrenModal.open = false;
        },
        emitUpdate(){
            this.$emit("update");
        }
    },
    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
        ...mapState("auth", ["user"]),
        currentBuildings() {
            return this.currentPromotion ? this.currentPromotion.buildings : [];
        },
        hasCurrentBuildings() {
            return this.currentBuildings.length > 0;
        },
        hasCurrentLots() {
            return this.lots ? this.lots.data.length > 0 : false;
        },
    },
};
</script>
