<template>
    <div class="p-4">
        <div v-if="applications.length">
            <div
                v-for="candidacy in candidate.candidacies"
                :key="candidacy.id"                
            >
            <h2
                class="font-semibold text-center bg-blue-400 text-white shadow py-1 mb-2"
            >
                Candidature(s) {{candidacy.promotion.title}}
            </h2>
                <div
                    v-for="application in candidacy.applications"
                    :key="application.id"
                    
                    class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4"
                >
                    <!--class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-1 shadow"-->
                    <div
                        class="rounded-sm overflow-hidden border-2 border-t-0"
                        :class="borders[application.status]"
                    >
                        <div
                            class="text-center px-2 py-1"
                            :class="colors[application.status]"
                        >
                            <span class="font-bold">
                                {{ application.lot.reference }} |
                            </span>
                            <span class="font-semibold">
                                {{ application.status_text }}
                            </span>
                        </div>
                        <div class="px-3 py-2">
                            <div class="grid grid-cols-2 text-center gap-2 mb-2">
                                <div>
                                    <div class="font-bold text-sm text-gray-700">
                                        Pièc.
                                    </div>
                                    <div>
                                        <span
                                            class="font-bold text-sm text-gray-700"
                                        >
                                            {{ application.lot.nb_rooms }}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="font-bold text-sm text-gray-700">
                                        Surf.
                                    </div>
                                    <div>
                                        <span class="text-gray-500 px-1">
                                            {{ application.lot.area_hab }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 text-center gap-2">
                                <div>
                                    <div class="font-bold text-sm text-gray-700">
                                        Prix
                                    </div>
                                    <div>
                                        <span class="text-gray-500 px-1">
                                            {{
                                                formatCHF(
                                                    application.lot.total_price
                                                )
                                            }}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="font-bold text-sm text-gray-700">
                                        Etage
                                    </div>
                                    <div>
                                        <span class="text-gray-500 px-1">
                                            {{ application.lot.floor.title }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="px-2 py-1">
                            <div
                                class="grid grid-cols-3 md:grid-cols-5 text-center gap-2"
                            >
                                <div>
                                    <div
                                        class="font-bold text-sm"
                                        :class="{
                                            'text-green-400':
                                                application.status_FR_checked,
                                            'text-gray-400': !application.status_FR_checked,
                                        }"
                                    >
                                        FR
                                    </div>

                                    <div
                                        class="rounded-full mx-auto h-5 w-5 text-white flex justify-center items-center"
                                        :class="
                                            getBadgeClass(
                                                application.status_FR_checked
                                            )
                                        "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            class="h-3 w-3"
                                        >
                                            <path
                                                v-if="application.status_FR_checked"
                                                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                                            />
                                            <path
                                                v-else
                                                d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        class="font-bold text-sm"
                                        :class="{
                                            'text-green-400':
                                                application.status_AC_checked,
                                            'text-gray-400': !application.status_AC_checked,
                                        }"
                                    >
                                        AC
                                    </div>
                                    <div
                                        class="rounded-full mx-auto h-5 w-5 text-white flex justify-center items-center"
                                        :class="
                                            getBadgeClass(
                                                application.status_AC_checked
                                            )
                                        "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            class="h-3 w-3"
                                        >
                                            <path
                                                v-if="application.status_AC_checked"
                                                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                                            />
                                            <path
                                                v-else
                                                d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        class="font-bold text-sm"
                                        :class="{
                                            'text-green-400':
                                                application.status_FIN_checked,
                                            'text-gray-400': !application.status_FIN_checked,
                                        }"
                                    >
                                        FIN
                                    </div>
                                    <div
                                        class="rounded-full mx-auto h-5 w-5 text-white flex justify-center items-center"
                                        :class="
                                            getBadgeClass(
                                                application.status_FIN_checked
                                            )
                                        "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            class="h-3 w-3"
                                        >
                                            <path
                                                v-if="
                                                    application.status_FIN_checked
                                                "
                                                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                                            />
                                            <path
                                                v-else
                                                d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        class="font-bold text-sm"
                                        :class="{
                                            'text-green-400':
                                                application.status_SIGNPDV_checked,
                                            'text-gray-400': !application.status_SIGNPDV_checked,
                                        }"
                                    >
                                        PDV
                                    </div>
                                    <div
                                        class="rounded-full mx-auto h-5 w-5 text-white flex justify-center items-center"
                                        :class="
                                            getBadgeClass(
                                                application.status_SIGNPDV_checked
                                            )
                                        "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            class="h-3 w-3"
                                        >
                                            <path
                                                v-if="
                                                    application.status_SIGNPDV_checked
                                                "
                                                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                                            />
                                            <path
                                                v-else
                                                d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        class="font-bold text-sm"
                                        :class="{
                                            'text-green-400':
                                                application.status_SIGN_checked,
                                            'text-gray-400': !application.status_SIGN_checked,
                                        }"
                                    >
                                        SIGN
                                    </div>
                                    <div
                                        class="rounded-full mx-auto h-5 w-5 text-white flex justify-center items-center"
                                        :class="
                                            getBadgeClass(
                                                application.status_SIGN_checked
                                            )
                                        "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            class="h-3 w-3"
                                        >
                                            <path
                                                v-if="
                                                    application.status_SIGN_checked
                                                "
                                                d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                                            />
                                            <path
                                                v-else
                                                d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="text-center text-blue-400 underline hover:text-blue-600 py-2"
                        >
                            <a 
                            @click="openSalesTunnel(application)"
                            style="cursor: pointer"
                            >
                                Tunnel de vente
                            </a>
                            <!--
                            <router-link
                                class=""
                                :to="{
                                    name: 'tunnel-de-vente',
                                    query: {
                                        application: application.id,
                                        promotion: application.promotion_id,
                                    },
                                }"
                                target="_blank"
                            >
                                Tunnel de vente
                            </router-link>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="flex justify-center items-center font-medium text-gray-500 text-sm py-2">
            Aucune candidature
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { formatFloor, formatCHF } from "@/helpers/formatter";
// import StatusBadge from "@/components/Lots/StatusBadge";

export default {
    // components: { StatusBadge },
    data() {
        return {
            colors: [
                ["bg-orange-300", "text-white"],
                ["bg-orange-400", "text-white"],
                ["bg-green-300", "text-white"],
                ["bg-green-400", "text-white"],
            ],
            borders: [
                ["border-orange-300"],
                ["border-orange-400"],
                ["border-green-300"],
                ["border-green-400"],
            ],
        };
    },
    computed: {
        ...mapState("application", ["statuses"]),
        ...mapGetters("auth",["currentPromotion"]),
        candidate() {
            return this.$parent.candidate;
        },
        applications(){
            let applications = [];
            
            if(this.candidate){
                this.candidate.candidacies.forEach(candidacy => {
                    candidacy.applications.forEach(application => {
                        applications.push(application);                        
                    });
                });
            }
            return applications;
        }
    },
    methods: {
        formatFloor,
        formatCHF,
        getBadgeClass(isChecked) {
            return isChecked ? "bg-green-400" : "bg-gray-400";
        },
        openSalesTunnel(application) {
            if(application.candidacy1.promotion_id != this.currentPromotion.id){
                this.$store.dispatch("auth/changePromotion", application.candidacy1.promotion_id);
            }
            this.$router.push({
                name: "tunnel-de-vente",
                query: { application: application.id }
            });
        },
    },
};
</script>

<style>
</style>