import axios from "axios";

export default {
    get() {
        return axios.get("brokers");
    },
    getOnePublic(id) {
        return axios.get(`applications/public/${id}`);
    },
    addAssistant(brokerId, assistantId){
        return axios.post(`brokers/${brokerId}/assistants/${assistantId}`);
    }
};
