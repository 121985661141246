<template>
    <main v-if="false">
        <div class="max-w-7xl mx-auto py-2">
            <reset-query-link v-if="$route.query.candidat || candidatFilter"></reset-query-link>
            <div class="grid grid-flow-row md:grid-flow-col md:grid-cols-3">
                <div class="px-2 mb-2 md:mb-0">
                    <div
                        class="shadow-lg bg-white rounded border-2 border-red-500"
                    >
                        <div
                            class="text-center py-2 p-4 text-gray-700 font-medium"
                        >
                            En retard
                        </div>
                        <div class="flex flex-col p-1 pb-2 space-y-2">
                            <candidate-full-task
                                v-for="task in filteredActionsPast"
                                :key="task.id"
                                :task="task"
                            ></candidate-full-task>
                        </div>
                    </div>
                </div>
                <div class="px-2 mb-2 md:mb-0">
                    <div
                        class="shadow-lg bg-white rounded border-2 border-green-400"
                    >
                        <div
                            class="text-center py-2 p-4 text-gray-700 font-medium"
                        >
                            Aujourd'hui
                        </div>
                        <div class="flex flex-col p-1 pb-2 space-y-2">
                            <candidate-full-task
                                v-for="task in filteredActionsToday"
                                :key="task.id"
                                :task="task"
                            ></candidate-full-task>
                        </div>
                    </div>
                </div>
                <div class="px-2 mb-2 md:mb-0">
                    <div
                        class="shadow-lg bg-white rounded border-2 border-gray-500"
                    >
                        <div
                            class="text-center py-2 p-4 text-gray-700 font-medium"
                        >
                            Prochainement
                        </div>
                        <div class="flex flex-col p-1 pb-2 space-y-2">
                            <candidate-full-task
                                v-for="task in filteredActionsNext"
                                :key="task.id"
                                :task="task"
                            ></candidate-full-task>
                        </div>
                    </div>
                </div>
            </div>
            <show-task-modal
                :open="modals.showTask"
                :targetOverride="target"
            ></show-task-modal>
            <edit-task-modal
                @update="update"
                :open="modals.editTask"
                :targetOverride="target"
            ></edit-task-modal>
        </div>
    </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import action from "@/api/action";
import CandidateFullTask from "@/components/Candidates/CandidateFullTask";
import ShowTaskModal from "@/components/Candidates/modals/ShowTaskModal";
import EditTaskModal from "@/components/Candidates/modals/EditTaskModal";
import _ from "underscore";
import { concat } from "@/helpers/formatter";
import ResetQueryLink from "@/components/global/ResetQueryLink";

export default {
    components: {
        CandidateFullTask,
        ShowTaskModal,
        EditTaskModal,
        ResetQueryLink
    },
    data() {
        return {
            actionsNext: [],
            actionsToday: [],
            actionsPast: [],
            modals: {
                showTask: false,
                editTask: false
            },
            target: null
        };
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapGetters("auth", ["currentPromotion"]),
        filteredActionsPast() {
            return this.filterActions(this.actionsPast);
        },
        filteredActionsToday() {
            return this.filterActions(this.actionsToday);
        },
        filteredActionsNext() {
            return this.filterActions(this.actionsNext);
        },
        candidateCurrentActionType() {
            return this.$store.getters["candidate/currentActionType"];
        },
        lotCurrentActionType() {
            return this.$store.getters["lot/currentActionType"];
        },
        applicationCurrentActionType() {
            return this.$store.getters["application/currentActionType"];
        },
        candidatFilter(){
            return this.$store.state.app.filters.candidat;
        }
    },
    methods: {
        update() {
            this.getData();
        },
        getData() {
            this.$store.commit("loader/SET_LOADING", true);
            var getAction = null;
            if(this.user.type == "broker")
                getAction = action.get(this.currentPromotion.id);
            else if(this.user.type == "notary")
                getAction = action.notaryGet(this.currentPromotion.id);
            getAction.then(({ data }) => {
                this.actionsNext = data.data.next;
                this.actionsToday = data.data.today;
                this.actionsPast = data.data.past;
                this.$store.commit("loader/SET_LOADING", false);
            }).catch(() => {
                this.$store.commit("loader/SET_LOADING", false);
            });
        },
        filterActions(actions) {
            let filteredActions = _.filter(actions, action => {
                let actionText = concat([
                    action.title,
                    action.detail,
                    action.target != null && action.target_class == "candidate" ? action.target.full_name : "",
                    action.target != null && action.target_class == "lot" ? action.target.reference : "",
                    action.target != null && action.target_class == "application" ? action.target.lot.reference : "",
                    action.target != null && action.target_class == "application" ? action.target.candidacy1.candidate.full_name : "",
                ]).toLowerCase();

                let containsText =
                    this.params.search != null
                        ? actionText.includes(this.params.search.toLowerCase())
                        : true;
                        
                let hasSameBrokerId = true;
                if (this.params.broker != null) {
                    if (action.assigned_to != null)
                        hasSameBrokerId =
                            action.assigned_to.id == this.params.broker;
                    else hasSameBrokerId = false;
                } else hasSameBrokerId = true;

                return containsText && hasSameBrokerId;
            });
            if (this.$route.query.candidat) {
                filteredActions = filteredActions.filter(action => {
                    return action.target.id == this.$route.query.candidat;
                });
            }

            // // filter by price
            // // if (
            // //     this.globalFilters.price.min !=
            // //         this.globalFilters.priceRange.min ||
            // //     this.globalFilters.price.max !=
            // //         this.globalFilters.priceRange.max
            // // ) {
            // //     filteredActions = filteredActions.filter(action => {
            // //         return this.checkActionFilterRelatedToLot(
            // //             action,
            // //             "price",
            // //             "price",
            // //             [
            // //                 this.globalFilters.price.min,
            // //                 this.globalFilters.price.max
            // //             ],
            // //             true
            // //         );
            // //     });
            // // }

            // // filter by nb_rooms
            // if (this.globalFilters.nb_rooms.length) {
            //     filteredActions = filteredActions.filter(action => {
            //         return this.checkActionFilterRelatedToLot(
            //             action,
            //             "nb_rooms",
            //             "nb_rooms",
            //             this.globalFilters.nb_rooms
            //         );
            //     });
            // }

            // // // filter by floor
            // // if (this.globalFilters.floors.length) {
            // //     filteredActions = filteredActions.filter(action => {
            // //         return this.checkActionFilterRelatedToLot(
            // //             action,
            // //             "floor",
            // //             "floor",
            // //             this.globalFilters.floors
            // //         );
            // //     });
            // // }

            // // // filter by building
            // // if (this.globalFilters.buildings.length) {
            // //     filteredActions = filteredActions.filter(action => {
            // //         return this.checkActionFilterRelatedToLot(
            // //             action,
            // //             "building_id",
            // //             "id",
            // //             this.globalFilters.buildings
            // //         );
            // //     });
            // // }

            return filteredActions;
        },

        checkActionFilterRelatedToLot(
            action,
            key,
            keyLot,
            acceptedValues,
            isBetween = false
        ) {
            // if isBetween is true we check the first value of acceptedValues (min) and second value of acceptedValues (max)
            switch (action.target_class) {
                case "candidate":
                    if (
                        action.target &&
                        action.target.lots &&
                        action.target.lots.length
                    ) {
                        for (var i in action.target.lots) {
                            let currentLot = action.target.lots[i];
                            if (isBetween) {
                                if (
                                    currentLot[key] >= acceptedValues[0] &&
                                    currentLot[key] <= acceptedValues[1]
                                ) {
                                    return true;
                                }
                            } else if (
                                acceptedValues.includes(currentLot[key])
                            ) {
                                return true;
                            }
                        }
                    }
                    return false;
                case "application":
                    if (isBetween) {
                        if (
                            action.target.lot &&
                            action.target.lot[key] >= acceptedValues[0] &&
                            action.target.lot[key] <= acceptedValues[1]
                        ) {
                            return true;
                        }
                    } else if (
                        action.target &&
                        action.target.lot &&
                        acceptedValues.includes(action.target.lot[key])
                    ) {
                        return true;
                    }
                    return false;
                case "lot":
                    if (isBetween) {
                        if (
                            action.target[keyLot] >= acceptedValues[0] &&
                            action.target[keyLot] <= acceptedValues[1]
                        ) {
                            return true;
                        }
                    } else if (acceptedValues.includes(action[keyLot])) {
                        return true;
                    }
                    return false;
                default:
                    return false;
            }
        }
    },

    watch: {
        lotCurrentActionType: function(newVal, oldVal) {
            this.target = "lot";
            if (newVal !== oldVal) {
                switch (newVal) {
                    case "showTask":
                        this.modals.showTask = true;
                        break;
                    case "editTask":
                        this.modals.editTask = true;
                        break;
                    default:
                        this.modals.showTask = false;
                        this.modals.editTask = false;
                }
            }
        },
        applicationCurrentActionType: function(newVal, oldVal) {
            this.target = "application";
            if (newVal !== oldVal) {
                switch (newVal) {
                    case "showTask":
                        this.modals.showTask = true;
                        break;
                    case "editTask":
                        this.modals.editTask = true;
                        break;
                    default:
                        this.modals.showTask = false;
                        this.modals.editTask = false;
                }
            }
        },
        candidateCurrentActionType: function(newVal, oldVal) {
            this.target = "candidate";
            if (newVal !== oldVal) {
                switch (newVal) {
                    case "showTask":
                        this.modals.showTask = true;
                        break;
                    case "editTask":
                        this.modals.editTask = true;
                        break;
                    default:
                        this.modals.showTask = false;
                        this.modals.editTask = false;
                }
            }
        },
        currentPromotion(newVal, oldVal){
            if(newVal !== oldVal && newVal)
                this.getData(newVal.id);
        }
    },
    mounted() {
        if(this.currentPromotion)
            this.getData(this.currentPromotion.id);
    }
};
</script>
