<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Acte d'achat pour la candidature de {{mainCandidate.full_name}} sur le lot {{currentActionTarget.lot.reference}}
        </h3>
        <div slot="body">
            <div class="mt-6">
                <label
                    for="date"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2 text-left"
                >Date</label>
                <div class="flex items-center">
                    <datetime 
                        v-model="body.date"
                        type="datetime"
                        value-zone="UTC+2"
                        zone="UTC+2"
                        input-id="promotion_start_at"
                        input-class="form-input"
                        :minute-step="15"
                    >
                    </datetime>
                </div>
            </div>
            <div class="text-left mt-4">
                <div class="flex">
                    <div class="m-1 block flex-initial">
                        <label
                            for="sre-email"
                            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                            >Email du candidat 1</label
                        >
                        <span>
                            {{mainCandidate.email}}
                        </span>
                    </div>
                    <div v-if="secondCandidate" class="m-1 block flex-initial">
                        <label
                            for="sre-email"
                            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                            >Email du candidat 2</label
                        >
                        <span>
                            {{secondCandidate.email}}
                        </span>
                    </div>
                </div>
                <div class="pt-2">
                    <label class="block text-sm font-medium leading-5 text-gray-700 mb-2">Ne pas envoyer l'e-mail au(x)
                        candidat(s)
                        <input type="checkbox" v-model="body.do_not_send_email" />
                    </label>
                </div>
            </div>
            <div class="text-left mt-4">
                <label
                    for="pap-subject"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >Sujet de l'email</label>
                <input
                    type="text"
                    id="pap-subject"
                    v-model="body.subject"
                    class="form-input w-full"
                />
            </div>
            <div class="mt-4">
                <label
                    for="body"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >Contenu</label>
                <div class="mt-1 rounded-md shadow-sm">
                    <textarea
                        v-model="body.body"
                        id="body"
                        rows="12"
                        class="border p-2 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:outline-none focus:border-blue-400 rounded"
                    ></textarea>
                </div>
                <p v-if="!body.do_not_send_email" class="mt-2 text-sm text-gray-500">
                    Ce message sera envoyé par email au(x) candidat(s).
                </p>
            </div>
        </div>
        <div
            slot="footer"
            class="mt-12 text-right"
        >
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        :disabled="!isValid"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >
                        Valider
                    </button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import Email from "@/mixins/Email";
import { Datetime } from "vue-datetime";

export default {
    mixins : [Email],
    components: {
        DefaultModal,
        Datetime
    },
    data() {
        return {
            body:{
                subject: "",
                emails: "",
                body : "",
                date: null,
                do_not_send_email: false
            },
            isDisabled: false,
        };
    },
    computed: {
        ...mapGetters("application", ["currentActionTarget", "currentActionType"]),
        ...mapGetters("auth", ["currentPromotion"]),
        ...mapState("auth", ["user"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "planActOfPurchase";
        },
        hasSecondCandidate() {
            return this.currentActionTarget.candidacy2 && this.currentActionTarget.candidacy2.candidate;
        },
        notary() {
            return this.currentPromotion ? this.currentPromotion.main_notary : null;
        },
        mainCandidate(){
            return this.currentActionTarget.candidacy1.candidate;
        },
        secondCandidate(){
            if(this.hasSecondCandidate)
                return this.currentActionTarget.candidacy2.candidate;
            return null;
        },
        isValid(){
            if(this.isDisabled)
                return false;
            return this.body.date && this.body.body.length && this.body.subject.length;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
    },
    methods: {
        close() {
            this.body.body = "";
            this.body.do_not_send_email = false;
            this.body.date = null;
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },
        submit() {
            this.isDisabled = true;
            this.body.body = this.body.body.replace(/\n\r?/g, "<br />");
            const command = {
                name : "planActOfPurchase",
                params : {
                    entity : "application",
                    entity_id : this.currentActionTarget.id,
                    additionnal_fields : this.body
                }
            };
            // this.$store.commit("loader/SET_LOADING", true);
            this.$store.dispatch("app/executeCommand", command)
                .then(() => {
                    this.close();
                    this.$emit("update");
                })
                .finally(() => {
                    this.isDisabled = false;
                });
            this.body.body = "";
        },
        renderBody() {
            if(!this.notary)
                alert("Veuillez ajouter un notaire à la promotion avant de valider cette étape");
            let text = "";
            text +=
                "Nous avons le plaisir de vous inviter à planifier la dernière étape pour la signature officielle de l'acte d'achat chez le notaire !\n\n";
            text +=
                "Dans le cadre de l'acquisition de votre nouveau logement dans le projet cité en titre, nous vous invitions à réserver votre rendez-vous chez le notaire.\n\n";
            if(this.notary){
                if (this.notary.phone)
                    text += "Vous pouvez prendre rendez-vous auprès de "+(this.notary.gender=="M"?"M":"Mme")+"."+this.notary.last_name+" "+this.notary.first_name+" par téléphone directement au "+this.notary.phone+ ".\n\n";
            /*text +=
                "Bien évidemment, si aucune des disponibilités proposées ne vous convient, vous pouvez prendre contact avec nous au ";*/
            }

            text +=
                "Nous restons à votre disposition et nous réjouissons de vous accompagner dans cette étape décisive.\n";

            this.body.body = text;
        },
        renderSubject() {
            this.body.subject = `${this.currentPromotion.title} - Votre acquisition du lot ${this.currentActionTarget.lot.reference}`;
        },
    },
    watch: {
        open(val) {
            if (val) {
                this.render();
            }
        },
        notary(val) {
            if (val) {
                this.renderBody();
            }
        }
    }
};
</script>
