<template>
    <tr
        @contextmenu.prevent="rightClick"
        :class="{ 'bg-gray-200': openContextMenu }"
    >
        <td
            class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 text-left"
        >
            <template v-if="building">
                {{ building.title }}
            </template>
        </td>
        <td>
            {{ lot.reference }}
        </td>
        <td v-if="!isCandidate">
            {{ lot.lot_ppe }}
        </td>
        <td v-if="!isCandidate">
            {{ lot.feuillet_ppe }}
        </td>
        <td v-if="!isCandidate">
            <span v-if="lot.raw_status">
                PRS
            </span>
        </td>
        <td>
            <status-badge
                :status="lot.status"
                :statusText="lot.status_text"
            ></status-badge>
        </td>
        <td>
            {{ lot.type.title }}
        </td>
        <td>
            {{ lot.nb_rooms }}
        </td>
        <td>
            {{ lot.floor.title }}
        </td>
        <td>{{ formatCHF(lot.price) }}</td>
        <td>{{ lot.exposure || "-" }}</td>

        <!-- surface -->
        <td>{{ lot.area_ppe || "-" }}</td>
        <td>{{ lot.area_hab || "-" }}</td>
        <td>{{ lot.area_jar || "-" }}</td>
        <td>{{ lot.area_bal || "-" }}</td>
        <td>{{ lot.area_ter || "-" }}</td>

        <td :class="{ underline: lot.children.length }">
            <span @click="openChildren(lot)" class="cursor-pointer">
                {{ lot.children.length || "-" }}
            </span>
        </td>
        <td v-if="!isCandidate" class="text-center">
            <lot-priority-badge
                v-for="application in lot.applications"
                v-bind:key="application.id"
                class="mr-2"
                :priority="parseInt(application.priority)"
                :application="application"
            ></lot-priority-badge>
        </td>
        <td v-if="!isCandidate" class="text-left">
            <div class="flex">
                <action
                    v-for="task in tasks.slice(0, 3)"
                    :key="task.id"
                    :task="task"
                    taskTargetType="lot"
                    @update="emitUpdate"
                ></action>
                <button
                    v-if="tasks.length > 5"
                    class="bg-white rounded-full shadow border"
                    style="height: 30px; width: 30px"
                    title="voir plus.."
                >
                    +
                </button>
            </div>
        </td>
        <lot-row-actions-right-click
            :isOpen="openContextMenu"
            :x="contextMenuX"
            :y="contextMenuY"
            :lot="lot"
            @close="closeContextMenu"
            @update="emitUpdate"
        ></lot-row-actions-right-click>
    </tr>
</template>

<script>
// import LotRowActions from "@/components/Lots/LotRowActions";
import LotRowActionsRightClick from "@/components/Lots/LotRowActionsRightClick";
import StatusBadge from "@/components/Lots/StatusBadge";
import Action from "@/components/Actions/Action";
import LotPriorityBadge from "@/components/Lots/LotPriorityBadge";
import Auth from "@/mixins/Auth";
import { formatFloor, formatCHF } from "@/helpers/formatter";
import { mapState } from "vuex";

export default {
    mixins: [Auth],
    components: {
        // LotRowActions,
        LotRowActionsRightClick,
        StatusBadge,
        Action,
        LotPriorityBadge,
    },

    data() {
        return {
            openContextMenu: false,
            contextMenuX: null,
            contextMenuY: null,
        };
    },
    props: {
        lot: {
            type: Object,
            required: true,
        },
        building: {
            type: Object,
            default: null,
        },
    },

    computed: {
        ...mapState("candidate", ["candidates"]),
        ...mapState("auth", ["user"]),
        isNotary() {
            return this.user && this.user.type === "notary";
        },
        tasks() {
            return this.lot.actions.filter((task) => task.done_at == null);
        },
    },

    methods: {
        formatFloor,
        formatCHF,
        openHistory(lot) {
            this.$emit("open-history", lot);
        },
        openChildren(lot) {
            this.$store.commit("lot/SET_CURRENT_ACTION_TARGET", lot);
            this.$store.commit("lot/SET_CURRENT_ACTION_TYPE", "children");
        },
        rightClick(e) {
            if (this.isBroker) {
                this.openContextMenu = true;
                this.contextMenuX = e.pageX;
                this.contextMenuY = e.pageY;
            }
        },
        closeContextMenu() {
            this.openContextMenu = false;
        },
        emitUpdate(){
            this.$emit("update");
        },
    },
};
</script>
