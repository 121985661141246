<script>
import { Line } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

export default {
    extends: Line,
    components: {
        ChartJsPluginDataLabels
    },
    props: ["chartData", "options"],
    mounted() {
        this.update();
    },
    methods: {
        update() {
            this.renderChart(this.chartData, this.options);
        }
    }
};
</script>