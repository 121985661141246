<template>
    <main class="pb-8" v-if="cards">
        <!-- first row -->
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 pt-4">
            <div
                class="grid gap-5 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4"
            >
                <stat
                    color="gray-500"
                    text="Disponibles"
                    :nb="cards['Disponible'].count"
                    :chf="formatCHF(cards['Disponible'].price)"
                    :percentage="cards['Disponible'].percentage"
                >
                    <i class="material-icons align-bottom text-2xl">hourglass_full</i>
                </stat>
                <stat
                    color="orange-300"
                    text="Optionnés"
                    :nb="cards['Optionné'].count"
                    :chf="formatCHF(cards['Optionné'].price)"
                    :percentage="cards['Optionné'].percentage"
                >
                    <i class="material-icons align-bottom text-2xl">help</i>
                </stat>
                <stat
                    color="orange-400"
                    text="Réservés"
                    :nb="cards['Réservé'].count"
                    :chf="formatCHF(cards['Réservé'].price)"
                    :percentage="cards['Réservé'].percentage"
                >
                    <i class="material-icons align-bottom text-2xl">pan_tool</i>
                </stat>
                <stat
                    color="green-400"
                    text="Signés"
                    :nb="cards['Signé'].count"
                    :chf="formatCHF(cards['Signé'].price)"
                    :percentage="cards['Signé'].percentage"
                >
                    <i class="material-icons align-bottom text-2xl">check</i>
                </stat>
            </div>
            <!-- second row -->
            <!-- Evolution commercialisation (par mois) -->
            <div>
                <div
                    class="w-full lg:w-2/3 inline-block lg:pr-2 mt-3 align-top"
                >
                    <graph-container :title="evolutionTitle">
                        <template v-slot:icon>
                            <i class="material-icons align-bottom">show_chart</i>
                        </template>
                        <template v-slot:chart>
                            <line-chart
                                ref="line"
                                :height="270"
                                :chartData="charts.evolutionSales.chartData"
                                :options="charts.evolutionSales.options"
                            ></line-chart>
                        </template>
                    </graph-container>
                </div>
                <div
                    class="lg:w-1/3 w-full inline-block lg:pl-3 rounded mt-3 align-top"
                >
                    <graph-container title="Vue d'ensemble">
                        <template v-slot:icon>
                            <i class="material-icons align-bottom"
                                >show_chart</i
                            >
                        </template>
                        <template v-slot:chart>
                            <doughnut-chart
                                ref="doughnut"
                                :chartData="charts.globalSituation.chartData"
                                :options="charts.globalSituation.options"
                                :height="270"
                            ></doughnut-chart>
                        </template>
                    </graph-container>
                </div>
            </div>
            <!-- third row -->
            <div
                class="flex flex-col md:grid md:grid-flow-col md:grid-cols-2 md:gap-5 mt-3 h-auto"
            >
                <graph-container title="Par nombre de pièces">
                    <template v-slot:icon>
                        <i class="material-icons align-bottom">insert_chart</i>
                    </template>
                    <template v-slot:chart>
                        <bar-chart
                            ref="bar1"
                            :chartData="charts.evolutionSalesByRooms.chartData"
                            :options="charts.evolutionSalesByRooms.options"
                            :height="270"
                        ></bar-chart>
                    </template>
                </graph-container>
                <graph-container title="Par étage">
                    <template v-slot:icon>
                        <i class="material-icons align-bottom">insert_chart</i>
                    </template>
                    <template v-slot:chart>
                        <bar-chart
                            ref="bar2"
                            :chartData="charts.evolutionSalesByFloor.chartData"
                            :options="charts.evolutionSalesByFloor.options"
                            :height="270"
                        ></bar-chart>
                    </template>
                </graph-container>
            </div>
        </div>
    </main>
</template>

<script>
import analytics from "@/api/analytics";
import { formatCHF } from "@/helpers/formatter";
import Stat from "@/components/Dashboard/Stat";
import GraphContainer from "@/components/Dashboard/GraphContainer";
import LineChart from "@/components/Dashboard/Charts/Line";
import DoughnutChart from "@/components/Dashboard/Charts/Doughnut";
import BarChart from "@/components/Dashboard/Charts/Bar";
import dashboardChartsConfig from "@/helpers/dashboardChartsConfig";
import View from "@/mixins/View";
import { mapGetters } from "vuex";

export default {
    mixins : [View],
    name: "Dashboard",
    components: {
        Stat,
        GraphContainer,
        LineChart,
        DoughnutChart,
        BarChart,
    },
    computed : {
        ...mapGetters("app", ["formattedFilters"]),
        ...mapGetters("auth", ["changingPromotion","currentPromotion"]),
        evolutionTitle(){
            return this.currentPromotion.multi_agency ? "Evolution de la commercialisation globale" : "Evolution de la commercialisation";
        }
    },
    data() {
        return {
            loaded: false,
            isUpdating: false,
            analytics: null,
            charts: dashboardChartsConfig,
            // Charts DATA
            cards : null,
            by_room : null,
            by_floor : null,
            global_situation : null,
        };
    },

    mounted() {
        this.$watch("changingPromotion", () => this.fetchData());
    },

    methods: {
        formatCHF,
        async fetchData() {
            // Lors du changement de promotion, on change beaucoup de filtres qui
            // occasionnent des requête API inutiles. Tant qu'on est en cours de changement
            // de promotion, on ne veut pas fetchData, on veut juste attendre la fin pour
            // obtenir les nouvelles données proprement en une requête.
            // Pour cela on a un $watch dans le mounted qui permet de rappeler fetchData
            // quand on passe `changingPromotion` passe de `true` à `false`.
            if (this.changingPromotion) return;

            this.$store.commit("loader/SET_LOADING", true);
            let res = await analytics.dashboard(this.formattedFilters);
            this.$store.commit("loader/SET_LOADING", false);
            
            this.updateCards(res.data["status_cards"]);
            this.updateGlobalSituation(res.data["global_situation"]);
            this.updateByRoom(res.data["by_room"]);
            this.updateByFloor(res.data["by_floor"]);
            this.updateSalesEvolution(res.data["sales_evolution"]);
        },
        updateCards(status_cards){
            this.cards = status_cards.data;
        },
        updateGlobalSituation(global_situation){
            this.global_situation = global_situation;
            this.charts.globalSituation.chartData.datasets[0].data = [
                this.global_situation.data["Disponible"],
                this.global_situation.data["Optionné"],
                this.global_situation.data["Réservé"],
                this.global_situation.data["Signé"],
            ];
            this.$nextTick(() => {
                this.$refs.doughnut.update();
            });
        },
        updateByRoom(by_room){
            this.by_room = by_room;
            this.charts.evolutionSalesByRooms.chartData.labels = this.by_room.labels;
            this.charts.evolutionSalesByRooms.chartData.datasets[0].data = this.by_room.data["Disponible"];
            this.charts.evolutionSalesByRooms.chartData.datasets[1].data = this.by_room.data["Optionné"];
            this.charts.evolutionSalesByRooms.chartData.datasets[2].data = this.by_room.data["Réservé"];
            this.charts.evolutionSalesByRooms.chartData.datasets[3].data = this.by_room.data["Signé"];
            this.$nextTick(() => {
                this.$refs.bar1.update();
            });
        },
        updateByFloor(by_floor){
            this.by_floor = by_floor;
            this.charts.evolutionSalesByFloor.chartData.labels = this.by_floor.labels;
            this.charts.evolutionSalesByFloor.chartData.datasets[0].data = this.by_floor.data["Disponible"];
            this.charts.evolutionSalesByFloor.chartData.datasets[1].data = this.by_floor.data["Optionné"];
            this.charts.evolutionSalesByFloor.chartData.datasets[2].data = this.by_floor.data["Réservé"];
            this.charts.evolutionSalesByFloor.chartData.datasets[3].data = this.by_floor.data["Signé"];
            
            this.$nextTick(() => {
                this.$refs.bar2.update();
            });
        },
        updateSalesEvolution(sales_evolution){
            this.sales_evolution = sales_evolution;
            this.charts.evolutionSales.chartData.labels = this.sales_evolution.labels;
            this.charts.evolutionSales.chartData.datasets[0].data = this.sales_evolution.data["Disponible"];
            this.charts.evolutionSales.chartData.datasets[1].data = this.sales_evolution.data["Optionné"];
            this.charts.evolutionSales.chartData.datasets[2].data = this.sales_evolution.data["Réservé"];
            this.charts.evolutionSales.chartData.datasets[3].data = this.sales_evolution.data["Signé"];

            this.$nextTick(() => {
                this.$refs.line.update();
            });
        },
    },
    // watch : {
    //     formattedFilters : {
    //         handler(){
    //             this.fetchData();
    //         },
    //         deep : true
    //     }
    // }
};
</script>
