<template>
    <default-modal
        v-if="open"
        @close="close"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Ajouter une campagne
        </h3>
        <div slot="body" class="w-80vw">
            <div
                class="mt-4 text-left"
                id="title_group"
            >
                <label
                    for="title"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >Titre *</label>
                <input
                    type="text"
                    id="title"
                    v-model="body.title"
                    class="form-input w-full"
                />
            </div>
            <div class="mt-4 grid text-left">
                <label
                    for="type"
                    class="text-sm font-medium leading-5 text-gray-700"
                >Plateforme</label>
                <div class="grid grid-cols-5 lg:grid-cols-6  mt-2 text-left gap-2">
                    <div class="col-span-5 ">
                        <input
                            type="text"
                            id="type"
                            v-model="body.method"
                            class="form-input inline-block"
                        />
                    </div>
                    <div class="col-span-1">
                        <color-select
                            :collection="colors"
                            @change="(color) => {this.body.color = color}"
                        ></color-select>
                    </div>
                </div>
            </div>
            <div class="mt-4 grid grid-cols-3 text-left gap-4">
                <div>
                    <label
                        for="lot"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                    >Début</label>
                    <datepicker
                        :disabled-dates="startDisabledDates"
                        v-model="body.start_at"
                        input-class="form-input"
                        :language="fr"
                    ></datepicker>
                </div>
                <div>
                    <label
                        for="lot"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                    >Fin</label>
                    <datepicker
                        :disabled-dates="endDisabledDates"
                        v-model="body.end_at"
                        input-class="form-input"
                        :language="fr"
                    ></datepicker>
                </div>
                <div>
                    <label
                        for="title"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                    >Prix (CHF)</label>
                    <input
                        type="number"
                        id="price"
                        v-model="body.price"
                        class="form-input w-full"
                    />
                </div>
            </div>
            <div class="mt-4 text-left">
                <label
                    for="title"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >URL (lien)</label>
                <input
                    type="text"
                    id="url"
                    v-model="body.url"
                    class="form-input w-full"
                />
            </div>
            <div class="text-left mt-4 grid grid-cols-2">
                <div>
                    <label
                        for="file"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Fichiers</label>
                    <!-- ADD FILE -->

                    <file-dragger
                        ref="fileDragger"
                        :multiple="true"
                        @openEdit="editingFiles = true"
                        @closeEdit="editingFiles = false"
                    ></file-dragger>
                </div>
                <div>
                    <div>
                        <label
                            for="comment"
                            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >   
                            <p>Commentaire :</p>
                            <textarea v-model="body.comment" id="comment" rows="5" class="full-width form-input"></textarea>
                        </label>
                    </div>                   
                </div>
            </div>

        </div>
        <div slot="footer" class="mt-12 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >
                        Valider
                    </button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import FileDragger from "@/components/global/FileDragger";
import CampaignModal from "@/mixins/CampaignModal";
import ColorSelect from "@/components/Forms/ColorSelect";
import {mapGetters} from "vuex";
export default {
    mixins: [CampaignModal],
    components: {
        ColorSelect,
        FileDragger
    },
    computed : {
        ...mapGetters("campaign", ["currentActionTarget", "currentActionType"]),
        open(){
            return this.currentActionType == "addCampaign";
        }
    },
    methods: {
        close() {
            this.resetValues();
            this.$store.dispatch("campaign/closeModals");
        },
        submit() {
            let data = new FormData();

            for (var key in this.body) {
                if(this.body[key] !== null)
                    data.append(key, this.body[key]);
            }

            if(this.body.start_at){
                data.append(
                    "start_at",
                    this.$options.filters.date_ymd(this.body.start_at)
                );
            }
            if(this.body.end_at){
                data.append(
                    "end_at",
                    this.$options.filters.date_ymd(this.body.end_at)
                );
            }

            if (this.$refs.fileDragger) {
                this.$refs.fileDragger.appendToFormData(data);
            }
    
            if (this.isValid) {
                this.isDisabled = true;
                this.alreadySent = true;
                this.$store.commit("loader/SET_LOADING", true);
                this.$store
                    .dispatch("promotion/addCampaign", data)
                    .then(() => {
                        this.close();
                        this.resetValues();

                        this.$store.commit("loader/SET_LOADING", false);
                        this.$emit("createdCampaign");
                    })
                    .catch(()=>{
                        this.$store.commit("loader/SET_LOADING", false);
                        this.$toast.open({
                            message: "Une erreur est survenue",
                            type: "error"
                        });
                    })
                    .finally(() => {
                        this.isDisabled = false;
                    });
            }
        }
    }
};
</script>

<style>
</style>