export function checkGuard(guards, authType) {

    if (!guards) guards = [];
    if (guards.length < 1) {
    // no guards, it's a public route
        return true;
    }

    if (!guards.includes(authType)) {
        return "401";
    }

    return true;
}

export function resetQuery() {
    // if(Object.keys(this.$route.query).length){
    //     this.$router.push({
    //         name: this.$route.name
    //     });
    // }    
    let query = Object.assign({}, this.$route.query);
    for(var i in query){
        delete query[i];
        this.$router.replace({ query });
    }
    this.$store.commit("app/CLEAR_QUERY_FILTERS");
}