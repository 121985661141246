import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import AuthRoute from "@/router/auth";
import Public from "@/router/public";

import { checkGuard } from "@/helpers/router";

Vue.use(VueRouter);

const routes = [
    ...Public,
    ...AuthRoute
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "border-red-500",
    routes
});


router.beforeEach(async (routeTo, routeFrom, next) => {
    let guards = routeTo.meta.guard;

    if (!guards || guards.length < 1) {
        // Public Route
        next();
        return;
    }

    let user = store.getters["auth/user"];
    if (! user) {
        await store.dispatch("auth/user");
        user = store.getters["auth/user"];
    }

    let filters = store.getters["app/filters"];
    if (! filters) {
        await store.dispatch("app/filters");
    }

    if (routeTo != routeFrom){ //TODO a voir comment on peut revenir à la page 1 si on tente d'obtenir une page qui n'existe pas (trop grande)
        store.dispatch("app/setPage",1);
    }
        
    if (user && user.type === "candidate" && user.number_connections === 0 && routeTo.name !== "settings" && !localStorage.getItem("demo")) {
        next({ name: "settings", query: { password: true } });
    } else {
        // user already log
        // need to force to get rid of error : Uncaught (in promise) TypeError: Cannot read property 'length' of undefined (in checkGuard)
        let nextVal = checkGuard(guards, user.type);
        nextVal === true ? next() : next(nextVal);
    }
});

export default router;
