const state = {
    contextEvent : null,
};

const getters = {
    contextEvent : (state) => state.contextEvent,
};

const mutations = {
    SET_CONTEXT_EVENT(state, value){
        state.contextEvent = value;
    },
};

export default {
    state,
    getters,
    mutations
};
