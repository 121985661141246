<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 p-2 gap-2">
	<div class="p-2 rounded-md flex flex-col space-y-3" v-if="user.admin">
		<div class="font-semibold text-sm text-gray-700">
			Ajouter un courtier
		</div>
		<div>
			<picture-select
                ref="brokerSelect"
                :searchProps="['full_name']"
                :collection="availableBrokers"
                :value="form.broker_id"
                textProp="full_name"
                imgProp="profile_picture"
                defaultText="Sélectionner un courtier"
                valueProp="id"
                @change="
                  val => {
                    handleBrokerChange(val);
                  }
                "
              />
		</div>
		<div class="">
			<button class="bg-blue-400 hover:bg-blue-600 text-white px-2 py-1 font-medium font-semibold transition-all duration-300 hover:shadow focus:outline-none" @click="addBroker">
				Ajouter
			</button>
		</div>
	</div>
	<div>
		<BrokersList :brokers="promotion.brokers" :promotion="promotion" :edit="true" @remove="emitUpdate"></BrokersList>
	</div>
  </div>
</template>

<script>
import BrokersList from "@/components/Brokers/BrokersList";
import PictureSelect from "@/components/Forms/PictureSelect";
import brokers from "@/api/brokers";
import {pluck} from "underscore";
import promotion from "@/api/promotion";
import { mapGetters } from "vuex";

export default {
    components : {
        BrokersList,
        PictureSelect
    },
    props : {
        promotion : {
            type : Object,
            required : true
        }
    },
    data(){
        return this.initialState();
    },
    mounted(){
        this.init();
    },
    computed : {
        ...mapGetters("auth", ["user"]),
        availableBrokers(){
            let promotionBrokerIds = pluck(this.promotion.brokers, "id");
            return this.brokers.filter(el => {
                return promotionBrokerIds.indexOf(el.id) < 0;
            });
        }
    },
    methods : {
        initialState(){
            return {
                form : {
                    broker_id : null,
                },
                brokers : []
            };
        },
        init(){
            this.$store.commit("loader/SET_LOADING", true);
            brokers.get().then((res) => {
                this.brokers = res.data.data;
                this.$store.commit("loader/SET_LOADING", false);
            });
        },
        handleBrokerChange(val){
            this.broker_id = val;
        },
        emitUpdate(){
            this.$emit("update");
        },
        addBroker(){
            this.$store.commit("loader/SET_LOADING", true);
            promotion.addBroker(this.promotion.id, this.broker_id).then(() => {
                this.$store.commit("loader/SET_LOADING", false);
                this.emitUpdate();
            });
        },
    }
};
</script>

<style>

</style>