<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <h3 slot="header" class="text-left text-xl leading-6 font-medium text-blue-900 pb-5">
            Tâche à réaliser pour {{entityName}}
        </h3>
        <div slot="body">
            <div class="text-left">
                <dl>
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3funding_ sm:py-5">
                        <dt class="text-sm leading-5 font-medium text-gray-500">Titre</dt>
                        <dd
                            class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
                        >{{ currentActionTarget.title }}</dd>
                    </div>
                    <div
                        class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-3funding_ sm:py-5"
                    >
                        <dt class="text-sm leading-5 font-medium text-gray-500">Type</dt>
                        <dd
                            class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
                        >{{ currentActionTarget.type_text }}</dd>
                    </div>
                    <div
                        class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-3funding_ sm:py-5"
                    >
                        <dt class="text-sm leading-5 font-medium text-gray-500">Date</dt>
                        <dd class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
                            <span v-if="currentActionTarget.end_at">{{ currentActionTarget.end_at | date_dmyhm }}</span>
                            <span v-else></span>
                        </dd>
                    </div>
                    <div
                        class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-3funding_ sm:py-5"
                    >
                        <dt class="text-sm leading-5 font-medium text-gray-500">Détail</dt>
                        <dd
                            class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
                        >{{ currentActionTarget.detail }}</dd>
                    </div>
                    <div
                        class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-3funding_ sm:py-5"
                    >
                        <dt class="text-sm leading-5 font-medium text-gray-500">Attribué à</dt>
                        <dd
                            v-if="currentActionTarget.assigned_to"
                            class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
                        >
                            <img
                                :src="currentActionTarget.assigned_to.profile_picture"
                                class="rounded-full h-6 w-6 inline-block"
                            />
                            {{ currentActionTarget.assigned_to.full_name }}
                        </dd>
                        <dd
                            v-else
                            class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
                        >-</dd>
                    </div>
                </dl>
            </div>
        </div>

        <div slot="footer" class="mt-12 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Fermer</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import TaskModal from "@/mixins/TaskModal";

export default {
    components: {
        DefaultModal
    },
    mixins: [TaskModal],
    data() {
        return {};
    },
    computed : {
        open(){
            return this.currentActionType === "showTask" && this.currentActionTarget != null && this.targetType != null;
        },
    },
    methods: {
        fetchData(){
            this.$store.dispatch("task/getTarget", {
                type : this.targetType,
                id : this.currentActionTarget.target_id
            });
        },
    },
    mounted(){
        
    }
};
</script>
