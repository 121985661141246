<template>
    <div class="rounded p-3 shadow bg-white" v-if="currentPromotion.broker">
        <div class="flex justify-center grid">
            <img
                :src="assignedBroker.profile_picture"
                class="rounded-full w-40 h-40 sm:mb-3"
            />
        </div>
        <div>
            <h3 class="text-xl leading-6 text-blue-900 mb-3 text-center">{{assignedBroker.full_name}}</h3>
            <div class="text-blue-400 text-center">
                <div>
                    <a :href="`tel:${assignedBroker.phone}`">{{assignedBroker.phone}}</a>
                </div>
                <div>
                    <a :href="`mailto:${assignedBroker.email}`">{{assignedBroker.email}}</a>
                </div>
                <div class="mt-1">
                    <button
                        @click="redirectToMessages"
                        class="btn shadow bg-blue-400 py-2 px-3 text-white"
                    >Prendre contact</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
        ...mapState("auth", ["user"]),
        assignedBroker(){
            if(this.user){
                if(this.user.broker){
                    return this.user.broker;
                }
            }
            return this.currentPromotion.broker;   
        }
    },
    methods: {
        redirectToMessages() {
            this.$router.push({ name: "message", query: { openModal: 1 } });
        }
    }
};
</script>

<style>
</style>