<template>
    <div>
        <div class="px-2 grid grid-rows">
            <div v-for="candidacy in candidate.candidacies" :key="candidacy.id">
                <h2
                    class="font-semibold text-center bg-blue-400 text-white shadow py-1"
                >
                    Documents {{candidacy.promotion.title}}
                </h2>
                <files-list
                class="mb-4"
                    :candidacy="candidacy"
                    @addFile="emitReload"
                    @removeFile="emitReload"
                    :candidateView="true"
                >
                </files-list>
            </div>
        </div>
    </div>
</template>

<script>
import FilesList from "@/components/Candidates/FilesList";
export default {
    components: {
        FilesList,
    },
    computed: {
        candidate() {
            return this.$parent.candidate;
        },
    },
    methods: {
        emitReload() {
            this.$emit("needReload");
        },
    },
};
</script>

<style>
</style>