<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-gray-800 pb-5"
        >
            Ajouter une tâche au planning

        </h3>
        <div slot="body" class="text-left">
            <div class="mt-3">
                <div class="text-left" id="title_group">
                    <label
                        for="title"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Titre</label
                    >
                    <input
                        type="text"
                        id="title"
                        v-model="body.title"
                        class="form-input w-full"
                    />
                </div>
            </div>
            <div class="mt-2">
                <div class="mt-2 text-left">
                    <label
                        for="category"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Catégorie</label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <planning-category-select
                            id="category"
                            :collection="planningCategories"
                            badgeTextProp="value"
                            textProp="label"
                            defaultText="Sélectionner"
                            valueProp="value"
                            :value="body.category"
                            @change="handleCategoryChange"
                        ></planning-category-select>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-4 mb-2 mt-2">
                <div>
                    <label
                        for="task"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                    >Début</label>
                    <datepicker
                        v-model="body.dateFrom"
                        input-class="form-input"
                        :language="fr"
                    ></datepicker>
                </div>
                <div>
                    <label
                        for="task"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                    >Fin</label>
                    <datepicker
                        v-model="body.dateTo"
                        input-class="form-input"
                        :language="fr"
                    ></datepicker>
                </div>
            </div>
            <div>
                <label
                    for="lot"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                >Détail</label>
                <textarea
                    class="form-input h-16"
                    resize="false"
                    v-model="body.details"
                ></textarea>
            </div>
        </div>

        <div slot="footer" class="mt-12 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >
                        Valider
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import { fr } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import PlanningCategorySelect from "@/components/Forms/PlanningCategorySelect";

export default {
    components: {
        DefaultModal,
        Datepicker,
        PlanningCategorySelect,
    },

    data() {
        return {
            fr: fr,
            body: {
                title: "",
                dateFrom: null,
                category: null,
                dateTo: null,
                details: "",
            },
            isDisabled: false,
        };
    },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        editTask: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
        
        planningCategories(){
            return [
                {
                    value: "Phase préalable",
                    label: "Phase préalable",
                    color: "bg-indigo-300",
                },
                {
                    value: "Phase administrative - Autorisation de construire",
                    label: "Phase administrative - Autorisation de construire",
                    color: "bg-yellow-300",
                },
                {
                    value: "Phase commercialisation",
                    label: "Phase commercialisation",
                    color: "bg-yellow-500",
                },
                {
                    value: "Phase travaux",
                    label: "Phase travaux",
                    color: "bg-green-400",
                },
            ];
        },
        isValid() {
            if(this.isDisabled)
                return false;
            return this.body.title && this.body.dateFrom && this.body.dateTo && this.body.category;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
    },
    methods: {
        handleCategoryChange(val) {
            this.body.category = val;
        },
        close() {
            this.$emit("close");
        },
        resetValues() {
            this.body = {
                title: null,
                details: null,
                dateFrom: null,
                dateTo: null,
                category: null,
            };
        },
        submit() {
            this.body.promotion_id = this.currentPromotion.id;
            this.body.dateFrom = this.body.dateFrom !== null ? this.$options.filters.date_ymd(this.body.dateFrom) : null,
            this.body.dateTo = this.body.dateTo !== null ? this.$options.filters.date_ymd(this.body.dateTo) : null,
            this.$emit("submit",this.body);
            this.close();
            this.resetValues();
        },
    },
    watch: {
        open(val) {
            if (val) {
                if (this.editTask) {
                    this.body = { 
                        ...this.editTask,
                    };
                }
            }
        }
    },
};
</script>
