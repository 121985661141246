<template>
    <div class="flex justify-center">
        <a
            v-if="brochure"
            :href="brochure.url"
            target="_blank"
        >
            <div class="whitespace-no-wrap	">
                <div class="arrow-left inline-block"></div>
                <div class="rounded-sm bg-blue-400 text-white px-4 py-2 text-lg font-medium text-center inline-block">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="h-6 w-6 inline-block fill-current align-top mr-1"
                    >
                        <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                    </svg>
                    Brochure de la promotion
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
        brochure() {
            if (!this.currentPromotion) return null;
            
            return this.currentPromotion.promotion_files.find(
                (item) => item.type_id === 3
            );
        },
    },
};
</script>

<style>
.arrow-left {
    width: 0;
    height: 0;
    border-top: 21px solid transparent;
    border-bottom: 21px solid transparent;
    border-right: 21px solid #73d1ea;
    vertical-align: bottom;
}
</style>