// import router from "@/router";
import store from "@/store";
import auth from "@/mixins/Auth";
import router from "@/router";
import action from "@/api/action";

function applicationHasTask(application, taskTitle) {
    return (
        application.actions.length &&
    application.actions.findIndex(
        action => action.title == taskTitle && action.done_at === null
    ) > -1
    );
}

export default {
    info_not_first_priority: {
        label: () => "Le candidat doit être en priorité 1 pour avancer dans le processus",
        color: "red-400",
        onclick() {},
        show: application => {
            return auth.computed.isBroker() && application.priority != 1;
        }
    },
    send_reservation_email: {
        label: () => "Envoyer fiche réservation",
        color: "green-400",
        onclick(application) {
            store.commit(
                "application/SET_CURRENT_ACTION_TYPE",
                "sendReservationEmail"
            );
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        },
        show: application => {
            return auth.computed.isBroker() && !application.reservation_sent_at;
        }
    },
    resend_reservation_email: {
        label: () => "Envoyer la FR à nouveau",
        show: application => {
            return (
                auth.computed.isBroker() && application.reservation_sent_at != null
            );
        },
        color: "gray-500",
        onclick(application) {
            store.commit(
                "application/SET_CURRENT_ACTION_TYPE",
                "sendReservationEmail"
            );
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    show_reservation: {
        label: () => "Fiche de réservation",
        show(application) {
            return application.reservation_sent_at != null && auth.computed.isBroker();
        },
        onclick(application) {
            let routeData = router.resolve({
                name: "candidate-form",
                params: { id: application.public_id },
                target: "_blank"
            });
            window.open(routeData.href, "_blank");
        }
    },
    reservation_complete: {
        label: () => "Fiche réservation complète",
        color: "green-500",
        show: application => {
            return (
                auth.computed.isBroker() && application.first_reservation_at && !application.reservation_confirmed_at
            );
        },
        onclick(application) {
            store.commit(
                "application/SET_CURRENT_ACTION_TYPE",
                "reservationComplete"
            );
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    send_notification_incomplete: {
        label: () => "Fiche réservation incomplète",
        color: "red-500",
        show(application) {
            return (
                auth.computed.isBroker() &&
        application.first_reservation_at &&
        !application.reservation_confirmed_at
            );
        },
        onclick(application) {
            store.commit(
                "application/SET_CURRENT_ACTION_TYPE",
                "sendNotificationIncomplete"
            );
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    schedule_agreement: {
        label: () => "Fixer signature PdV",
        color: "green-500",
        show: application => auth.computed.isBroker() && application.status === 1 && !application.promise_planned_at,
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "scheduleAgreement");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    send_act: {
        label: () => "Projet d'acte envoyé",
        color: "green-500",
        show: application =>
            (auth.computed.isBroker() || auth.computed.isNotary()) &&
      applicationHasTask(application, "Envoyer projet d'acte"),
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "sendAct");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    send_call_for_funds: {
        label: () => "Appel de fonds envoyé",
        color: "green-500",
        show: application =>
            (auth.computed.isBroker() || auth.computed.isNotary()) && applicationHasTask(application, "Envoyer appel de fonds"),
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "sendCallForFunds");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    reservation_deposit_paid: {
        label: () => "Acompte réservation payé",
        show: application =>
            (auth.computed.isBroker() || auth.computed.isNotary()) && application.reservation_deposit_confirmed_at === null && application.status == 0 && application.reservation_sent_at != null,
        color: "green-500",
        onclick(application) {
            store.commit(
                "application/SET_CURRENT_ACTION_TYPE",
                "reservationDepositPaid"
            );
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    // NE PAS CONFONDRE
    act_of_purchase_signed: {
        label: () => "Acte de vente signé",
        color: "green-500",
        show: application => auth.computed.isBroker() && application.status == 2 && application.act_of_purchase_at != null,
        onclick(application) {
            store.commit(
                "application/SET_CURRENT_ACTION_TYPE",
                "actOfPurchaseSigned"
            );
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    // AVEC CA
    plan_act_of_purchase: {
        label: () => "Fixer signature acte vente",
        color: "green-500",
        show: application => auth.computed.isBroker() && application.status == 2 && !application.act_of_purchase_at,
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "planActOfPurchase");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    update_negociated: {
        label: () => "Négociation",
        show: () => auth.computed.isBroker(),
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "updateNegociated");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    client_solvent: {
        label: () => "Client solvable",
        color: "green-500",
        show: application =>
            (auth.computed.isBroker() || auth.computed.isBanker()) &&
      application.status == 1 &&
      (!application.status_FIN_checked || !application.solvent),
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "clientSolvent");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    client_non_solvent: {
        label: () => "Client non solvable",
        color: "red-500",
        show: application =>
            (auth.computed.isBroker() || auth.computed.isBanker()) &&
      applicationHasTask(application, "Vérifier si client solvable"),
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "clientNonSolvent");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    confirm_received_agreement_funds: {
        label: () => "Confirmer réception fonds PdV",
        color: "green-500",
        show: application =>
            (auth.computed.isBroker() || auth.computed.isNotary()) &&
      applicationHasTask(application, "Confirmer réception fonds PdV"),
        onclick(application) {
            store.commit(
                "application/SET_CURRENT_ACTION_TYPE",
                "confirmReceivedAgreementFunds"
            );
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    update_statuses: {
        label: () => "Modifier les statuts",
        show: () => auth.computed.isBroker(),
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "updateStatuses");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    add_task: {
        label: () => "Ajouter une tâche",
        show: () => auth.computed.isBroker(),
        onclick(application) {
            store.dispatch("task/openAddTask", {
                target: application,
                targetType: "application"
            });
        }
    },
    update_priority: {
        label: () => "Modifier la priorité",
        show: () => auth.computed.isBroker(),
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "updatePriority");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    send_new_access: {
        label: () => "Envoyer les identifiants",
        onclick(application) {
            store.commit(
                "application/SET_CURRENT_ACTION_TYPE",
                "sendAccess"
            );
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        },
        show: application => {
            return auth.computed.isBroker() && application.reservation_sent_at != null;
        }
    },
    delete_application: {
        label: () => "Supprimer la candidature",
        color: "red-500",
        show: () => {
            return auth.computed.isBroker();
        },
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "deleteApplication");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    add_partenaire: {
        label: () => "Ajouter un partenaire",
        show: application => {
            return auth.computed.isBroker() && !application.candidacy2;
        },
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "addCandidacy");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    remove_partenaire: {
        label: () => "Retirer un partenaire",
        show: application => {
            return auth.computed.isBroker() && application.candidacy2 !== null;
        },
        onclick(application) {
            store.commit("application/SET_CURRENT_ACTION_TYPE", "removePartner");
            store.commit("application/SET_CURRENT_ACTION_TARGET", application);
        }
    },
    logs: {
        label: () => "Historique",
        show: () => {
            return auth.computed.isBroker();
        },
        onclick(application) {
            router.push({ name: "logs", query: {
                application_id: application.id,
            } });
        }
    },
    download_application: {
        label: () => "Télécharger la candidature",
        show: () => {
            return auth.computed.isBroker() || auth.computed.isNotary() || auth.computed.isBanker() || auth.computed.isPromoter();
        },
        onclick(application) {

            store.dispatch("notification/addToast", {
                type: "warning",
                title: "Génération du PDF",
                content: "Téléchargement en cours..."
            });
            
            //appel pour generer PDF+URL
            action.applicationPdf(application.public_id)
                .then((response) => {
                    if (response.status === 200) {
                        //si ok download
                        window.open(response.data, "_blank");
                    }
                });
        }
    }
};
