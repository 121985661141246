import axios from "axios";

export default {
    execute(commandName, params){
        return axios.post(`/commands/${commandName}`, params, {
            headers : {
                "Content-Type" : "application/json"
            }
        });
    }
};