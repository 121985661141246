<template>
    <default-modal
        :open="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Merci pour votre inscription
        </h3>
        <div slot="body">
            <div class="text-left">
                Merci de l'interet que vous portez au projet, nous vous contacterons par email a l'adresse que vous venez d'indiquer.
            </div>
        </div>
        <div
            slot="footer"
            class="mt-12 text-right"
        >
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <a
                        :href="url"
                        class="bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Retourner sur le site de la promotion
                    </a>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";

export default {
    components: {
        DefaultModal
    },
    data() {
        return {};
    },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        url: {
            type: String,
            default: null
        }
    },
    computed: {},
    methods: {
        close() {
            this.$emit("close");
        },
        goToPromotion() {
        }
    }
};
</script>