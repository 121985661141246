<template>
    <div>
        <div class="mt-2" v-if="candidateHasFiles">
            <div
                class="mt-1 align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
            >
                <table class="min-w-full bg-gray-200">
                    <thead class="flex flex-col">
                        <tr>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Date d'ajout
                            </th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-full"
                            >
                                Nom
                            </th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Fichier
                            </th>
                            <th
                                v-if="isBroker || isCandidate"
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50"
                            ></th>
                        </tr>
                    </thead>
                    <tbody v-if="candidateHasFiles" class="flex flex-col overflow-y-scroll h-40 ">
                        <tr
                            class="bg-white w-full"
                            v-for="file in candidacy.files"
                            :key="file.id"
                        >
                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{ file.created_at | date_dmyhm }}
                            </td>
                            <td
                                class="px-6 py-4 w-full whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{ file.name }}
                            </td>

                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                <a
                                    @click.prevent="download(file)"
                                    target="_blank"
                                    class="text-blue-400 hover:text-blue-500 flex justify-center"
                                >
                                    <svg
                                        class="w-6 h-6"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                        />
                                    </svg>
                                </a>
                            </td>
                            <td v-if="isBroker || isCandidate || isPromoter">
                                <button
                                    class="flex m-auto focus:outline-none text-gray-400 hover:text-red-700 transition-colors duration-300"
                                    @click="remove(file)"
                                >
                                    <svg
                                        class="w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mt-2" v-else>
            <p
                class="text-sm leading-5 text-gray-700 text-left"
                v-if="candidate"
            >
                Aucun fichier n'a encore été ajouté sur le candidat
                <span class="font-semibold">{{ candidate.full_name }}</span
                >.
            </p>
        </div>

        <!-- ADD FILE -->
        <div v-if="isBroker || isNotary || isBanker || isArchitect || isPromoter">
            <hr class="mt-4 mb-4" />

            <file-dragger
                ref="fileDragger"
                @change="updateFilesCount"
                :multiple="true"
                @openEdit="editingFiles = true"
                @closeEdit="editingFiles = false"
            ></file-dragger>
        </div>

        <div slot="footer" class="mt-6 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >
                        Ajouter
                    </button>
                </span>
                <span
                    v-if="!candidateView"
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import FileDragger from "@/components/global/FileDragger";
import Auth from "@/mixins/Auth";

import files from "@/api/files";

export default {
    mixins: [Auth],
    data() {
        return {
            filesCount: 0,
            editingFiles: false,
            isDisabled: false,
        };
    },
    components: {
        FileDragger,
    },
    props: {
        candidacy: {
            required: true,
            type: Object,
        },
        candidateView: {
            default: false,
        },
    },
    computed: {
        candidateHasFiles() {
            // return false;
            return this.candidacy.files && this.candidacy.files.length > 0;
        },
        candidate(){
            return this.candidacy.candidate;
        },
        isValid() {
            if(this.isDisabled)
                return false;
            return this.filesCount > 0 && !this.editingFiles;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
    },
    methods: {
        resetValues() {
            this.filesCount = 0;
            if(this.$refs.fileDragger)
                this.$refs.fileDragger.resetValues();
        },
        changeFile(event) {
            this.body.file = event.target.files[0];
        },
        remove(file) {
            if (!confirm("Voulez-vous vraiment supprimer ce document ?")) {
                return false;
            }
            this.$store
                .dispatch("candidacy/removeFile", file.id)
                .then(() => {
                    this.$emit("removeFile");
                    this.close();
                });
               
        },
        submit() {
            const data = new FormData();
            if (this.isValid) {
                this.isDisabled = true;
                if(this.$refs.fileDragger){
                    this.$refs.fileDragger.appendToFormData(data);
                }

                data.append("candidacy_id", this.candidacy.id);

                this.$store
                    .dispatch("candidacy/addFile", data)
                    .then(() => {
                        this.$emit("addFile");
                        this.close();
                    }).finally(() => {
                        this.isDisabled = false;
                    });
            }
        },
        download(file){
            return files.download(file);
        },
        close() {
            this.resetValues();
            this.$emit("close");
        },
        updateFilesCount(files){
            this.filesCount = files.length;
        },
    },
};
</script>

<style></style>
