<template>
    <div class="bg-white rounded shadow-lg">
        <div class="text-center p-6 border-b">
            <img
                class="h-24 w-24 rounded-full mx-auto"
                :src="candidate.profile_picture"
                :alt="candidate.full_name"
            />
            <div class="pt-2 text-lg font-semibold underline">
                {{ candidate.full_name }}  
            </div>
            <div v-for="candidacy in candidate.candidacies" :key="candidacy.id" class="mt-2">
                <p class="text-sm text-gray-600 mt-4">
                    <vip-badge v-if="candidacy.vip"
                    class="ml-1"
                    :candidacy="candidacy"
                /> {{candidacy.promotion.title}}
                </p>                
            </div>
        </div>
        <div class="border-b">
            <a
                href="#"
                class="px-4 py-2 flex"
                @click="emitChangeMenu('informations')"
                :class="menuClasses.informations"
            >
                <div class="text-blue-400">
                    <svg
                        strokeWidth="1"
                        viewBox="0 0 22 22"
                        class="w-5 h-5 fill-current"
                    >
                        <path
                            d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 11v4h2V9H9v2zm0-6v2h2V5H9z"
                        />
                    </svg>
                </div>
                <div class="pl-3">
                    <p class="text-sm font-medium text-gray-800 leading-none">
                        Informations
                    </p>
                    <p class="text-xs text-gray-500">
                        Afficher les informations du candidat
                    </p>
                </div>
            </a>
            <a
                href="#"
                class="px-4 py-2 flex"
                @click="emitChangeMenu('candidatures')"
                :class="menuClasses.candidatures"
            >
                <div class="text-blue-400">
                    <svg
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        class="w-5 h-5"
                    >
                        <path
                            d="M4 18h12V6h-4V2H4v16zm-2 1V0h12l4 4v16H2v-1z"
                        />
                    </svg>
                </div>
                <div class="pl-3">
                    <p class="text-sm font-medium text-gray-800 leading-none">
                        Candidatures
                    </p>
                    <p class="text-xs text-gray-500">Voir les candidatures</p>
                </div>
            </a>
            <a
                href="#"
                class="px-4 py-2 flex"
                @click="emitChangeMenu('documents')"
                :class="menuClasses.documents"
            >
                <div class="text-blue-400">
                    <svg
                        strokeWidth="1"
                        viewBox="0 0 22 22"
                        class="w-5 h-5 fill-current"
                    >
                        <path
                            d="M15 3H7a7 7 0 1 0 0 14h8v-2H7A5 5 0 0 1 7 5h8a3 3 0 0 1 0 6H7a1 1 0 0 1 0-2h8V7H7a3 3 0 1 0 0 6h8a5 5 0 0 0 0-10z"
                        />
                    </svg>
                </div>
                <div class="pl-3">
                    <p class="text-sm font-medium text-gray-800 leading-none">
                        Documents
                    </p>
                    <p class="text-xs text-gray-500">
                        Fichiers ajoutés par le candidat &amp; le courtier
                    </p>
                </div>
            </a>
            <a
                href="#"
                class="px-4 py-2 flex"
                @click="emitChangeMenu('commentaires')"
                :class="menuClasses.commentaires"
            >
                <div class="text-blue-400">
                    <svg
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        viewBox="0 0 24 24"
                        class="w-5 h-5 inline-block"
                    >
                        <path
                            d="M0 3h20v2H0V3zm0 4h20v2H0V7zm0 4h20v2H0v-2zm0 4h20v2H0v-2z"
                        />
                    </svg>
                </div>
                <div class="pl-3">
                    <p class="text-sm font-medium text-gray-800 leading-none">
                        Commentaires
                    </p>
                    <p class="text-xs text-gray-500">
                        Voir / modifier les commentaires du candidat
                    </p>
                </div>
            </a>
            <a
                href="#"
                class="px-4 py-2 flex"
                @click="emitChangeMenu('messages')"
                :class="menuClasses.messages"
            >
                <div class="text-blue-400">
                    <svg
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        viewBox="0 0 24 24"
                        class="w-5 h-5"
                    >
                        <path
                            d="M17 11v3l-3-3H8a2 2 0 0 1-2-2V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-1zm-3 2v2a2 2 0 0 1-2 2H6l-3 3v-3H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h2v3a4 4 0 0 0 4 4h6z"
                        />
                    </svg>
                </div>
                <div class="pl-3">
                    <p class="text-sm font-medium text-gray-800 leading-none">
                        Messages
                    </p>
                    <p class="text-xs text-gray-500">
                        Discussions avec le candidat
                    </p>
                </div>
            </a>
        </div>

        <div class="grid grid-cols-2">
            <div
                class="bg-blue-400 hover:bg-blue-200 text-white text-center border-r"
                :class="emailClass"
            >
                <a
                    :href="candidate.email ? `mailto:${candidate.email}` : '#'"
                    class="inline-block w-full h-full py-1 pb-2"
                    :class="candidate.email ? '' : 'cursor-not-allowed'"
                    @click.stop
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="h-4 w-4 inline-block fill-current"
                    >
                        <path
                            d="M13.6 13.47A4.99 4.99 0 0 1 5 10a5 5 0 0 1 8-4V5h2v6.5a1.5 1.5 0 0 0 3 0V10a8 8 0 1 0-4.42 7.16l.9 1.79A10 10 0 1 1 20 10h-.18.17v1.5a3.5 3.5 0 0 1-6.4 1.97zM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                        />
                    </svg>
                </a>
            </div>
            <div class="text-white text-center" :class="phoneClass">
                <a
                    :href="candidate.phone ? `tel:${candidate.phone}` : '#'"
                    class="inline-block w-full h-full py-1 pb-2"
                    :class="candidate.phone ? '' : 'cursor-not-allowed'"
                    @click.stop
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="h-4 w-4 inline-block fill-current"
                    >
                        <path
                            d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"
                        />
                    </svg>
                </a>
            </div>
        </div>
        
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import VIPBadge from "@/components/Candidates/VIPBadge";

const menus = [
    "informations",
    "candidatures",
    "commentaires",
    "messages",
    "documents",
];
const DEFAULTMENU = menus[0];

export default {
    components: {
        "vip-badge": VIPBadge
    },
    props: {
        candidate: {
            required: true,
            type: Object,
        },
        initialMenu: {
            type: String,
        },
    },
    data() {
        return {
            currentMenu: DEFAULTMENU,
            showCandidateTooltip : false,
        };
    },
    mounted() {
        if (this.initialMenu) this.currentMenu = this.initialMenu;
    },
    computed: {
        ...mapState("promotion", ["promotions"]),
        ...mapGetters("auth",["currentPromotion"]),
        menuClasses() {
            var menuClasses = {};
            for (var item of menus) {
                menuClasses[item] =
                    this.currentMenu == item
                        ? "bg-gray-200"
                        : "bg-white hover:bg-gray-100";
            }
            return menuClasses;
        },
        phoneClass() {
            return this.candidate.phone
                ? "bg-blue-400 hover:bg-blue-200"
                : "bg-gray-500 hover:bg-gray-500 cursor-not-allowed";
        },
        emailClass() {
            return this.candidate.email
                ? "bg-blue-400 hover:bg-blue-200"
                : "bg-gray-500 hover:bg-gray-500 cursor-not-allowed";
        },
        promotion() {
            if (!this.promotions || !this.candidate) return null;
            return this.promotions.find(
                (x) => x.id === this.candidate.promotion_id
            );
        },
    },
    methods: {
        emitChangeMenu(menu) {
            if (menu !== this.currentMenu) {
                this.currentMenu = menu;
                this.$emit("selectMenu", menu);
            }
        },
        handleViewInAppClick(){
            if(this.candidate.promotion_id != this.currentPromotion.id){
                this.$store.commit("message/SET_CURRENT_ACTION_TYPE", "chagePromotion");
            }
            else{
                this.viewInApp();
            }
        }
    },
};
</script>

<style>
</style>