export default {
    date: value => {
        const date = new Date(value);
        return date.toLocaleString(["fr-FR"], {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
        });
    },
    date_dmy: value => {
        let d = new Date(value),
            day = "" + d.getDate(),
            month = d.toLocaleString("fr-FR", { month: "long" }),
            year = d.getFullYear();

        if (day.length < 2) day = "0" + day;

        return [day, month, year].join(" ");
    },
    date_ymd: value => {
        let d = new Date(value),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = "0" + month;
        if (day.length < 2) 
            day = "0" + day;

        return [year, month, day].join("-");
    },
    date_dm: value => {
        let d = new Date(value),
            day = "" + d.getDate(),
            month = d.toLocaleString("fr-FR", { month: "long" });

        if (day.length < 2) day = "0" + day;

        return [day, month].join(" ");
    },
    date_dmy_t: value => {
        let d = new Date(value),
            day = "" + d.getDate(),
            month = "" + (d.getMonth() + 1),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    },
    date_dmyhm: value => {
        
        let d = new Date(value),
            day = "" + d.getDate(),
            month = "" + (d.getMonth() + 1),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return (
            [day, month, year].join("/") +
            " " +
            d.toLocaleTimeString("fr", {
                hour: "2-digit",
                minute: "2-digit"
            })
        );
    },
    date_dmyhms: value => {
        
        let d = new Date(value),
            day = "" + d.getDate(),
            month = "" + (d.getMonth() + 1),
            year = d.getFullYear();
        
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return (
            [day, month, year].join("/") +
            " " +
            
            d.toLocaleTimeString("fr", {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
            })
        );
    },
    to_hm: value => {
        let hours = Math.floor(value / 60);
        let minutes = value % 60;
        let hour = hours > 1 ? hours + " heures " : hours + " heure ";
        let min = minutes > 0 ? minutes + " minutes" : "";
        return hour + min;
    }
};
