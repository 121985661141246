<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-4xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-gray-800 pb-5 px-4"
        >
            Créer un promoteur pour
            <span class="font-bold text-blue-900">{{
                promotion ? promotion.title : '-'
            }}</span>
        </h3>
        <div slot="body" class="text-left px-4">
            <div class="mt-3">
                <div class="text-left">
                    <label
                        for="first_name"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Prénom</label
                    >
                    <input
                        type="text"
                        id="first_name"
                        v-model="promoter.first_name"
                        class="form-input w-full"
                    />
                </div>
            </div>

            <div class="mt-3">
                <div class="text-left">
                    <label
                        for="last_name"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Nom</label
                    >
                    <input
                        type="text"
                        id="last_name"
                        v-model="promoter.last_name"
                        class="form-input w-full"
                    />
                </div>
            </div>

            <div class="mt-3">
                <div class="text-left">
                    <label
                        for="email"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Email</label
                    >
                    <input
                        type="email"
                        id="email"
                        v-model="promoter.email"
                        class="form-input w-full"
                    />
                </div>
            </div>

            <div class="mt-3">
                <div class="text-left">
                    <label
                        for="password"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Mot de passe* (à communiquer vous même au promoteur)</label
                    >
                    <input
                        type="text"
                        id="password"
                        v-model="promoter.password"
                        class="form-input w-full"
                    />
                </div>
            </div>
        </div>

        <div slot="footer" class="mt-12 text-right px-4">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >
                        Valider
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";

export default {
    components: {
        DefaultModal,
    },

    props: {
        open: {
            type: Boolean,
            required: true
        },

        promotion: {
            type: Object,
            required: true
        }
    },

    data() {
        return this.initialState();
    },

    computed: {
        isValid() {
            if(this.isDisabled)
                return false;
            const fields = ["first_name", "last_name", "email", "password"];
            for (const field of fields) {
                if ([null, ""].includes(this.promoter[field])) {
                    return false;
                }
            }
            return true;
        },

        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
    },
    methods: {
        initialState(){
            return  {
                promoter: {
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "Na3f."+new Date().getFullYear()+".$"
                },
                isDisabled: false,
            };
        },
        reset(){
            Object.assign(this.$data, this.initialState());
        },
        close() {
            this.$emit("close");
        },

        submit() {
            this.isDisabled = true;
            this.$emit("submit", this.promoter);
            this.isDisabled = false;
            this.reset();
        },
    }
};
</script>
