export default {
    methods : {
        render(){
            this.body.emails = this.mainCandidate.email;
            if (this.hasSecondCandidate && this.secondCandidate.email) {
                this.body.emails +=
                        "," + this.secondCandidate.email;
            }
            if (this.currentActionTarget) {
                this.renderBody();
                this.renderSubject();
            }
        }
    }
};