<template>
    <div
        v-if="priorities"
        class="inline-flex items-center justify-center leading-none h-6 w-6 rounded-full text-xs font-medium pb-px relative cursor-default"
        :class="candidatePriority.color"
        @mouseenter="showTooltip = true"
        @mouseleave="showTooltip = false"
    >
		<div
      class="rounded text-sm bg-white border absolute p-2 text-left border-gray-200"
			:class="candidatePriority.color"
        v-show="showTooltip"
        style="bottom : calc(100%); transform : translateX(-50%)"
    >
            <div>{{ candidatePriority.full_label }}</div>
        </div>

        {{ candidatePriority.label }}
    </div>
</template>

<script>
import PriorityBadge from "@/mixins/PriorityBadge";
import { mapState } from "vuex";

export default {
    mixins: [PriorityBadge],
    
    data(){
        return {
            showTooltip : false,
        };
    },
    computed : {
        ...mapState("candidate", ["priorities"]),
        candidatePriority(){
            return this.priorities.find(x=> x.value === this.priority);
        },
    },
};
</script>

<style></style>
