import message from "@/api/message";
import modals from "../partials/modals";

const state = {
    ...modals.state,
    threads: null,
    thread : null,
};

const getters = {
    ...modals.getters,
    threads: state => {
        return state.threads;
    },
    thread: state => {
        return state.thread;
    },
};

const actions = {
    getThreads({ commit, rootGetters }) {
        commit("loader/SET_LOADING", true, { root: true });

        return message
            .getThreads(rootGetters["app/formattedFilters"])
            .then(response => {
                if (response.status === 200) {
                    commit("SET_THREADS", response.data);
                }

                commit("loader/SET_LOADING", false, { root: true });
                return response;
            });
    },

    getThread({commit, dispatch}, threadId){
        return message.getThread(threadId).then((res) => {
            commit("SET_THREAD", res.data);
            if(res.status === 200){
                dispatch(
                    "markAsRead",
                    parseInt(threadId)
                );
            }
        });
    },

    createThread(root, body){
        return message.createThread(body);
    },

    replyToThread(root, body){
        return message.replyToThread(body, body.get("thread_id"));
    },

    markAsRead({ dispatch, rootGetters }, id) {
        return message
            .markAsRead(rootGetters["auth/formattedFilters"], id)
            .then(response => {
                dispatch("auth/user", null, {root : true});
                return response;
            })
            .catch(error => {
                return error;
            });
    },
    closeModals({commit}){
        commit("SET_CURRENT_ACTION_TARGET", null);
        commit("SET_CURRENT_ACTION_TYPE", null);
    }
};

const mutations = {
    ...modals.mutations,
    SET_THREADS(state, value) {
        state.threads = value;
    },
    SET_THREAD(state, value){
        state.thread = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
