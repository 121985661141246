<template>
    <div
        class="flex justify-between bg-white shadow mx-auto pb-2 px-3 sm:px-6 lg:px-8"
    >
        <div v-if="filters" class="flex items-end pt-2 pb-1 grid  sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
            <!-- Filtres immeuble -->
            <div v-show="showLotFilters" class="flex inline-block" style="min-width : 300px">
                <MultiSelect
                    title="Immeuble"
                    label="title"
                    :collection="filtersCollections.buildings"
                    @change="updateSelectBuildings"
                    ref="buildingsSelect"
                    :minCount="1"
                ></MultiSelect>
                <MultiSelect
                    title="Etage"
                    label="title"
                    :minCount="1"
                    :collection="filtersCollections.floors"
                    @change="updateSelectFloors"
                    ref="floorsSelect"
                ></MultiSelect>
                <MultiSelect
                    :minCount="1"
                    title="Nb. de pièces"
                    label="title"
                    :collection="filtersCollections.nb_rooms"
                    @change="updateSelectNbRooms"
                    ref="roomsSelect"
                ></MultiSelect>
                <!-- Filtre par agence -->
                <div
                    class="inline-block mr-3 mt-1 select-none"
                    v-if="currentPromotion.multi_agency && isMultiAgency"
                    id="showMyAgency"
                >
                    <label>
                        <input
                            type="checkbox"                        
                            @change="handleShowMyAgency"
                            ref="showMyAgency"
                        />
                        Mon agence
                    </label>
                </div>                           
            </div>
            <div class="">
                <!-- Switch KCHF -->
                <on-off
                    class="mr-4"
                    v-if="nb_kchf"
                    startText="En nombre"
                    endText="En KCHF"
                    @change="
                        (on) => {
                            handleOnOffChange(on);
                        }
                    "
                />                          
                <!-- Filtre prix  -->
                <div
                    v-show="showPriceSlider"
                    class="mt-2 mr-5 inline-block self-center max-w-xs text-xs leading-4 font-medium text-gray-500"
                >
                    <div class="text-center">Prix de vente (KCHF)</div>
                    <div>
                        <span class="mr-1 inline-block w-6">{{slider.current.displayedMin}}</span>
                        <div class="w-48 inline-block">
                            <div ref="slider"></div>
                        </div>
                        <span class="ml-5 inline-block w-6 text-right">{{slider.current.displayedMax}}</span>
                    </div>
                </div>                
                <!-- Recherche -->
                <div v-show="showSearch" class="inline-block mr-4">
                    <div class="mt-1 flex rounded-md shadow-sm w-64">
                        <div class="relative flex-grow focus-within:z-10">
                            <input
                                v-model="search"
                                id="search"
                                @input="debouncedChange"
                                :class="{ 'rounded-r-md': !search }"
                                class="px-3 py-2 border border-gray-300 block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5 appearance-none focus:outline-none focus:border-blue-400"
                                placeholder="Recherche"
                            />
                        </div>
                        <button
                            v-if="search"
                            @click="clearSearch"
                            class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 rounded-r-md text-sm leading-5 font-medium text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-400 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                        >
                            <svg
                                class="h-5 w-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                    fill-rule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <!-- Bouton reset -->
                <div
                    class="inline-block p-2 w-10 rounded shadow align-bottom text-gray-700 cursor-pointer hover:shadow-lg transition-all select-none"
                    @click="resetFilters"
                >
                    <svg
                        width="24"
                        height="24"
                        class="fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.662 23l-5.369-5.365c-.195-.195-.293-.45-.293-.707 0-.256.098-.512.293-.707l14.929-14.928c.195-.194.451-.293.707-.293.255 0 .512.099.707.293l7.071 7.073c.196.195.293.451.293.708 0 .256-.097.511-.293.707l-11.216 11.219h5.514v2h-12.343zm3.657-2l-5.486-5.486-1.419 1.414 4.076 4.072h2.829zm6.605-17.581l-10.677 10.68 5.658 5.659 10.676-10.682-5.657-5.657z"
                        />
                    </svg>
                </div>
            </div>

            <picture-select
                ref="brokerSelect"
                v-if="brokersFilter"
                :collection="currentPromotion.brokers"
                valueProp="id"
                textProp="full_name"
                imgProp="profile_picture"
                defaultText="Tous les courtiers"
                @change="updateSelectBroker"
            ></picture-select>

            <div
                class="inline-block mr-3 ml-2 select-none"
                v-if="showNonRetainedCheckbox"
                id="showNonRetained"
            >
                <label>
                    <input
                        type="checkbox"                        
                        @change="handleShowNonRetained"
                        ref="showNonRetainedInput"
                    />
                    Afficher non retenus
                </label>
            </div>


        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import MultiSelect from "@/components/Forms/MultiSelect";
import Slider from "@/mixins/Slider";
import { resetQuery } from "@/helpers/router";
// import { formatFloor } from "@/helpers/formatter";
import PictureSelect from "@/components/Forms/PictureSelect";
import OnOff from "@/components/Forms/OnOff";
import QueryParamsFilters from "@/mixins/QueryParamsFilters";

export default {
    mixins: [Slider, QueryParamsFilters],
    components: { MultiSelect, PictureSelect, OnOff },

    props: {
        brokersFilter: {
            type: Boolean,
            default: false,
        },

        nb_kchf: {
            type: Boolean,
            default: false,
        },

        showNonRetainedCheckbox: {
            type: Boolean,
            default: false,
        },

        showPriceSlider: {
            type: Boolean,
            default: true,
        },

        showLotFilters: {
            type: Boolean,
            default: true,
        },

        showSearch: {
            type: Boolean,
            default: true,
        },
        isMultiAgency: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            debouncedChange: null,
            search: null,
        };
    },

    mounted() {
        const el = this;
        this.debouncedChange = _.debounce(function() {
            el.setFilter("search", el.search);
        }, 300);

        this.createSlider();
    },

    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
        ...mapState("app", ["filters", "filtersCollections"]),
        ...mapState("auth", ["user"]),

        ...mapState("global", ["globalSearch"]),
        ...mapState("broker", ["brokers"]),
        
        sliderInstance() {
            return this.$refs.slider;
        },
    },
    methods: {
        resetQuery,
        resetFilters() {

            const selects = ["buildingsSelect", "roomsSelect", "floorsSelect"];
            for (var i in selects) {
                if (this.$refs[selects[i]]) {
                    this.$refs[selects[i]].selectAll(false);
                }
            }

            this.clearSearch();

            this.setFilter("showNonRetained", null);
            if (this.$refs.showNonRetainedInput)
                this.$refs.showNonRetainedInput.checked = false;


            this.setFilter("broker", null);
            if (this.$refs.brokerSelect)
                this.$refs.brokerSelect.selected = null;

            this.clearSlider();
            this.resetQuery();
        },
        handleOnOffChange(val) {
            this.setFilter("toKCHF", val);
        },
        clearSearch() {
            this.search = null;
            this.setFilter("search", this.search);
        },
        setFilter(key, val) {
            this.$store.commit("app/SET_FILTER", {
                key,
                value: val,
            });
        },
        updateSelectNbRooms(value) {
            this.setFilter("nb_rooms", value);
        },
        updateSelectFloors(value) {
            this.setFilter("floors", value);
        },
        updateSelectBuildings(value) {
            this.setFilter("buildings", value);
        },
        updateSelectBroker(broker) {
            this.setFilter("broker", broker);
        },
        handleShowNonRetained(event) {
            this.setFilter("showNonRetained", event.target.checked ? event.target.checked : null);
        },
        handleShowMyAgency(event) {
            this.setFilter("showMyAgency", event.target.checked ? event.target.checked : null);
        },
    },
    watch : {
        filtersCollections : {
            deep : true,
            handler(){
                this.clearSlider();
            }
        },
    },

    beforeDestroy() {
        if (this.currentPromotion) {
            if (this.sliderInstance.noUiSlider) {
                this.sliderInstance.noUiSlider.off("change");
                this.sliderInstance.noUiSlider.off("update");
            }
        }
    },
};
</script>
