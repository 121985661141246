<template>
    <div class="flex flex-col" v-if="photoGroups && photoGroups.data.length">
        <candidate-album-row
            v-for="photoGroup in photoGroups.data"
            :key="photoGroup.id"
            :photoGroup="photoGroup"
            @openSlider="openSlider"
        ></candidate-album-row>
        <the-pagination
            v-if="photoGroups"
            :lastPage="photoGroups.last_page"
            :currentPage="photoGroups.current_page"
            :from="photoGroups.from"
            :to="photoGroups.to"
            :total="photoGroups.total"
        ></the-pagination>
    </div>
</template>

<script>
import CandidateAlbumRow from "@/components/Progression/CandidateAlbumRow";
import ThePagination from "@/components/global/ThePagination";
//import { mapGetters } from "vuex";

export default {
    components: {
        CandidateAlbumRow,ThePagination
    },
    data() {
        return {};
    },
    methods: {
        openSlider(id) {
            this.$emit("openSlider", id);
        }
    },
    /*computed: {
        //...mapGetters("photo",["photoGroups"]),
        hasPhotos() {
            return this.photoGroups.length > 0;
        }
    },*/
    props: {
        photoGroups: {
            type: Object,
            required: true,
        },
    }
};
</script>
