import modals from "../partials/modals";

const state = {
    ...modals.state,
};

const getters = {
    ...modals.getters
};

const actions = {
    closeModals({commit}){
        commit("SET_CURRENT_ACTION_TARGET", null);
        commit("SET_CURRENT_ACTION_TYPE", null);
    }
};

const mutations = {
    ...modals.mutations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
