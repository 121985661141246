<template>
  <div class="shadow rounded-lg inline-block mr-5" v-on-clickaway="close">
    <div class="relative">
      <button
        @click.prevent="toggle"
        class="rounded-lg inline-flex items-center bg-white focus:outline-none focus:shadow-outline text-gray-700 py-2 px-2 md:pl-4 md:pr-2 text-sm border"
      >
        <span class="inline-block" v-if="selected != null">
          <status-badge
            class="mr-2"
            :status="selected.status"
            :statusText="selected.status_text"
          ></status-badge>
          [{{ selected.reference }}] - {{ selected.full_title }}
        </span>
        <span v-else>
          {{ defaultText }}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 ml-1"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <rect x="0" y="0" width="24" height="24" stroke="none" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </button>

      <div
        v-show="open"
        class="z-40 absolute top-0 left-0 bg-white rounded-lg shadow-lg mt-12 -mr-1 block py-1 w-120"
      >
        <div>
          <input
            type="text"
            class="w-full p-2"
            placeholder="Recherche"
            v-model="search"
            ref="search"
          />
        </div>
        <div class="overflow-y-auto h-56">
          <div
            @click="selectLot(null)"
            class="px-3 py-2 font-semibold hover:bg-gray-200 cursor-pointer"
          >
            Aucun
          </div>
          <template v-for="(lot, index) in filteredLots">
            <div
              class="flex justify-between items-center text-truncate hover:bg-gray-100 px-4 py-2"
              :class="{
                'bg-gray-300 cursor-not-allowed hover:bg-gray-300':
                  lot.disabled,
                'bg-blue-100 hover:bg-blue-100': isSelected(lot),
                'cursor-pointer': !lot.disabled
              }"
              :key="index"
              @click="selectLot(lot)"
            >
              <div class="flex">
                <status-badge
                  class="mr-2"
                  :status="lot.status"
                  :statusText="lot.status_text"
                ></status-badge>
                <div class="select-none text-gray-700 text-sm">
                  {{ lot.reference }} - {{ lot.full_title }}
                </div>
              </div>
              <span
                v-if="lot.disabled"
                class="text-sm font-semibold text-red-500"
              >
                Existe déjà
              </span>
              <span
                v-if="isSelected(lot)"
                class="text-sm font-semibold text-blue-600"
              >
                Sélectionné
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import { firstBy } from "thenby";
import StatusBadge from "@/components/Lots/StatusBadge";
import { mapGetters } from "vuex";

export default {
    mixins: [clickaway],

    components: {
        StatusBadge
    },

    props: {
        defaultText: {
            type: String
        },
        initialValue: null,
        value: null,
        lots: {
            type: Array
        }
    },

    data() {
        return {
            open: false,
            selected: null,
            search: ""
        };
    },

    mounted() {
        this.$store.dispatch("lot/getAll").then(res => {
            if (res.status == 200) {
                if (this.initialValue) this.setSelected(this.initialValue);
            }
        });
    },

    computed: {
        ...mapGetters("lot", ["allLots"]),
        sortedLots() {
            if (this.lots) {
                return this.lots;
            } else if (this.allLots) {
                let clonedLots = [...this.allLots];
                return clonedLots.sort(
                    firstBy((a, b) => {
                        return a.status - b.status;
                    })
                        .thenBy("floor")
                        .thenBy("id")
                );
            }
            return [];
        },

        filteredLots() {
            return this.sortedLots.filter(lot => {
                const searchOn = (lot.reference + " " + lot.full_title).toLowerCase();
                let containsSearch =
          this.search == "" || searchOn.includes(this.search.toLowerCase());

                return containsSearch;
            });
        }
    },
    methods: {
        setSelected(id) {
            if (id === null) {
                this.selected = null;
            } else {
                this.selected = this.sortedLots.find(x => x.id === id);
            }
        },

        close() {
            this.open = false;
            this.search = "";
        },

        toggle() {
            this.open = !this.open;

            if (this.open) {
                this.$nextTick(() => {
                    this.$refs.search.focus();
                });
            }
        },

        selectLot(lot) {
            if (lot) {
                if (!lot.disabled) {
                    this.setSelected(lot.id);
                    this.$emit("change", this.selected.id);
                }
            } else {
                this.setSelected(null);
                this.$emit("change", null);
            }
            this.close();
        },
        
        isSelected(lot) {
            return this.selected && lot.id == this.selected.id;
        }
    },
    watch: {
        initialValue(newVal) {
            this.setSelected(newVal);
        }
    }
};
</script>

<style>
</style>