<template>
    <div>
        <div v-if="candidacy.comment">
            <p class="text-sm leading-5 text-gray-700 text-left">
                Commentaire du candidat :
                <textarea
                    class="form-input h-16"
                    resize="false"
                    readonly
                    v-model="candidacy.comment"
                ></textarea>
            </p>
        </div>
        <div class="mb-4" v-if="hasComments">
            <div
                class="mt-2 align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 text-center"
            >
                <table class="w-full">
                    <thead class="bg-gray-200">
                        <tr>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Date d'ajout
                            </th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Ajouté par
                            </th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Commentaire
                            </th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="bg-white border-t border-gray-200"
                            v-for="comment in candidacy.user_comments"
                            :key="comment.id"
                        >
                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{ comment.created_at | date_dmyhm }}
                            </td>
                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{ comment.created_by.full_name }}
                            </td>

                            <td
                                class="px-6 py-4 text-sm leading-5 text-gray-600"
                            >
                                {{ comment.comment }}
                            </td>
                            <td
                                class="px-6 py-4 text-sm leading-5 text-gray-600"
                            >
                                <span
                                    v-if="user.id == comment.created_by_id"
                                    class="hover:underline hover:text-blue-700 text-blue-600 cursor-pointer"
                                    @click="() => startEditMode(comment)"
                                    >Modifier</span
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mt-2 mb-2" v-else>
            <p
                class="text-sm leading-5 text-gray-700 text-left"
                v-if="candidate"
            >
                Aucun commentaire n'a encore été ajouté sur le candidat
                <span class="font-semibold">{{ candidate.full_name }}</span
                >.
            </p>
        </div>
        <div class="text-left">
            <label
                v-if="!editMode.on"
                for="comment"
                class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >Ajouter un nouveau commentaire
            </label>
            <label
                v-else
                for="comment"
                class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                >Modifier le commentaire (<span
                    class="hover:underline hover:text-blue-700 text-blue-600 cursor-pointer"
                    @click="closeEditMode"
                    >annuler</span
                >)
            </label>
            <textarea
                id="comment"
                ref="commentTextarea"
                v-model="body.comment"
                class="form-input resize-y h-32"
            ></textarea>
        </div>
        <div class="text-right">
            <div class="mt-3 mb-3 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >
                        {{ submitText }}
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        v-if="showCloseButton"
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        candidacy: {
            required: true,
            default: null,
        },
        showCloseButton: {
            default: false,
        },
    },
    data() {
        return {
            editMode: {
                comment_id: null,
                on: false,
            },
            body: {
                comment: "",
            },
            isDisabled: false,
        };
    },
    computed: {
        ...mapState("auth", ["user"]),
        submitText() {
            return this.isEditing ? "Modifier" : "Ajouter";
        },
        candidate(){
            return this.candidacy.candidate;
        },
        isEditing() {
            return this.editMode.on && this.editMode.comment_id != null;
        },
        hasComments() {
            return this.candidacy && this.candidacy.user_comments.length;
        },
        isValid() {
            if(this.isDisabled)
                return false;
            return this.body.comment.length > 0;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
    },
    methods: {
        submit() {
            if (this.editMode.on) {
                this.updateCandidateComment();
            } else {
                this.createComment();
            }
        },
        createComment() {
            if (this.isValid) {
                this.isDisabled = true;
                let command = {
                    name : "createComment",
                    params : {
                        entity : "candidacy",
                        entity_id : this.candidacy.id,
                        additionnal_fields : this.body
                    }
                };

                this.$store.dispatch("app/executeCommand", command).then(() => {
                    this.$emit("addComment");
                    this.close();
                }).finally(() => {
                    this.isDisabled = false;
                });
            }
        },
        updateCandidateComment() {
            if (this.isValid) {
                this.isDisabled = true;
                let command = {
                    name : "updateComment",
                    params : {
                        entity : "candidacy",
                        entity_id : this.candidacy.id,
                        additionnal_fields : {
                            comment_id: this.editMode.comment_id,
                            comment: this.body.comment,
                        }
                    }
                };

                this.$store.dispatch("app/executeCommand", command).then(() => {
                    this.$emit("addComment");
                    this.close();
                }).finally(() => {
                    this.isDisabled = false;
                });
            }
        },
        startEditMode(comment) {
            this.editMode.on = true;
            this.editMode.comment_id = comment.id;
            this.body.comment = comment.comment;
        },
        closeEditMode() {
            this.editMode.on = false;
            this.editMode.comment = null;
            this.body.comment = "";
        },
        resetValues() {
            Object.assign(this.$data, this.$options.data());
        },
        close() {
            this.resetValues();
            this.$emit("close");
        },
    },
};
</script>

<style>
</style>