import axios from "axios";

export default {
    addPlanningTask(data) {
        return axios.post("/planning", data);
    },
    update(planning_id,data) {
        return axios.put(`/planning/${planning_id}`, data);
    },
    delete(planning_id) {
        return axios.delete(`/planning/${planning_id}`);
    },
};