<template>
    <default-modal
        v-if="open"
        @close="close"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900"
        >
            <div v-if="edit">
                <span v-if="isBroker">Modifier le candidat </span>
                <span v-else>Candidat </span>{{ candidacy.candidate.full_name }}
            </div>
            <span v-else>Ajouter un candidat</span>

        </h3>

        <div slot="body" ref="body" id="unzoom">
            <div
                class="mt-2 bg-red-200 text-red-600 border-l-4 border-red-600 pl-2"
                v-if="create"
            >
                Attention ! L'e-mail d'inscription ne sera pas envoyé au candidat. Il recevra ses identifiants de connexion lors de l'envoi de la fiche de réservation.
            </div>
            <div class="flex justify-between grid grid-cols-2 lg:grid-cols-9 gap-2">
                <div class="mt-2 text-left">
                    <label
                        for="ecm-vip"
                        class="text-sm font-medium leading-5 text-gray-700 mb-2"
                        >VIP</label
                    >
                    <input
                        :disabled="disableFields"
                        type="checkbox"
                        id="ecm-vip"
                        :checked="candidacy.vip"
                        v-model="candidacy.vip"
                        class="ml-4"
                    />
                </div>
                <div class="mt-2 text-left">
                    <div id="ecm-gender-1">
                        <label for="toggle" class="text-xs text-gray-700"
                            >Homme
                        </label>
                        <div
                            class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                        >
                            <input
                                :disabled="disableFields"
                                type="checkbox"
                                name="toggle"
                                id="toggle"
                                v-model="genderBool"
                                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"
                            />
                            <label
                                for="toggle"
                                class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                            ></label>
                        </div>
                        <label for="toggle" class="text-xs text-gray-700"
                            >Femme</label
                        >
                    </div>                    
                </div>                
            </div>
            <!-- -->
            <div class="mt-3 text-left grid grid-cols-3 gap-3">
                <div class="">
                    <label
                        for="ecm-first-name-1"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Prénom *</label
                    >
                    <input
                        :disabled="disableFields"
                        type="text"
                        id="ecm-first-name-1"
                        v-model="candidacy.candidate.first_name"
                        class="form-input w-full"
                    />
                </div>
                <div class="">
                    <label
                        for="ecm-last-name-1"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Nom *</label
                    >
                    <input
                        :disabled="disableFields"
                        type="text"
                        id="ecm-last-name-1"
                        v-model="candidacy.candidate.last_name"
                        class="form-input w-full"
                    />
                </div>
                <div class="">
                    <label
                        for="ecm-email-1"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >E-mail *</label
                    >
                    <input
                        :disabled="disableFields"
                        type="text"
                        id="ecm-email-1"
                        v-model="candidacy.candidate.email"
                        class="form-input w-full"
                    />
                </div>
            </div>
            <div class="mt-3 text-left grid grid-cols-3 gap-3">
                <div>
                    <label
                        for="ecm-tel-1"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Tel</label
                    >
                    <input
                        :disabled="disableFields"
                        type="text"
                        id="ecm-tel-1"
                        v-model="candidacy.candidate.phone"
                        class="form-input w-full"
                    />
                </div>
                <div>
                    <label
                        for="ecm-phone-private-1"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Tel privé</label
                    >
                    <input
                        :disabled="disableFields"
                        type="text"
                        id="ecm-phone-private-1"
                        v-model="candidacy.candidate.phone_private"
                        class="form-input w-full"
                    />
                </div>
                <div>
                    <label
                        for="ecm-phone-pro-1"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Tel pro</label
                    >
                    <input
                        :disabled="disableFields"
                        type="text"
                        id="ecm-phone-pro-1"
                        v-model="candidacy.candidate.phone_pro"
                        class="form-input w-full"
                    />
                </div>
            </div>
            
            <div
                class="mt-3 text-left grid grid-cols-3 gap-3"
            >
                <div>
                    <label
                        for="ecm-status-2"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Statut *</label
                    >
                    <badge-select
                        :disabled="disableFields"
                        :collection="candidateStatuses"
                        defaultText="Sélectionner"
                        :value="candidacy.status"
                        valueProp="value"
                        @change="handleStatusChange"
                    ></badge-select>
                </div>
                <div>
                    <label
                        for="ecm-priority"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Priorité *</label
                    >
                    <badge-select
                        :disabled="disableFields"
                        :collection="candidatePriorities"
                        badgeTextProp="label"
                        textProp="full_label"
                        defaultText="Sélectionner"
                        :value="candidacy.priority"
                        valueProp="value"
                        @change="handlePriorityChange"
                    ></badge-select>
                </div>
                <div>
                    <label
                        for="ecm-broker"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Courtier assigné</label
                    >
                    <picture-select
                        :disabled="disableFields"
                        ref="pictureSelect"
                        imgProp="profile_picture"
                        defaultText="Tous les courtiers"
                        :collection="currentPromotion.brokers"
                        :value="candidacy.broker_id"
                        textProp="full_name"
                        valueProp="id"
                        @change="handleBrokerChange"
                    ></picture-select>
                </div>
            </div>
            <div class="mt-4 text-left grid grid-cols-4 gap-4">
                <div>
                    <label
                        for="ecm-household-size"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Taille foyer</label
                    >
                    <input
                        :disabled="disableFields"
                        type="number"
                        id="ecm-household-size"
                        v-model="candidate.household_size"
                        class="form-input w-full"
                    />
                </div>
                <div>
                    <label
                        for="ecm-household-income"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Revenus foyer</label
                    >
                    <input
                        :disabled="disableFields"
                        type="number"
                        id="ecm-household-income"
                        v-model="candidate.household_income"
                        class="form-input w-full"
                    />
                </div>
                <div>
                    <label
                        for="ecm-own-funds"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Fonds propres</label
                    >
                    <input
                        :disabled="disableFields"
                        type="number"
                        id="ecm-own-funds"
                        v-model="candidate.own_funds"
                        class="form-input w-full"
                    />
                </div>
                <div>
                    <label
                        for="ecm-budget"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Budget</label
                    >
                    <input
                        :disabled="disableFields"
                        type="number"
                        id="ecm-budget"
                        v-model="candidate.budget"
                        class="form-input w-full"
                    />
                </div>
            </div>
            <div class="mt-4 text-left gap-4">
                <div>
                    <label
                        for="ecm-budget"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >
                        <span v-if="!create">
                            Commentaire laissé par le candidat sur le mini-site:
                        </span>
                        <span v-else>Commentaire :</span>
                    </label>
                    <textarea
                        type="number"
                        id="ecm-budget"
                        v-model="candidacy.comment"
                        class="form-input w-full h-40 text-gray-700"
                        :readonly="!create"
                        v-if="candidacy.comment || create"
                    ></textarea>
                    <div
                        v-if="!candidacy.comment && edit"
                        class="text-gray-400"
                    >
                        Le candidat n'a laissé aucun commentaire lors de son
                        inscription
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="mt-5 text-right">
            <div 
                class="mt-5 grid grid-cols-1 gap-2"
                :class="{
                    'md:grid-cols-3' : edit,
                    'md:grid-cols-2' : !edit,
                }"
            >
                <button
                    @click="close"
                    type="button"
                    class="inline-flex justify-center md:w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                    Fermer
                </button>
                <button
                    v-if="isBroker && edit && !disableFields"
                    @click="openDeleteModal"
                    :visible="!disableFields"
                    type="button"
                    class="flex justify-center items-center md:w-full rounded-md border border-gray-300 px-8 py-2 bg-red-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-600 transition duration-150 sm:text-sm sm:leading-5"
                >
                    <svg class="w-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                    Supprimer
                </button>
                <button
                    v-if="isBroker && !disableFields"
                    @click="submit"
                    type="button"
                    :disabled="!isValid"
                    class="justify-center md:w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    :class="submitButtonClass"
                >
                    <span v-if="create">Créer candidat(s)</span>
                    <span v-else>Mettre à jour</span>
                </button>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import BadgeSelect from "@/components/Forms/BadgeSelect";
import PictureSelect from "@/components/Forms/PictureSelect";
import Auth from "@/mixins/Auth";

export default {
    mixins: [Auth],
    components: { DefaultModal, BadgeSelect, PictureSelect },
    data() {
        return this.initialState();
    },
    computed: {
        ...mapState("candidacy", ["currentActionTarget", "currentActionType"]),
        ...mapGetters("auth", ["currentPromotion"]),
        ...mapState("auth", ["user"]),
        create(){
            return this.currentActionTarget == null && this.currentActionType === "create";
        },
        edit(){
            return this.currentActionTarget != null && this.currentActionType === "edit";
        },
        candidate(){
            return this.candidacy.candidate;
        },
        open(){
            return this.create || this.edit;
        },
        disableFields() {
            if(this.isBroker){
                if ((this.user.agency.id == this.candidacy.agency_id) || this.create) {
                    return false;
                }
            }
            return true;
        },
        candidateStatuses() {
            return this.$store.getters["candidate/statuses"];
        },
        candidatePriorities() {
            return this.$store.getters["candidate/priorities"];
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
        isValid() {
            if(this.isDisabled)
                return false;
            const candidacyFields = ["status", "priority"];
            for (let field of candidacyFields) {
                if ([null, ""].includes(this.candidacy[field])) 
                    return false;
            }
            const candidateFields = ["first_name", "last_name", "email"];
            for (let field of candidateFields) {
                if(!this.candidacy.candidate[field])
                    return false;
                if ([null, ""].includes(this.candidacy.candidate[field].replaceAll(" ",""))) 
                    return false;
            }
            return true;
        },
    },
    methods: {
        initialState(){
            return {
                candidacy : {
                    candidate: {
                        first_name: "",
                        last_name: "",
                        full_name: null,
                        gender: "M",
                        household_size: null,
                        household_income: null,
                        onw_funds: null,
                        email: "",
                        phone: "",
                        phone_pro: "",
                        phone_private: "",
                        broker_id: null,
                        budget: null,
                        parent_candidate_id: null,
                    },
                    priority: null,
                    status: null,
                    vip: false,
                    couple: false,
                    broker_id : null
                },
                genderBool: false,
                isDisabled: false,
            };
        },
        close() {
            this.reset();
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TARGET", null);
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TYPE", null);
        },
        submit() {
            if (this.isValid) {
                this.isDisabled = true;
                this.candidacy.candidate.gender = this.genderBool ? "F" : "M";

                if(this.create)
                    this.createCandidacy();
                else
                    this.updateCandidacy();
            }
        },

        init() {
            if (this.edit) {

                this.candidacy = JSON.parse(JSON.stringify(this.currentActionTarget));
                const statusIndex = this.candidateStatuses.findIndex((item) => {
                    return item.label == this.currentActionTarget.status;
                });
                const priority = this.candidatePriorities.find(
                    (item) => {
                        return item.value == this.currentActionTarget.priority;
                    }
                );
                
                this.candidacy.candidate.status = statusIndex;
                this.candidacy.candidate.priority = priority.value;                

                this.candidacy.broker_id = this.currentActionTarget.broker_id;

                if (this.candidacy.candidate.gender === "M") {
                    this.genderBool = false;
                } else {
                    this.genderBool = true;
                }
            }
        },
        createCandidacy(){
            this.$store.dispatch("app/executeCommand",  {
                name : "createCandidacy", 
                params : {
                    entity : "promotion",
                    entity_id : this.currentPromotion.id,
                    additionnal_fields : this.candidacy
                }
            })
                .then((data) => {
                    if(data.data == "already_exists"){
                        this.$toast.open({
                            type: "error",
                            message: "Email déjà existant sur un autre compte"
                        });
                    } else {
                        this.$emit("update");
                        this.close();
                    }
                }).finally(() => {
                    this.isDisabled = false;
                });
        },
        updateCandidacy(){
            const command = {
                name : "updateCandidacy",
                params : {
                    entity : "candidacy",
                    entity_id : this.candidacy.id,
                    additionnal_fields : {
                        ...this.candidacy,
                        promotion_id : this.currentPromotion.id
                    }
                }
            };
            this.$store.dispatch("app/executeCommand", command)
                .then(() => {
                    this.$emit("update");
                    this.close();
                });
        },
        openDeleteModal(){
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TYPE", "delete");
        },
        reset() {
            Object.assign(this.$data, this.initialState());
        },
        handleStatusChange(val) {
            this.candidacy.status = val;
        },
        handlePriorityChange(val) {
            this.candidacy.priority = val;
        },
        handleBrokerChange(val) {
            this.candidacy.broker_id = val;
        },
    },
    watch: {
        open(newVal){
            if(newVal){
                this.reset();
                this.init();
            }
        },
    },
};
</script>

<style scoped lang="scss">
#unzoom {
    zoom: 0.9
};
</style>
