<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Modifier les statuts
        </h3>
        <div slot="body" class="text-left grid grid-cols-2 gap-3">
            <div>
                <label
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2 text-left"
                >
                    FR :
                    <input
                        type="text"
                        class="form-input"
                        :class="
                            statusComputedGreenBorderClass(body.status_FR_checked)
                        "
                        v-model="body.status_FR"
                        disabled
                    />
                    Valider :
                    <input
                        type="checkbox"
                        class="inline-block"
                        v-model="body.status_FR_checked"
                        disabled
                    />
                </label>
            </div>
            <div>
                <label
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2 text-left"
                >
                    AC :
                    <input
                        type="text"
                        class="form-input"
                        :class="
                            statusComputedGreenBorderClass(body.status_AC_checked)
                        "
                        v-model="body.status_AC"
                        disabled
                    />
                    Valider :
                    <input
                        type="checkbox"
                        class="inline-block"
                        v-model="body.status_AC_checked"
                        disabled
                    />
                </label>
            </div>
            <div>
                <label
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2 text-left"
                >
                    FIN :
                    <input
                        type="text"
                        class="form-input"
                        :class="
                            statusComputedGreenBorderClass(body.status_FIN_checked)
                        "
                        v-model="body.status_FIN"
                        disabled
                    />
                    Valider :
                    <input
                        type="checkbox"
                        class="inline-block"
                        v-model="body.status_FIN_checked"
                        disabled
                    />
                </label>
            </div>
            <div>
                <label
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2 text-left"
                >
                    SIGNPDV :
                    <input
                        type="text"
                        class="form-input"
                        :class="
                            statusComputedGreenBorderClass(
                                body.status_SIGNPDV_checked
                            )
                        "
                        v-model="body.status_SIGNPDV"
                        :disabled="statusToBool(body.status_SIGNPDV_checked)"
                    />
                    Valider :
                    <input
                        type="checkbox"
                        class="inline-block"
                        v-model="body.status_SIGNPDV_checked"
                        disabled
                    />
                </label>
            </div>
            <div>
                <label
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2 text-left"
                >
                    SIGN :
                    <input
                        type="text"
                        class="form-input"
                        :class="
                            statusComputedGreenBorderClass(body.status_SIGN_checked)
                        "
                        v-model="body.status_SIGN"
                        :disabled="statusToBool(body.status_SIGN_checked)"
                    />
                    Valider :
                    <input
                        type="checkbox"
                        class="inline-block"
                        v-model="body.status_SIGN_checked"
                        disabled
                    />
                </label>
            </div>
        </div>
        <div slot="footer" class="mt-12 text-right">
            <button
                @click="close"
                type="button"
                class="inline-flex justify-center mr-4 w-48 rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
                Fermer
            </button>
            <button
                @click="submit"
                type="button"
                class="inline-flex justify-center w-48 rounded-md border border-gray-300 px-4 py-2 text-base leading-6 font-medium shadow-sm focus:outline-none focus:border-blue-400 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                :class="submitButtonClass"
            >
                Modifier
            </button>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import { mapState } from "vuex";

export default {
    components: { DefaultModal },
    data() {
        return {
            body : {
                status_FR: "",
                status_AC: "",
                status_FIN: "",
                status_SIGNPDV: "",
                status_SIGN: "",
                status_AC_checked: "",
                status_FR_checked: "",
                status_FIN_checked: "",
                status_SIGNPDV_checked: "",
                status_SIGN_checked: ""
            }
        };
    },
    
    computed: {
        ...mapState("application", ["currentActionTarget", "currentActionType"]),
        submitButtonClass() {
            return "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer";
        },
        open(){
            return this.currentActionTarget && this.currentActionType === "updateStatuses";
        }
    },
    methods: {
        init() {
            if(this.currentActionTarget){
                for (var key of [
                    "status_FR",
                    "status_AC",
                    "status_SIGN",
                    "status_SIGNPDV",
                    "status_FIN",
                    "status_FR_checked",
                    "status_AC_checked",
                    "status_SIGN_checked",
                    "status_SIGNPDV_checked",
                    "status_FIN_checked"
                ]) {
                    this.body[key] = this.currentActionTarget[key];
                }
            }
        },
        resetValues() {
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
            this.price = "";
        },
        close() {
            this.resetValues();
            this.$emit("close");
        },
        submit() {
            const command = {
                name : "updateApplication",
                params : {
                    entity : "application",
                    entity_id : this.currentActionTarget.id,
                    additionnal_fields : this.body
                }
            };

            this.$store
                .dispatch("app/executeCommand", command)
                .then(() => {
                    this.$emit("update");
                    this.close();
                });
        },
        statusComputedGreenBorderClass(data) {
            if (data) {
                //return "border-green-400 border-2";
                return "text-green-400";
            }
            return "";
        },
        statusToBool(data){
            if(data)
                return true;
            return false;
        }
    },
    watch: {
        open(newVal) {
            if(newVal)
                this.init();
        }
    }
};
</script>
