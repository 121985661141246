<template>
    <div class="inline-block relative w-48 mr-2">
        <select
            class="block h-8 appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-1 pr-8 rounded shadow-sm leading-tight focus:outline-none focus:shadow-outline"
            @change="change($event)"
            :name="name"
        >
            <option :value="null">{{ defaultText }}</option>
            <option
                :value="item[keyAttribute]"
                v-for="item in collection"
                :key="item[keyAttribute]"
                :selected="value && item[keyAttribute] === value[keyAttribute]"
            >
                {{ item[labelAttribute] }}
            </option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
            >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {},
        keyAttribute: {
            default: "id",
        },
        labelAttribute: {
            required: true,
        },
        collection: {
            required: true
        },
        defaultText: {
            default: "",
        },
        name: {
            default: null,
        },
    },

    methods: {
        change(event) {
            // On utilise un == ici à la place de === car le event.target.value est une chaîne de caractères.
            this.$emit("input", this.collection.find((item) => item[this.keyAttribute] == event.target.value));
        }
    }
};
</script>

<style></style>
