<template>
    <div class="min-h-screen" :class="bgColorClass">
        <the-header></the-header>
        <template v-if="ready">
            <filters
                v-show="displayFilters"
                :brokersFilter="displayBrokersFilter"
                :nb_kchf="displayNbKchf"
                :showNonRetainedCheckbox="showNonRetainedCheckbox"
                :showPriceSlider="showPriceSlider"
                :showLotFilters="showLotFilters"
                :showSearch="showSearch"
                :isMultiAgency="isMultiAgency"
            ></filters>

            <slot></slot>
            <DemoSelector v-if="demoUserType"></DemoSelector>
        </template>
    </div>
</template>

<script>
import TheHeader from "@/components/global/TheHeader";
import _DefaultLayout from "../mixins/DefaultLayout";
import Filters from "../components/Filters/Filters";
import DemoSelector from "../components/Demo/DemoSelector";
import Auth from "@/mixins/Auth";
import { mapGetters } from "vuex";

export default {
    components: { TheHeader, Filters, DemoSelector },
    mixins: [_DefaultLayout, Auth],
    computed: {
        ...mapGetters("app", ["ready"]),
        demoUserType(){
            return localStorage.getItem("demo");
        },
        displayFilters() {
            return this.$route.meta.filters != null
                ? this.$route.meta.filters
                : true;
        },
        displayBrokersFilter() {
            return this.$route.meta.brokersFilter != null
                ? this.$route.meta.brokersFilter
                : false;
        },
        displayNbKchf() {
            return this.$route.meta.nb_kchf != null
                ? this.$route.meta.nb_kchf
                : false;
        },
        showNonRetainedCheckbox() {
            return this.$route.meta.showNonRetainedCheckbox != null
                ? this.$route.meta.showNonRetainedCheckbox
                : false;
        },
        showPriceSlider() {
            return this.$route.meta.showPriceSlider != null
                ? this.$route.meta.showPriceSlider
                : true;
        },
        showLotFilters() {
            return this.$route.meta.showLotFilters != null
                ? this.$route.meta.showLotFilters
                : true;
        },
        showSearch() {
            return this.$route.meta.showSearch != null
                ? this.$route.meta.showSearch
                : true;
        },
        isMultiAgency() {
            return this.$route.meta.isMultiAgency != null
                ? this.$route.meta.isMultiAgency
                : false;
        },        
    },
};
</script>
