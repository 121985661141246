import investor from "@/api/investor";

const state = {
    investors: null
};

const getters = {
    investors: state => {
        return state.investors;
    }
};

const actions = {
    getAll({ commit, rootGetters}) {
        return investor
            .get(rootGetters["app/formattedFilters"])
            .then(response => {
                if (response.status === 200) {
                    commit("SET_INVESTORS", response.data);
                }
                return response;
            })
            .catch(error => {
                return error;
            });
    }
};

const mutations = {
    SET_INVESTORS(state, value) {
        state.investors = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
