<template>
    <div class="shadow bg-white rounded border-2 border-blue-400 pb-3 mt-2">
        <div class="text-center py-2 p-4 text-blue-400 font-medium">
            Mes documents
        </div>
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4 px-3 pt-3">
            <div v-if="user.candidacy">
                <template v-for="appli in user.candidacy.applications">
                <router-link
                    :key="appli.id"
                    :to="{
                        name: 'candidate-form',
                        params: { id: appli.public_id },
                    }"
                >
                    <div
                        class="flex justify-between w-full bg-white p-4 shadow-sm rounded  hover:border-blue-200 border cursor-pointer text-gray-700"
                    >
                        <div>
                            <div>Fiche de réservation</div>
                            <div class="text-xs text-blue-600">{{appli.lot.reference}} - {{appli.lot.full_title}}</div>
                            <status-badge
                                :status="appli.status"
                                :statusText="appli.status_text"
                            ></status-badge>
                        </div>
                        <div class="flex items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="float-right h-5 w-5 fill-current text-gray-700 hover:text-blue-900"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z"
                                />
                            </svg>
                        </div>
                    </div>
                </router-link>
                </template>
            </div>
            <template v-for="file in user.candidacy.files">
                <a
                    @click.prevent="download(file)"
                    :key="file.id"
                    :href="file.url" target="_blank"
                >
                    <div class="flex justify-between w-full bg-white p-4 shadow-sm rounded hover:border-blue-200 border cursor-pointer text-gray-700">
                        {{ file.name }}
                        <div class="flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="float-right h-5 w-5 fill-current text-gray-700 hover:text-blue-900"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"
                                />
                            </svg>
                        </div>
                    </div>
                </a>
            </template>
            
            <template v-for="file in otherCandidacyFiles">
                <a
                    @click.prevent="download(file)"
                    :key="file.id"
                    :href="file.url" target="_blank"
                >
                    <div class="flex justify-between w-full bg-white p-4 shadow-sm rounded hover:border-blue-200 border cursor-pointer text-gray-700">
                        {{ file.name }}
                        <div class="flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="float-right h-5 w-5 fill-current text-gray-700 hover:text-blue-900"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"
                                />
                            </svg>
                        </div>
                    </div>
                </a>
            </template>

        </div>
    </div>
</template>

<script>
import StatusBadge from "@/components/SalesTunnel/StatusBadge";
import { mapGetters, mapState } from "vuex";
import files from "@/api/files";

export default {
    components: {
        StatusBadge,
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapGetters("files", ["files"]),
        otherCandidacyFiles(){
            let files = [];
            this.user.candidacy.applications.forEach((item) => {
                if(item.candidacy1 && (item.candidacy1.id != this.user.candidacy.id)){
                    item.candidacy1.files.forEach((item) => {
                        files.push(item);
                    });
                }
                if(item.candidacy2 && (item.candidacy2.id != this.user.candidacy.id)){
                    item.candidacy2.files.forEach((item) => {
                        files.push(item);
                    });
                }
            });
            return files;
        }
    },
    methods:{
        download(file){
            return files.download(file);
        }
    }
};
</script>