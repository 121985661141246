<template>
  <div class="bg-white rounded shadow-lg">
    <div class="text-center p-6 border-b">
      <img
        class="h-24 w-auto rounded-full mx-auto"
        :src="promotion.logo_full_path"
        :alt="promotion.title"
      />
      <p class="pt-2 text-lg font-semibold">
        <!-- <router-link
          :to="{
            name: 'candidats',
            query: { candidat: candidate.id, promotion: candidate.promotion_id }
          }"
          >{{ candidate.full_name }}</router-link> -->
      </p>
      <p class="text-sm text-gray-600">
        {{ promotion.title }}
      </p>
    </div>
    <div class="mb-4">
      <a
        href="#"
        class="px-6 py-4 hover:bg-gray-200 flex"
        :class="{ 'bg-gray-200': currentMenu === 'informations' }"
        @click="emitChangeMenu('informations')"
      >
        <div class="text-blue-400">
          <svg strokeWidth="1" viewBox="0 0 22 22" class="w-5 h-5 fill-current">
            <path
              d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 11v4h2V9H9v2zm0-6v2h2V5H9z"
            />
          </svg>
        </div>
        <div class="pl-3">
          <p class="text-sm font-medium text-gray-800 leading-none">
            Informations / Paramètres
          </p>
          <p class="text-xs text-gray-500 mt-1 leading-tight">
            Voir / modifier les paramètres et infos de la promotion
          </p>
        </div>
      </a>
      <a
        href="#"
        class="px-6 py-4 hover:bg-gray-200 flex"
        :class="{ 'bg-gray-200': currentMenu === 'lots' }"
        @click="emitChangeMenu('lots')"
      >
        <div class="text-blue-400">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
            ></path>
          </svg>
        </div>
        <div class="pl-3">
          <p class="text-sm font-medium text-gray-800 leading-none">
            Lots
          </p>
          <p class="text-xs text-gray-500 mt-1 leading-tight">
            Gestion des immeubles et lots de la promotion
          </p>
        </div>
      </a>
      <a
        href="#"
        class="px-6 py-4 hover:bg-gray-200 flex"
        :class="{ 'bg-gray-200': currentMenu === 'notaries' }"
        @click="emitChangeMenu('notaries')"
      >
        <div class="text-blue-400">
          <svg
            fill="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            viewBox="0 0 24 24"
            class="w-5 h-5 inline-block"
          >
            <path
              d="M0 3h20v2H0V3zm0 4h20v2H0V7zm0 4h20v2H0v-2zm0 4h20v2H0v-2z"
            />
          </svg>
        </div>
        <div class="pl-3">
          <p class="text-sm font-medium text-gray-800 leading-none">
            Notaires
          </p>
          <p class="text-xs text-gray-500 mt-1 leading-tight">
            Gérer les notaires de la promotion, voir le notaire principal
          </p>
        </div>
      </a>
      <a
        href="#"
        class="px-6 py-4 hover:bg-gray-200 flex"
        :class="{ 'bg-gray-200': currentMenu === 'brokers' }"
        @click="emitChangeMenu('brokers')"
      >
        <div class="text-blue-400">
          <svg strokeWidth="1" viewBox="0 0 22 22" class="w-5 h-5 fill-current">
            <path
              d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
            ></path>
          </svg>
        </div>
        <div class="pl-3">
          <p class="text-sm font-medium text-gray-800 leading-none">
            Courtiers
          </p>
          <p class="text-xs text-gray-500 mt-1 leading-tight">
            Gérer les accès à la promotion
          </p>
        </div>
      </a>
      <a
        href="#"
        class="px-6 py-4 hover:bg-gray-200 flex"
        :class="{ 'bg-gray-200': currentMenu === 'promoters' }"
        @click="emitChangeMenu('promoters')"
      >
        <div class="text-blue-400">
          <svg strokeWidth="1" viewBox="0 0 22 22" class="w-5 h-5 fill-current">
            <path
              d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
            ></path>
          </svg>
        </div>
        <div class="pl-3">
          <p class="text-sm font-medium text-gray-800 leading-none">
            Promoteurs
          </p>
          <p class="text-xs text-gray-500 mt-1 leading-tight">
            Gérer les accès promoteurs
          </p>
        </div>
      </a>
      <a
        href="#"
        class="px-6 py-4 hover:bg-gray-200 flex"
        :class="{ 'bg-gray-200': currentMenu === 'bankers' }"
        @click="emitChangeMenu('bankers')"
      >
        <div class="text-blue-400">
          <svg strokeWidth="1" viewBox="0 0 22 22" class="w-5 h-5 fill-current">
            <path
              d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
            ></path>
          </svg>
        </div>
        <div class="pl-3">
          <p class="text-sm font-medium text-gray-800 leading-none">
            Banquiers
          </p>
          <p class="text-xs text-gray-500 mt-1 leading-tight">
            Gérer les accès banquiers
          </p>
        </div>
      </a>
      <a
        href="#"
        class="px-6 py-4 hover:bg-gray-200 flex"
        :class="{ 'bg-gray-200': currentMenu === 'architects' }"
        @click="emitChangeMenu('architects')"
      >
        <div class="text-blue-400">
          <svg strokeWidth="1" viewBox="0 0 22 22" class="w-5 h-5 fill-current">
            <path
              d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
            ></path>
          </svg>
        </div>
        <div class="pl-3">
          <p class="text-sm font-medium text-gray-800 leading-none">
            Architectes
          </p>
          <p class="text-xs text-gray-500 mt-1 leading-tight">
            Gérer les accès architectes
          </p>
        </div>
      </a>
    </div>
    <div class="w-full flex px-4 bg-blue-400">
      <div class="w-1/2 text-center text-white text-lg py-4 border-r">
        <span>{{ promotion.buildings.length }}</span>
        {{ promotion.buildings.length > 1 ? "immeubles" : "immeuble" }}
      </div>
      <div class="w-1/2 text-center text-white text-lg py-4">
        <span>{{ nbLots }}</span> lots
      </div>
    </div>
  </div>
</template>

<script>
const INFORMATIONS = "informations";

export default {
    components: {},

    props: {
        promotion: {
            required: true,
            type: Object
        },
        initialMenu: {
            type: String
        }
    },

    data() {
        return {
            currentMenu: INFORMATIONS
        };
    },

    mounted() {
        if (this.initialMenu) {
            this.currentMenu = this.initialMenu;
        }
    },

    computed: {
        nbLots(){
            let nb = 0;
            for(const build of this.promotion.buildings){
                nb += build.lots.length;
            }
            return nb;
        }
    },

    methods: {
        emitChangeMenu(menu) {
            if (menu !== this.currentMenu) {
                this.currentMenu = menu;
                this.$emit("selectMenu", menu);
            }
        }
    }
};
</script>

<style>
</style>