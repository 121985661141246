
export default {
    props: {
        collection: {
            required: true,
            default : null
        },
        defaultText: {
            type: String,
        },
        textProp: {
            type: String,
        },
        valueProp: {
            type: String
        },
        value: {
            type: [String, Number]
        },
        disabled: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            open: false,
            selected: null
        };
    },
    computed: {
        selectedText(){
            let textProps = this.textProp.split(".");
            let selected = this.selected;
            for(var tp of textProps){
                selected = selected[tp];
            }
            return selected;
        },
        filteredCollection() {
            if(this.collection){
                if(this.search && this.searchProps){
                    return this.findItemsByText(this.search);
                }
            }
            return this.collection;
        },
        isOpen() {
            return this.open && !this.disabled;
        },
        behaviourClass() {
            return {
                "bg-gray-100": this.disabled,
                "cursor-not-allowed": this.disabled
            };
        },
    },
    methods: {
        getItemValue(item){
            let val = item;
            if(item){
                for(var prop of this.valueProp.split(".")){
                    val = val[prop];
                }
            }
            return val;     
        },
        init(){
            if (this.value != null && this.collection.length) {
                this.select(this.findItemByValue(this.value));
            }
            else {
                this.selected = null;
            }
        },
        close() {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        },
        select(item) {
            this.selected = item;
            this.$emit("change", this.getItemValue(this.selected));
            this.close();
        },
        getItemText(item){
            let textProps = this.textProp.split(".");
            let selected = item;
            for(var tp of textProps){
                selected = selected[tp];
            }
            return selected;
        },
        getItemSearchString(item){
            let itemPropString = "";
            for(var sp of this.searchProps){
                let props = sp.split(".");
                let value = item;
                for(let p of props){
                    value = value[p]; 
                }
                itemPropString += value;
            }
            return itemPropString;
        },
        findItemByValue(value){
            return this.collection.find(item => {
                return this.getItemValue(item) == value;
            });
        },
        findItemsByText(value){
            return this.collection.filter(item => {
                return this.getItemSearchString(item).toLowerCase().includes(value.toLowerCase());
            });
        }
    },

    watch: {
        value() {
            this.init();
        },
        collection(newVal, oldVal){
            if(newVal !== oldVal)
                this.init();
        }
    },
};