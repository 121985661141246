<template>
<div>
	<div class="h-screen w-screen sm:block lg:flex items-center justify-center relative" id="main">
		<h2 class="hidden lg:inline-block absolute text-blue-900 font-medium text-2xl" :style="{top : '30px', left : '30px'}">Bienvenue sur le mode DEMO e-promotion</h2>
		<h3 class="hidden lg:inline-block absolute text-blue-900 font-medium text-lg" :style="{top : '70px', left : '40px'}">Choisissez le type de compte à essayer</h3>

		<div class="grid grid-cols-2 justify-items-center lg:flex lg:relative">
			<div id="white-bubble" class="hidden lg:flex items-center justify-center">
				<img src="/demo/logo.png" id="demo-logo">
			</div>	
			<div :class="buttonClass" :style="buttons[0]" @click="demoLogin('broker')">
				<div>
					<div class="flex justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
						</svg>
					</div>
					Courtier
				</div>
			</div>
			<div :class="buttonClass" :style="buttons[1]" @click="demoLogin('candidate')">
				<div>
					<div class="flex justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
						</svg>
					</div>
					Candidat
				</div>
			</div>
			<div :class="buttonClass" :style="buttons[2]" @click="demoLogin('promoter')">
				<div>
					<div class="flex justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
						</svg>
					</div>
					Promoteur
				</div>
			</div>
			<div :class="buttonClass" :style="buttons[3]" @click="demoLogin('architect')">
				<div>
					<div class="flex justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
						</svg>
					</div>
					Architecte
				</div>
			</div>
			<div :class="buttonClass" :style="buttons[4]" @click="demoLogin('banker')">
				<div>
					<div class="flex justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
						</svg>
					</div>
					Banquier
				</div>
			</div>
			<div :class="buttonClass" :style="buttons[5]" @click="demoLogin('pilot')">
				<div>
					<div class="flex justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
						</svg>
					</div>
					Pilote
				</div>
			</div>
			<div :class="buttonClass" :style="buttons[6]" @click="demoLogin('notary')">
				<div>
					<div class="flex justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path d="M12 14l9-5-9-5-9 5 9 5z" />
							<path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
						</svg>
					</div>
					Notaire
				</div>
			</div>
			<div :class="buttonClass" :style="buttons[7]" @click="demoLogin('investor')">
				<div>
					<div class="flex justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
						</svg>
					</div>
					Investisseur
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import Demo from "@/mixins/Demo";
export default {
    mixins : [Demo],
    computed : {
        buttonClass(){
            return "rounded-full h-32 w-32 shadow bg-blue-900 text-center text-white font-medium py-2 cursor-pointer flex items-center justify-center hover:shadow-lg lg:absolute transition-all duration-300 justify-self-center";
        },
        buttons(){
            return [	
                {top : "-220px", left : "-420px"},
                {top : "-390px", left : "110px"},
                {top : "-220px", left : "370px"},
                {top : "30px", left : "380px"},
                {top : "260px", left : 0},
                {top : "170px", left : "-270px"},
                {top : "30px", left : "-500px"},
                {top : "-370px", left : "-180px"},
            ];
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Demo";
            }
        },
    },   	
};
</script>

<style>

#main{
	background-color: #f4f4f4;
	background-image: url('/img/naef-bg-rounded.jpg');
	background-repeat: no-repeat;
	background-position: center center;
}

#white-bubble{
	height : 200px;
	width : 200px;
	left : 240px;
	top : 200px;
	position: absolute;
	background-image: url('/img/white-bubble.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}
#demo-logo{
	height : 75px;
	width : 160px;
}


</style>