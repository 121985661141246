<template>
  <div class="px-10 py-6">
    <div
      class="flex items-center justify-between"
      v-if="!promotion.main_notary"
    >
      <p class="font-semibold text-gray-800">Pas de notaire attitré</p>
    </div>
    <div v-if="promotion.main_notary">
      <p class="text-gray-800 font-semibold">Informations</p>
      <div class="text-sm grid grid-cols-2 grid-col gap-4 mt-4">
        <div>
          <div class="font-semibold text-gray-700">Nom</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.full_name }}
          </div>
        </div>
        <div>
          <div class="font-semibold text-gray-700">Adresse</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.full_address }}
          </div>
        </div>
        <div>
          <div class="font-semibold text-gray-700">Email</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.email }}
          </div>
        </div>
        <div>
          <div class="font-semibold text-gray-700">Téléphone</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.phone }}
          </div>
        </div>
      </div>
      <p class="text-gray-800 font-semibold mt-8">Secrétaire</p>
      <div class="text-sm grid grid-cols-2 grid-col gap-4 mt-4">
        <div>
          <div class="font-semibold text-gray-700">Genre</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.secretary_gender }}
          </div>
        </div>
        <div>
          <div class="font-semibold text-gray-700">Nom</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.secretary_name }}
          </div>
        </div>
        <div>
          <div class="font-semibold text-gray-700">Email</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.secretary_email }}
          </div>
        </div>
        <div>
          <div class="font-semibold text-gray-700">Téléphone</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.secretary_phone }}
          </div>
        </div>
      </div>
      <p class="text-gray-800 font-semibold mt-8">Compte bancaire</p>
      <div class="text-sm grid grid-cols-2 grid-col gap-4 mt-4">
        <div>
          <div class="font-semibold text-gray-700">Titulaire du compte</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.bank_holder }}
          </div>
        </div>
        <div>
          <div class="font-semibold text-gray-700">Nom de la banque</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.bank_name }}
          </div>
        </div>
        <div>
          <div class="font-semibold text-gray-700">IBAN</div>
          <div class="text-gray-500">
            {{ promotion.main_notary.iban }}
          </div>
        </div>
      </div>
    </div>

    <hr class="my-10" />

    <div class="grid grid-cols-2">
      <div class="space-y-4" v-if="user.admin">
        <div class="font-semibold text-sm text-gray-700">
          Ajouter un notaire à la promotion
        </div>
        <div>
          <picture-select
            :searchProps="['full_name']"
            :collection="missingNotaries"
            :value="new_notary_id"
            textProp="full_name"
            imgProp="profile_picture"
            defaultText="Sélectionner un notaire"
            valueProp="id"
            @change="(notary_id) => (new_notary_id = notary_id)"
          />
        </div>
        <button
          class="
            bg-blue-400
            hover:bg-blue-600
            text-white
            px-2
            py-1
            font-medium font-semibold
            transition-all
            duration-300
            hover:shadow
            focus:outline-none
          "
          :disabled="!this.new_notary_id"
          @click="addNotary"
        >
          Ajouter
        </button>
        <br>
        <button
        @click="openCreateNotary = true"
        class="
          pb-1
          py-1
          px-2
          border border-transparent
          text-sm
          font-medium
          rounded-sm
          text-white
          bg-blue-400
          hover:bg-blue-600
          focus:outline-none focus:border-green-700
          transition
          duration-150
          ease-in-out
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          class="h-4 w-4 inline-block fill-current align-middle mr-2"
        >
          <path
            d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
          ></path></svg
        ><span class="align-middle">Créer un notaire</span>
      </button>
      </div>
      <div class="rounded-md h-full w-full overflow-y-auto border divide-y">
        <div
          class="
            bg-white
            px-2
            py-2
            shadow-sm
            flex
            items-center
            space-x-3
            hover:bg-gray-100
          "
          v-for="notary in promotion.notaries"
          :key="notary.id"
        >
          <div class="flex flex-grow items-center">
            <img class="h-10 w-10 rounded-full" :src="notary.profile_picture" />
            <div class="flex-1 min-w-0 p-2">
              <p class="text-sm font-medium text-gray-900">
                {{ notary.full_name }}
              </p>
              <p class="text-xs font-semibold text-gray-600">
                {{ notary.email }}
              </p>
            </div>
            <div
              class="
                flex
                items-center
                justify-center
                h-8
                w-8
                bg-red-500
                text-white
                rounded-full
                hover:shadow-lg
                cursor-pointer
                transition-all
                duration-300
              "
              v-if="user.admin"
              @click="removeNotary(notary)"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div
              class="
                flex
                items-center
                justify-center
                h-8
                w-8
                bg-gray-500
                text-white
                rounded-full
                hover:shadow-lg
                cursor-pointer
                transition-all
                duration-300
                ml-2
              "
              v-if="user.admin"
              @click="openedEditNotary(notary)"
            >
              <svg
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                ></path>
              </svg>
            </div>
            <div
              @click="updateMainNotary(notary)"
              :class="{
                'bg-green-500': !notary.pivot.main,
                'bg-gray-400': notary.pivot.main,
              }"
              class="
                flex
                items-center
                justify-center
                text-white
                rounded-full
                hover:shadow-lg
                cursor-pointer
                transition-all
                duration-300
                px-3
                py-1
                ml-2
              "
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span v-if="!notary.pivot.main" class="text-xs"
                >Ajouter en tant que principal</span
              >
              <span v-else class="text-xs">Retirer en tant que principal</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <add-notary-modal
      :open="openCreateNotary"
      :promotion="promotion"
      @close="openCreateNotary = false"
      @submit="submitNotary"
    />

    <edit-notary-modal
      :open="openEditNotary"
      :promotion="promotion"
      :notary="editedNotary"
      @close="openEditNotary = false"
      @submit="submitEditNotary"
    />
  </div>
</template>

<script>
import notaries from "@/api/notaries";
import PictureSelect from "@/components/Forms/PictureSelect";

import AddNotaryModal from "@/components/Promotion/modals/AddNotaryModal";
import EditNotaryModal from "@/components/Promotion/modals/EditNotaryModal";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
    components: { PictureSelect, AddNotaryModal, EditNotaryModal },

    props: {
        promotion: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            notaries: [],
            new_notary_id: null,
            openCreateNotary: false,
            openEditNotary: false,
            editedNotary: null
        };
    },

    mounted() {
        notaries.get().then((response) => {
            this.notaries = response.data.data;
        });
    },

    computed: {
        ...mapGetters("auth", ["user"]),
        missingNotaries() {
            // Suppression des notaires déjà présents dans la promotion.
            return this.notaries.filter((notary_from_all) => {
                return !this.promotion.notaries
                    .map((notary) => notary.id)
                    .includes(notary_from_all.id);
            });
        },
    },

    methods: {
        async addNotary() {
            await axios.post(
                `/promotions/${this.promotion.id}/add-notary/${this.new_notary_id}`
            );
            this.new_notary_id = null;
            this.$emit("update");
        },

        async removeNotary(notary) {
            await axios.post(
                `/promotions/${this.promotion.id}/remove-notary/${notary.id}`
            );
            this.$emit("update");
        },

        async submitNotary(notary) {
            console.log(notary);
            const data = await axios.post(
                `/promotions/${this.promotion.id}/notaries`,
                notary
            );
            this.notaries.push(data.data);

            this.openCreateNotary = false;
            this.$emit("update");
        },

        async submitEditNotary(notary) {
            console.log(notary);
            const data = await axios.put(
                `/promotions/${this.promotion.id}/notaries/${notary.id}`,
                notary
            );

            let updatedNotary = data.data.data;
            let index = this.promotion.notaries.findIndex(
                (n) => n.id === updatedNotary.id
            );
            if (index > -1) {
                this.promotion.notaries.splice(index, 1, {...updatedNotary, pivot: this.promotion.notaries[index].pivot});
            }

            this.openEditNotary = false;
        },

        async updateMainNotary(notary) {
            const data = await axios.put(
                `/promotions/${this.promotion.id}/update-notary-main/${notary.id}`,
                notary
            );
            let updatedNotaries = data.data.data;
            this.promotion.notaries = updatedNotaries;
        },

        openedEditNotary(notary){
            console.log(notary);
            this.openEditNotary = true;
            this.editedNotary = notary;
        }
    },
};
</script>