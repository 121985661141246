<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Modifier la priorité de
            {{ currentActionTarget.candidacy1.candidate.full_name }} sur le lot
            {{ currentActionTarget.lot.reference }}
        </h3>
        <div slot="body">
            <div class="mt-2 text-left" v-if="! currentPromotion.multi_agency">
                <label
                    for="ecm-priority"
                    class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                    >Priorité *</label
                >
                <badge-select
                    :collection="priorities"
                    badgeTextProp="value"
                    textProp="label"
                    defaultText="Sélectionner"
                    valueProp="value"
                    :value="priorityIndex"
                    ref="prioritySelect"
                    @change="handlePriorityChange"
                ></badge-select>
            </div>
            <div class="mt-2 text-left space-y-4" v-if="currentPromotion.multi_agency">
                <div class="flex items-center space-x-2">
                    <span>Priorité actuelle :</span>
                    <application-priority-badge
                        :application="currentActionTarget"
                        :tooltip="true"
                        :click="false"
                    />
                    <div class="text-sm">
                        (dernier changement le {{ currentActionTarget.last_priority_change  | date_dmyhm }})
                    </div>
                </div>

                <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex items-center space-x-2" 
                    v-if="currentActionTarget.lot.best_available_priority && currentActionTarget.priority === 9" 
                    @click="go_to_best_available_priority">
                    <span>Changer sa priorité en</span>
                    <application-priority-badge
                        :priority="currentActionTarget.lot.best_available_priority"
                        :application="currentActionTarget"
                        :tooltip="false"
                        :click="false"
                    />
                </button>
                <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex items-center space-x-2" 
                    v-if="currentActionTarget.priority !== 9" 
                    @click="go_to_latest_priority">
                    <span>Libérer complètement sa place</span>
                    <application-priority-badge
                        :priority="9"
                        :application="currentActionTarget"
                        :tooltip="false"
                        :click="false"
                    />
                </button>
                <button 
                    type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex items-center space-x-2" 
                    v-if="currentActionTarget.priority == 1 && user.email == 'support.dev@naef.ch'" @click="update_last_priority_change">
                    <span>Remettre à zéro son compteur de priorité</span>
                    <application-priority-badge
                        :priority="1"
                        :application="currentActionTarget"
                        :tooltip="false"
                        :click="false"
                    />
                </button>
            </div>
        </div>
        <div slot="footer" class="mt-12 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span v-if="! currentPromotion.multi_agency"
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-transparent px-8 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Valider
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import BadgeSelect from "@/components/Forms/BadgeSelect";
import ApplicationPriorityBadge from "@/components/Candidates/ApplicationPriorityBadge";


export default {
    components: { DefaultModal, BadgeSelect, ApplicationPriorityBadge },
    data() {
        return {
            priorityIndex: null
        };
    },
    computed: {
        ...mapGetters("auth",["currentPromotion","user"]),
        ...mapGetters("application", ["currentActionTarget", "currentActionType", "priorities"]),
        open(){
            return this.currentActionTarget !== null && (this.currentActionType === "updatePriority");
        },
        isValid(){
            if(this.isDisabled)
                return false;
            return this.priorityIndex != null;
        },    
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },        
    },
    methods: {
        close() {
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },
        submit() {
            // Seulement possible si nous sommes en mono-agence.
            this.$store
                .dispatch("app/executeCommand", {
                    name : "updateApplication",
                    params : {
                        entity : "application",
                        entity_id : this.currentActionTarget.id,
                        additionnal_fields : {
                            priority : this.priorityIndex,
                        }
                    }
                })
                .then(() => {
                    this.$emit("update");
                    this.close();
                });
        },
        handlePriorityChange(val) {
            this.priorityIndex = val;
        },
        init() {
            this.priorityIndex = this.currentActionTarget.priority;
        },
        go_to_latest_priority() {
            // Seulement possible si nous sommes en multi-agence.
            this.$store
                .dispatch("app/executeCommand", {
                    name : "goToLatestPriority",
                    params : {
                        entity : "application",
                        entity_id : this.currentActionTarget.id,
                    }
                })
                .then(() => {
                    this.$emit("update");
                    this.close();
                });
        },
        go_to_best_available_priority() {
            // Seulement possible si nous sommes en multi-agence.
            this.$store
                .dispatch("app/executeCommand", {
                    name : "goToBestAvailablePriority",
                    params : {
                        entity : "application",
                        entity_id : this.currentActionTarget.id,
                    }
                })
                .then(() => {
                    this.$emit("update");
                    this.close();
                });
        },
        update_last_priority_change() {
            // Seulement possible si nous sommes en multi-agence.
            this.$store
                .dispatch("app/executeCommand", {
                    name : "UpdateLastPriorityChange",
                    params : {
                        entity : "application",
                        entity_id : this.currentActionTarget.id,
                        /*additionnal_fields : { //à voir pour rendre cela parametrable
                            last_priority_change: new Date()
                        }*/
                    }
                })
                .then(() => {
                    this.$emit("update");
                    this.close();
                });
        },
    },
    watch: {
        open(newVal) {
            if (newVal) {
                this.init();
            }
        }
    }
};
</script>
