<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 p-2 gap-2">
	<div class="p-2 rounded-md flex flex-col space-y-3" v-if="user.admin">
		<div class="font-semibold text-sm text-gray-700">
			Ajouter un promoteur
		</div>
		<div>
			<picture-select
                ref="promoterselect"
                :searchProps="['full_name']"
                :collection="availablepromoters"
                :value="form.promoter_id"
                textProp="full_name"
                imgProp="profile_picture"
                defaultText="Sélectionner un promoteur"
                valueProp="id"
                @change="
                  val => {
                    handlePromoterChange(val);
                  }
                "
              />
		</div>
		<div class="">
			<button class="bg-blue-400 hover:bg-blue-600 text-white px-2 py-1 font-medium font-semibold transition-all duration-300 hover:shadow focus:outline-none" @click="addPromoter">
				Ajouter
			</button>
		</div>
        <button
            @click="openCreatePromoter = true"
            class="pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                class="h-4 w-4 inline-block fill-current align-middle mr-2"
            >
                <path
                d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                ></path></svg
            ><span class="align-middle">Créer un promoteur</span>
        </button>
	</div>
	<div>
		<promoters-list :promoters="promotion.promoters" :promotion="promotion" :edit="true" @remove="emitUpdate"></promoters-list>
	</div>
    <add-promoter-modal :open="openCreatePromoter" :promotion="promotion" @close="openCreatePromoter = false" @submit="createPromoter"/>
  </div>
</template>

<script>
import axios from "axios";

import PromotersList from "@/components/Promoters/PromotersList";
import PictureSelect from "@/components/Forms/PictureSelect";
import promoters from "@/api/promoters";
import {pluck} from "underscore";
import promotion from "@/api/promotion";
import { mapGetters } from "vuex";

import AddPromoterModal from "@/components/Promotion/modals/AddPromoterModal";

export default {
    components : {
        PromotersList,
        PictureSelect,
        AddPromoterModal
    },
    props : {
        promotion : {
            type : Object,
            required : true
        }
    },
    data() {
        return this.initialState();
    },
    mounted(){
        this.init();
    },
    computed : {
        ...mapGetters("auth", ["user"]),
        availablepromoters() {
            let promotionPromoterIds = pluck(this.promotion.promoters, "id");
            return this.promoters.filter(el => {
                return promotionPromoterIds.indexOf(el.id) < 0;
            });
        }
    },
    methods : {
        initialState(){
            return {
                form : {
                    promoter_id : null,
                },
                promoters : [],
                openCreatePromoter: false
            };
        },
        init(){
            this.$store.commit("loader/SET_LOADING", true);
            promoters.get().then((res) => {
                this.promoters = res.data.data;
                this.$store.commit("loader/SET_LOADING", false);
            });
        },
        handlePromoterChange(val){
            this.promoter_id = val;
        },
        emitUpdate(){
            this.$emit("update");
        },
        addPromoter(){
            this.$store.commit("loader/SET_LOADING", true);
            promotion.addPromoter(this.promotion.id, this.promoter_id).then(() => {
                this.$store.commit("loader/SET_LOADING", false);
                this.emitUpdate();
            });
        },
        async createPromoter(Promoter) {
            const data = await axios.post(
                `/promotions/${this.promotion.id}/promoters`, Promoter
            );
            this.promoters.push(data.data);
        
            this.openCreatePromoter = false;
            this.$emit("update");
        },
    }
};
</script>

<style>

</style>