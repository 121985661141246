import axios from "axios";
import store from "@/store";
import router from "@/router";
import Cookies from "js-cookie";
import { getToken } from "@/helpers/auth";

const UNAUTHORIZED = 401;
// const NOT_FOUND = 404;

export default function setup() {
    axios.interceptors.request.use(
        config => {
            config.headers.Accept = "application/json";
            const token = getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => {
            if(response.data.message){
                store.dispatch("notification/addToast", {
                    type: "success",
                    title: "Succès",
                    content: response.data.message
                });
            }
            return response;
        },
        error => {
            const response = error.response;
            let errorMessage = "";

            switch(response.status){
                case 401:
                    errorMessage = "Merci de vous connecter";
                    break;
                case 403:
                    errorMessage = "Accès refusé";
                    break;
                case 404:
                    errorMessage = "Page introuvable";
                    break;
                default:
                    errorMessage = response.data.message;
                    break;
            }

            // La page de login a son propre toast en cas d'erreur 404.
            if (error.config.url !== "login") {
                store.dispatch("notification/addToast", {
                    type: "error",
                    title: "Erreur",
                    content: errorMessage
                });
            }

            store.commit("loader/SET_LOADING", false);
            if (response.status === UNAUTHORIZED) {
                Cookies.remove("token");
                localStorage.setItem("redirectAfterLogin", window.location.href);
                router.push({name: "login"});

                // This promise will never resolve because we redirect to the login page
                // and don't want to call the resolve (because we never got the valid response)
                // and neither throw errors on missig catches.
                return new Promise(() => {}); 
            } 

            return Promise.reject(error);
        }
    );
}
