<template>
    <main>
        <div class="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="text-right mb-2" v-if="isBroker">
                <button
                    v-if="isBroker"
                    @click="openAddQuestionModal"
                    class="pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 inline-block fill-current align-middle"
                        viewBox="0 0 20 20"
                    >
                        <path
                            d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                        />
                    </svg>
                    <span class="align-middle ml-2">Ajouter une question</span>
                </button>
            </div>
            <div>
                <div
                    class="border-l-2 border-blue-400 bg-blue-100 text-blue-600 px-4 py-3"
                >
                    Vous retrouverez ici les questions fréquentes. Cliquez sur
                    une question afin de visualiser les conseils proposés.
                </div>
                <question
                    v-for="(question, index) in questions"
                    :question="question"
                    :key="index"
                    @delete="init"
                    @click="
                        question => {
                            setQuestion(question);
                        }
                    "
                />
            </div>
        </div>
        <!-- <question-modal
            v-if="isBroker"
            :question="currentActionTarget"
        /> -->
        <add-question-modal
            @create="init"
        >
        </add-question-modal>
    </main>
</template>

<script>
import AddQuestionModal from "@/components/FAQ/modals/AddQuestionModal";
// import QuestionModal from "@/components/FAQ/modals/QuestionModal";
import Question from "@/components/FAQ/Question";
import { mapState } from "vuex";
import Auth from "@/mixins/Auth";
import question from "@/api/question";

export default {
    mixins: [Auth],
    data() {
        return {
            questions : [],
        };
    },
    components: {
        AddQuestionModal,
        Question
    },
    computed: {
        ...mapState("question", [
            "currentActionTarget",
            "currentActionType"
        ]),
        promotion(){
            return this.$store.getters["auth/currentPromotion"];
        },
        hasCurrentActionTarget() {
            return this.currentActionTarget !== null;
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            if(this.promotion){
                question.get(this.promotion.id).then(res => {
                    this.questions = res.data.data;
                });
            }
        },
        setQuestion(val) {
            this.$store.commit("question/SET_CURRENT_ACTION_TARGET", val);
            this.$store.commit("question/SET_CURRENT_ACTION_TYPE", "viewQuestion");
        },
        setDeleteQuestion(val) {
            this.$store.commit("question/SET_CURRENT_ACTION_TARGET", val);
            this.$store.commit("question/SET_CURRENT_ACTION_TYPE", "deleteQuestion");
        },
        openAddQuestionModal() {
            this.$store.commit("question/SET_CURRENT_ACTION_TYPE", "addQuestion");
        },
        closeModal() {
            this.$store.commit("question/SET_CURRENT_ACTION_TARGET", null);
            this.$store.commit("question/SET_CURRENT_ACTION_TYPE", null);
        }
    },
    watch: {
        promotion(val){
            if(val)
                this.init();
        },
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | FAQ";
            }
        },        
    }
};
</script>