<template>
  <main class="p-4">
    <div
      class="grid auto-rows-auto grid-cols-3 md:grid-cols-6 lg:grid-cols-10 gap-3"
      v-if="promotion"
    >
      <div class="col-span-3 md:col-span-3 lg:col-span-3">
        <promotion-card
          :promotion="promotion"
          :initialMenu="currentView"
          @selectMenu="handleSelectMenu"
        />
      </div>
      <promotion-container
        :promotion="promotion"
        :currentView="currentView"
        class="col-span-3 md:col-span-3 lg:col-span-7"
      />
    </div>
  </main>
</template>

<script>
import promotion from "@/api/promotion";
import { mapGetters } from "vuex";
import PromotionCard from "@/components/Promotion/Card";
import PromotionContainer from "@/components/Promotion/Container";

export default {
    components: {
        PromotionCard,
        PromotionContainer
    },
    data() {
        return {
            promotion: null,
            currentView: "informations"
        };
    },
    mounted() {
        this.init();
    },
    computed : {
        ...mapGetters("auth", ["getpromotion"]),
        selectedMenu(){
            return this.$store.getters["candidate/selectedMenu"];
        },
    },
    methods: {
        async init() {
            this.$store.commit("loader/SET_LOADING", true);
            await this.$store.dispatch("auth/getCurrentPromotion"); //pour etre sûr de charger la promotion choisie
            promotion
                .getOne(this.getpromotion)
                .then(res => {
                    this.promotion = res.data.data;
                    if (this.selectedMenu) {
                        this.selectMenu(this.selectedMenu);
                    }
                    this.$store.commit("loader/SET_LOADING", false);
                })
                .catch(err => {
                    this.$toast.open({
                        message: err,
                        type: "error"
                    });
                    this.$store.commit("loader/SET_LOADING", false);
                });
            
        },
        handleSelectMenu(menu) {
            this.$store.commit("candidate/SET_SELECTED_MENU", menu);
            this.selectMenu(menu);
        },
        selectMenu(menu) {
            this.currentView = menu;
        }
    }
};
</script>

<style>
</style>
