<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium pb-5 text-red-400"
        >Envoyer les identifiants à ce/ces candidat/s ? <br>(sera envoyé seulement si ils ne l'ont pas encore reçu)</h3>
        <div slot="body">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5 bg-blue-600 hover:bg-blue-400 text-white cursor-pointer"
                    >Envoyer</button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Annuler</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import _YesNoModal from "@/mixins/YesNoModal";
import {mapGetters} from "vuex";

export default {
    mixins: [_YesNoModal],
    computed:{
        ...mapGetters("application", ["currentActionType", "currentActionTarget"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "sendAccess";
        }
    },
    methods: {
        submit() {
            const command = {
                name : "sendNewAccess",
                params : {
                    entity : "application",
                    entity_id : this.currentActionTarget.id,
                }
            };
            this.$store.dispatch("app/executeCommand", command)
                .then(() => {
                    this.$emit("update");
                    this.close();
                });
        }
    }
};
</script>

<style>
</style>