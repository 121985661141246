import modals from "../partials/modals";
import photos from "@/api/photos";
const state = {
    ...modals.state,
    photoGroups : null,
};

const getters = {
    ...modals.getters,
    photoGroups(state){
        return state.photoGroups;
    },
    photoGroupsHome(state){
        if(state.photoGroups){
            const photoGroupsHome = state.photoGroups
                .filter(photoGroup => {
                    return photoGroup.home_page === true;
                });
            return photoGroupsHome;
        }
        return [];
    },
    photosHome(state,getters){
        let photos = [];

        for(let pg of getters.photoGroupsHome){
            photos = [...photos, ...pg.photos];
        }
        return photos;
    }
};

const actions = {
    getPhotoGroups({commit, rootGetters}){
        photos.getPhotoGroups(rootGetters["app/formattedFilters"])
            .then(res => {
                commit("SET_PHOTO_GROUPS", res.data.data.data);
            });
    },
    closeModals({commit}){
        commit("SET_CURRENT_ACTION_TARGET", null);
        commit("SET_CURRENT_ACTION_TYPE", null);
    }
};

const mutations = {
    ...modals.mutations,
    SET_PHOTO_GROUPS(state, value){
        state.photoGroups = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
