import { homeRouteNameFromUserType } from "@/helpers/auth";

export default {
    methods: {
        redirectHome() {
            const user = this.$store.getters["auth/user"];

            if(! user) {
                this.$router.push({ name: "login" });
                return;
            }

            if (user.type === "candidate" && user.number_connections === 0 && !localStorage.getItem("demo")) {
                this.$router.push({
                    name: "settings",
                    query: { password: true },
                });
                return;
            }

            this.$router.push({ name: homeRouteNameFromUserType(user.type) });
        },
    },
};
