import { mapState } from "vuex";
import store from "@/store";
import Cookies from "js-cookie";
import { homeRouteNameFromUserType } from "@/helpers/auth";

// Don't use mapGetters or mapState, or contextMenu / modals will break
function getUser(){
    return store.getters["auth/user"];
}

export default {
    computed: {
        ...mapState("auth", ["user"]),
        isCandidate() {
            return getUser() && getUser().type === "candidate";
        },
        isNotary() {
            return (
                getUser() && getUser().type === "notary"
            );
        },
        isBroker() {
            return getUser() && getUser().type == "broker";
        },
        isBanker() {
            return (
                getUser() && getUser().type === "banker"
            );
        },
        isPromoter() {
            return (
                getUser() && getUser().type === "promoter"
            );
        },
        isArchitect() {
            return (
                getUser() && getUser().type === "architect"
            );
        },
        isPilot() {
            return (
                getUser() && getUser().type === "pilot"
            );
        },
        isInvestor() {
            return (
                getUser() && getUser().type === "investor"
            );
        },
        isInvestorLight() {
            return (
                getUser() && getUser().type === "investor" && getUser().restricted_access === 1
            );
        },
        isDemo(){
            return localStorage.getItem("demo");
        },
        user(){
            return getUser();
        },
        homeRouteName() {
            if (! getUser()) return null;
            return homeRouteNameFromUserType(getUser().type);
        },
        connected(){
            return Cookies.get("token") != null;
        }
    },
    methods : {
        logout() {
            this.$store.dispatch("auth/logout").then(() => {
                this.$router.push({ name: "login" });
                Cookies.remove("token");
                this.clearApplication();
                window.location.reload();
            });
        },
        clearApplication(){
            localStorage.clear();
            Cookies.remove("token");
            const stores = ["auth", "promotion"];
            for(let store of stores){
                this.$store.dispatch(`${store}/clear`);
            }
        },
    }

};