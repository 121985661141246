<template>
    <div>
        <div
            class="bg-white px-2 py-2 shadow-sm rounded w-100 hover:border-blue-200 border cursor-pointer w-full"
            @click="openThread"
        >
            <div class="flex justify-between">
                <div class="flex self-center">
                    <img
                        :src="thread.interlocutor.profile_picture"
                        class="rounded-full w-8 h-8"
                    />
                    <div class="flex self-center justify-center ml-4 text-gray-700 font-semibold text-sm pr-4 border-r-2">
                        {{ thread.interlocutor.full_name }}
                    </div>
                    <div class="flex self-center pl-3 text-md font-semibold text-blue-600">
                        {{ thread.title }}
                    </div>
                </div>
                <div class="flex self-center flex-col">
                    <span class="text-gray-500 text-xs">{{ thread.messages_count }} message(s)</span>
                    <span v-if="thread.unread_count">
                        <span
                            class="bg-red-500 rounded-full px-2 py-0 text-center object-right-top text-white text-xs ml-1"
                        >
                            {{ thread.unread_count }} non lus
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        thread: {
            required: true,
            type: Object,
        },
    },
    methods: {
        openThread() {
            this.$router.push({
                name: "message-one",
                params: { id: this.thread.id },
            });
        },
    },
};
</script>