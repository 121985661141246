<template>
  <CtxColumn :title="candidacy.candidate.full_name">
        <CtxRow 
            v-if="showAction('fiche_reservation')"
            :row="actions.fiche_reservation" 
            @click="handleClick(actions.fiche_reservation)"
        >
            <div slot="left">
                <svg
                    class="mr-3 h-4 w-4"
                    fill="currentcolor"
                    viewBox="0 0 20 20"
                >
                    <path
                        d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"
                    />
                </svg>
            </div>
        </CtxRow>

        <CtxRow 
            v-if="showAction('attribute_lot')"
            :row="actions.attribute_lot" 
            @click="handleClick(actions.attribute_lot)"
        >
            <div slot="left">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="h-4 w-4 fill-current mr-3"
                >
                    <path d="M1 5l9 5-9 5V5zm9 0l9 5-9 5V5z" />
                </svg>
            </div>
        </CtxRow>
        
        <CtxRow 
            v-if="showAction('add_task')"
            :row="actions.add_task" 
            @click="handleClick(actions.add_task)"
        >
            <div slot="left">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="h-4 w-4 fill-current mr-3"
                >
                <path
                    d="M15 9h-3v2h3v3h2v-3h3V9h-3V6h-2v3zM0 3h10v2H0V3zm0 8h10v2H0v-2zm0-4h10v2H0V7zm0 8h10v2H0v-2z"
                />
                </svg>
            </div>
        </CtxRow>
        <!-- <CtxRow 
            v-if="actions.task_history.show(clickTarget)"
            :row="actions.task_history" 
            @click="handleClick(actions.task_history)"
        >
            <div slot="left">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="h-4 w-4 fill-current mr-3"
                >
                    <path
                        d="M1 4h2v2H1V4zm4 0h14v2H5V4zM1 9h2v2H1V9zm4 0h14v2H5V9zm-4 5h2v2H1v-2zm4 0h14v2H5v-2z"
                    />
                </svg>
            </div>
        </CtxRow> -->
        <CtxRow 
            :row="actions.edit_candidate" 
            @click="handleClick(actions.edit_candidate)"
        >
            <div slot="left">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="h-4 w-4 fill-current mr-3"
                >
                    <path
                        d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z"
                    />
                </svg>
            </div>
        </CtxRow>
        <CtxRow 
            :row="actions.show_files" 
            @click="handleClick(actions.show_files)"
        >
            <div slot="left">
                <div class="relative">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="h-4 w-4 fill-current mr-3"
                    >
                        <path
                            d="M15 3H7a7 7 0 1 0 0 14h8v-2H7A5 5 0 0 1 7 5h8a3 3 0 0 1 0 6H7a1 1 0 0 1 0-2h8V7H7a3 3 0 1 0 0 6h8a5 5 0 0 0 0-10z"
                        />
                    </svg>
                    <div v-if="clickTarget.files.length && clickTarget.files.length > 0" class="absolute h-3 w-3 bg-red-600 rounded-full text-white text-2xs flex items-center justify-center" style="top : -50%; right : 20%;">
                            <span>{{clickTarget.files.length}}</span>
                    </div>
                </div>
            </div>
        </CtxRow>
        <CtxRow 
            v-if="showAction('show_comments')"
            :row="actions.show_comments" 
            @click="handleClick(actions.show_comments)"
        >
            <div slot="left">
                <div class="relative">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="h-4 w-4 fill-current mr-3"
                    >
                        <path
                            d="M10 15l-4 4v-4H2a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-8zM5 7v2h2V7H5zm4 0v2h2V7H9zm4 0v2h2V7h-2z"
                        />
                    </svg>
                    <div v-if="clickTarget.user_comments.length" class="absolute h-3 w-3 bg-red-600 rounded-full text-white text-2xs flex items-center justify-center" style="top : -50%; right : 20%;">
                        <span>{{clickTarget.user_comments.length}}</span>
                    </div>
                </div>
            </div>
        </CtxRow>
        <CtxRow 
            :row="actions.contact" 
            @click="handleClick(actions.contact)"
        >
            <div slot="left">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="h-4 w-4 fill-current mr-3"
                >
                    <path
                        d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                    ></path>
                    <path
                        d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                    ></path>
                </svg>
            </div>
        </CtxRow>
        <CtxRow
            v-if="showAction('show_messages')"
            :row="actions.show_messages" 
            @click="handleClick(actions.show_messages)"
        >
            <div slot="left">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="h-4 w-4 fill-current mr-3"
                >
                    <path
                        d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                    ></path>
                    <path
                        d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                    ></path>
                </svg>
            </div>
        </CtxRow>
        <CtxRow
            v-if="showAction('logs')"
            :row="actions.logs" 
            @click="handleClick(actions.logs)"
        >
            <div slot="left">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            </div>
        </CtxRow>
        <CtxRow 
            v-if="showAction('change_agency')"
            :row="actions.change_agency" 
            @click="handleClick(actions.change_agency)"
        >
            <div slot="left">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="h-4 w-4 fill-current mr-3"
                >
                    <path d="M10 7H2v6h8v5l8-8-8-8v5z" />
                </svg>
            </div>
        </CtxRow>

    </CtxColumn>
</template>

<script>
import CtxColumn from "@/components/global/ContextMenuColumn";
import CtxRow from "@/components/global/ContextMenuRow";
import contextMenuActions from "@/components/Candidacies/contextMenu/actions";
import {mapState} from "vuex";

export default {
    components : {
        CtxColumn,
        CtxRow
    },
    props : {
        candidacy : {
            type : Object,
            required : false,
            default : null
        },
        rules : {
            type : Object,
            required : false
        }
    },
    data(){
        return {
            actions : contextMenuActions
        };
    },
    computed : {
        ...mapState("candidacy", ["currentActionTarget", "currentActionType", "contextEvent"]),
        clickTarget(){
            if(this.candidacy)   
                return this.candidacy;
            return this.currentActionTarget;
        }
    },
    methods : {
        handleClick(action){
            action.onclick(this.clickTarget);
            this.close();
        },
        showAction(actionKey){
            let show = true;
            if(this.rules && this.rules[actionKey] && this.rules[actionKey].show){
                show &= this.rules[actionKey].show() && this.actions[actionKey].show(this.clickTarget);
            }
            if(this.actions[actionKey].show){
                show &= this.actions[actionKey].show(this.clickTarget);
            }
            return show;
        },
        close(){
            this.$store.commit("candidacy/SET_CONTEXT_EVENT", null);
            this.$emit("close");
        }
    }
};
</script>

<style>

</style>