export default{
    computed : {
        queryParams(){
            return this.$route.query;
        }
    },
    methods : {
        isFilter(paramKey){
            return ["lot", "application", "candidat"].includes(paramKey);
        }
    },
    watch : {
        queryParams(newVal){
            for(var key in newVal){
                if(this.isFilter(key)){
                    this.$store.commit("app/SET_FILTER", {key, value : newVal[key]});
                }
            }
        }
    }
};