<template>
  <div 
    id="demoSelector"
    title="Changer d'utilisateur"
    class="fixed border bg-white shadow-lg z-50"
    :class="{'active' : enabled}"
    v-on-clickaway="disable"
    @click="toggle"
  >
    <div id="demo-toggler" class="bg-blue-900 text-white px-6 py-1 rounded-t-md text-center flex items-center justify-around cursor-pointer z-50">
      <span>Mode Demo</span>
      <svg v-if="enabled" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path></svg>
      <svg v-else class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10l7-7m0 0l7 7m-7-7v18"></path></svg>
    </div>

    <ul class="fab-options" v-if="enabled">
      <li 
        v-for="(userType, index) in userTypes" :key="index"
        class="hover:bg-blue-400 hover:text-white text-blue-900 cursor-pointer"
        @click="demoLogin(userType.type)"
      >
        <div class="shadow px-2 py-1">
          <span class="fab-label">{{userType.name}}</span>
        </div>
      </li>
    </ul>      

  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import Demo from "@/mixins/Demo";
export default {
    mixins: [clickaway, Demo],
    data(){
        return {
            enabled : false,
            userTypes : [
                {
                    type : "broker",
                    name : "Courtier",
                },
                {
                    type : "candidate",
                    name : "Candidat",
                },
                {
                    type : "promoter",
                    name : "Promoteur",
                },
                {
                    type : "notary",
                    name : "Notaire"
                },
                {
                    type : "architect",
                    name : "Architecte / constructeur"
                },
                {
                    type : "banker",
                    name : "Banquier"
                },
                {
                    type : "pilot",
                    name : "Pilote"
                },
                {
                    type : "investor",
                    name : "Investisseur"
                },
            ]
        };
    },
    methods : {
        toggle(){
            this.enabled = !this.enabled;
        },
        disable(){
            this.enabled = false;
        }
    }
};
</script>

<style scoped>
#demoSelector{
  left : 50%;
  bottom : 0;
  transform: translateX(-50%);
}
</style>