import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import axiosSetup from "@/helpers/interceptors";

import "@/assets/scss/tailwind.scss";
import "@/assets/scss/custom.scss";

Vue.config.productionTip = false;

import Default from "./layouts/Default";
import Auth from "./layouts/Auth";

import "nouislider/distribute/nouislider.css";

// import filters
import DateFilters from "./filters/date";
for (let filter in DateFilters) {
    Vue.filter(filter, DateFilters[filter]);
}

Vue.component("default-layout", Default);
Vue.component("auth-layout", Auth);

import VueToast from "vue-toast-notification";

Vue.use(VueToast);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

axiosSetup();

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
