<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Détails de la photo {{ photo.name }} du {{ photo.date | date_dmy }}
        </h3>
        <div slot="body">
            <div class="text-left">
                <dl>
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3funding_ sm:py-5">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Nom
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
                            {{ photo.name }}
                        </dd>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3funding_ sm:py-5">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Lot
                        </dt>
                        <dd
                            v-if="photo.lot"
                            class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
                        >
                            {{ photo.lot.title }} <span class="font-semibold">({{ photo.lot.reference }})</span>
                        </dd>
                        <dd
                            v-else
                            class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
                        >
                            -
                        </dd>
                    </div>
                    <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-3funding_ sm:py-5">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Date
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
                            {{ photo.date | date_dmyhm}}
                        </dd>
                    </div>
                    <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-3funding_ sm:py-5">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Détail
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
                            {{ photo.comment }}
                        </dd>
                    </div>
                    <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-3funding_ sm:py-5">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Photo
                        </dt>
                        <dd class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
                            <div class="w-120">
                                <img :src="photo.url">
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>

        <div
            slot="footer"
            class="mt-12 text-right"
        >
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import {mapGetters} from "vuex";

export default {
    components: {
        DefaultModal
    },
    
    methods: {
        close() {
            this.$store.dispatch("photo/closeModals");
        }
    },
    computed: {
        ...mapGetters("photo", ["currentActionType", "currentActionTarget"]),
        open(){
            return this.currentActionTarget != null && this.currentActionType === "showPhoto";
        }
    },
};
</script>
