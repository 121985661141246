<template>
    <div
        class="absolute w-56 rounded-md shadow-lg z-20"
        :style="position"
        v-on-clickaway="close"
        v-if="isOpen"
    >
        <div class="rounded-md bg-white shadow-xs">
            <div class="py-1">
                <a
                    v-for="(action, index) in actions"
                    :key="index"
                    @click.prevent="clickAction(action.value)"
                    href="#"
                    class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-600 hover:bg-gray-100 hover:text-blue-900 focus:outline-none focus:bg-gray-100 focus:text-blue-900"
                >
                    <svg
                        v-if="action.icon"
                        v-html="action.icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="h-4 w-4 fill-current mr-3"
                    >
                        {{action.icon}}
                    </svg>
                    {{ action.label }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mixin as clickaway } from "vue-clickaway";
import RightClickMenu from "@/mixins/RightClickMenu";
export default {
    mixins: [clickaway, RightClickMenu],

    data() {
        return {};
    },

    props: {
        lot: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState("lot", ["currentActionTarget"]),
        ...mapGetters("auth", ["currentPromotion"]),
        actions() {
            let actions = [
                {
                    label: "Ajouter une tâche",
                    value: "addTask",
                    icon:
                        "<path d=\"M15 9h-3v2h3v3h2v-3h3V9h-3V6h-2v3zM0 3h10v2H0V3zm0 8h10v2H0v-2zm0-4h10v2H0V7zm0 8h10v2H0v-2z\" />"
                },
            ];

            if (this.lot.raw_status === 0) {
                actions.push({
                    label: "Passer en PRS",
                    value: "moveToPRS",
                    icon:
                        "<path d=\"M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z\"/>"
                });
            } else if (this.lot.raw_status === 1) {
                actions.push({
                    label: "Passer en disponible",
                    value: "moveToAvailable",
                    icon:
                        "<path d=\"M4 8V6a6 6 0 1 1 12 0h-3v2h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z\"/>"
                });
            }
            
            if(!this.currentPromotion.multi_agency){
                actions.push({
                    label: "Modifier prix",
                    value: "updatePrice",
                    icon:
                            "<path d=\"M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm1-5h1a3 3 0 0 0 0-6H7.99a1 1 0 0 1 0-2H14V5h-3V3H9v2H8a3 3 0 1 0 0 6h4a1 1 0 1 1 0 2H6v2h3v2h2v-2z\"/>"
                });
            }
            
            actions.push({
                label: "Voir l'historique",
                value: "showLogs",
                icon: "<path fill-rule=\"evenodd\" d=\"M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z\" clip-rule=\"evenodd\" />",
            });
            

            return actions;
        }
    },

    methods: {
        clickAction(action) {
            switch (action) {
                case "addTask":
                    this.openAddTask();
                    break;
                case "moveToPRS":
                    this.updateStatus(1);
                    break;
                case "moveToAvailable":
                    this.updateStatus(0);
                    break;
                case "showLogs":
                    this.$router.push({ name: "logs", query: {
                        lot_id: this.currentActionTarget.id,
                    } });
                    break;
                default:
                    this.$store.commit(
                        "lot/SET_CURRENT_ACTION_TARGET",
                        this.lot
                    );
                    this.$store.commit("lot/SET_CURRENT_ACTION_TYPE", action);
            }
        },
        openAddTask(){
            this.$store.dispatch("task/openAddTask", {
                target: this.lot,
                targetType: "lot"
            });
        },
        close() {
            this.$emit("close");
        },
        updateStatus(status) {
            this.$store.commit("loader/SET_LOADING", true);
            this.$store
                .dispatch("lot/update", {
                    id: this.lot.id,
                    status: status
                })
                .then(() => {
                    this.$store
                        .dispatch("lot/get")
                        .then(() => {
                            this.$store.commit("loader/SET_LOADING", false);
                        })
                        .catch(() => {
                            this.$store.commit("loader/SET_LOADING", false);
                        });
                    this.$toast.open({
                        message: "Statut mis à jour",
                        type: "success"
                    });
                    this.$emit("update");
                    this.$emit("close");
                })
                .catch(() => {
                    this.$toast.open({
                        message: "Une erreur est survenue",
                        type: "error"
                    });
                });
        }
    },
    watch: {
        isOpen(val) {
            if (val) {
                this.$store.commit("lot/SET_CURRENT_ACTION_TARGET", this.lot);
            }
        },
        currentActionTarget(val) {
            
            if (!val || val.id !== this.lot.id) {
                this.close();
            }
        }
    }
};
</script>

<style></style>
