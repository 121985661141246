<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Liste des documents rattachés à :
            {{ currentActionTarget.candidate.full_name }}
        </h3>
        <div slot="body">
            <files-list
                :candidacy="currentActionTarget"
                ref="filesList"
                @close="close"
                @removeFile="setUpdated"
                @addFile="updateFiles"
            ></files-list>
        </div>
    </default-modal>
</template>

<script>
import { mapState } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import FilesList from "@/components/Candidates/FilesList";

export default {
    components: { DefaultModal, FilesList },
    data() {
        return {
            body: {
                name: null,
                file: null,
            },
            updated : false,
        };
    },
    computed: {
        ...mapState("candidacy", ["currentActionTarget", "currentActionType"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "showFiles";
        },
        candidate(){
            return this.currentActionTarget.candidate;
        },
        hasFiles() {
            return this.currentActionTarget && this.currentActionTarget.files
                ? this.currentActionTarget.files.length > 0
                : false;
        },
    },
    methods: {
        close() {
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TYPE", null);
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TARGET", null);

            if(this.updated)
                this.$emit("update");
        },
        resetValues() {
            this.$refs.filesList.resetValues();
        },
        setUpdated(){
            this.updated = true;
        },
        updateFiles(){
            this.setUpdated();
        }
    },
};
</script>
