export default {
    globalSituation: {
        chartData: {
            labels: ["Disponible", "Optionné", "Réservé", "Signé"],
            datasets: [
                {
                    data: [0, 0, 0],
                    backgroundColor: [
                        "#a0aec0",
                        "#fbd38d",
                        "#f6ad55",
                        "#68d391",
                    ],
                    hoverBackgroundColor: [
                        "#718096",
                        "#facc44",
                        "#ed8936",
                        "#48bb78",
                    ],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            rotation: 1 * Math.PI,
            circumference: Math.PI,
            legend: {
                display: false,
            },
            plugins: {
                datalabels: {
                    font: {
                        size: 18,
                    },
                    color: "#fff",
                },
            },
        },
    },
    evolutionSales: {
        chartData: {
            labels: [
                "Juin",
                "Juillet",
                "Aout",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre",
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
            ],
            datasets: [
                {
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    label: "Disponible",
                    borderColor: "#a0aec0",
                    fill: false,
                },
                {
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    label: "Optionné",
                    borderColor: "#fbd38d",
                    fill: false,
                },
                {
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    label: "Réservé",
                    borderColor: "#f6ad55",
                    fill: false,
                },
                {
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    label: "Signé",
                    borderColor: "#68d391",
                    fill: false,
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            beginAtZero: true,
            scales : {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }],
            },
            plugins: {
                datalabels: {
                    display: function() {
                        return false;
                    },
                },
            },
        },
    },
    evolutionSalesByRooms: {
        chartData: {
            labels: [],
            datasets: [
                {
                    stack : "bar",
                    label: "Disponible",
                    backgroundColor: "#a0aec0",
                    data: [],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                },
                {
                    stack : "bar",
                    label: "Optionné",
                    backgroundColor: "#fbd38d",
                    data: [],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                },
                {
                    stack : "bar",
                    label: "Réservé",
                    backgroundColor: "#f6ad55",
                    data: [],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                },
                {
                    stack : "bar",
                    label: "Signé",
                    backgroundColor: "#68d391",
                    data: [],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                },
            ],
        },
        options: {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            responsive: true,
            scales: {
                xAxes : [{
                    stacked : true,
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                }],
            },
            plugins: {
                datalabels: {
                    font: {
                        size: 18,
                    },
                    color: "#fff",
                    display: function(context) {
                        return context.dataset.data[context.dataIndex] !== 0;
                    }
                },
            },
        },
    },
    evolutionSalesByFloor: {
        chartData: {
            labels: ["1er", "2ème", "3ème", "4ème", "5ème"],
            datasets: [
                {
                    stack: "bar",
                    label: "Disponible",
                    backgroundColor: "#a0aec0",
                    data: [0, 0, 0, 0, 0],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                },
                {
                    stack: "bar",
                    label: "Optionné",
                    backgroundColor: "#fbd38d",
                    data: [0, 0, 0, 0, 0],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                },
                {
                    stack: "bar",
                    label: "Réservé",
                    backgroundColor: "#f6ad55",
                    data: [0, 0, 0, 0, 0],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                },
                {
                    stack: "bar",
                    label: "Signé",
                    backgroundColor: "#68d391",
                    data: [0, 0, 0, 0, 0],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            scales: {
                xAxes : [{
                    stacked : true,
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                }],
            },
            plugins: {
                datalabels: {
                    font: {
                        size: 18,
                    },
                    color: "#fff",
                    display: function(context) {
                        return context.dataset.data[context.dataIndex] !== 0;
                    }
                },
            },
        },
    },
};