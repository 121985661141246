<template>
  <carousel
    :perPage="1"
    :paginationPadding="7"
    :autoplay="true"
    :autoplayTimeout="3000"
  >
    <slide v-for="(file, index) in photosHome" :key="index">
      <div v-if="file.is_video">
        <video controls>
          <source :src="file.url_video" />
        </video>
      </div>
      <div
        v-else
        class="inline-block w-full"
        :style="
          `background-image : url(${file.url_1300w}); height: 700px; width : 100%; background-position: center center; background-size : cover;`
        "
      ></div>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters("photo",["photosHome"])
    },
    components: {
        Carousel,
        Slide
    },
};
</script>

<style>
.VueCarousel-dot.VueCarousel-dot--active {
  background-color: #63b3ed !important;
}
.VueCarousel-dot:active {
  border: none !important;
}
</style>