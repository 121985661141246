<template>
    
    <div class="badge px-2 text-2xs inline-block text-white bg-blue-400 rounded relative cursor-default select-none"
        :class="reason_opened && candidacy && candidacy.reason_vip ? 'underline' : ''"
        @mouseover="showReason"
        @mouseleave="hideReason"
    >
        VIP
        <div class="rounded text-sm bg-white border absolute border-gray-600 text-gray-700 p-2 z-50" 
            style="transform : translateY(-80%); left : calc(110%); min-width : 300px; max-width : 500px; line-break: strict; white-space: normal;" 
            v-if="reason_opened && candidacy && candidacy.reason_vip"
        >
            <p>{{candidacy.reason_vip}}</p>
        </div>
    </div>
    
</template>

<script>
export default {
    props : {
        candidacy : {
            type : Object,
            required : false,
            default : null,
        }
    },

    data(){
        return {
            reason_opened : false,
        };
    },
    methods : {
        showReason(){
            this.reason_opened = true;
        },
        hideReason(){
            this.reason_opened = false;
        }
    }
};
</script>

<style>
</style>