<template>
    <div class="mt-4">
        <div
            class="flex items-center px-3 absolute"
            style="margin-top: -13px;"
        >
            <div class="rounded-md p-3 shadow text-white icon-badge mr-2 bg-gray-700">
                <slot name="icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M1 10h3v10H1V10zM6 0h3v20H6V0zm5 8h3v12h-3V8zm5-4h3v16h-3V4z" />
                    </svg>
                </slot>
            </div>
            <h3 class="text-lg leading-6 font-medium text-gray-700 mt-2 text-2xl">{{ title }}</h3>
        </div>
        <div class="bg-white p-1 rounded-lg shadow pt-12 border h-full">
            <slot name="chart"> </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        }
    }
};
</script>

<style></style>
