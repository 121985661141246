import Cookies from "js-cookie";
import store from "@/store";

export function getToken() {
    // Pourquoi nous avons besoin de stocker le token dans le store
    // si Cookies.get("token") est accessible partout ?
    // Peut-être devrions nous supprimer les références à getters["auth/token"]
    return Cookies.get("token") || store.getters["auth/token"];
}

export function parseJwt(token) {
    // Copy paste from https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));

    return JSON.parse(jsonPayload);
}

export function homeRouteNameFromToken(token) {
    const data = parseJwt(token);
    return homeRouteNameFromUserType(data.user_type);
}

export function homeRouteNameFromUserType(user_type) {
    return {
        "candidate": "candidate-home",
        "notary": "candidate-home",
        "broker": "dashboard",
        "promoter": "dashboard",
        "banker": "dashboard",
        "architect": "dashboard",
        "pilot": "dashboard",
        "investor": "candidate-home"
    }[user_type];
}