<template>
	<div class="bg-white shadow overflow-hidden sm:rounded-lg border border-blue-900"
		v-if="candidacy"
	>
		<div class="p-4 border-b border-gray-200 sm:px-6">
			<h3
				class="text-lg leading-6 font-medium text-blue-900"
			>Informations financement</h3>
			<p
				class="mt-1 max-w-2xl text-sm leading-5 text-gray-500"
			>Informations concernant le financement de votre lot.</p>
		</div>


		<div class="px-4 pb-4 md:p-0">
			<div class="sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
				<div class="pt-3 text-sm leading-5 sm:col-span-3 text-center font-medium text-blue-900 md:border-0 md:pt-0">
					En cas de prêt hypothécaire, veuillez indiquer :
				</div>
			</div>

			<!-- MONTANT -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Montant en CHF</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-1">
					<input
						:value="candidacy.mortgage_amount"
						:disabled="disabled"
						@input="(e) => handleChange('mortgage_amount', e.target.value)"
						id="funding_0_amount"
						type="number"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					/>
				</div>
			</div>
			<!-- NOM BANQUE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Nom de la banque</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-1">
					<input
						:value="candidacy.mortgage_bank_name"
						:disabled="disabled"
						@input="(e) => handleChange('mortgage_bank_name', e.target.value)"
						id="funding_0_bank_name"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					/>
				</div>
			</div>
			<!-- PERSONNE DE CONTACT -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Personne de contact</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-1">
					<input
						:value="candidacy.mortgage_contact_name"
						:disabled="disabled"
						@input="(e) => handleChange('mortgage_contact_name', e.target.value)"
						id="funding_0_contact_name"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					/>
				</div>
			</div>

			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5 sm:border-t sm:border-gray-200"
			>
				<div class="pt-3 text-sm leading-5 sm:col-span-3 text-center font-medium text-blue-900 border-t border-gray-200 md:border-0 md:pt-0">
					En cas de retrait du 2e pilier, veuillez indiquer :
				</div>
			</div>

			<!-- MONTANT -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Montant en CHF</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-1">
					<input
						:value="candidacy.second_pillar_amount"
						:disabled="disabled"
						@input="(e) => handleChange('second_pillar_amount', e.target.value)"
						type="number"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					/>
				</div>
			</div>
			<!-- NOM BANQUE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Nom de la caisse</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-1">
					<input
						:value="candidacy.second_pillar_bank_name"
						:disabled="disabled"
						@input="(e) => handleChange('second_pillar_bank_name', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					/>
				</div>
			</div>
			<!-- PERSONNE DE CONTACT -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Personne de contact</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-1">
					<input
						:value="candidacy.second_pillar_contact_name"
						:disabled="disabled"
						@input="(e) => handleChange('second_pillar_contact_name', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					/>
				</div>
			</div>

			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5 sm:border-t sm:border-gray-200"
			>
				<div class="pt-3 text-sm leading-5 sm:col-span-3 text-center font-medium text-blue-900 border-t border-gray-200 md:border-0 md:pt-0">
					En cas de retrait du 3e pilier, veuillez indiquer :
				</div>
			</div>

			<!-- MONTANT -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Montant en CHF</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-1">
					<input
						:value="candidacy.third_pillar_amount"
						:disabled="disabled"
						@input="(e) => handleChange('third_pillar_amount', e.target.value)"
						type="number"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					/>
				</div>
			</div>
			<!-- NOM BANQUE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Nom de la caisse</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-1">
					<input
						:value="candidacy.third_pillar_bank_name"
						:disabled="disabled"
						@input="(e) => handleChange('third_pillar_bank_name', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					/>
				</div>
			</div>
			<!-- PERSONNE DE CONTACT -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Personne de contact</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-1">
					<input
						:value="candidacy.third_pillar_contact_name"
						:disabled="disabled"
						@input="(e) => handleChange('third_pillar_contact_name', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters } from "vuex";
export default {
    props : {
        isSecondCandidate : {
            type : Boolean,
            default : false,
        },
        disabled : {
            type : Boolean,
        },
    },
    computed : {
        ...mapGetters("reservationForm", ["candidacy1", "candidacy2"]),
        candidacy(){
            if(this.isSecondCandidate)
                return this.candidacy2;
            return this.candidacy1;
        }
    },
    methods : {
        handleChange(key, value){	
            const data = {
                key,
                value
            };
            if(this.isSecondCandidate)
                this.$store.commit("reservationForm/SET_CANDIDACY_2_DATA", data);
            else
                this.$store.commit("reservationForm/SET_CANDIDACY_1_DATA", data);
        }
    }
};
</script>

<style>

</style>