<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <div slot="header">
            <h3
                class="text-left text-xl leading-6 font-medium pb-5 text-gray-700"
            >
                Supprimer le lot {{lot.reference}}
            </h3>
            <div class="mb-3 text-left bg-red-200 text-red-600 border-l-4 border-red-600 p-3">
                Attention ! Toutes les données seront supprimées (lot, candidature, lots associés).
            </div>
        </div>
        <div slot="body">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5 bg-red-500 hover:bg-red-600 text-white cursor-pointer"
                    >
						<svg class="w-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
						Oui, supprimer le lot
					</button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Annuler</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import _YesNoModal from "@/mixins/YesNoModal";

export default {
    props : {
        lot : {
            required : true,
            type : Object,
        },
        building : {
            required : true,
            type : Object,
        },
        open : {
            required : true,
            default : false,
            type : Boolean,
        },
        promotion : {
            required : true,
            type : Object,
        },
		
    },
    mixins: [_YesNoModal],
    methods: {
        close(){
            this.$emit("close");
        },
        submit(){
            this.$emit("submit");
        },
    },
};
</script>

<style>
</style>