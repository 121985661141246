<template>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <div class="flex flex-col">
            <div
                class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                id="table"
            >
                <div
                    class="align-middle inline-block min-w-full shadow overflow-x-hidden sm:rounded-lg border-b border-gray-200"
                >
                    <div class="grid grid-flow-col grid-cols-4 gap-2">
                        <status-arrow
                            background="orange-300"
                            value="0"
                            type="application"
                            >Optionné</status-arrow
                        >
                        <status-arrow
                            background="orange-400"
                            value="1"
                            type="application"
                            >Réservé</status-arrow
                        >
                        <status-arrow
                            background="green-300"
                            value="2"
                            type="application"
                            >Promesse de vente</status-arrow
                        >
                        <status-arrow
                            background="green-500"
                            value="3"
                            type="application"
                            >Vendu</status-arrow
                        >
                    </div>

                    <table class="min-w-full main--table">
                        <thead class="bg-gray-200">
                            <tr>
                                <th></th>
                                <sortable-th
                                    style="background-position : center center !important;"
                                    column="priority"
                                ></sortable-th>
                                <th
                                    style="padding-left : 10px;"
                                    class="text-left"
                                    column="main_candidate"
                                    subColumn="full_name"
                                    >Client</th
                                >
                                <sortable-th
                                    style="padding-left : 5px !important; padding-right : 2px !important;"
                                    propsClass="text-left"
                                    column="status"
                                    >Statut</sortable-th
                                >
                                <sortable-th 
                                propsClass="text-left"
                                column="buildings.title"
                                >Immeuble
                                </sortable-th>
                                <sortable-th
                                    column="lots.reference"
                                    subColumn="reference"
                                    >Lot</sortable-th
                                >
                                <sortable-th
                                    column="lots.nb_rooms"
                                    >Pièces</sortable-th
                                >
                                <sortable-th v-tooltip="'Surface habitable'" column="lots.area_hab"
                                    >Sur.</sortable-th
                                >
                                <sortable-th
                                    column="floors.order"
                                    >Étage</sortable-th
                                >
                                <sortable-th
                                    column="lots.price"
                                    subColumn="price"
                                    >Prix</sortable-th
                                >
                                <template v-if="!simpleView">
                                    <sortable-th v-tooltip="'Fiche de réservation'" style="padding-left : 4px" column="status_FR">FR</sortable-th>
                                    <sortable-th v-tooltip="'Acompte'" style="padding-left : 4px" column="status_AC">AC</sortable-th>
                                    <sortable-th v-tooltip="'Financement'" style="padding-left : 4px" column="status_FIN">FIN</sortable-th>
                                    <sortable-th v-tooltip="'Signature promesse de vente'" style="padding-left : 4px" column="status_SIGNPDV">SIGN PdV</sortable-th>
                                    <sortable-th v-tooltip="'Signature acte de vente'" style="padding-left : 4px" column="status_SIGN">SIGN</sortable-th>
                                </template>
                                <th class="p-1">A faire</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white" v-if="hasApplications">
                            <sales-tunnel-row
                                v-for="application in applications.data"
                                :key="application.id"
                                :application="application"
                                :simple-view="simpleView"
                                @update="emitUpdate"
                            ></sales-tunnel-row>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <the-pagination
            v-if="applications"
            :lastPage="applications.last_page"
            :currentPage="applications.current_page"
            :from="applications.from"
            :to="applications.to"
            :total="applications.total"
        ></the-pagination>
    </div>
</template>

<script>
import { mapState } from "vuex";
import {VTooltip} from "v-tooltip";
import SalesTunnelRow from "@/components/SalesTunnel/SalesTunnelRow";
import Sortable from "@/mixins/Sortable";
import SortableTh from "@/components/Tables/SortableTh";
import StatusArrow from "@/components/Filters/StatusArrow";
import ThePagination from "@/components/global/ThePagination";
import "v-tooltip/dist/v-tooltip.css";

export default {
    mixins: [Sortable],

    directives: {
        tooltip: VTooltip,
    },

    components: {
        SalesTunnelRow,
        SortableTh,
        StatusArrow,
        ThePagination,
    },

    props: {
        count : {
            type : Number,
            default : 0
        },
        applications: {
            type: Object,
            required: false,
            nullable: true,
        },
        simpleView : {
            type : Boolean,
            default : false
        }
    },
    methods: {
        emitUpdate(data){
            this.$emit("update", data);
        }
    },
    computed: {
        ...mapState("candidate", ["candidates"]),
        hasApplications() {
            return this.applications && this.applications.data;
        },
    }
};
</script>
