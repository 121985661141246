<template>
<div class="lg:grid grid-cols-5 bg-gray-200 rounded-md shadow-md overflow-hidden mb-3 border border-blue-900">

  <HeaderStep title="Informations candidature" :stepNumber="1" ></HeaderStep>
  <HeaderStep title="Candidat 1" :stepNumber="2" ></HeaderStep>
  <HeaderStep title="Candidat 2" :stepNumber="3" ></HeaderStep>
  <HeaderStep title="Financement candidat 1" :stepNumber="4" ></HeaderStep>
  <HeaderStep title="Financement candidat 2" :stepNumber="5" ></HeaderStep>

</div>
</template>

<script>
import HeaderStep from "./HeaderStep";
export default {
    components : {
        HeaderStep
    },
};
</script>