<template>
    <div
        class="text-left mx-1 select-none"
        v-on-clickaway="close"
        @contextmenu.stop.prevent
    >
        <div>
            <span class="rounded-md shadow-sm">
                <div
                    @click="(evt) => open(evt)"
                    class="relative cursor-pointer flex items-center shadow rounded px-1 py-1 bg-gray-100 text-xs leading-5 font-semibold text-white active:text-gray-800 transition ease-in-out duration-150"
                    :class="backgroundColor"
                >
                    <img
                        v-if="task.assigned_to"
                        class="h-6 w-6 rounded-full flex-shrink"
                        :src="task.assigned_to.profile_picture"
                        :title="task.assigned_to.full_name"
                    />
                    <span class="mx-2 flex-grow truncate">{{ task.title }}</span>

                    <div class="flex-shrink">
                        <span
                            class="rounded-full bg-white h-6 w-6 flex items-center justify-center text-gray-700"
                            v-if="task.end_at"
                        >
                            <svg
                                class="w-4 h-4"
                                v-if="task.type == 1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z" />
                            </svg>
                            <svg
                                class="w-4 h-4"
                                v-else
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M16.32 7.1A8 8 0 1 1 9 4.06V2h2v2.06c1.46.18 2.8.76 3.9 1.62l1.46-1.46 1.42 1.42-1.46 1.45zM10 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12zM7 0h6v2H7V0zm5.12 8.46l1.42 1.42L10 13.4 8.59 12l3.53-3.54z" />
                            </svg>
                        </span>
                    </div>
                    <div
                        v-show="isOpen"
                        :style="positionSubAction"
                        class="fixed mt-2 w-56 rounded-md shadow-lg z-30"
                        
                    >
                        <div class="rounded-md bg-white shadow-xs" ref="subActionRef">
                            <div class="py-1">
                                <div
                                    v-for="(subAction, i) in subActions"
                                    :key="i"
                                    @click.prevent.stop="subAction.click"
                                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                                    :class="subAction.color"
                                >
                                    <svg
                                    
                                        v-if="subAction.icon"
                                        v-html="subAction.icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        class="h-4 w-4 fill-current mr-3 inline-block"
                                    >
                                        {{subAction.icon}}
                                    </svg>
                                    {{ subAction.label }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import Task from "@/mixins/Task";

export default {
    mixins: [clickaway, Task],
    computed : {
        position() {
            return "top: 100%; right: 0;";
        },
    },
};
</script>
