<template>
  <div
    aria-live="assertive"
    class="fixed inset-x-0 bottom-0 mt-8 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <toast
        class="w-full"
        v-for="toast in toasts"
        :key="toast.id"
        :toast="toast"
      />
    </div>
  </div>
</template>

<script>
import Toast from "./Toast";

export default {
    components: { Toast },
    
    props: {},

    computed: {
        toasts() {
            return this.$store.getters["notification/toasts"];
        }
    }
};
</script>

<style scoped></style>
