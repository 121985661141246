<template>
    <form ref="form" @submit.prevent="submit">
        <default-modal
            v-if="fileToDelete"
            @close="close"
            maxWidthClass="max-w-3xl overflow-visible"
        >
            <h3 slot="header" class="text-left text-xl leading-6 font-medium text-gray-800 pb-5">
                Supprimer <span class="font-bold text-blue-900">{{ fileToDelete.name }}</span>
            </h3>
            <div slot="body" class="text-left space-y-4">
                Êtes-vous sûr de vouloir supprimer ce fichier ?
            </div>

            <div
                slot="footer"
                class="mt-12 text-right"
            >
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                        <button type="submit"
                            :disabled="isDisabled"
                            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                            :class="{
                                'bg-red-600 hover:bg-red-700 text-gray-100 cursor-pointer': ! isDisabled,
                                'bg-gray-300 text-gray-700 cursor-not-allowed': isDisabled,
                            }"

                        >Supprimer</button>
                    </span>
                    <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button
                            @click="$emit('change', false)"
                            type="button"
                            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >Fermer</button>
                    </span>
                </div>
            </div>
        </default-modal>
    </form>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    components: {
        DefaultModal,
    },

    model: {
        prop: "fileToDelete",
        event: "change",
    },

    data() {
        return {
            isDisabled: false,
        };
    },
    
    props: ["fileToDelete"],

    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
    },
    
    methods: {
        close() {
            this.$emit("change", null);
        },

        async submit() {
            this.isDisabled = true;
            this.$store.commit("loader/SET_LOADING", true);

            try {
                await axios.delete(`/promotions/${this.currentPromotion.id}/files/${this.fileToDelete.id}`);
                this.$emit("deleted");
                this.close();
            } finally {
                this.isDisabled = false;
                this.$store.commit("loader/SET_LOADING", false);
            }
        },
    },
};
</script>
