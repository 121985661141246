<template>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <div class="flex flex-col p-8 space-y-8">
            <div v-if="$route.query">
                <button class="text-blue-600 underline text-sm cursor-pointer"
                    v-if="$route.query.application_id"
                    @click="$router.push({ name: 'tunnel-de-vente' })"
                >
                    Retourner au Tunnel des ventes
                </button>
                <button class="text-blue-600 underline text-sm cursor-pointer"
                    v-if="$route.query.candidacy_id"
                    @click="$router.push({ name: 'candidats' })"
                >
                    Retourner aux candidats
                </button>
                <button class="text-blue-600 underline text-sm cursor-pointer"
                    v-if="$route.query.lot_id"
                    @click="$router .push({ name: 'lots' })"
                >
                    Retourner aux lots
                </button>
            </div>

            <div
                class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                id="table"
            >
                <div
                    class="align-middle inline-block min-w-full shadow overflow-x-hidden sm:rounded-lg border-b border-gray-200"
                    v-if="logs && logs.data.length"
                >
                    <table class="min-w-full main--table">
                        <thead class="bg-gray-200">
                            <tr>
                                <th>Date</th>
                                <th>Exécuteur</th>
                                <th>Sur</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            <tr v-for="log in logs.data" :key="log.id">
                                <td class="text-left">
                                    {{ log.created_at | date_dmyhms }}
                                </td>
                                <td class="text-left ">
                                    {{ log.executed_by_title }}
                                </td>
                                <td class="text-left ">
                                    {{ log.executed_on_title }}
                                </td>
                                <td class="text-left whitespace-normal">{{ log.title }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-gray-500" v-show="(! logs || ! logs.data.length) && ! loading">
                    Aucun historique pour ce filtre.
                </div>
            </div>
        </div>
        <the-pagination
            v-if="logs"
            :lastPage="logs.last_page"
            :currentPage="logs.current_page"
            :from="logs.from"
            :to="logs.to"
            :total="logs.total"
        ></the-pagination>
    </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import ThePagination from "@/components/global/ThePagination";

export default {
    components: { ThePagination },
    
    data() {
        return {
            logs: null,
        };
    },

    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
        ...mapGetters("app", ["formattedFilters"]),
        ...mapState("loader", ["loading"]),

        params() {
            return { 
                // Les `formattedFilters` nous permettent d'avoir accès
                // à la variable `page` pour la pagination.
                ...this.formattedFilters,

                application_id: this.$route.query.application_id,
                lot_id: this.$route.query.lot_id,
                candidacy_id: this.$route.query.candidacy_id,
            };
        },
    },
    
    async mounted() {
        // Lorsque l'on récupère les logs, il me semble que les `formattedFilters` changent
        // ce qui provoque le watchers ci-dessous de relancer une requête. Pour ne pas faire
        // deux requêtes à l'initialisation, j'ai mis un `await`, on attend ainsi que les logs soient
        // bien chargés avant de mettre le watcher en place.
        await this.getLogs();

        this.$watch("params", () => this.getLogs());
    },

    methods: {
        async getLogs() {
            this.$store.commit("loader/SET_LOADING", true);
            let response = await axios.get("/logs", { params: this.params });

            this.logs = response.data;
            this.$store.commit("loader/SET_LOADING", false);
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Historique";
            }
        },
    },       
};
</script>