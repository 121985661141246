<template>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <div class="flex flex-col">
            <div
                class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                id="table"
            >
                <div
                    class="align-middle inline-block min-w-full shadow overflow-x-hidden sm:rounded-lg border-b border-gray-200"
                >
                    <div class="grid grid-flow-col grid-cols-5 gap-2">
                        <status-arrow
                            background="gray-500"
                            value="0"
                            type="candidacy"
                            >Prospect</status-arrow
                        >
                        <status-arrow
                            background="orange-300"
                            value="3"
                            type="candidacy"
                            >Client</status-arrow
                        >
                        <status-arrow
                            background="orange-400"
                            value="4"
                            type="candidacy"
                            >Client acquéreur</status-arrow
                        >
                        <status-arrow
                            background="green-300"
                            value="5"
                            type="candidacy"
                            >Promettant acquéreur</status-arrow
                        >
                        <status-arrow
                            background="green-600"
                            value="6"
                            type="candidacy"
                            >Acquéreur</status-arrow
                        >
                    </div>
                    <table class="min-w-full main--table">
                        <thead class="bg-gray-200">
                            <tr>
                                <th v-if="user.agency.id == 1" title="Validé sur Salesforce">Salesforce</th>
                                <th></th>
                                <sortable-th column="candidates.full_name" class="text-left" style="padding-left : 10px;">Client</sortable-th>
                                <sortable-th column="priority">Note</sortable-th>
                                <th>Statut</th>
                                <th title="Candidatures">Cand.</th>
                                <sortable-th column="created_at">Enregistrement</sortable-th>
                                <th>Budget</th>
                                <sortable-th column="candidates.email">email</sortable-th>
                                <sortable-th column="candidates.phone">Tel.</sortable-th>
                                <th>A faire</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            <candidacy-row
                                v-for="candidacy in candidacies.data"
                                :key="candidacy.id"
                                :candidacy="candidacy"
                                @update="emitUpdate"
                            ></candidacy-row>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <the-pagination
            :lastPage="candidacies.last_page"
            :currentPage="candidacies.current_page"
            :from="candidacies.from"
            :to="candidacies.to"
            :total="candidacies.total"
        ></the-pagination>
    </div>
</template>

<script>
import { mapState } from "vuex";
import CandidacyRow from "@/components/Candidacies/CandidacyRow";
import Sortable from "@/mixins/Sortable";
import SortableTh from "@/components/Tables/SortableTh";
import StatusArrow from "@/components/Filters/StatusArrow";
import ThePagination from "@/components/global/ThePagination";

export default {
    mixins: [Sortable],
    components: {
        CandidacyRow,
        SortableTh,
        StatusArrow,
        ThePagination,
    },
    data() {
        return {
            s_status: null,
            page: null,
        };
    },
    computed: {
        ...mapState("auth", ["user"]),
    },
    props: {
        candidacies: {
            required: true,
        },
    },
    methods: {
        
        emitUpdate(data){
            this.$emit("update", data);
        }
    },
};
</script>
