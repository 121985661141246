<template>
    <div></div>
</template>

<script>
import Redirect from "@/mixins/Redirect";

export default {
    mixins : [Redirect],
    created(){
        this.redirectHome();
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Redirection";
            }
        },
    },       
};
</script>

<style>

</style>