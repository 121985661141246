<template>
    <div
        class="fixed flex items-center justify-center h-full w-full z-50 text-right"
        style="top : 0; right : 0; left : 0; bottom : 0; background-color : rgba(10,10,10,0.3)"
    >

        <scale-loader
            :loading="loading"
            :color="color"
            :size="size"
        />

        <span class="fixed inline-block text-lg text-red-500 h-6 w-6" style="top : 15px; right : 15px;">
            <svg
                @click="stopLoading"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                class="fill-current h-10 w-10"
            >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM11.4 10l2.83-2.83-1.41-1.41L10 8.59 7.17 5.76 5.76 7.17 8.59 10l-2.83 2.83 1.41 1.41L10 11.41l2.83 2.83 1.41-1.41L11.41 10z" /></svg>
        </span>

    </div>
</template>

<script>
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
export default {
    props: {
        loading: {
            default: false
        }
    },
    data() {
        return {
            color: "#F56565",
            size: "50px"
        };
    },
    methods: {
        stopLoading() {
            this.$store.commit("loader/SET_LOADING", false);
        }
    },
    components: {
        ScaleLoader
    }
};
</script>

<style>
</style>