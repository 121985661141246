import Cookies from "js-cookie";
import auth from "@/api/auth";

const initialState =  {
    user: null,
    token: "",
    changingPromotion: false,
    promotion_id: null
    // preferences: null,
};

const state = initialState;

const getters = {
    token: (state) => {
        return state.token;
    },
    user: (state) => {
        return state.user;
    },
    changingPromotion: (state) => {
        return state.changingPromotion;
    },
    preferences : (state) => {
        if(state.user)
            return state.user.preferences;
        return null;
    },
    currentPromotion : (state) => {
        if(state.user)
            return state.user.promotions.find((promotion) => promotion.id === state.user.preferences.selected_promotion );
        return null;
    },
    getpromotion : (state) => {
        return state.promotion_id;
    }
};

const actions = {
    login({ commit }, data) {
        return auth
            .login(data)
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_TOKEN", response.data.token);
                }
                return response;
            })
            .catch((error) => {
                return error;
            });
    },

    logout({ commit }) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        Cookies.remove("token");
    },

    user({ commit }) {
        return auth
            .user()
            .then((res) => {
                if (res.status === 200) {
                    commit("SET_USER", res.data.data);
                }
                return res;
            });
    },

    update({ dispatch }, body ) {
        return auth
            .update(body)
            .then((res) => {
                if (res.status === 200) {
                    dispatch("user");
                    
                    //commit("SET_USER", res.data.data);
                }
                return res.data;
            });
    },

    updatePassword(context, body) {
        return auth
            .updatePassword(body)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                return error;
            });
    },
    clear({commit}){
        commit("CLEAR");
    },
    
    getPreferences({commit}){
        return auth
            .getPreferences()
            .then((res) => {
                commit("SET_PREFERENCES", res.data.data);
                return res;
            });
    },

    async changePromotion({ dispatch, commit }, promotionId){
        commit("CHANGING_PROMOTION", true);
        commit("loader/SET_LOADING", true, { root: true });

        await auth.setCurrentPromotion(promotionId);

        await Promise.all([
            dispatch("getPreferences"),
            dispatch("app/filters", null, {root:true}),
            dispatch("auth/user", null, {root:true}),
        ]);
        
        commit("loader/SET_LOADING", false, { root: true });
        commit("CHANGING_PROMOTION", false);
    },

    getCurrentPromotion({commit}){
        return auth
            .getCurrentPromotion()
            .then((res) => {
                commit("SET_CURRENT_PROMOTION", res.data.promotion_id);
                return res.data.promotion_id;
            });
    }
};

const mutations = {
    SET_TOKEN(state, value) {
        state.token = value;
    },
    SET_PREFERENCES(state, value){
        state.user = {...state.user, preferences : value};
    },
    SET_USER(state, value) {
        state.user = value;
    },
    CLEAR(state){
        Object.assign(state, initialState);
    },
    CHANGING_PROMOTION(state, value) {
        state.changingPromotion = value;
    },
    SET_CURRENT_PROMOTION(state, value){
        state.promotion_id = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
