import axios from "axios";

export default {
    get(params) {
        return axios.get("/candidacies", {params});
    },
    getAll(params){
        return axios.get("/candidacies/all", {params});
    },
    createFile(body) {
        return axios.post("/candidacies/files", body);
    },
    removeFile(id) {
        return axios.delete(`/candidacies/files/${id}`);
    },
    download(params) {
        return axios.get("/candidacies/download", {params});
    },
};
