<template>
  <default-modal
    v-if="open"
    @close="close"
    maxWidthClass="max-w-5xl overflow-visible max-h-screen"
  >
    <h3
      slot="header"
      class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
    >
      Détails de l'album {{ currentActionTarget.name }}
    </h3>
    <div slot="body">
      <div class="px-4">
        <carousel
          :perPage="1"
          :paginationPadding="7"
          :autoplay="true"
          :navigationEnabled="true"
          navigationNextLabel='<svg viewBox="0 0 20 20" fill="currentColor" class="arrow-narrow-right w-6 h-6"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>'
          navigationPrevLabel='<svg viewBox="0 0 20 20" fill="currentColor" class="arrow-narrow-left w-6 h-6"><path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>'
          :autoplayTimeout="3000"
        >
          <slide
            v-for="file in currentActionTarget.photos"
            :key="file.id"
          >
            <video v-if="file.is_video" width="1300" controls>
              <source :src="file.url_video" />
            </video>
            <img v-else :src="file.url_1300w" width="1300" />
          </slide>
        </carousel>
      </div>
    </div>

    <div slot="footer" class="mt-12 text-right">
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            @click="close"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Fermer
          </button>
        </span>
      </div>
    </div>
  </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import { Carousel, Slide } from "vue-carousel";
import { mapGetters } from "vuex";

export default {
    components: {
        DefaultModal,
        Carousel,
        Slide
    },

    data() {
        return {};
    },

    methods: {
        close() {
            this.$store.dispatch("photo/closeModals");
        },
    },

    computed: {
        ...mapGetters("photo", ["currentActionType", "currentActionTarget"]),
        open() {
            return (
                this.currentActionType === "slidePhoto" &&
        this.currentActionTarget != null
            );
        }
    }
};
</script>
