<template>
    <main>
        <div class="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="text-right mb-2" v-if="isBroker">
                <button
                    @click="openAddCandidate"
                    class="pb-2 pt-1 px-3  border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 inline-block fill-current align-middle"
                        viewBox="0 0 20 20"
                    >
                        <path
                            d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                        />
                    </svg>
                    <span class="align-middle ml-2">Ajouter un candidat</span>
                </button>

                <button
                    @click="downloadCandidates"
                    class="ml-3 pb-2 pt-1 px-3 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                >
                    <svg class="inline-block w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path 
                            fill-rule="evenodd"
                            d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                            clip-rule="evenodd"
                        >
                        </path
                    ></svg>
                    <span class="align-middle ml-2"
                        >Liste des candidats</span
                    >
                </button>
            </div>
            <reset-query-link
                v-if="$route.query.lot || $route.query.candidat || lotFilter || candidatFilter"
            ></reset-query-link>
            
            <candidacies-table
                :candidacies="candidacies"
                v-if="this.candidacies"
                @update="fetchData"
            />

            <attribute-lot-modal
                @update="fetchData"
            ></attribute-lot-modal>
            <add-task-modal
                @createdTask="fetchData"
            ></add-task-modal>
            <tasks-history-modal
            ></tasks-history-modal>
            <files-list-modal @update="fetchData"></files-list-modal>
            <show-task-modal
            ></show-task-modal>
            <edit-task-modal
                @update="fetchData"
            ></edit-task-modal>
            <edit-candidate-modal @update="fetchData"></edit-candidate-modal>
            <comments-list-modal
                @update="fetchData"
            ></comments-list-modal>
            <DeleteCandidateModal @delete="fetchData"></DeleteCandidateModal>
            <change-agency-modal
                @update="fetchData"
            ></change-agency-modal>
            <add-thread-modal></add-thread-modal>
        </div>
        <CandidatesContextMenu></CandidatesContextMenu>
    </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import candidacy from "@/api/candidacy";

import AttributeLotModal from "@/components/Candidates/modals/AttributeLotModal";
import AddTaskModal from "@/components/Candidates/modals/AddTaskModal";
import TasksHistoryModal from "@/components/Candidates/modals/TasksHistoryModal";
import FilesListModal from "@/components/Candidates/modals/FilesListModal";
import ShowTaskModal from "@/components/Candidates/modals/ShowTaskModal";
import EditTaskModal from "@/components/Candidates/modals/EditTaskModal";
import EditCandidateModal from "@/components/Candidates/modals/EditModal";
import CommentsListModal from "@/components/Candidates/modals/CommentsListModal";
import CandidaciesTable from "@/components/Candidacies/CandidaciesTable";
import ChangeAgencyModal from "@/components/Candidates/modals/ChangeAgencyModal";
import ResetQueryLink from "@/components/global/ResetQueryLink";
import DeleteCandidateModal from "@/components/Candidates/modals/DeleteModal";
import AddThreadModal from "@/components/Messages/modal/AddThreadModal";

import Auth from "@/mixins/Auth";
import View from "@/mixins/View";
import CandidatesContextMenu from "@/components/Candidacies/contextMenu/Menu";

export default {
    mixins: [Auth, View],

    components: {
        CandidaciesTable,
        AttributeLotModal,
        ChangeAgencyModal,
        AddTaskModal,
        ShowTaskModal,
        EditTaskModal,
        TasksHistoryModal,
        EditCandidateModal,
        FilesListModal,
        CommentsListModal,
        ResetQueryLink,
        DeleteCandidateModal,
        CandidatesContextMenu,
        AddThreadModal
    },
    data() {
        return {
            loading: true,
            currentTaskTarget: "candidate",
            candidates: [],
        };
    },

    computed: {
        ...mapState("candidate", ["currentActionTarget", "currentActionType"]),
        ...mapGetters("candidacy", ["candidacies"]),
        ...mapGetters("auth", ["preferences"]),
        currentActionTypeApplication() {
            return this.$store.getters["application/currentActionType"];
        },
        lotFilter(){
            return this.$store.state.app.filters.lot;
        },
        candidatFilter(){
            return this.$store.state.app.filters.candidat;
        }
    },

    methods: {
        fetchData(){
            this.$store.dispatch("candidacy/get");
        },

        openAddCandidate() {
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TARGET", null);
            this.$store.commit(
                "candidacy/SET_CURRENT_ACTION_TYPE",
                "create"
            );
        },
    
        downloadCandidates() {
            candidacy.download({
                promotion : this.preferences.selected_promotion
            })
                .then((res) => {
                    window.open(res.data.url, "_blank");
                });
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Candidats";
            }
        },
    }       
};
</script>
