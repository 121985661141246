<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-gray-800 pb-5 px-4"
        >
            {{ editBuilding ? "Modification de l'immeuble " + building.title : "Nouvel immeuble pour la promotion " + promotion.title}}
            <span class="font-bold text-blue-900">{{
                promotion.title
            }}</span>
        </h3>
        <div slot="body" class="text-left px-4">
            <div class="mt-3">
                <div class="text-left">
                    <label
                        for="title"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Nom</label
                    >
                    <input
                        type="text"
                        id="title"
                        v-model="building.title"
                        class="form-input w-full"
                    />
                </div>
            </div>

            <div class="mt-3">
                <div class="text-left">
                    <label
                        for="street"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Adresse</label
                    >
                    <input
                        type="text"
                        id="street"
                        v-model="building.street"
                        class="form-input w-full"
                    />
                </div>
            </div>

            <div class="mt-3 grid grid-cols-2 gap-4">
                <div class="text-left">
                    <label
                        for="city"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Ville</label
                    >
                    <input
                        type="text"
                        id="city"
                        v-model="building.city"
                        class="form-input w-full"
                    />
                </div>

                <div class="text-left">
                    <label
                        for="zip"
                        class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Code postal</label
                    >
                    <input
                        type="text"
                        id="zip"
                        v-model="building.zip"
                        class="form-input w-full"
                    />
                </div>
            </div>
        </div>

        <div slot="footer" class="mt-12 text-right px-4">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >
                        Valider
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";

export default {
    components: {
        DefaultModal,
    },

    props: {
        open: {
            type: Boolean,
            required: true
        },

        promotion: {
            type: Object,
            required: true
        },

        editBuilding: {
            type: Object,
            required: false,
        },
    },

    data() {
        return this.initialState();
    },
    computed: {
        isValid() {
            if(this.isDisabled)
                return false;
            const fields = ["title", "city"];
            for (const field of fields) {
                if ([null, ""].includes(this.building[field])) {
                    return false;
                }
            }
            return true;
        },

        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
    },
    methods: {
        initialState(){
            return  {
                building: {
                    title: "",
                    street: "",
                    city: "",
                    zip: "",
                },
                isDisabled: false,
            };
        },
        reset(){
            Object.assign(this.$data, this.initialState());
        },
        close() {
            this.$emit("close");
        },

        submit() {
            this.isDisabled = true;
            this.$emit("submit", this.building);
            this.isDisabled = false;
            this.reset();
        },
    },
    watch: {
        open(val) {
            if (val) {
                if (this.editBuilding) {
                    this.building = { 
                        ...this.editBuilding,
                    };
                }
            }
        }
    }
};
</script>
