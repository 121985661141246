<template>
	<div>
        <div class="flex flex-row justify-between pb-2">
            <div class="font-semibold text-sm text-gray-700">
                    <div>Ajouter un assistant</div>
                    <div class="text-xs text-gray-500">(Vos e-mails entrants lui seront forwardés)</div>
            </div>
            <div class="flex flex-row">
                <picture-select
                    ref="brokerSelect"
                    :searchProps="['full_name']"
                    :collection="availableBrokers"
                    :value="form.broker_id"
                    textProp="full_name"
                    imgProp="profile_picture"
                    defaultText="Sélectionner un courtier"
                    valueProp="id"
                    @change="
                        val => {
                        handleBrokerChange(val);
                        }
                    "
                    />
                <button class="bg-blue-400 hover:bg-blue-600 text-white px-2 py-1 font-medium font-semibold transition-all duration-300 hover:shadow focus:outline-none" @click="addAssistant">
                    Ajouter
                </button>
            </div>
        </div>
        <div>
            <BrokersList :brokers="assistants" :promotion="promotion" :edit="true" @remove="setAssistants"></BrokersList>
        </div>
    </div>

</template>

<script>
import {pluck} from "underscore";
import brokers from "@/api/brokers";
import BrokersList from "@/components/Brokers/BrokersList";
import PictureSelect from "@/components/Forms/PictureSelect";
import { mapGetters } from "vuex";

export default {
    components : {
        BrokersList,
        PictureSelect
    },
    props : {
        promotion : {
            type : Object,
            required : true
        }
    },
    mounted(){
        this.init();
    },
    data(){
        return this.initialState();
    },
    computed : {
        availableBrokers(){
            let assistantsIds= pluck(this.user.assistants, "id");
            return this.promotion.brokers.filter(el => {
                return assistantsIds.indexOf(el.id) < 0;
            });
        },
        ...mapGetters("auth", ["user"])
    },
    methods : {
        init(){
            this.assistants = this.user.assistants;
        },
        initialState(){
            return {
                form : {
                    broker_id : null,
                },
                assistants : []
            };
        },
        handleBrokerChange(val){
            this.broker_id = val;
        },
        emitUpdate(){
            this.$emit("update");
        },
        addAssistant(){
            this.$store.commit("loader/SET_LOADING", true);
            brokers.addAssistant(this.user.id, this.broker_id).then((res) => {
                this.$store.commit("loader/SET_LOADING", false);
                this.assistants = res.data.data;
            });
        },
        setAssistants(assistants){
            this.assistants = assistants;
        }
    }
};
</script>

<style>

</style>