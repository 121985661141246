<template>
    <div class="text-sm leading-5 relative text-gray-700 py-2" :class="{ 'cursor-pointer': ! candidate.hidden }" v-if="candidacy">
        <div class="inline-block text-gray-500 mr-1 pr-1 border-r" v-if="! candidate.hidden">
            <a
                :href="candidate.email ? `mailTo:${candidate.email}` : '#'"
                :class="{
                    'mr-1': true,
                    'text-blue-400': candidate.email,
                    'text-gray-300 cursor-not-allowed': !candidate.email,
                }"
                @click.stop
                @mouseover="showEmail"
                @mouseleave="hideEmail"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="h-4 w-4 inline-block fill-current"
                >
                    <path
                        d="M13.6 13.47A4.99 4.99 0 0 1 5 10a5 5 0 0 1 8-4V5h2v6.5a1.5 1.5 0 0 0 3 0V10a8 8 0 1 0-4.42 7.16l.9 1.79A10 10 0 1 1 20 10h-.18.17v1.5a3.5 3.5 0 0 1-6.4 1.97zM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    />
                </svg>
            </a>
            <a
                :href="candidate.phone ? `tel:${candidate.phone}` : '#'"
                :class="{
                    'mr-1': true,
                    'text-blue-400': candidate.phone,
                    'text-gray-300 cursor-not-allowed': !candidate.phone,
                }"
                @click.stop
                @mouseover="showPhone"
                @mouseleave="hidePhone"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="h-4 w-4 inline-block fill-current"
                >
                    <path
                        d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"
                    />
                </svg>
            </a>
        </div>

        <span
            @mouseover="candidate.hidden ? null : showMain()"
            @mouseleave="candidate.hidden ? null : hideMain()"
            @click="candidate.hidden ? null : openEditModal()"
            class="font-medium"
            :class="{ 'underline': !candidate.hidden }"
        >{{ candidate.full_name }}</span>
        <vip-badge
            v-if="candidacy.vip"
            class="ml-1"
            :candidacy="candidacy"
        ></vip-badge>

        <div
            class="rounded text-sm bg-white border border-gray-200 absolute text-gray-700 p-2 text-left z-50"
            v-show="showTooltip"
            style="bottom: calc(100%); left: calc(50% - 7rem)"
        >
            <ul class="mb-2">
                <li>
                    <span class="text-gray-400">Nom : </span>
                    <span class="tegt-gray-500">{{ candidate.full_name }}</span>
                </li>
                <li v-if="candidate.email">
                    <span class="text-gray-400">Email : </span>
                    <span class="tegt-gray-500">{{ candidate.email }}</span>
                </li>
                <li v-if="candidate.phone">
                    <span class="text-gray-400">Tel. : </span>
                    <span class="tegt-gray-500">{{ candidate.phone }}</span>
                </li>
            </ul>
            <span class="text-xs text-blue-600">Cliquer pour voir le candidat</span
            >
        </div>
        <div
            class="rounded text-sm bg-white border border-gray-200 absolute text-gray-700 p-2 text-left z-50"
            v-show="showPhoneTooltip"
            style="bottom: calc(100%); left: calc(50% - 7rem)"
        >
            <div>
                {{ candidate.phone }}
            </div>
            <span class="text-xs text-blue-600">Cliquer pour appeler</span>
        </div>
        <div
            class="rounded text-sm bg-white border border-gray-200 absolute text-gray-700 p-2 text-left z-50"
            v-show="showEmailTooltip"
            style="bottom: calc(100%); left: calc(50% - 7rem)"
        >
            <div>
                {{ candidate.email }}
            </div>
            <span class="text-xs text-blue-600"
                >Cliquer pour envoyer un e-mail</span
            >
        </div>
    </div>
</template>

<script>
import VIPBadge from "@/components/Candidates/VIPBadge";
export default {
    data() {
        return {
            showTooltip: false,
            showEmailTooltip: false,
            showPhoneTooltip: false,
        };
    },

    components: {
        "vip-badge": VIPBadge,
    },
    props: {
        candidacy: {
            type: Object,
            required: true,
        },
    },
    computed : {
        candidate(){
            return this.candidacy.candidate;
        }
    },
    methods: {
        showMain() {
            this.showTooltip = true;
        },
        hideMain() {
            this.showTooltip = false;
        },
        showPhone() {
            if (this.candidate.phone) this.showPhoneTooltip = true;
        },
        hidePhone() {
            this.showPhoneTooltip = false;
        },
        showEmail() {
            if (this.candidate.email) this.showEmailTooltip = true;
        },
        hideEmail() {
            this.showEmailTooltip = false;
        },
        openEditModal() {
            this.$store.commit(
                "candidacy/SET_CURRENT_ACTION_TARGET",
                this.candidacy
            );
            this.$store.commit(
                "candidacy/SET_CURRENT_ACTION_TYPE",
                "edit"
            );
        },
    },
};
</script>

<style>
</style>