<template>
    <div>
        <span
            @click="resetQuery"
            class="text-blue-600 underline text-sm cursor-pointer"
        >Un filtre est actif, cliquez ici pour le supprimer</span>
    </div>
</template>

<script>
import { resetQuery } from "@/helpers/router";

export default {
    methods: {
        resetQuery
    }
};
</script>

<style>
</style>