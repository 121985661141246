<template>
    <div
        class="inline-block mt-2 select-none rounded-lg shadow p-2"
        @click="toggle"
    >
        <span
            class="mr-2 text-gray-500 align-top"
            :class="startTextClass"
        >{{
      startText
    }}</span>
        <span
            role="checkbox"
            tabindex="0"
            aria-checked="false"
            class="group relative inline-flex items-center justify-center flex-shrink-0 h-5 w-10 cursor-pointer focus:outline-none"
        >
            <!-- On: "bg-indigo-600", Off: "bg-gray-200" -->
            <span
                aria-hidden="true"
                class="absolute inline-block h-4 w-10 mx-auto rounded-full transition-colors ease-in-out duration-200 border shadow"
                :class="firstSpanClass"
            ></span>
            <!-- On: "translate-x-5", Off: "translate-x-0" -->
            <span
                aria-hidden="true"
                class="absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform group-focus:shadow-outline group-focus:border-blue-400 transition-transform ease-in-out duration-200"
                :class="secondSpanClass"
            ></span>
        </span>
        <span
            class="mr-2 ml-2 align-top"
            :class="endTextClass"
        >{{ endText }}</span>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: ["startText", "endText"],
    data() {
        return {
            on: false
        };
    },
    methods: {
        toggle() {
            this.on = !this.on;
            this.$emit("change", this.on);
        }
    },
    computed: {
        startTextClass() {
            return this.on ? "text-gray-500" : "text-gray-700 underline";
        },
        endTextClass() {
            return this.on ? "text-gray-700 underline" : "text-gray-500";
        },
        firstSpanClass() {
            return this.on ? "bg-gray-700" : "bg-gray-200";
        },
        secondSpanClass() {
            return this.on ? "translate-x-5" : "translate-x-0";
        }
    }
};
</script>
