import files from "@/api/files";

const state = {
    types: [
        {
            value: "Document",
            label: "Document",
            title: "Document",
        },
        {
            value: "Brochure",
            label: "Brochure",
            title: "Brochure",
        },
        {
            value: "Plan",
            label: "Plan",
            title: "Plan",
        },
        {
            value: null,
            label: "Autre",
            title: "Autre",
        },
    ],
    files : null,
};

const getters = {
    types: state => state.types,
    files : state => state.files
};

const actions = {
    get({commit, rootGetters}){
        return files.get(rootGetters["app/formattedFilters"])
            .then(res => {
                commit("SET_FILES", res.data);
            });
    },
};

const mutations = {
    SET_FILES(state, value){
        state.files = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
