import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import promotion from "./modules/promotion";
import broker from "./modules/broker";
import candidate from "./modules/candidate";
import candidacy from "./modules/candidacy";
import lot from "./modules/lot";
import application from "./modules/application";
import campaign from "./modules/campaign";
import message from "./modules/message";
import global from "./modules/global";
import color from "./modules/color";
import question from "./modules/question";
import loader from "./modules/loader";
import files from "./modules/files";
import task from "./modules/task";
import photo from "./modules/photo";
import promotionFile from "./modules/promotionFile";
import reservationForm from "./modules/reservationForm";
import app from "./modules/app";
import notification from "./modules/notification";
import investor from "./modules/investor";
import pilot from "./modules/pilot";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        app,
        auth,
        promotion,
        campaign,
        candidate,
        lot,
        application,
        message,
        global,
        broker,
        color,
        loader,
        question,
        files,
        task,
        photo,
        promotionFile,
        candidacy,
        reservationForm,
        notification,
        investor,
        pilot
    },
    strict: true
});

export default store;
