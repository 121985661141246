import FormRegister from "@/views/Candidats/public/FormRegister.vue";
import Login from "@/views/Auth/Login.vue";
import Error401 from "@/views/Errors/401.vue";
import Error404 from "@/views/Errors/404";

import ResetPassword from "@/views/Auth/ResetPassword.vue";
import UpdatePassword from "@/views/Auth/UpdatePassword.vue";
import Demo from "@/views/Auth/Demo";

import { getToken, homeRouteNameFromToken } from "@/helpers/auth";

export default [
    {
        path: "/",
        name: "root",
        redirect: () => {
            const token = getToken();
            if (token) {
                // Dans ce module nous n'avons pas accès à l'utilisateur complet,
                // nous connaissons seulement le token mais nous pouvons en parsant
                // le token récupérer le type d'utilisateur et donc la route d'accueil associée.
                return { name: homeRouteNameFromToken(token) };
            } else {
                return { name: "login" };
            }
        },
    },
    {
        path: "/candidate/register/:id",
        name: "candidate-register",
        component: FormRegister,
        meta: { layout: "auth", guard: [] }
    },
    {
        path: "/demo",
        name: "demo",
        component: Demo,
        meta: { layout: "auth", guard: [] }
    },
    {
        path: "/401",
        name: "401",
        component: Error401,
        meta: { layout: "auth", guard: [] }
    },
    {
        path: "*",
        name: "notFound",
        component: Error404,
        meta: { layout: "auth", guard: [] }
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: { layout: "auth", guard: [] }
    },
    {
        path: "/password/reset",
        name: "password-reset",
        component: ResetPassword,
        meta: { layout: "auth", guard: [] }
    },
    {
        path: "/password/update/:token",
        name: "update-reset",
        component: UpdatePassword,
        meta: { layout: "auth", guard: [] }
    },
];
