<template>
    <div>
        <!-- Button for the visual period -->
        <button 
            type="button"
            @click="updateSelectedTask"
            :style="`
            width: ${task.widthInPx}px;
            `"
            :class="`truncate h-8 ${task.singleDay ? '' : 'py-1 px-2 border-2 border-transparent'} rounded text-2xs ${backgroundColorsByCategory[task.category]} ${task.tooSmall ? groupHoverBackgroundColorsByCategory[task.category] : ''} ${borderColorsByCategory[task.category]}`"
            @mouseover="show"
            @mouseleave="hide"
        >
            <span v-show="!task.tooSmall">{{ task.title }}</span>  
        </button>
        <!-- Button for the text at the right if it's too small to fit in the visual part -->
        <button type="button" @click="updateSelectedTask" @mouseover="show" @mouseleave="hide" v-show="task.tooSmall" class="text-left text-2xs text-gray-700 ml-1 group-hover:text-red-500">
            <div>{{ task.title }}</div>
            <div class="flex items-center space-x-2">
                <span>{{ format(task.dateFrom, 'dd MMMM yyyy') }}</span>
                <span v-if="! task.singleDay">—</span>
                <span v-if="! task.singleDay">{{ format(task.dateTo, 'dd MMMM yyyy') }}</span>
            </div>
        </button>  
        <div
            class="rounded text-sm bg-gray-800 opacity-75 border border-gray-200 absolute text-gray-300 p-2 text-left w-64"                                    
            v-show="tooltip && showTooltip"
            style="bottom: calc(100% + 0.5rem); left: calc(1% + 0.5rem)"
        >
            <span>{{ format(task.dateFrom, 'dd MMMM yyyy') }} - {{ format(task.dateTo, 'dd MMMM yyyy') }}</span>
        </div>    
    </div>
</template>

<script>

import format from "date-fns/format";
import { fr } from "date-fns/locale";
export default {
    mixins: [],
    props: {
        task: {
            type: Object,
            required: false,
        },
        tooltip: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            showTooltip: false,

            // Couleurs utiles pour les catégories, nous ne pouvons pas faire des classes
            // dynamiques avec TailwindCSS car sinon PurgeCSS ne peut pas fonctionner.
            backgroundColorsByCategory: {
                "Phase préalable": "bg-indigo-300",
                "Phase administrative - Autorisation de construire": "bg-yellow-300",
                "Phase commercialisation": "bg-yellow-500",
                "Phase travaux": "bg-green-400",
            },
            borderColorsByCategory: {
                "Phase préalable": "hover:border-indigo-500",
                "Phase administrative - Autorisation de construire": "hover:border-yellow-500",
                "Phase commercialisation": "hover:border-yellow-700",
                "Phase travaux": "hover:border-green-600",
            },
            groupHoverBackgroundColorsByCategory: {
                "Phase préalable": "group-hover:bg-indigo-500",
                "Phase administrative - Autorisation de construire": "group-hover:bg-yellow-500",
                "Phase commercialisation": "group-hover:bg-yellow-700",
                "Phase travaux": "group-hover:bg-green-600",
            },
        };
    },
    methods: {
        show() {
            this.showTooltip = true;
        },
        hide() {
            this.showTooltip = false;
        },
        format(date, format_string) {
            return format(date, format_string, { locale: fr });
        },    
        updateSelectedTask(){
            this.$emit("update-selected-task",this.task);
        }    
    },
    computed: {

    },
};
</script>
