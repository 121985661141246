import pilot from "@/api/pilot";

const state = {
    pilots: null
};

const getters = {
    pilots: state => {
        return state.pilots;
    }
};

const actions = {
    getAll({ commit, rootGetters}) {
        return pilot
            .get(rootGetters["app/formattedFilters"])
            .then(response => {
                if (response.status === 200) {
                    commit("SET_PILOTS", response.data);
                }
                return response;
            })
            .catch(error => {
                return error;
            });
    }
};

const mutations = {
    SET_PILOTS(state, value) {
        state.pilots = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
