<template>
    <div class="shadow rounded-lg inline-block mr-5" v-on-clickaway="close">
        <div class="relative">
            <button
                @click.prevent="toggle"
                class="rounded-lg inline-flex items-center bg-white hover:text-gray-700 focus:outline-none focus:shadow-outline text-gray-500 py-2 px-2 md:px-4 text-sm"
                :class="behaviourClass"
            >
                <span v-if="selected" class="flex items-center h-6">
                    <span
                        class="inline-flex items-center mr-2 h-5 w-5 rounded-full text-sm font-medium leading-5"
                        :class="selected[badgeColorProp]"
                        ></span
                    >
                    <span v-if="textProp">{{ selected[textProp] }}</span>
                </span>
                <span v-else>{{ defaultText }}</span>
                <svg
                    v-if="!disabled"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 ml-1"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <rect x="0" y="0" width="24" height="24" stroke="none" />
                    <polyline points="6 9 12 15 18 9" />
                </svg>
            </button>

            <div
                v-show="isOpen && !disabled"
                class="z-40 absolute top-0 left-0 w-100 bg-white rounded-lg shadow-lg mt-12 -mr-1 block py-1 overflow-hidden"
            >
                <label
                    v-if="selected != null"
                    class="flex justify-start items-center text-truncate hover:bg-gray-100 px-4 py-2 cursor-pointer"
                    @click="select(null)"
                >
                    <div
                        class="select-none text-gray-700 text-sm font-semibold"
                    >
                        {{ defaultText }}
                    </div>
                </label>
                <template v-for="(item, index) in collection">
                    <label
                        v-if="item != selected"
                        class="flex justify-start items-center text-truncate hover:bg-gray-100 px-4 py-2 cursor-pointer"
                        :key="index"
                        @click="select(item)"
                    >
                        <div>
                            <span
                                class="rounded-full h-5 w-5 inline-block mr-2 mt-2"
                                :class="item[badgeColorProp]"
                            ></span>
                        </div>
                        <div
                            class="select-none text-gray-700 text-sm inline-block"
                            v-if="textProp"
                        >
                            {{ getItemText(item) }}
                        </div>
                    </label>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import CustomSelect from "@/mixins/CustomSelect";
import { mixin as clickaway } from "vue-clickaway";
export default {
    mixins: [clickaway, CustomSelect],
    props: {
        badgeTextProp: {
            type: String,
            default: "label",
        },
        badgeColorProp: {
            type: String,
            default: "color",
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style>
</style>