<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
            v-if="currentActionTarget"
        >
            Historique des tâches réalisés sur {{ candidate.full_name }}
        </h3>
        <div slot="body">
            <div
                class="mt-2"
                v-if="hasHistory"
            >
                <p class="text-sm leading-5 text-gray-700 text-left">
                    Tâches réalisées sur le candidat
                    <span class="font-semibold">{{ currentActionTarget.full_name }}</span>
                    :
                </p>
                <div class="mt-6 align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full">
                        <thead>
                            <tr>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Date de création
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Titre
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Deadline
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Créé par
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Date de réalisation
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Réalisé par
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="bg-white"
                                v-for="action in tasks"
                                :key="action.id"
                            >
                                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600">
                                    {{ action.created_at | date_dmyhm }}
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600">
                                    {{ action.title }}
                                </td>
                                <td
                                    v-if="action.deadline_at"
                                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                                >
                                    {{ action.deadline_at | date_dmy }}
                                </td>
                                <td
                                    v-else
                                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                                >
                                    -
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600">
                                    {{
                    action.created_by
                      ? action.created_by.first_name +
                        " " +
                        action.created_by.last_name
                      : "-"
                  }}
                                </td>
                                <td
                                    v-if="action.done_at"
                                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                                >
                                    {{ action.done_at | date_dmyhm }}
                                </td>
                                <td
                                    v-else
                                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                                >
                                    -
                                </td>
                                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600">
                                    {{
                    action.done_by
                      ? action.done_by.first_name +
                        " " +
                        action.done_by.last_name
                      : "-"
                  }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                class="mt-2"
                v-else
            >
                <p
                    class="text-sm leading-5 text-gray-700 text-left"
                    v-if="currentActionTarget"
                >
                    Aucune tâche n'a encore été réalisée sur le candidat
                    <span class="font-semibold">{{ currentActionTarget.full_name }}</span>.
                </p>
            </div>
        </div>
        <div
            slot="footer"
            class="mt-12 text-right"
        >
            <button
                @click="close"
                type="button"
                class="inline-flex justify-center w-48 rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
                Fermer
            </button>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
export default {
    components: { DefaultModal },
    computed: {
        ...mapGetters("candidacy", ["currentActionTarget", "currentActionType"]),
        candidate(){
            return this.currentActionTarget.candidate;
        },
        open(){
            return this.currentActionTarget != null && this.currentActionType === "taskHistory";
        },
        applicationTasks(){
            // if opened from salesTunnel
            let applicationTarget = this.$store.getters["application/currentActionTarget"];
    
            if(applicationTarget){
                return applicationTarget.actions;
            }
            return [];
        },
        candidateApplicationTasks(){
            let tasks = [];
            if(this.currentActionTarget.applications_as_main){
                this.currentActionTarget.applications_as_main.forEach(application => {
                    application.actions.forEach(action => {
                        tasks.push(action);
                    });
                });
            }
            return tasks;
        },
        hasHistory() {
            return this.currentActionTarget ? this.tasks.length > 0 : false;
        },
        tasks() {
            let tasks = [];
            if (this.currentActionTarget.actions)
                tasks = [...this.currentActionTarget.actions];
            tasks = [...tasks, ...this.applicationTasks, ...this.candidateApplicationTasks];
            return tasks;
        }
    },
    methods: {
        close() {
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TYPE", null);
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TARGET", null);
            this.$store.commit("application/SET_CURRENT_ACTION_TARGET", null);
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        }
    }
};
</script>
