<template>
    <main class="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div v-if="isCandidate">
            <user-files v-if="isCandidate"/>
        </div>
        
        <documents class="mt-4" />
        
    </main>
</template>

<script>
import Documents from "@/components/Files/Documents";
import UserFiles from "@/components/Files/UserFiles";
import Auth from "@/mixins/Auth";
import View from "@/mixins/View";

export default {
    mixins: [Auth, View],
    components: {
        UserFiles,
        Documents,
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Documents";
            }
        },
    },
    
    methods: {
        fetchData() {

        },
    },
};
</script>

<style>
</style>