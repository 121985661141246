<template>
  <main v-if="thread">
    <div class="py-5 max-w-5xl mx-auto sm:px-6 lg:px-8 container">
      <h3 class="text-blue-900 text-center text-lg font-medium">
        {{ thread.title }}
      </h3>
      <h4 class="text-blue-900 text-center font-medium">
        {{ thread.interlocutor.full_name }}
      </h4>

      <router-link
        class="text-blue-400 flex items-center inline-block w-48 hover:underline"
        :to="{ name: 'message' }"
      >
        <svg
          class="h-4 w-4 inline-block fill-current align-middle mr-1"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        Sujets de discussion
      </router-link>
      <div v-if="thread" class="mt-6">
        <div
          class="flex items-center mb-6"
          v-for="message in thread.messages"
          :key="message.id"
        >
          <div class="w-10 self-start" v-if="message.is_from_me">
            <img
              :src="message.sender.profile_picture"
              class="rounded-full w-8 h-8 mr-auto"
            />
          </div>
          <div class="flex flex-col w-full">
            <div
              class="p-2 bg-white rounded-md shadow-md whitespace-pre-line"
              :class="{
                'bg-blue-400 text-white': message.is_from_me
              }"
            >
              <div class="text-xs font-semibold mb-1" v-if="! message.is_from_me">
                {{ message.sender.full_name }}
              </div>
              {{ message.content }}
            </div>
            <!-- Files & created_at -->
            <div class="flex flex-row justify-between">
              <div class="flex items-center text-2xs text-gray-500 mt-1 mx-2">
                {{ message.created_at | date_dmyhm }}
              </div>
              <div class="">
                <div v-for="file in message.files" :key="file.id" class="px-2 cursor-pointer">
                  <a
                    @click.prevent="download(file)"
                    target="_blank"
                    class="flex text-blue-400 hover:text-blue-600 hover:underline"
                  >
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      class="w-5 h-5 mr-1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="truncate">{{ file.name }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-10 self-start" v-if="!message.is_from_me">
            <img
              :src="message.sender.profile_picture"
              class="rounded-full w-8 h-8 ml-auto"
            />
          </div>
        </div>
        <div class="mt-6 bg-white rounded-md shadow-sm">
          <textarea
            v-model="body.content"
            id="content"
            rows="3"
            class="border p-2 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:outline-none focus:border-blue-400 rounded"
          ></textarea>
        </div>
        <div class="mt-2">
          <file-dragger
              ref="fileDragger"
              :multiple="true"
              @openEdit="editingFiles = true"
              @closeEdit="editingFiles = false"
          ></file-dragger>
        </div>
        <div class="mt-4 text-right">
          <button
            @click="submit"
            type="button"
            :disabled="!body.content"
            class="rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            :class="submitButtonClass"
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import FileDragger from "@/components/global/FileDragger";
import { mapGetters } from "vuex";
import files from "@/api/files";

import View from "@/mixins/View";

export default {
    mixins : [View],
    props: {
        candidateView: {
            default: false
        }
    },
    components : {
        FileDragger
    },

    data() {
        return {
            body : {
                content : "",
            },
            editingFiles : false
        };
    },

    computed: {
        ...mapGetters("message", ["thread"]),
        ...mapGetters("auth", ["preferences"]),
        submitButtonClass() {
            return this.body.content !== ""
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        }
    },

    methods: {
        submit() {
            
            let data = new FormData();
            data.append("content", this.body.content);
            data.append("promotion", this.preferences.selected_promotion);
            data.append("thread_id", this.$route.params.id);

            if (this.$refs.fileDragger) {
                this.$refs.fileDragger.appendToFormData(data);
            }

            this.$store
                .dispatch("message/replyToThread", data)
                .then(() => {
                    this.fetchData();
                    this.resetValues();
                });
            
            this.body.content = "";
            
        },
        resetValues(){
            this.body.content = "";
            this.editingFiles = false;
            this.$refs.fileDragger.resetValues();
        },
        handleAddFiles(event) {
            this.files = this.files.concat(Array.from(event.target.files));
        },
        removeFile(index) {
            this.files.splice(index, 1);
        },
        fetchData() {
            this.$store.dispatch("message/getThread", this.$route.params.id);
        },
        download(file){
            files.download(file);
        }
    }
};
</script>