<template>
    <div class="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="text-right mb-2">
            <button
                v-if="isBroker"
                @click="add = true"
                class="pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 inline-block fill-current align-middle"
                    viewBox="0 0 20 20"
                >
                    <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                </svg>
                <span class="align-middle ml-2">Créer un rapport</span>
            </button>
        </div>

        <div class="bg-white shadow sm:rounded-md">
            <div class="flex" style="min-height : 120px;">
                <div class="w-full">
                    <div class="align-middle min-w-full shadow sm:rounded-lg border-b border-gray-200 h-full">
                        <div class="overflow-x-auto">
                            <table class="min-w-full main--table">
                                <thead class="bg-gray-200">
                                    <tr>
                                        <th>ID</th>
                                        <th>Date de création</th>
                                        <th>Nb visites</th>
                                        <th>Nb futures visites</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white">
                                    <tr v-for="report in reports" :key="report.id">
                                        <td @click="add = true">{{ report.id }}</td>
                                        <td>{{ report.created_at | date_dmy  }}</td>
                                        <td>{{ report.number_of_visits  }}</td>
                                        <td>{{ report.number_of_future_visits }}</td>
                                        <td>
                                            <a
                                                :href="reportUrl(report)"
                                                class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-600 hover:bg-gray-100 hover:text-blue-900 focus:outline-none focus:bg-gray-100 focus:text-blue-900"
                                            >
                                                <svg
                                                    class="fill-current mr-3 h-5 w-5"
                                                    xlmns="https://www.w3.org/2000/svg"
                                                    fill="currentcolor"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path d="M9.26 13a2 2 0 0 1 .01-2.01A3 3 0 0 0 9 5H5a3 3 0 0 0 0 6h.08a6.06 6.06 0 0 0 0 2H5A5 5 0 0 1 5 3h4a5 5 0 0 1 .26 10zm1.48-6a2 2 0 0 1-.01 2.01A3 3 0 0 0 11 15h4a3 3 0 0 0 0-6h-.08a6.06 6.06 0 0 0 0-2H15a5 5 0 0 1 0 10h-4a5 5 0 0 1-.26-10z" />
                                                </svg>
                                                Télécharger
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                href='#'
                                                @click="deleteReport(report)"
                                                class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-600 hover:bg-gray-100 hover:text-blue-900 focus:outline-none focus:bg-gray-100 focus:text-blue-900"
                                            >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                            </svg>
                                                Supprimer
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <form @submit.prevent="submit">
            <default-modal
                v-if="add"
                @close="add = false"
            >
                <h3
                    slot="header"
                    class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
                >
                    Créer un rapport
                </h3>
                <div slot="body" class="w-80vw space-y-4">
                    <div>
                        <label
                            for="number_of_visits"
                            class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                        >Nombre de visites depuis le début</label>
                        <input
                            type="number"
                            id="number_of_visits"
                            v-model="newReport.number_of_visits"
                            class="form-input w-full"
                            :min="minNumberOfVisits"
                        />
                    </div>
                    <div>
                        <label
                            for="number_of_future_visits"
                            class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                        >Nombre de futures visites</label>
                        <input
                            type="number"
                            id="number_of_future_visits"
                            v-model="newReport.number_of_future_visits"
                            class="form-input w-full"
                            min="0"
                        />
                    </div>
                    <div>
                        <label
                            for="file"
                            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        >Rapport de visites (capture d'écran)</label>
                        <file-dragger
                            ref="fileDragger"
                            @openEdit="editingFiles = true"
                            @closeEdit="editingFiles = false"
                        ></file-dragger>
                    </div>
                    <div>
                        <label
                            for="feedback_positive"
                            class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                        >Feedback positif</label>
                        <input
                            type="text"
                            id="feedback_positive"
                            v-model="newReport.feedback_positive"
                            class="form-input w-full"
                        />
                    </div>
                    <div>
                        <label
                            for="feedback_todo"
                            class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                        >Feedback à prendre en considération</label>
                        <input
                            type="text"
                            id="feedback_todo"
                            v-model="newReport.feedback_todo"
                            class="form-input w-full"
                        />
                    </div>
                    <div>
                        <label
                            for="feedback_problem"
                            class="block text-sm font-medium leading-5 text-gray-700 mb-3"
                        >Feedback bloquant</label>
                        <input
                            type="text"
                            id="feedback_problem"
                            v-model="newReport.feedback_problem"
                            class="form-input w-full"
                        />
                    </div>
                    <div class="mt-2 text-left">
                        <label
                            for="include_lots"
                            class="text-sm font-medium leading-5 text-gray-700 mb-2"
                            >Inclure le tableau des réservations</label
                        >
                        <input
                            type="checkbox"
                            id="include_lots"
                            v-model="newReport.include_lots"
                            class="ml-4"
                        />
                    </div>
                    <div class="mt-2 text-left">
                        <label
                            for="include_marketing_campaigns"
                            class="text-sm font-medium leading-5 text-gray-700 mb-2"
                            >Inclure les actions marketing</label
                        >
                        <input
                            type="checkbox"
                            id="include_marketing_campaigns"
                            v-model="newReport.include_marketing_campaigns"
                            class="ml-4"
                        />
                    </div>
                </div>
                <div slot="footer" class="mt-12 text-right">
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                            <button
                                type="submit"
                                :disabled="addDisabled"
                                class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                            >
                                Valider
                            </button>
                        </span>
                        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                            <button
                                @click="add = false"
                                type="button"
                                class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                            >
                                Fermer
                            </button>
                        </span>
                    </div>
                </div>
            </default-modal>
        </form>
    </div>
</template>
<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import FileDragger from "@/components/global/FileDragger";
import { mapGetters } from "vuex";

import Auth from "@/mixins/Auth";
import View from "@/mixins/View";

import axios from "axios";

const defaultNewReport = () => {
    return {
        "number_of_visits": 0,
        "number_of_future_visits": 0,
        "visits_report": null,
        "feedback_positive": "",
        "feedback_todo": "",
        "feedback_problem": "",
        "include_lots": true,
        "include_marketing_campaigns": true,
    };
};

export default {
    mixins : [Auth, View],
    data() {
        return {
            reports: [],
            add: false,
            addDisabled: false,
            newReport: defaultNewReport(),
        };
    },
    components: {
        DefaultModal,
        FileDragger,
    },
    computed: {
        ...mapGetters("app", ["formattedFilters"]),
        minNumberOfVisits() {
            if (!this.reports.length) return 0;
            return Math.max(...this.reports.map((report) => report.number_of_visits));
        },
    },
    methods : {
        async fetchData() {
            this.reports = (await axios.get(`promotions/${this.$store.getters["auth/currentPromotion"].id}/marketing-reports`)).data;
        },
        reportUrl(report) {
            return `${axios.defaults.baseURL}/promotions/${this.$store.getters["auth/currentPromotion"].id}/marketing-reports/${report.id}`;
        },
        async deleteReport(report) {
            this.$store.commit("loader/SET_LOADING", true);
            const url = `${axios.defaults.baseURL}/promotions/${this.$store.getters["auth/currentPromotion"].id}/marketing-reports/${report.id}`;
            
            try {
                await axios.delete(url);
                const index = this.reports.findIndex(r => r.id === report.id);
                if (index !== -1) {
                    this.reports.splice(index, 1);
                }
                this.$toast.open({
                    message: "Rapport supprimé",
                    type: "success"
                });
            } catch (error) {
                console.error("There was an error deleting the report!", error);
                this.$toast.open({
                    message: "Une erreur est survenue",
                    type: "error"
                });
                throw error;
            } finally {
                this.$store.commit("loader/SET_LOADING", false);
            }
            
        },
        submit() {
            this.addDisabled = true;

            let data = new FormData();

            for (let key in this.newReport) {
                if (typeof this.newReport[key] == "boolean") {
                    data.append(key, this.newReport[key] ? 1 : 0);
                } else {
                    data.append(key, this.newReport[key]);
                }
            }

            data.append("visits_report", this.$refs.fileDragger.files[0]);

            this.$store.commit("loader/SET_LOADING", true);
            axios.post(`promotions/${this.$store.getters["auth/currentPromotion"].id}/marketing-reports`, data)
                .then((response) => {
                    this.add = false;
                    this.newReport = defaultNewReport();
                    this.reports.unshift(response.data);

                    this.$store.commit("loader/SET_LOADING", false);
                })
                .catch((err)=>{
                    console.error(err);
                    this.$store.commit("loader/SET_LOADING", false);
                    this.$toast.open({
                        message: "Une erreur est survenue",
                        type: "error"
                    });
                })
                .finally(() => {
                    this.addDisabled = false;
                });
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Marketing";
            }
        },
    },     
};
</script>