import application from "@/api/application";

import contextMenu from "../partials/contextMenu";

const state = {
    ...contextMenu.state,
    applications: null,
    count: null,
    
    tooltipApplication : null,
    tooltipEvent : null,    

    currentActionType: null,
    currentActionTarget: null,
    currentTask: null,
    priorities: [
        {
            value: 1,
            label: "Priorité 1",
            color: "bg-green-300 text-white",
        },
        {
            value: 2,
            label: "Priorité 2",
            color: "bg-orange-300 text-white",
        },
        {
            value: 3,
            label: "Priorité 3",
            color: "bg-gray-300 text-white",
        },
        {
            value: 9,
            label: "Non prioritaire",
            color: "bg-red-300 text-white",
        },
    ],
};

const getters = {
    ...contextMenu.getters,
    applications: (state) => {
        return state.applications;
    },
    currentActionType: (state) => {
        return state.currentActionType;
    },
    currentActionTarget: (state) => {
        return state.currentActionTarget;
    },
    currentTask: (state) => {
        return state.currentTask;
    },
    priorities : (state) => state.priorities,
};

const actions = {
    get({commit, rootGetters}) {
        commit("loader/SET_LOADING", true, { root: true });

        return application
            .get(rootGetters["app/formattedFilters"])
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_APPLICATIONS", response.data);
                }
                commit("loader/SET_LOADING", false, { root: true });
                return response;
            });
    },
    update({ commit }, body) {
        return application
            .update(body.id, body)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.data) {
                        commit("UPDATE_APPLICATION", response.data.data);
                    } else {
                        commit("DELETE_APPLICATION", body.id);
                    }
                }
                return response;
            })
            .catch((error) => {
                return error;
            });
    },

    markAsComplete({ commit }, body) {
        return application
            .markAsComplete(body.id, body)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.data) {
                        commit("UPDATE_APPLICATION", response.data.data);
                    }
                }
                return response;
            })
            .catch((error) => {
                return error;
            });
    },

    addAction({ commit, rootGetters }, body) {
        return application
            .createAction(rootGetters["auth/currentPromotion"].id, body)
            .then((response) => {
                if (response.status === 200) {
                    commit("ADD_APPLICATION_ACTION", response.data.data);
                }
                return response;
            })
            .catch((error) => {
                return error;
            });
    },
    updateAction({ commit, rootGetters }, body) {
        return application
            .updateAction(
                rootGetters["auth/currentPromotion"].id,
                body.action_id,
                body
            )
            .then((response) => {
                if (response.status === 200) {
                    commit("UPDATE_APPLICATION_ACTION", response.data.data);
                }
                return response;
            })
            .catch((error) => {
                return error;
            });
    },
    delete({ commit }, id) {
        return application
            .delete(id)
            .then((response) => {
                if (response.status === 200) {
                    commit("DELETE_APPLICATION", response.data.data.id);
                }
                return response;
            })
            .catch((error) => {
                return error;
            });
    },
};

const mutations = {
    ...contextMenu.mutations,
    SET_APPLICATIONS(state, value) {
        state.applications = value;
    },
    SET_COUNT(state, value){
        state.count = value;
    },
    SET_CURRENT_ACTION_TARGET(state, value) {
        state.currentActionTarget = value;
    },

    SET_CURRENT_ACTION_TYPE(state, value) {
        state.currentActionType = value;
    },

    SET_CURRENT_TASK(state, value) {
        state.currentTask = value;
    },

    SET_TOOLTIP_APPLICATION(state, value){
        state.tooltipApplication = value;
    },
    SET_TOOLTIP_EVENT(state, value){
        state.tooltipEvent = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
