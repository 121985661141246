<template>
  <main>
    <div class="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div
        class="flex flex-row-reverse justify-between mb-2"
        :class="{ 'flex-row': $route.query.lot }"
      >
        <div class="flex">
          <button
            v-if="isBroker || isPilot"
            @click="openAddAlbum"
            type="button"
            class="pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 inline-block fill-current align-middle mr-2"
              viewBox="0 0 20 20"
            >
              <path
                d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
              />
            </svg>
            <span class="align-middle">Ajouter un album</span>
          </button>
        </div>
        <reset-query-link v-if="$route.query.lot || lotFilter"></reset-query-link>
      </div>
      <div
        class="border-l-2 border-blue-400 bg-blue-100 text-blue-600 px-4 py-3 mb-2"
      >
        Vous retrouverez ici les différents albums photos de la promotion.
        <span v-if="!photoGroups">Aucun album pour cette promotion. </span>
        <span
          class="underline cursor-pointer hover:text-blue-700 transition duration-150"
          @click="openAddAlbum"
          v-if="isBroker || isPilot"
          >Créer un album</span
        >
      </div>
      <progression-table
        v-if="photoGroups"
        :photoGroups="photoGroups"
        @update="fetchData"
      ></progression-table>
      <div v-if="isDemo">
        <DemoVideo></DemoVideo>
      </div>

      <add-photo-modal
        @update="fetchData"
      ></add-photo-modal>
      <add-album-modal
        @close="openAddAlbum"
        @createdPhotoGroup="fetchData"
      ></add-album-modal>
      <edit-album-modal
        @update="fetchData"
      ></edit-album-modal>
      <show-photo-modal></show-photo-modal>
      <delete-album-modal
        @update="fetchData"
      ></delete-album-modal>
    </div>
  </main>
</template>

<script>
//import { mapGetters } from "vuex";
import ProgressionTable from "@/components/Progression/ProgressionTable";
import AddPhotoModal from "@/components/Progression/modals/AddPhotoModal";
import AddAlbumModal from "@/components/Progression/modals/AddAlbumModal";
import EditAlbumModal from "@/components/Progression/modals/EditAlbumModal";
import ShowPhotoModal from "@/components/Progression/modals/ShowPhotoModal";
import DeleteAlbumModal from "@/components/Progression/modals/DeleteAlbumModal";
import ResetQueryLink from "@/components/global/ResetQueryLink";
import DemoVideo from "@/components/Progression/DemoVideo";
import Auth from "@/mixins/Auth";
import View from "@/mixins/View";
import photo from "@/api/photos";

export default {
    mixins: [Auth, View],

    components: {
        ProgressionTable,
        AddPhotoModal,
        AddAlbumModal,
        EditAlbumModal,
        ShowPhotoModal,
        DeleteAlbumModal,
        ResetQueryLink,
        DemoVideo
    },

    data() {
        return {
            photoGroups: null,
            photoGroupToAddPhoto: null,
            editPhotoGroup: null,
            deleteAlbum: null,
            modals: {
                addPhoto: false,
                addAlbum: false,
                openPhoto: null,
                deleteAlbum: false,
                editPhotoGroup: false
            }
        };
    },
    computed: {
        lotFilter(){
            return this.$store.state.app.filters.lot;
        }
    },

    methods: {
        openAddAlbum() {
            this.$store.commit("photo/SET_CURRENT_ACTION_TYPE", "addAlbum");
        },
        openEditAlbum(album) {
            this.$store.commit("photo/SET_CURRENT_ACTION_TYPE", "editAlbum");
            this.$store.commit("photo/SET_CURRENT_ACTION_TARGET", album);
        },
        openPhoto(photo) {
            this.modals.openPhoto = photo;
        },
        openDeleteAlbumModal(album) {
            this.$store.commit("photo/SET_CURRENT_ACTION_TYPE", "deleteAlbum");
            this.$store.commit("photo/SET_CURRENT_ACTION_TARGET", album);
        },
        addPhoto(album) {
            this.$store.commit("photo/SET_CURRENT_ACTION_TYPE", "addPhoto");
            this.$store.commit("photo/SET_CURRENT_ACTION_TARGET", album);
        },
        closeOpenPhoto() {
            this.modals.openPhoto = false;
        },
        closeDeleteAlbum() {
            this.modals.deleteAlbum = false;
            this.deleteAlbum = null;
        },
        fetchData() {
            //this.$store.dispatch("photo/getPhotoGroups");
            photo.getPhotoGroups(this.formattedFilters).then(res => {
                this.photoGroups = res.data.data;
            });
        }
    },
};
</script>
