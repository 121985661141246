<template>
    <default-modal
        v-if="open"
        @close="close"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Ajouter un partenaire à {{ currentActionTarget.candidacy1.candidate.full_name }}
        </h3>
        <div slot="body">
            
            <div class="mt-4 text-left">
                <picture-select
                    ref="candidacySelect"
                    :disabled="!(all_candidacies && all_candidacies.length > 0)"
                    :searchProps="['candidate.full_name']"
                    :collection="filteredCandidacies"
                    textProp="candidate.full_name"
                    imgProp="candidate.profile_picture"
                    defaultText="Candidats"
                    valueProp="id"
                    @change="
                    val => {
                        updateSelectedCandidate(val);                        
                    }
                    "
                />
            </div>
        </div>
        
        <div slot="footer" class="mt-12 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-transparent px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5 text-white"
                        :class="submitButtonClass"
                        :disabled="! isValid"
                    >
                        Valider
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import PictureSelect from "@/components/Forms/PictureSelect";

export default {
    components: {
        DefaultModal, 
        PictureSelect,
    },
    data() {
        return {
            application : null,
            candidacy_2_id : null,
            isDisabled: false,
        };
    },

    computed: {
        ...mapState("candidacy", ["all_candidacies"]),
        ...mapState("application", ["currentActionTarget", "currentActionType"]),
        ...mapGetters("auth", ["user","preferences", "currentPromotion"]),

        open(){
            return this.currentActionTarget && (this.currentActionType === "addCandidacy");
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
        filteredCandidacies() {
            if(this.all_candidacies)
                return this.all_candidacies.filter((x) => (x.id != this.currentActionTarget.candidacy1.id && (this.user.agency.id != 1 || this.currentPromotion.crm_updated_at == null || x.crm_validated)));
            return [];
        },
        isValid(){
            if(this.isDisabled)
                return false;
            return this.candidacy_2_id;
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.application = this.currentActionTarget;
        },
        close() {
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },
        getData() {
            this.$store.dispatch("candidacy/getAll");
        },
        updateSelectedCandidate(val) {
            this.candidacy_2_id= val;
        },
        submit(){
            this.isDisabled = true;
            const command = {
                name : "addCandidacy", 
                params : {
                    entity : "application",
                    entity_id : this.currentActionTarget.id,
                    additionnal_fields : {
                        promotion_id : this.preferences.selected_promotion,
                        candidacy_2_id : this.candidacy_2_id
                    }
                }};

            this.$store.dispatch("app/executeCommand",command).then(() =>{
                this.close();
                this.$emit("update");
            }).finally(()=>{
                this.isDisabled = false;
            });
        }
    },
    watch: {
        currentActionTarget(newVal, oldVal) {
            if (newVal !== null) {
                if (newVal !== oldVal) {
                    this.init();
                }
            } 
        },
        open(){
            this.getData();
        }
    },
};
</script>
