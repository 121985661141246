<template>
    <tr
        @contextmenu.prevent="e => openCandidateContextMenu(e)"
    >

        <td v-if="user.agency.id == 1">
            {{candidacy.crm_validated ? "Oui" : ""}}
        </td>
        <td style="padding: 0 0 0 2px !important">
            <div>
                <img
                    v-if="candidacy.broker"
                    class="h-8 w-8 rounded-full inline-block max-w-none"
                    :src="candidacy.broker.profile_picture"
                    :title="candidacy.broker.full_name"
                />
            </div>
        </td>
        <td style="padding : 5px 3px !important">
            <div class="flex items-center">
                <div class="text-left">
                    <candidate-badge                        
                        :candidacy="candidacy"
                        :canClick="false"
                    ></candidate-badge>
                </div>
            </div>
        </td>
        <td style="padding-left : 3px !important; padding-right: 3px !important;">
            <priority-badge
                :priority="parseInt(candidacy.priority)"
            ></priority-badge>
        </td>
        <td style="padding-left : 3px !important; padding-right: 3px !important;">
            <status-badge :status="candidacy.status_text"></status-badge>
        </td>
        <td style="padding-left : 3px !important; padding-right: 3px !important;">
            <div
                :class="{
                    'grid-cols-2 grid': candidacy.applications.length > 1,
                }"
            >
                <div
                    class="text-center py-1"
                    v-for="(application, index) in candidacy.applications"
                    :key="index"
                >
                    <application-priority-badge
                        :application="application"
                        :lot="application.lot"
                        :tooltip="true"
                        :click="true"
                    />
                </div>
            </div>
        </td>
        <td style="padding-left : 3px !important; padding-right: 3px !important;">
            <span class="text-xs"> {{ candidacy.created_at | date_dmyhm }} </span>
        </td>
        <td>
            {{ candidacy.budget ? formatCHF(candidacy.budget) : "-" }}
        </td>
        <td style="padding-left : 3px !important; padding-right: 3px !important;">
            <div class="text-xs leading-5 text-gray-500" v-if="candidacy.candidate.email">
                <a :href="`mailto:${candidacy.candidate.email}`">{{ candidacy.candidate.email }}</a>
            </div>
        </td>
        <td style="padding-left : 3px !important; padding-right: 3px !important;">
            <div>
                <a class="text-xs" :href="`tel:${candidacy.candidate.phone}`">{{
                    candidacy.candidate.phone
                }}</a>
            </div>
        </td>

         <td
            class="whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 font-medium"
            style="padding : 2px !important;"
        >
            <div
                class="grid gap-1"
                :class="`grid-rows-${actions.length}`"
            >
                <action
                    v-for="task in actions"
                    :key="task.id"
                    :task="task"
                    taskTargetType="candidacy"
                    @update="emitUpdate"
                ></action>

                <router-link
                    v-tooltip="'Voir dans le tunnel de vente'"
                    :to="{
                        name: 'tunnel-de-vente',
                        query: { candidacy: candidacy.id },
                    }"
                    v-if="applicationTasks && applicationTasks.length"
                    class="flex items-start m-auto"
                >
                    <div
                        class="badge text-blue-600 bg-white py-1 px-2 rounded shadow mx-1"
                    >
                        <div class="h-6 inline-block align-top">
                            +{{ applicationTasks.length }}
                            {{
                                applicationTasks.length > 1 ? "tâches" : "tâche"
                            }}
                            candidature
                        </div>
                    </div>
                </router-link>
            </div>
        </td>
        <td style="padding : 5px 3px !important">
            <div class="flex">
                <span
                    v-if="hasComments"
                    :title="`${candidacy.user_comments.length} ${
                        candidacy.user_comments.length > 1
                            ? 'commentaires'
                            : 'commentaire'
                    }`"
                >
                    <svg
                        @click="clickAction('showComments')"
                        class="w-6 h-6 fill-current mx-1 text-yellow-400 hover:text-yellow-500 cursor-pointer"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </span>
                <span
                    v-if="candidacy.files && candidacy.files.length"
                    :title="`${candidacy.files.length} ${
                        candidacy.files.length > 1 ? 'documents' : 'document'
                    }`"
                >
                    <svg
                        @click="clickAction('showFiles')"
                        class="w-6 h-6 fill-current mx-1 text-gray-400 hover:text-gray-600 cursor-pointer"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"
                        />
                        <path
                            d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"
                        />
                    </svg>
                </span>

                <span
                    v-if="
                        candidacy.unread_messages_by &&
                        candidacy.unread_messages_by.length
                    "
                    :title="`${candidacy.unread_messages_by.length} message(s) non lu(s)`"
                >
                    <router-link
                        :to="{
                            name: 'message',
                            query: { candidacy: candidacy.id },
                        }"
                    >
                        <svg
                            @click="clickAction('showFiles')"
                            class="w-5 h-5 fill-current mx-1 text-red-500 hover:text-red-600 cursor-pointer"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z"
                            />
                        </svg>
                    </router-link>
                </span>
            </div>
        </td>        
    </tr>
</template>

<script>
// import CandidatesRowActions from "@/components/Candidates/CandidatesRowActions";
// import CandidatesRowActionsRightClick from "@/components/Candidates/CandidatesRowActionsRightClick";
import Action from "@/components/Actions/Action";
// import VIPBadge from "@/components/Candidates/VIPBadge";
import { formatCHF } from "@/helpers/formatter";
import StatusBadge from "@/components/Candidates/StatusBadge";
import PriorityBadge from "@/components/Candidates/PriorityBadge";
// import CandidatePriorityBadge from "@/components/Candidates/CandidatePriorityBadge";
import ApplicationPriorityBadge from "@/components/Candidates/ApplicationPriorityBadge";
import CandidateBadge from "@/components/Candidates/CandidateBadge";
//import { mapGetters } from "vuex";
import Phone from "@/mixins/Phone";
import {VTooltip} from "v-tooltip";
import { mapState } from "vuex";

export default {
    mixins: [Phone],
    data() {
        return {
            openContextMenu: false,
            contextMenuX: null,
            contextMenuY: null,
        };
    },
    directives: {
        tooltip: VTooltip,
    },
    components: {
        Action,
        StatusBadge,
        PriorityBadge,
        ApplicationPriorityBadge,
        CandidateBadge
    },
    props: {
        candidacy: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState("auth", ["user"]),
        hasComments(){
            return this.candidacy.user_comments && this.candidacy.user_comments.length;
        },
        hasApplications() {
            return this.candidacy.applications.length > 0;
        },
        tasks() {
            if (!this.candidacy.actions) return [];
            return this.candidacy.actions.filter(
                (task) => task.done_at == null
            );
        },
        actions() {
            if (!this.candidacy.actions) return [];
            return this.candidacy.actions.filter(
                (task) => task.done_at == null
            );
        },
        applicationTasks() {
            var applicationTasks = [];

            if (this.candidacy.applications.length) {
                for (var i in this.candidacy.applications) {
                    let currentApplication = this.candidacy.applications[i];
                    if(currentApplication.actions){
                        applicationTasks = applicationTasks.concat(
                            currentApplication.actions.filter((task) => {
                                return task.done_at == null;
                            })
                        );
                    }
                }
            }
            return applicationTasks;
        },
    },

    methods: {
        formatCHF,
        clickAction(action) {
            this.$store.commit(
                "candidacy/SET_CURRENT_ACTION_TARGET",
                this.candidacy
            );
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TYPE", action);
        },
        emitUpdate(data){
            this.$emit("update", data);
        },
        openCandidateContextMenu(event){
            this.$store.commit("candidacy/SET_CONTEXT_EVENT", event);
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TARGET", this.candidacy);
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TYPE", "context");
        },
        closeContextMenu() {
            this.$store.commit("candidacy/SET_CONTEXT_EVENT", null);
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TARGET", null);
            this.$store.commit("candidacy/SET_CURRENT_ACTION_TYPE", null);
        },
    },
};
</script>
