const state = {
    currentActionTarget : null,
    currentActionType : null,
    
};

const getters = {
    currentActionTarget : (state) => state.currentActionTarget,
    currentActionType : (state) => state.currentActionType,
};

const mutations = {
    SET_CURRENT_ACTION_TARGET(state, value){
        state.currentActionTarget = value;
    },
    SET_CURRENT_ACTION_TYPE(state, value){
        state.currentActionType = value;
    },
};


export default {
    state,
    getters,
    mutations
};
