<template>
    <Ctx ref="ctx" v-if="active" :active="active" @close="close" targetType="application">
        <Column v-show="displayColumn"></Column>
        <CandidaciesColumn
            v-if="mainCandidate"
            :candidacy="currentActionTarget.candidacy1"
            :rules="rules"
            @close="close"
        >
        </CandidaciesColumn>
        <CandidaciesColumn
            v-if="secondCandidate"
            :candidacy="currentActionTarget.candidacy2"
            :rules="{...rules, ...rulesSecondCandidate}"
            @close="close"
        >
        </CandidaciesColumn>
    </Ctx>
</template>

<script>
import Ctx from "@/components/global/ContextMenu";
import Column from "./Column";
import CandidaciesColumn from "@/components/Candidacies/contextMenu/Column";
import {mapState} from "vuex";

import Auth from "@/mixins/Auth";

export default {
    mixins: [Auth],
    components : {
        Ctx,
        Column,
        CandidaciesColumn
    },
    data(){
        return {
            rules : {
                add_to_promotion : {
                    show : () => false,
                },
                change_agency : {
                    show : () => false,
                },
                attribute_lot : {
                    show : () => false,
                }
            },
            rulesSecondCandidate : {
                fiche_reservation : {
                    show : () => false,
                },
            }
        };
    },
    computed: {
        ...mapState("application", ["currentActionTarget", "currentActionType", "contextEvent"]),
        active(){
            return this.currentActionTarget != null && this.currentActionType == "context" && this.contextEvent != null;
        },
        mainCandidate(){
            return this.currentActionTarget.candidacy1.candidate;
        },
        secondCandidate(){
            if(this.currentActionTarget.candidacy2){
                return this.currentActionTarget.candidacy2.candidate;
            }
            return null;
        },
        displayColumn() {
            return this.isArchitect
                ? false
                : true;
        },
    },
    methods : {
        close(){
            this.$store.commit("application/SET_CONTEXT_EVENT", null);
            // this.$store.commit("application/SET_CURRENT_ACTION_TARGET", null);
        }
    }
};
</script>

<style></style>
