import DefaultModal from "@/components/global/modals/DefaultModal";
import { mapState } from "vuex";
export default{
    components : {
        DefaultModal,
    },
    data(){
        return {
			
        };
    },
    computed: {
        ...mapState("application", ["currentActionTarget"])
    },

    methods : {
        close() {
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
            this.$emit("close");
        }
    },
};