<template>
  <div class="h-40 mr-4 mb-4" @click="openSlider">
    <video v-if="photo.is_video" class="h-40" controls>
        <source :src="photo.url_video">
    </video>
    <img v-else :src="photo.url_160h" class="h-full" />
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    mixins: [clickaway],

    data() {
        return {
            isOpen: false
        };
    },

    components: {},

    props: {
        photo: {
            type: Object,
            required: true
        }
    },

    methods: {
        openSlider() {
            this.$emit("openSlider");
        }
    }
};
</script>
