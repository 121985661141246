<template>
    <main>
        <div class="py-5 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="grid md:grid-cols-8 gap-4">
                <div class="lg:col-span-2 md:col-span-3">
                    <promotion-header
                        v-if="currentPromotion"
                    ></promotion-header>
                    <broker-card
                        v-if="currentPromotion"
                        class="mt-4"
                    ></broker-card>
                    <brochure-card
                        v-if="currentPromotion"
                        class="mt-4 md:mt-6"
                    ></brochure-card>
                </div>
                <div class="lg:col-span-6 md:col-span-5 overflow-auto">
                    <carousel></carousel>
                </div>
            </div>
        </div>
        <!-- <progression-steps></progression-steps> -->
    </main>
</template>

<script>
import PromotionHeader from "@/components/Promotion/PromotionHeader";
import BrokerCard from "@/components/Brokers/Card";
import { mapGetters } from "vuex";
import BrochureCard from "@/components/Home/candidate/BrochureCard";
import Carousel from "@/components/Home/candidate/Carousel";
// import ProgressionSteps from "@/components/Candidates/ProgressionSteps";
import View from "@/mixins/View";

export default {
    mixins:[View],
    components: {
        PromotionHeader,
        BrokerCard,
        BrochureCard,
        Carousel
        // ProgressionSteps
    },
    computed: {
        ...mapGetters("auth", ["currentPromotion"])
    },
    methods:{
        fetchData(){
            this.$store.dispatch("photo/getPhotoGroups");
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Accueil";
            }
        },
    },      
};
</script>

<style>
</style>