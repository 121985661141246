import axios from "axios";

export default {
    get(params) {
        return axios.get("lots", {params});
    },
    candidateGet(params) {
        return axios.get("/candidate/lots", {params});
    },
    notaryGet(promotionId) {
        return axios.get(`/notaries/${promotionId}/lots`);
    },
    getOnePublic(id) {
        return axios.get(`lots/public/${id}`);
    },
    update(id, body) {
        return axios.put(`lots/${id}`, body);
    },
    createAction(promotionId, body) {
        return axios.post(`/promotions/${promotionId}/lots/actions`, body);
    },
    updateAction(promotionId, id, body) {
        return axios.put(`/promotions/${promotionId}/lots/actions/${id}`, body);
    },
    getTypes(){
        return axios.get("lots_types");
    },
    getFloors(){
        return axios.get("lots_floors");
    },
    download(params) {
        return axios.get("/lots/download", {params});
    },
};
