<template>
    <div class="flex flex-col space-y-2" v-if="threads && threads.data.length">
        <Thread
            v-for="thread in threads.data"
            :key="thread.id"
            :thread="thread"
        ></Thread>
        <the-pagination
            v-if="threads"
            :lastPage="threads.last_page"
            :currentPage="threads.current_page"
            :from="threads.from"
            :to="threads.to"
            :total="threads.total"
        ></the-pagination>
    </div>
</template>

<script>
import Thread from "@/components/Messages/Thread";
import ThePagination from "@/components/global/ThePagination";

export default {
    components: {
        Thread,
        ThePagination
    },

    props: ["threads"],
};
</script>