<template>
  <default-modal
    v-if="open"
    @close="close"
    maxWidthClass="max-w-3xl overflow-visible"
  >
    <h3
      slot="header"
      class="text-left text-xl leading-6 font-medium text-gray-800 pb-5 px-4"
    >
      {{ editLot ? "Modification du lot " + editLot.title : "Nouveau lot pour la promotion " + promotion.title}}
    </h3>
    <div slot="body" class="text-left px-4">
      <div v-if="parentLot">
        <p class="text-gray-800">Lot associé au lot {{ parentLot.title }}.</p>
      </div>
      <div class="mt-3">
        <div class="text-left">
          <label
            for="type"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Type</label
          >
          <div class="inline-block relative w-full">
            <select
              v-model="lot.lot_type_id"
              class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 pl-4 py-2 pr-8 rounded shadow-sm leading-tight focus:outline-none focus:shadow-outline"
            >
              <option
                v-for="type in types"
                :key="type.id"
                :value="type.id"
                >{{ type.title }}</option
              >
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 grid grid-cols-2 gap-4">
        <!-- etage -->
        <div class="text-left">
          <label
            for="floor"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
          >
            Etage
          </label>
           <div class="inline-block relative w-full">
            <select
              v-model="lot.floor_id"
              class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 pl-4 py-2 pr-8 rounded shadow-sm leading-tight focus:outline-none focus:shadow-outline"
            >
              <option
                v-for="floor in floors"
                :key="floor.id"
                :value="floor.id"
                >{{ floor.title }}</option
              >
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <!-- pieces -->
        <div class="text-left">
           <label
            for="nb_rooms"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
          >
            Nb. Pièces
          </label>
          <input
            type="number"
            min="1"
            step=".5"
            id="nb_rooms"
            v-model="lot.nb_rooms"
            class="form-input w-full"
          />
        </div>
      </div>

      <div class="mt-3 grid grid-cols-2 gap-4">
        <div class="mt-3">
          <div class="text-left">
            <label
              for="reference"
              class="block text-sm font-medium leading-5 text-gray-700 mb-2"
              >Reference</label
            >
            <input
              type="text"
              id="reference"
              v-model="lot.reference"
              class="form-input w-full"
            />
          </div>
        </div>
        <div class="mt-3">
          <div class="text-left">
            <label
              for="name_child"
              class="block text-sm font-medium leading-5 text-gray-700 mb-2"
              >Nom</label
            >
            <input
              :disabled="!lot.parent_id"
              type="text"
              id="name_child"
              v-model="lot.name_child"
              class="form-input w-full"
            />
          </div>
        </div>
      </div>


      <div class="mt-3">
        <div class="text-left">
          <label
            for="price"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Prix (CHF)</label
          >
          <input
            type="number"
            id="title"
            v-model.number="lot.raw_price"
            class="form-input w-full"
          />
        </div>
      </div>

      <div class="mt-3 grid grid-cols-2 gap-4">
        <div class="text-left">
          <label
            for="area_ppe"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >{{ surfacePPETitle }}</label
          >
          <input
            type="number"
            id="area_ppe"
            v-model.number="lot.area_ppe"
            class="form-input w-full"
          />
        </div>

        <div class="text-left">
          <label
            for="exposure"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Exposition</label
          >
          <select v-model.trim="lot.exposure" id="exposure" title="exposure" name="exposure" class="w-full" >
            <option value="Nord">Nord</option>
            <option value="Sud">Sud</option>
            <option value="Est">Est</option>
            <option value="Ouest">Ouest</option>
            <option value="Nord-Est">Nord-Est</option>
            <option value="Nord-Ouest">Nord-Ouest</option>
            <option value="Sud-Est">Sud-Est</option>
            <option value="Sud-Ouest">Sud-Ouest</option>
          </select>
        </div>
      </div>

      <div class="mt-3 grid grid-cols-2 gap-4">
        <div class="text-left">
          <label
            for="area_hab"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >{{ surfaceHabitableTitle }}</label
          >
          <input
            type="number"
            id="area_hab"
            v-model.number="lot.area_hab"
            class="form-input w-full"
          />
        </div>

        <div class="text-left">
          <label
            for="area_jar"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Surface jardin</label
          >
          <input
            type="number"
            id="area_jar"
            v-model.number="lot.area_jar"
            class="form-input w-full"
            :disabled="isParking"
          />
        </div>
      </div>

      <div class="mt-3 grid grid-cols-2 gap-4">
        <div class="text-left">
          <label
            for="area_bal"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Surface balcon</label
          >
          <input
            type="number"
            id="area_bal"
            v-model.number="lot.area_bal"
            class="form-input w-full"
            :disabled="isParking"
          />
        </div>

        <div class="text-left">
          <label
            for="area_ter"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Surface terrasse</label
          >
          <input
            type="number"
            id="area_ter"
            v-model.number="lot.area_ter"
            class="form-input w-full"
            :disabled="isParking"
          />
        </div>
      </div>

      <!-- PPE -->
      <div class="mt-3 grid grid-cols-2 gap-4">
        <div class="text-left">
          <label
            for="lot_ppe"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Lot PPE</label
          >
          <input
            type="text"
            id="lot_ppe"
            v-model.trim="lot.lot_ppe"
            class="form-input w-full"
          />
        </div>

        <div class="text-left">
          <label
            for="feuillet_ppe"
            class="block text-sm font-medium leading-5 text-gray-700 mb-2"
            >Feuillet PPE</label
          >
          <input
            type="text"
            id="feuillet_ppe"
            v-model.trim="lot.feuillet_ppe"
            class="form-input w-full"
          />
        </div>
      </div>
    </div>

    <div slot="footer" class="mt-12 text-right px-4">
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            @click="submit"
            type="button"
            :disabled="!isValid"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            :class="submitButtonClass"
          >
            Valider
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            @click="close"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Fermer
          </button>
        </span>
      </div>
    </div>
  </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import {mapGetters} from "vuex";
export default {
    components: {
        DefaultModal
    },

    props: {
        open: {
            type: Boolean,
            required: true
        },

        promotion: {
            type: Object,
            required: true
        },

        building: {
            type: Object,
            required: true
        },

        editLot: {
            type: Object,
            required: false
        },

        parentLot: {
            type: Object,
            required: false
        },
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters("lot", ["floors","types"]),
        isValid() {
            if(this.isDisabled)
                return false;
            return this.lot.floor_id && this.lot.lot_type_id && this.lot.reference && this.lot.raw_price >= 0 ? true : false;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
        surfacePPETitle(){
            let villa_id = this.types.find(function(e) {
                if(e.title == "Villa"){
                    return e;
                } 
            }).id;
            return this.lot.lot_type_id == villa_id ? "Surface SPB" : "Surface PPE";            
        },
        isParking(){
            return this.lot.lot_type_id < 5;
        },
        surfaceHabitableTitle(){
            let villa_id = this.types.find(function(e) {
                if(e.title == "Villa"){
                    return e;
                } 
            }).id;
            let appart_id = this.types.find(function(e) {
                if(e.title == "Appartement"){
                    return e;
                } 
            }).id;
            switch (this.lot.lot_type_id) {
                case villa_id:
                    return "Surface utile";
                case appart_id:
                    return "Surface pondérée";
                default:
                    return "Surface habitable";
            }
        },
    },

    methods: {
        initialState(){
            this.$store.dispatch("lot/getFloors");
            this.$store.dispatch("lot/getTypes");
            return {
                lot: {
                    price: 0,
                    reference: "",
                    lot_type_id: 0,
                    floor_id: 0,
                    nb_rooms: 1,
                    area_hab: 0,
                    area_jar: 0,
                    area_bal: 0,
                    area_ter: 0,
                    lot_ppe : "",
                    feuillet_ppe : "",
                },
                isDisabled: false,
            };
        },
        close() {
            this.$emit("close");
        },
        reset(){
            Object.assign(this.$data, this.initialState());
        },
        submit() {
            this.isDisabled = true;
            this.$emit("submit", this.lot);
            this.isDisabled = false;
            this.reset();
        }
    },

    watch: {
        open(val) {
            if (val) {
                if (this.editLot) {
                    this.lot = { 
                        ...this.editLot,
                    };
                }
                if (this.parentLot) {
                    this.lot.parent_id = this.parentLot.id;
                }
            }
        }
    }
};
</script>
