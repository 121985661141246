<template>
    <div>
        <h2
            class="font-semibold text-center bg-blue-400 text-white shadow py-1"
        >
            Sujets de discussions
        </h2>
        <div class="p-2" v-if="threads.length">
            <Thread
                v-for="thread in threads"
                :key="thread.id"
                :thread="thread"
            ></Thread>  
        </div>
        <div v-else class="flex justify-center items-center font-medium text-gray-500 text-sm py-2">
            Aucun sujet de discussion
        </div>
        <!--<div class="flex justify-end px-2">
            <button 
                class="rounded-sm font-medium text-sm shadow-sm focus:outline-none text-white px-2 py-1 bg-blue-400"
                @click="handleNewDiscussionClick"
            >
                Nouveau sujet de discussion
            </button>
        </div>-->
    </div>
</template>

<script>
import Thread from "@/components/Messages/Thread";

export default {
    components: {
        Thread
    },
    data(){
        return {
            openChangePromotionConfirm : false,
        };
    },
    computed: {
        candidate() {
            return this.$parent.candidate;
        },
        currentPromotion(){
            return this.$store.getters["auth/currentPromotion"];
        },
        threads(){
            return this.candidate.threads_by.concat(this.candidate.threads_to);
        }
    },
    methods : {
        openCreateDiscussion(){
            this.$router.push({name: "message",query: { candidate: this.candidate.id, openModal: true },});
        },
        handleNewDiscussionClick(){
            if(this.candidate.promotion_id != this.currentPromotion.id)
                this.$store.commit("message/SET_CURRENT_ACTION_TYPE", "changePromotion");
            else
                this.openCreateDiscussion();
        },
    }
};
</script>

<style>
</style>