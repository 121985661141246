<template>
	<div class="flex flex-col items-center justify-center py-4">
		<h3 class="text-blue-900 text-lg font-medium text-center">Avancement des travaux</h3>
		<div class="rounded-md overflow-hidden shadow-lg border-2 border-white flex justify-center w-90vw md:w-1/2">
			<iframe class="w-full" height="500" src="https://www.youtube-nocookie.com/embed/D7BHAAWKgQg?mute=1&controls=0&rel=0" title="Demo avancement travaux" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>
	</div>
</template>

<script>
export default {

};
</script>

<style>

</style>