import Auth from "@/mixins/Auth";
import { mapGetters } from "vuex";

export default {
    mixins: [Auth],

    props: {
        task: {
            type: Object,
            required: true
        },
        taskTargetType: {
            type: String,
            required : true
        }
    },
    data() {
        return {
            positionSubAction: null,
            isOpen: false
        };
    },
    computed: {
        ...mapGetters("task", ["targetType", "target", "entityName", "currentActionTarget"]),
        subActions() {
            //defining global actions
            var actions = [{
                label: "Afficher les informations",
                icon: "<path d=\"M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z\"/>",
                click : () => {
                    this.$store.commit("task/SET_TARGET_TYPE", this.taskTargetType);
                    this.$store.commit("task/SET_TARGET", null);
                    this.$store.commit("task/SET_CURRENT_ACTION_TARGET", this.task);
                    this.$store.commit("task/SET_CURRENT_ACTION_TYPE", "showTask");
                }
            }];
            
            if (!this.task.automatic_end && this.task.is_editable) {
                actions.push({
                    label: "Marquer comme terminé",
                    action: "markAsFinish",
                    icon: "<path d=\"M0 11l2-2 5 5L18 3l2 2L7 18z\"/>",
                    color: "text-green-400 hover:text-green-400",
                    click : () => {
                        this.markAsFinished();
                    }
                });
                actions.push({
                    label: "Modifier",
                    action: "editTask",
                    icon: "<path d=\"M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z\"/>",
                    click : () => {
                        this.$store.commit("task/SET_TARGET_TYPE", this.taskTargetType);
                        this.$store.commit("task/SET_TARGET", null);
                        this.$store.commit("task/SET_CURRENT_ACTION_TARGET", this.task);
                        this.$store.commit("task/SET_CURRENT_ACTION_TYPE", "editTask");
                    }
                });
            }
            return actions;
        },
        backgroundColor() {

            switch (this.task.time_status) {
                case "Aujourd'hui":
                    return "bg-green-400";
                case "Prochainement":
                    return "bg-gray-500";
                case "En retard":
                    return "bg-red-400";
                default:
                    return "";
            }
        },
        backgroundColorDark() {
            switch (this.task.time_status) {
                case "Aujourd'hui":
                    return "bg-green-500";
                case "Prochainement":
                    return "bg-gray-500";
                case "En retard":
                    return "bg-red-500";
                default:
                    return "";
            }
        },
        color() {
            switch (this.task.time_status) {
                case "Aujourd'hui":
                    return "text-green-600";
                case "Prochainement":
                    return "text-gray-600";
                case "En retard":
                    return "text-red-600";
                default:
                    return "";
            }
        },
    },
    methods: {
        open(evt){
            this.isOpen = !this.isOpen;
            let transform = "";
            
            this.$nextTick(() => {
                if(evt.clientX > (window.innerWidth-this.$refs.subActionRef.clientWidth)){
                    transform = "translateX(-100%)";
                }
                if(evt.clientY > (window.innerHeight-this.$refs.subActionRef.clientHeight)){
                    transform += "translateY(-100%)";
                }
                this.positionSubAction = {
                    top : `${evt.clientY}px`,
                    left : `${evt.clientX}px`,
                    transform: transform,
                };
            });
        },
        close(){
            this.isOpen = false;
        },
        markAsFinished(){
            const command = {
                name : "markActionAsFinished",
                params : {
                    entity : "action",
                    entity_id : this.task.id
                }
            };
            this.$store.dispatch("app/executeCommand", command).then(() => {
                this.$emit("update");
                this.close();
            });
        }
    }
};
