Q`<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Modifier le prix négocié de
            {{ mainCandidate.full_name }} sur le lot
            {{ currentActionTarget.lot.reference }}
        </h3>
        <div slot="body" class="text-left">
            <div class="grid grid-cols-5">
                <input
                    type="number"
                    class="form-input col-span-2 text-right"
                    ref="input_price"
                    v-model="negociated_price"
                    step="1000"
                />
                <span class="col-span-3 align-bottom ml-2 leading-10">CHF</span>
            </div>
        </div>
        <div slot="footer" class="mt-12 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                        :disabled="!isValid"
                    >
                        Valider
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";

export default {
    components: { DefaultModal },
    data() {
        return {
            negociated_price: null,
            isDisabled: false,
        };
    },
    computed: {
        ...mapGetters("application", ["currentActionType", "currentActionTarget"]),
        mainCandidate(){
            return this.currentActionTarget.candidacy1.candidate;
        },
        secondCandidate(){
            if(this.currentActionTarget.candidacy2)
                return this.currentActionTarget.candidacy2.candidate;
            return false;
        },
        open(){
            return this.currentActionTarget && this.currentActionType === "updateNegociated";
        },
        isValid(){
            if(this.isDisabled)
                return false;
            return this.negociated_price != null;
        },    
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },             
    },
    mounted() {
        this.init();
    },
    methods: {
        close() {
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },         
        submit() {
            this.isDisabled = true;
            this.$store
                .dispatch("app/executeCommand", {
                    name : "updateApplication",
                    params : {
                        entity : "application",
                        entity_id: this.currentActionTarget.id,
                        additionnal_fields : {
                            negociated_price : this.negociated_price,
                        }
                    }
                }).then(() => {
                    this.$emit("update");
                    this.close();
                }).finally(() => {
                    this.isDisabled = false;
                });
        },
        init() {
            this.negociated_price = this.currentActionTarget.negociated_price;
        }
    },
    watch: {
        open(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.init();
            }
        }
    }
};
</script>
