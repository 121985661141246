<template>
  <span
    class="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium leading-5"
    :class="color"
  >
    {{ statusText }}
  </span>
</template>

<script>
export default {
    data() {
        return {
            colors: {
                "0": ["bg-orange-300", "text-white"],
                "1": ["bg-orange-400", "text-white"],
                "2": ["bg-green-300", "text-white"],
                "3": ["bg-green-400", "text-white"],
            }
        };
    },
    props: {
        status: {
            type: Number,
            default: null
        },
        statusText: {
            type: String,
            default: null
        }
    },
    computed: {
        color() {
            return this.colors[this.status];
        }
    }
};
</script>

<style></style>
