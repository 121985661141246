<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >Documents de la campagne <span class="text-gray-700">{{currentActionTarget.title}}</span>  </h3>
        <div slot="body">

			<div
                class="mt-1 align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
            >
                <table class="min-w-full bg-gray-200">
                    <thead>
                        <tr>
							<th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Date d'ajout
                            </th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Nom
                            </th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Fichier
                            </th>
                            <th
                                v-if="isBroker"
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50"
                            ></th>
                        </tr>
                    </thead>
                    <tbody v-if="currentActionTarget.files">
                        <tr
                            class="bg-white"
                            v-for="file in currentActionTarget.files"
                            :key="file.id"
                        >
                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{ file.created_at | date_dmyhm }}
                            </td>
                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{ file.name }}
                            </td>

                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                <a
                                    @click.prevent="download(file)"
                                    target="_blank"
                                    class="text-blue-400 hover:text-blue-500 flex justify-center"
                                >
                                    <svg
                                        class="w-6 h-6"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                        />
                                    </svg>
                                </a>
                            </td>
                            <td v-if="isBroker">
                                <button
                                    class="flex m-auto focus:outline-none text-gray-400 hover:text-red-700 transition-colors duration-300"
                                    @click="remove(file)"
                                >
                                    <svg
                                        class="w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-4 text-left">
                <label class="text-sm font-medium test-gray-700">Ajouter des fichiers</label>
                <FileDragger
                    ref="fileDragger"
                    @change="updateFiles"
                    :multiple="true"
                    @openEdit="editingFiles = true"
                    @closeEdit="editingFiles = false"
                >
                </FileDragger>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                        @click="submit"
                        type="button"
                        :disabled="!isValid"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass"
                    >Ajouter</button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >Fermer</button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import FileDragger from "@/components/global/FileDragger";
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import Auth from "@/mixins/Auth";
import files from "@/api/files";

export default {
    mixins : [Auth],
    components: {
        DefaultModal,
        FileDragger,
    },
    data(){
        return {
            isDisabled: false,
            files : []
        };
    },
    computed: {
        ...mapGetters("campaign", ["currentActionType", "currentActionTarget"]),
        open(){
            return this.currentActionType === "showFiles" && this.currentActionTarget != null;
        },
        isValid(){
            if(this.isDisabled)
                return false;
            return this.files.length > 0;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        }
    },
    methods: {
        close() {
            this.$store.dispatch("campaign/closeModals");
        },
        updateFiles(files){
            this.files = files;
        },
        submit() {
            this.isDisabled = true;
            let body = new FormData();
            
            if(this.$refs.fileDragger){
                this.$refs.fileDragger.appendToFormData(body);
            }

            this.$store.dispatch("campaign/addFiles", body).then(() => {
                this.$refs.fileDragger.resetValues();
                this.files = [];
            }).finally(() => {
                this.isDisabled = false;
            });
        },
        download(file){
            return files.download(file);
        },
        remove(file){
            if(confirm("Voulez-vous vraiment supprimer ce document ?")){
                this.$store.dispatch("campaign/removeFile", file);
            }
        }
    }
};
</script>