import campaign from "@/api/campaign";
import modals from "../partials/modals";

const state = {
    ...modals.state,
};

const getters = {
    ...modals.getters
};

const actions = {
    update(root,body){
        return campaign.update(body.id, body);
    },
    delete(root,body){
        return campaign.delete(body.id);
    },
    closeModals({commit}){
        commit("SET_CURRENT_ACTION_TARGET", null);
        commit("SET_CURRENT_ACTION_TYPE", null);
    },
    addFiles({commit, state}, files){
        return campaign.addFiles(state.currentActionTarget.id, files)
            .then(res => {
                if(res.status === 200){
                    // Renvoie tous les fichiers de la campagne
                    commit("SET_FILES", res.data.data);
                }
                return res;
            });
    },
    removeFile({commit, state}, file){
        return campaign.removeFile(state.currentActionTarget.id, file.id).then(res => {
            if(res.status === 200){
                // Renvoie tous les fichiers de la campagne
                commit("SET_FILES", res.data.data);
            }
            return res;
        });
    }
};

const mutations = {
    ...modals.mutations,
    SET_FILES(state, files){
        state.currentActionTarget.files = {...files};
    },
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
