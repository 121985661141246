import candidacy from "@/api/candidacy";
import modals from "../partials/modals";
import contextMenu from "../partials/contextMenu";
const state = {
    ...contextMenu.state,
    ...modals.state,
    candidacies: null,
    all_candidacies: null,
    currentTask: null,
    selectedMenu : null,
};

const getters = {
    ...contextMenu.getters,
    ...modals.getters,
    candidacies: (state) => {
        return state.candidacies;
    },
    all_candidacies: (state) => {
        return state.all_candidacies;
    },
};

const actions = {
    get({ commit, rootGetters }) {
        commit("loader/SET_LOADING", true, { root: true });

        return candidacy
            .get(rootGetters["app/formattedFilters"])
            .then((response) => {
                if (response.status === 200) {
                    commit("SET_CANDIDACIES", response.data);
                }
                commit("loader/SET_LOADING", false, { root: true });

                return response;
            });
    },
    getAll({commit, rootGetters}){
        return candidacy
            .getAll(rootGetters["app/formattedFilters"])
            .then(response => {
                if (response.status === 200) {
                    commit("SET_ALL_CANDIDACIES", response.data);
                }
                return response;
            });
    },
    closeModals({commit}){
        commit("SET_CURRENT_ACTION_TARGET", null);
        commit("SET_CURRENT_ACTION_TYPE", null);
    },
    
    addFile(root, body) {
        return candidacy.createFile(body);
    },

    removeFile(root, id) {
        return candidacy.removeFile(id);
    },

};

const mutations = {
    ...contextMenu.mutations,
    ...modals.mutations,

    SET_CANDIDACIES(state, value) {
        state.candidacies = value;
    },
    SET_ALL_CANDIDACIES(state, value) {
        state.all_candidacies = value;
    },
    SET_CURRENT_TASK(state, value) {
        state.currentTask = value;
    },

    SET_SELECTED_MENU(state, value){
        state.selectedMenu = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
