<template>
    <div
        class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 relative"
        id="login"
    >
        <div class="mt-8 sm:w-full sm:max-w-md absolute right-0 sm:pr-0 lg:pr-10">
            <div class="bg-white py-8 px-4 sm:rounded-lg sm:px-10 shadow-lg">
                <div class="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        class="mx-auto h-12 w-auto"
                        src="@/assets/imgs/naef-logo.png"
                        alt="logo Naef"
                    />
                    <h2
                        class="mt-6 text-center text-2xl leading-9 font-bold text-blue-900"
                    >Modification du mot de passe</h2>
                </div>
                <form action="#" method="POST" @submit.prevent="reset">
                    <div class="mt-6">
                        <label
                            for="email"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >Email</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                v-model="email"
                                id="email"
                                type="email"
                                required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-400 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                        </div>
                    </div>
                    <div class="mt-2">
                        <label
                            for="password"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >Mot de passe</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                v-model="password"
                                id="password"
                                type="password"
                                required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-400 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                        </div>
                    </div>

                    <div class="mt-2">
                        <label
                            for="passwordConfirmation"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >Confirmation du mot de passe</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                v-model="passwordConfirmation"
                                id="passwordConfirmation"
                                type="password"
                                required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-400 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                        </div>
                    </div>

                    <div class="mt-6">
                        <span class="block w-full rounded-md shadow-sm">
                            <button
                                type="submit"
                                class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                            >Modifier mon mot de passe</button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from "js-cookie";

import auth from "@/api/auth";

export default {
    data() {
        return {
            email: "",
            password: "",
            passwordConfirmation: ""
        };
    },

    beforeMount() {
        Cookies.remove("token");
        this.token = this.$route.params.token;

        if (!this.token) {
            this.$router.push({ name: "reset-password" });
        } else {
            auth.checkPasswordToken(this.token)
                .then(() => {
                    // token is ok
                })
                .catch(error => {
                    this.$toast.open({
                        type: "error",
                        message: error.response.data.error
                    });
                });
        }
    },

    methods: {
        reset() {
            if (this.password !== this.passwordConfirmation) {
                this.$toast.open({
                    type: "error",
                    message: "Les mots de passe doivent être identiques"
                });
            } else {
                const body = {
                    email: this.email,
                    password: this.password,
                    token: this.token
                };
                auth.resetPassword(body)
                    .then(() => {
                        this.$toast.open({
                            type: "success",
                            message: "Mot de passe bien modifié"
                        });
                        this.$router.push({ name: "login" });
                    })
                    .catch(() => {
                        this.$toast.open({
                            type: "error",
                            message: "Une erreur est survenue"
                        });
                    });
            }
        }
    }
};
</script>

<style scoped>
#login {
    background-image: url("/img/login-bg-2.jpg") !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
