<template>
	<li :class="rowStyle" class="px-2 py-2 flex hover:bg-gray-200 cursor-pointer" @click="$emit('click')">
        <div class="flex items-center justify-center">
            <slot name="left">

            </slot>
        </div>
        
        <div class="text-xs font-medium flex-grow truncate">
            {{row.label()}}
        </div>
    
        <div>
            <slot name="right">
            </slot>
        </div>
	</li>
</template>

<script>
export default {
    props : {
        row : {
            type : Object,
            required : true,
        },
    },
    computed : {
        rowStyle(){
            if(this.row.color)
                return `text-${this.row.color}`;
            return "text-gray-700";
        },

    },

};
</script>

<style>

</style>