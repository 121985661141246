<template>
    <main class="flex-1">
        <div class="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="text-right mb-2">
                <button
                v-if="isPilot"
                @click="openAddPlanningTaskModal"
                class="pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 inline-block fill-current align-middle"
                        viewBox="0 0 20 20"
                    >
                        <path
                        d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                        />
                    </svg>
                    <span class="align-middle ml-2">Ajouter une tâche</span>
                </button>
            </div>
            <div
                class="border-l-2 border-blue-400 bg-blue-100 text-blue-600 px-4 py-3"
            >
                Vous retrouverez ici le planning prévisionnel de la promotion
            </div> 
            <div v-if="rawPlanning && ! rawPlanning.length" class="text-center mt-8 italic text-gray-900">
                Aucun planning pour cette promotion
            </div>           
        </div>
        <div v-if="isLoaded" class="overflow-x-auto bg-white">
            <div class="flex h-24">
                <div class="w-64 flex-shrink-0 flex items-center justify-center mr-2" ref="sliderBox">
                    <label>
                        <input type="checkbox" v-model="monthly">
                        Mensuel
                    </label>
                </div >
                <div v-for="(period, i) in periods" :key="i" class="text-2xs whitespace-no-wrap flex items-end justify-start  font-mono" :style="`width: ${periodWidthInPx}px`">
                    <div class="transform -rotate-75 origin-bottom-left" >
                        <!-- On utilise opacity-0 pour avoir ne pas changer la taille du bloc et donc perdre l'alignement -->
                        <!-- i !== periods.length - 1 pour toujours afficher la dernière légende -->
                        <span :class="{ 'opacity-0': i % 3 !== 0 && ! monthly && i !== periods.length - 1 }">
                            {{ format(period, 'dd MMM yy') }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="relative mt-6">
                <!-- Vertical bars between periods -->
                <div class="ml-64 absolute w-px bg-gray-200 z-10" :style="`height: 101%; left: ${periodWidthInPx * (i - 1)}px; top: -9px`" v-for="i in periods.length" :key="i"></div>

                <!-- TODAY: red vertical bar -->
                <div v-if="rawPlanning" class="ml-64 absolute w-px bg-red-300 z-20" :style="`height: 101%; left: ${todaysOffsetInPx}px; top: -9px`"></div>

                <div v-for="(tasks, category) in planning" :key="category">
                    <div class="flex my-3"> <!-- Horizontal bars between categories -->
                        <div class="h-1 bg-gray-200 w-64 flex-shrink-0"></div>
                        <div class="h-1 bg-gray-200 flex-shrink-0" :style="`width: ${mainWidthInPx}px`"></div>
                    </div>
                    <div class="flex">
                        <div class="md:w-64 w-32 flex-shrink-0 text-xs flex flex-col items-center justify-center text-center bg-gray-100">
                            <!-- Category name -->
                            <div v-for="part in category.split('-')" :key="part">{{ part }}</div>
                        </div>
                        <div class="space-y-2">
                            <div class="group relative flex items-center z-20" v-for="task in tasks" :key="task.id"
                                :style="`left: ${task.offsetInPx - (task.singleDay ? (singleDaySizeInPx / 2) : 0)}px;`"
                            >
                                <planning-task-badge
                                :tooltip="true"
                                :task="task"
                                @update-selected-task="updateSelectedTask"
                                />                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <default-modal v-if="selectedTask" @close="selectedTask = null">
                <div slot="header" class="flex items-center space-x-2 mr-8 text-left text-xl leading-6 font-medium text-blue-900">
                    <span :class="`${backgroundColorsByCategory[selectedTask.category]} px-2 mt-px rounded tracking-widest text-2xs uppercase`">{{ selectedTask.category }}</span>
                    <span>{{ selectedTask.title }}</span>
                    <button
                        v-if="isPilot"
                        @click="openUpdatePlanningTaskModal"
                        type="button"
                        class="flex text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600">
                        <svg
                            class="fill-current mr-3 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
                        </svg>                        
                    </button>                    
                    <button
                        v-if="isPilot"
                        @click="openDeletePlanningTaskModal"
                        type="button"
                        class="flex text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-4 inline-block fill-current align-middle"
                            viewBox="0 0 20 20"
                        >
                            <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z"/>
                        </svg>                      
                    </button>                    
                </div>

                <div slot="body" class="mt-6 text-left w-64">
                    <div>Début : {{ format(selectedTask.dateFrom, 'dd MMMM yyyy') }}</div>
                    <div>Fin : {{ format(selectedTask.dateTo, 'dd MMMM yyyy') }}</div>

                    <div>
                        {{ selectedTask.details }}
                    </div>
                </div>
            </default-modal>        
        </div>
        <add-planning-task-modal
            :open="addPlanningTaskModal"
            @close="addPlanningTaskModal = false"
            @submit="addPlanningTask"    
        >
        </add-planning-task-modal>
        <add-planning-task-modal
            :open="updatePlanningTaskModal"
            :editTask="selectedTask"
            @close="updatePlanningTaskModal = false"
            @submit="updatePlanningTask"    
        >
        </add-planning-task-modal>
        <delete-planning-task-modal
            v-if="selectedTask"
            :open="deletePlanningTaskModal"
            :task="selectedTask"
            @close="deletePlanningTaskModal = false"
            @submit="deletePlanningTask"
        />    
    </main>
</template>

<script>
import axios from "axios";
import { fr } from "date-fns/locale";
import AddPlanningTaskModal from "@/components/Planning/modals/AddPlanningTaskModal";
import DeletePlanningTaskModal from "@/components/Planning/modals/DeletePlanningTaskModal";
import PlanningTaskBadge from "@/components/Planning/PlanningTaskBadge";
import parseISO from "date-fns/parseISO";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import addWeeks from "date-fns/addWeeks";
import addMonths from "date-fns/addMonths";
import format from "date-fns/format";
import DefaultModal from "@/components/global/modals/DefaultModal";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInMonths from "date-fns/differenceInMonths";
import isSameDay from "date-fns/isSameDay";
import isSameMonth from "date-fns/isSameMonth";
import getDaysInMonth from "date-fns/getDaysInMonth";
import { mapGetters } from "vuex";
import Auth from "@/mixins/Auth";
import planningApi from "@/api/planning";

export default {
    components: { DefaultModal, AddPlanningTaskModal, DeletePlanningTaskModal, PlanningTaskBadge,},
    mixins: [Auth],

    data() {
        return {
            // Si `monthly` est `false` cela signifie que nous sommes en mode « semaine »
            monthly: true,

            // Les données brutes retournées par l'API
            rawPlanning: null,

            // La tâche affiché dans la modale
            selectedTask: null,

            // Techniquement personne ne modifie ces valeurs mais elles sont présentes
            // ici en tant que constantes afin de ne pas avoir trop de chiffres magiques 
            // dans le template et les fonctions JS. Nous pourrions imaginer avoir ces valeurs 
            // en props si un jour un composant est extrait de cette page.
            singleDaySizeInPx: 4,
            taskMinWidthInPx: 40,
            periodWidthInPx: 20,

            // Couleurs utiles pour les catégories, nous ne pouvons pas faire des classes
            // dynamiques avec TailwindCSS car sinon PurgeCSS ne peut pas fonctionner.
            backgroundColorsByCategory: {
                "Phase préalable": "bg-indigo-300",
                "Phase administrative - Autorisation de construire": "bg-yellow-300",
                "Phase commercialisation": "bg-yellow-500",
                "Phase travaux": "bg-green-400",
            },
            borderColorsByCategory: {
                "Phase préalable": "hover:border-indigo-500",
                "Phase administrative - Autorisation de construire": "hover:border-yellow-500",
                "Phase commercialisation": "hover:border-yellow-700",
                "Phase travaux": "hover:border-green-600",
            },
            groupHoverBackgroundColorsByCategory: {
                "Phase préalable": "group-hover:bg-indigo-500",
                "Phase administrative - Autorisation de construire": "group-hover:bg-yellow-500",
                "Phase commercialisation": "group-hover:bg-yellow-700",
                "Phase travaux": "group-hover:bg-green-600",
            },

            addPlanningTaskModal: false,
            updatePlanningTaskModal: false,
            deletePlanningTaskModal: false,
        };
    },

    async mounted() {
        let response = await axios.get(`/planning?promotion_id=${this.currentPromotion.id}`);
        this.rawPlanning = response.data;

        this.$nextTick(() => {
            // Centre la barre vertical rouge du jour au milieu de l'écran
            if (this.isLoaded) {
                window.scrollTo(this.todaysOffsetInPx + this.$refs.sliderBox.offsetWidth - window.innerWidth / 2, document.body.scrollTop);
            }
        });
    },

    computed: {
        ...mapGetters("auth", ["currentPromotion"]),

        isLoaded() {
            return this.rawPlanning && this.rawPlanning.length;
        },

        minMax() {
            let min = null;
            let max = null;
            for (let task of this.rawPlanning) {
                if (! min || min > task.dateFrom) {
                    min = task.dateFrom;
                }
                if (! max || max < task.dateTo) {
                    max = task.dateTo;
                }
            }

            return { min: this.startOfPeriod(parseISO(min)), max: this.addPeriod(this.startOfPeriod(parseISO(max))) };
        },
        min() {
            return this.minMax.min;
        },
        max() {
            return this.minMax.max;
        },

        todaysOffsetInPx() {
            // Utilisé pour afficher une barre rouge vertical et scroller sur cette barre au chargement
            return this.distanceInPx(this.min, new Date());
        },
        mainWidthInPx() {
            // Utilisé pour faire des barres verticales (séparatrices des catégories) de la bonne taille
            return this.distanceInPx(this.min, this.max);
        },

        periods() {
            // Cette fonction permet de générer une liste de colonnes pour l'affichage (soit par semaine, soit par mois)

            if (! this.isLoaded) return [];

            let periods = [this.startOfPeriod(this.min)];
            let end = periods[periods.length - 1];

            while(end < this.max) {
                periods.push(this.addPeriod(end));
                end = periods[periods.length - 1];
            }

            return periods;
        },

        planning() {
            // Nous allons travailler un peu les données brutes renvoyées par l'API :
            //  - grouper les tâches par catégories
            //  - transformer les dates en objets
            //  - ajouter les distances en pixels des évènements (du début du planning et la largeur de l'évènement)
            //  - ajout de booléens utiles pour savoir si l'évènements est sur un seul jour et/ou trop petit pour son titre

            let planning = {};
            if (! this.isLoaded) return planning;

            for (let task of this.rawPlanning) {
                planning[task.category] = planning[task.category] || [];

                let dateFrom = parseISO(task.dateFrom);
                let dateTo = parseISO(task.dateTo);
                
                let singleDay = isSameDay(dateFrom, dateTo);
                let widthInPx = singleDay ? this.singleDaySizeInPx : this.distanceInPx(dateFrom, dateTo);

                planning[task.category].push({
                    id: task.id,
                    title: task.title,
                    details: task.details,
                    category: task.category,
                    dateFrom,
                    dateTo,
                    widthInPx,
                    offsetInPx: this.distanceInPx(this.min, dateFrom),
                    singleDay,
                    tooSmall: widthInPx < this.taskMinWidthInPx, // pour l'instant ceci est assez basique, nous pourrions faire un test avec le nombre de caractères du titre pour être plus précis.
                });
            }

            return planning;
        },
    },

    methods: {
        distanceInPx(date1, date2) {
            if (date1 > date2) console.error("date1 should be before date2");

            // Nous devons faire un calcul un peu spécial ici car tous les mois n'ont pas le même nombre de jours
            // et vu que nous affichons chaque mois avec une largeur fixe, le largeur d'un jour dans le mois n'est 
            // pas toujours la même.
            if (this.monthly) {
                if (isSameMonth(date1, date2)) {
                    // Si nous sommes le même mois, nous prenons la différence en jour et nous multiplions par la taille d'un jour pour ce mois ci.
                    return differenceInCalendarDays(date2, date1) * (this.periodWidthInPx / getDaysInMonth(date1));
                } else {
                    // Si nous ne sommes pas le même mois, nous allons prendre la distance entre la première date et la fin du mois
                    let firstMonthInPx = differenceInCalendarDays(addMonths(startOfMonth(date1), 1), date1) * (this.periodWidthInPx / getDaysInMonth(date1));
                    // Puis la distance entre le début du dernier mois et le jour de fin
                    let lastMonthInPx = differenceInCalendarDays(date2, startOfMonth(date2)) * (this.periodWidthInPx / getDaysInMonth(date2));
                    // Et enfin la distance des mois entre les deux
                    let monthsInPx = differenceInMonths(startOfMonth(date2), addMonths(startOfMonth(date1), 1)) * this.periodWidthInPx;

                    return firstMonthInPx + monthsInPx + lastMonthInPx;
                }
            } else {
                // Si nous sommes en affichage par semaine, nous n'avons pas ce problème
                // car il y a toujours le même nombre de jours dans une semaine (et donc nos jours sont de largeur fixe)
                return differenceInCalendarDays(date2, date1) * (this.periodWidthInPx / 7);
            }
        },
        startOfPeriod(date) {
            if (this.monthly) {
                return startOfMonth(date);
            } else {
                return startOfWeek(date);
            }
        },
        addPeriod(date) {
            if (this.monthly) {
                return addMonths(date, 1);
            } else {
                return addWeeks(date, 1);
            }
        },
        format(date, format_string) {
            return format(date, format_string, { locale: fr });
        },
        openAddPlanningTaskModal() {
            this.selectedTask = null;
            this.addPlanningTaskModal = true;
        },
        addPlanningTask(data){
            this.addPlanningTaskModal = false;

            planningApi
                .addPlanningTask(data)
                .then(res => {
                    this.rawPlanning =  res.data.data;
                });
        },
        openUpdatePlanningTaskModal(){
            this.updatePlanningTaskModal = true;
        },
        updatePlanningTask(data){
            this.updatePlanningTaskModal = false;
            planningApi
                .update(data.id,data)
                .then(res => {
                    this.selectedTask = null;
                    this.rawPlanning =  res.data.data;
                });
        },
        openDeletePlanningTaskModal(){
            this.deletePlanningTaskModal = true;
        },
        deletePlanningTask(id){
            this.deletePlanningTaskModal = false;
            planningApi
                .delete(id)
                .then(res => {
                    this.selectedTask = null;
                    this.rawPlanning =  res.data.data;
                });
        },
        updateSelectedTask(payload){
            this.selectedTask = payload;
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Planning";
            }
        },
    }, 
};
</script>