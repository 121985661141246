<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-5xl overflow-visible"
    >
        <div slot="header" class="text-left">
            <h3 class="text-xl leading-6 font-medium text-blue-900 mb-3">
                Supprimer la candidature de {{currentActionTarget.candidacy1.candidate.full_name}} ?
            </h3>
            <div
                class="mb-3 bg-blue-100 text-blue-600 border-l-4 border-blue-600 pl-2"
            >
                En cas d'erreur, veuillez contacter les développeurs en
                précisant le nom du candidat
            </div>
        </div>
        <div slot="body">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                    <button
                        @click="submit"
                        :disabled="!isValid"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 text-base leading-6 font-medium shadow-sm transition ease-in-out duration-150 sm:text-sm sm:leading-5 bg-red-500 hover:bg-red-600 text-white cursor-pointer"
                        :class="submitButtonClass"
                    >
                        Supprimer
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Annuler
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import "vue2-timepicker/dist/VueTimepicker.css";
import { fr } from "vuejs-datepicker/dist/locale";

export default {
    components: {
        DefaultModal
    },
    data() {
        return {
            fr: fr,
            type: 0,
            end_at: null,
            detail: "",
            title: "",
            broker_id: null, //assignedTo() morph
            time: {
                HH: "",
                mm: ""
            },
            isValid: true,
        };
    },
    mounted() {
        if (this.user) this.broker_id = this.user.id;
    },
    computed:{
        ...mapGetters("application", ["currentActionType", "currentActionTarget"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "deleteApplication";
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-red-500 hover:bg-red-600 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
    },
    methods: {
        close() {
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },
        submit() {
            
            this.isValid = false;
            const command = {
                name : "deleteApplication", 
                params : {
                    entity : "application",
                    entity_id : this.currentActionTarget.id,
                }};

            this.$store.dispatch("app/executeCommand",command).then(() =>{
                this.close();
                this.$emit("update");
            }).finally(() => {
                this.isValid = true;
            });

        }
    }
};
</script>