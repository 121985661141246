import axios from "axios";

export default {
    dashboard(params){
        return axios.get("analytics/dashboard", {params}, {
            headers : {
                "Content-Type" : "application/json"
            }
        });
    },
};
