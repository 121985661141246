<template>
    <main class="flex-1">
        <div class="py-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="text-right">
                <button
                v-if="isPilot && !updateMode"
                @click="updateMode = true"
                class="pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                >
                    <svg
                            class="h-4 w-4 inline-block fill-current align-middle"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
                        </svg>  
                    <span class="align-middle ml-2">Modifier les données</span>
                </button>
                <button
                v-if="isPilot && updateMode"
                @click="updateFinance"
                class="pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-green-400 hover:bg-green-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                > 
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block align-middle" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span class="align-middle ml-2">Enregistrer les données</span>
                </button>
                <button
                v-if="isPilot && updateMode"
                @click="cancelUpdateMode"
                class="ml-2 pb-2 pt-1 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-red-400 hover:bg-red-600 focus:outline-none focus:border-red-700 transition duration-150 ease-in-out"
                > 
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block align-middle" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <span class="align-middle ml-2">Annuler</span>
                </button>
            </div> 
            <div class="text-sm leading-5 font-semibold text-gray-600" v-if="isLoaded">
                Dernière mise à jour : {{ format(financesData.updated_at, 'dd MMMM yyyy') }}
            </div>                  
        </div>
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 pt-4">
            <div
                class="grid gap-5 sm:grid-cols-1 lg:grid-cols-2 "
                v-if="isLoaded"
            >
                <div class="bg-white overflow-hidden shadow rounded-lg">
                    <div class="p-3">
                        <div class="flex">
                            <div
                                class="rounded-md p-3 shadow text-white icon-badge bg-gray-700"
                                style="height: 48px"
                            >
                                <i class="material-icons align-bottom text-2xl">terrain</i>
                            </div>
                            <div class="ml-3 w-0 flex-1">
                                <dl>
                                    <dt
                                        class="text-left font-medium truncate"
                                    >
                                        <div class="flex justify-between text-gray-700">
                                            <span class="leading-8 text-2xl">Terrain</span>
                                            <span class="text-xl"></span>
                                        </div>
                                        <div class="flex justify-between text-gray-600 mt-5">
                                            <span class="leading-8 text-xl">Prix d'achat</span>
                                            <span v-if="!updateMode" class="text-xl">{{ financesData.land_price_1 }} KCHF</span>
                                            <div v-if="updateMode"><input type="number" v-model="body.land_price_1" class="text-right form-input"/></div>
                                        </div>
                                        <div class="flex justify-between text-gray-600 mt-2">
                                            <span class="leading-8 text-xl">Prix m²/SBP</span>
                                            <span v-if="!updateMode" class="text-xl">{{ financesData.land_price_2 }} CHF/m²/SBP</span>
                                            <div v-if="updateMode"><input type="number" v-model="body.land_price_2" class="text-right form-input"/></div>
                                        </div>
                                        <div class="flex justify-between text-gray-600 mt-2">
                                            <span class="leading-8 text-xl">Prix m²/terrain</span>
                                            <span v-if="!updateMode" class="text-xl">{{ financesData.land_price_3 }} CHF/m²</span>
                                            <div v-if="updateMode"><input type="number" v-model="body.land_price_3" class="text-right form-input"/></div>
                                        </div>
                                    </dt>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="bg-white overflow-hidden shadow rounded-lg">
                    <div class="p-3">
                        <div class="flex">
                            <div
                                class="rounded-md p-3 shadow text-white icon-badge bg-yellow-600"
                                style="height: 48px"
                            >
                                <i class="material-icons align-bottom text-2xl">exposure</i>
                            </div>
                            <div class="ml-3 w-0 flex-1">
                                <dl>
                                    <dt
                                        class="text-left font-medium truncate"
                                    >
                                        <div class="flex justify-between text-yellow-600">
                                            <span class="leading-8 text-2xl">Marge</span>
                                            <span class="text-xl"></span>
                                        </div>
                                        <div class="xl:grid xl:grid-cols-2">
                                            <div class="grid grid-cols-3">
                                                <div class="text-yellow-500 text-xl"></div>
                                                <div class="text-yellow-500 text-xl text-center">Initiale</div>
                                                <div class="text-yellow-500 text-xl text-right">À jour</div>
                                                <div class="text-yellow-500 text-xl">Marge</div>
                                                <div v-if="!updateMode" class="text-yellow-500 text-xl text-center">{{ financesData.initial_margin_1 }} KCHF</div>
                                                <input v-if="updateMode" type="number" v-model="body.initial_margin_1" class="text-right form-input"/>
                                                <div v-if="!updateMode" class="text-yellow-500 text-xl text-right">{{ financesData.updated_margin_1 }} KCHF</div>
                                                <input v-if="updateMode" type="number" v-model="body.updated_margin_1" class="text-right form-input"/>
                                                <div class="text-yellow-500 text-xl">Marge sur PR</div>
                                                <div v-if="!updateMode" class="text-yellow-500 text-xl text-center">{{ financesData.initial_margin_2 }} %</div>
                                                <input v-if="updateMode" type="number" v-model="body.initial_margin_2" class="text-right form-input"/>
                                                <div v-if="!updateMode" class="text-yellow-500 text-xl text-right">{{ financesData.updated_margin_2 }} %</div>
                                                <input v-if="updateMode" type="number" v-model="body.updated_margin_2" class="text-right form-input"/>
                                            </div>
                                            <div class="ml-4">
                                                <bar-chart
                                                    ref="bar3"
                                                    :chartData="charts.marginEvolution.chartData"
                                                    :options="charts.marginEvolution.options"
                                                    :height="240"
                                                ></bar-chart>
                                            </div>
                                        </div>
                                    </dt>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white overflow-hidden shadow rounded-lg">
                    <div class="p-3">
                        <div class="flex">
                            <div
                                class="rounded-md p-3 shadow text-white icon-badge bg-green-700"
                                style="height: 48px"
                            >
                                <i class="material-icons align-bottom text-2xl">paid</i>
                            </div>
                            <div class="ml-3 w-0 flex-1">
                                <dl>
                                    <dt
                                        class="text-left font-medium truncate"
                                    >
                                        <div class="flex justify-between text-green-700">
                                            <span class="leading-8 text-2xl">Montant des ventes</span>
                                            <span class="text-xl"></span>
                                        </div>
                                        <div class="grid grid-cols-3 gap-4">
                                            <div class="text-green-600 text-xl"></div>
                                            <div class="text-green-600 text-xl text-center">Initial</div>
                                            <div class="text-green-600 text-xl text-right">À jour</div>
                                            <div class="text-green-600 text-xl">Logements</div>
                                            <div v-if="!updateMode" class="text-green-600 text-center">{{ financesData.sale_initial_price_1 }} KCHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.sale_initial_price_1" class="text-right form-input"/>
                                            <div v-if="!updateMode" class="text-green-600 text-right">{{ financesData.sale_updated_price_1 }} KCHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.sale_updated_price_1" class="text-right form-input"/>
                                            <div class="text-green-600 text-xl">Prix m² moyen</div>
                                            <div v-if="!updateMode" class="text-green-600 text-center">{{ financesData.sale_initial_price_2 }} CHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.sale_initial_price_2" class="text-right form-input"/>
                                            <div v-if="!updateMode" class="text-green-600 text-right">{{ financesData.sale_updated_price_2 }} CHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.sale_updated_price_2" class="text-right form-input"/>
                                            <div class="text-green-600 text-xl">Commerces</div>
                                            <div v-if="!updateMode" class="text-green-600 text-center">{{ financesData.sale_initial_price_3 }} KCHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.sale_initial_price_3" class="text-right form-input"/>
                                            <div v-if="!updateMode" class="text-green-600 text-right">{{ financesData.sale_updated_price_3 }} KCHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.sale_updated_price_3" class="text-right form-input"/>
                                            <div class="text-green-600 text-xl">Prix m² moyen</div>
                                            <div v-if="!updateMode" class="text-green-600 text-center">{{ financesData.sale_initial_price_4 }} CHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.sale_initial_price_4" class="text-right form-input"/>
                                            <div v-if="!updateMode" class="text-green-600 text-right">{{ financesData.sale_updated_price_4 }} CHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.sale_updated_price_4" class="text-right form-input"/>
                                        </div>
                                        <div class="mt-4">
                                            <bar-chart
                                                ref="bar1"
                                                :chartData="charts.saleEvolution.chartData"
                                                :options="charts.saleEvolution.options"
                                                :height="200"
                                            ></bar-chart>
                                        </div>
                                    </dt>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="bg-white overflow-hidden shadow rounded-lg">
                    <div class="p-3">
                        <div class="flex">
                            <div
                                class="rounded-md p-3 shadow text-white icon-badge bg-orange-700"
                                style="height: 48px"
                            >
                                <i class="material-icons align-bottom text-2xl">foundation</i>
                            </div>
                            <div class="ml-3 w-0 flex-1">
                                <dl>
                                    <dt
                                        class="text-left font-medium truncate"
                                    >
                                        <div class="flex justify-between text-orange-700">
                                            <span class="leading-8 text-2xl">Construction</span>
                                            <span class="text-xl"></span>
                                        </div>
                                        <div class="grid grid-cols-3 gap-4">
                                            <div class="text-orange-600 text-xl"></div>
                                            <div class="text-orange-600 text-xl text-center">Initial</div>
                                            <div class="text-orange-600 text-xl text-right">À jour</div>
                                            <div class="text-orange-600 text-xl">Prix/m³ logements</div>
                                            <div v-if="!updateMode" class="text-orange-600 text-xl text-center">{{ financesData.construction_initial_price_1 }} CHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.construction_initial_price_1" class="text-right form-input"/>
                                            <div v-if="!updateMode" class="text-orange-600 text-xl text-right">{{ financesData.construction_updated_price_1 }} CHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.construction_updated_price_1" class="text-right form-input"/>
                                            <div class="text-orange-600 text-xl">Prix/m³ commerces</div>
                                            <div v-if="!updateMode" class="text-orange-600 text-xl text-center">{{ financesData.construction_initial_price_2 }} CHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.construction_initial_price_2" class="text-right form-input"/>
                                            <div v-if="!updateMode" class="text-orange-600 text-xl text-right">{{ financesData.construction_updated_price_2 }} CHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.construction_updated_price_2" class="text-right form-input"/>
                                            <div class="text-orange-600 text-xl">Prix/m³ garages</div>
                                            <div v-if="!updateMode" class="text-orange-600 text-xl text-center">{{ financesData.construction_initial_price_3 }} CHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.construction_initial_price_3" class="text-right form-input"/>
                                            <div v-if="!updateMode" class="text-orange-600 text-xl text-right">{{ financesData.construction_updated_price_3 }} CHF</div>
                                            <input v-if="updateMode" type="number" v-model="body.construction_updated_price_3" class="text-right form-input"/>
                                        </div>
                                        <div class="mt-4">
                                            <bar-chart
                                                ref="bar2"
                                                :chartData="charts.constructionEvolution.chartData"
                                                :options="charts.constructionEvolution.options"
                                                :height="240"
                                            ></bar-chart>
                                        </div>
                                    </dt>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </main>
</template>

<script>
import axios from "axios";
import { fr } from "date-fns/locale";
import { formatCHF } from "@/helpers/formatter";
import financesChartsConfig from "@/helpers/financesChartsConfig";
import BarChart from "@/components/Dashboard/Charts/Bar";
import View from "@/mixins/View";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { mapGetters } from "vuex";
import Auth from "@/mixins/Auth";

export default {
    mixins : [View,Auth,],
    name: "Dashboard",
    components: {
        BarChart,
    },
    computed : {
        ...mapGetters("app", ["formattedFilters"]),
        ...mapGetters("auth", ["changingPromotion","currentPromotion"]),
        isLoaded() {
            return this.finances;
        },
        financesData(){
            // Mise en forme des données pour l'affichage
            let financesData = {};
            if (! this.isLoaded) return financesData;

            financesData = {...this.finances};
            financesData.updated_at = parseISO(financesData.updated_at);
            financesData.land_price_1 = !financesData.land_price_1 ? "-" : this.formatNumber(financesData.land_price_1);
            financesData.land_price_2 = !financesData.land_price_2 ? "-" : this.formatNumber(financesData.land_price_2);
            financesData.land_price_3 = !financesData.land_price_3 ? "-" : this.formatNumber(financesData.land_price_3);
            financesData.sale_initial_price_1 = !financesData.sale_initial_price_1 ? "-" : this.formatNumber(financesData.sale_initial_price_1);
            financesData.sale_initial_price_2 = !financesData.sale_initial_price_2 ? "-" : this.formatNumber(financesData.sale_initial_price_2);
            financesData.sale_initial_price_3 = !financesData.sale_initial_price_3 ? "-" : this.formatNumber(financesData.sale_initial_price_3);
            financesData.sale_initial_price_4 = !financesData.sale_initial_price_4 ? "-" : this.formatNumber(financesData.sale_initial_price_4);
            financesData.sale_updated_price_1 = !financesData.sale_updated_price_1 ? "-" : this.formatNumber(financesData.sale_updated_price_1);
            financesData.sale_updated_price_2 = !financesData.sale_updated_price_2 ? "-" : this.formatNumber(financesData.sale_updated_price_2);
            financesData.sale_updated_price_3 = !financesData.sale_updated_price_3 ? "-" : this.formatNumber(financesData.sale_updated_price_3);
            financesData.sale_updated_price_4 = !financesData.sale_updated_price_4 ? "-" : this.formatNumber(financesData.sale_updated_price_4);
            financesData.construction_initial_price_1 = !financesData.construction_initial_price_1 ? "-" : this.formatNumber(financesData.construction_initial_price_1);
            financesData.construction_initial_price_2 = !financesData.construction_initial_price_2 ? "-" : this.formatNumber(financesData.construction_initial_price_2);
            financesData.construction_initial_price_3 = !financesData.construction_initial_price_3 ? "-" : this.formatNumber(financesData.construction_initial_price_3);
            financesData.construction_updated_price_1 = !financesData.construction_updated_price_1 ? "-" : this.formatNumber(financesData.construction_updated_price_1);
            financesData.construction_updated_price_2 = !financesData.construction_updated_price_2 ? "-" : this.formatNumber(financesData.construction_updated_price_2);
            financesData.construction_updated_price_3 = !financesData.construction_updated_price_3 ? "-" : this.formatNumber(financesData.construction_updated_price_3);
            financesData.initial_margin_1 = !financesData.initial_margin_1 ? "-" : this.formatNumber(financesData.initial_margin_1);
            financesData.initial_margin_2 = !financesData.initial_margin_2 ? "-" : this.formatNumber(financesData.initial_margin_2);
            financesData.updated_margin_1 = !financesData.updated_margin_1 ? "-" : this.formatNumber(financesData.updated_margin_1);
            financesData.updated_margin_2 = !financesData.updated_margin_2 ? "-" : this.formatNumber(financesData.updated_margin_2);

            return financesData;
        },
    },
    data() {
        return {
            updateMode: false,
            charts: financesChartsConfig,
            finances : null,
            body: null,
        };
    },

    async mounted() {
        this.$store.commit("loader/SET_LOADING", true);

        let response = await axios.get(`/finances?promotion_id=${this.currentPromotion.id}`);
        this.finances = response.data;
        this.body = {...this.finances};

        this.updateSaleChart(this.finances);
        this.updateConstructionChart(this.finances);
        this.updateMarginChart(this.finances);
        
        this.$store.commit("loader/SET_LOADING", false);
    },

    methods: {
        formatCHF,
        format(date, format_string) {
            return format(date, format_string, { locale: fr });
        },
        formatNumber(number) {
            return new Intl.NumberFormat().format(number);
        },
        async fetchData() {
            
        },
        updateSaleChart(finances){
            this.charts.saleEvolution.chartData.labels = ["Initial","À jour"];
            this.charts.saleEvolution.chartData.datasets[0].data = [
                !finances.sale_initial_price_3 ? 0 : finances.sale_initial_price_3,
                !finances.sale_updated_price_3 ? 0 : finances.sale_updated_price_3,
            ];
            this.charts.saleEvolution.chartData.datasets[1].data = [
                !finances.sale_initial_price_1 ? 0 : finances.sale_initial_price_1,
                !finances.sale_updated_price_1 ? 0 : finances.sale_updated_price_1,
            ];
            this.$nextTick(() => {
                this.$refs.bar1.update();
            });
        },
        updateConstructionChart(finances){
            this.charts.constructionEvolution.chartData.labels = ["Initial","À jour"];
            this.charts.constructionEvolution.chartData.datasets[0].data = [
                !finances.construction_initial_price_3 ? 0 : finances.construction_initial_price_3,
                !finances.construction_updated_price_3 ? 0 : finances.construction_updated_price_3,
            ];
            this.charts.constructionEvolution.chartData.datasets[1].data = [
                !finances.construction_initial_price_2 ? 0 : finances.construction_initial_price_2,
                !finances.construction_updated_price_2 ? 0 : finances.construction_updated_price_2,
            ];
            this.charts.constructionEvolution.chartData.datasets[2].data = [
                !finances.construction_initial_price_1 ? 0 : finances.construction_initial_price_1,
                !finances.construction_updated_price_1 ? 0 : finances.construction_updated_price_1,
            ];
            this.$nextTick(() => {
                this.$refs.bar2.update();
            });
        },
        updateMarginChart(finances){
            this.charts.marginEvolution.chartData.labels = ["Initial","À jour"];
            this.charts.marginEvolution.chartData.datasets[0].data = [
                !finances.initial_margin_1 ? 0 : finances.initial_margin_1,
                !finances.updated_margin_1 ? 0 : finances.updated_margin_1,
            ];
            this.$nextTick(() => {
                this.$refs.bar3.update();
            });
        },
        updateFinance(){
            let data = this.body;
            axios.put(`/finances/${data.id}`, data)
                .then(res=>{
                    this.finances = res.data.data;
                    this.body = {...this.finances};
                    this.updateSaleChart(this.finances);
                    this.updateConstructionChart(this.finances);
                    this.updateMarginChart(this.finances);
                });
            this.updateMode = false;
        },
        cancelUpdateMode(){
            this.body = {...this.finances};
            this.updateMode = false;
        },

    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Finances";
            }
        },
    }    
};
</script>