const state = {
    loading : false,
};


const mutations = {
    SET_LOADING(state, value) {
        state.loading = value;
    },
};

export default {
    namespaced: true,
    state,
    mutations
};
