<template>
    <div class="space-y-2">
        <div class="flex justify-between items-center">
            <div class="flex items-center">
                <div class="inline-block mr-4">
                    <div class="mt-1 flex rounded-md shadow-sm w-48">
                        <div class="relative flex-grow focus-within:z-10">
                            <input
                                v-model="search"
                                :class="{ 'rounded-r-md': !search }"
                                class="px-3 py-2 border border-gray-300 block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5 appearance-none focus:outline-none focus:border-blue-400"
                                placeholder="Recherche"
                            />
                        </div>
                        <button
                            v-show="search"
                            @click="search = ''"
                            class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 rounded-r-md text-sm leading-5 font-medium text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-400 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                        >
                            <svg
                                class="h-5 w-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                    fill-rule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div v-show="filesTypes.length">
                    <MultiSelect
                        title="Type de document"
                        :collection="filesTypesInMultiSelectFormat"
                        @change="selectedFilesTypes = $event.map(({ value }) => value)"
                    ></MultiSelect>
                </div>
                <div>
                <button type="button"
                    @click="newFile"
                    v-if="(isBroker || isPilot) && !isDemo "
                    class="px-4 py-2 flex items-center space-x-2 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block fill-current align-middle" viewBox="0 0 20 20" >
                        <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                    </svg>
                    <span>Ajouter un fichier</span>
                </button>
            </div>
            </div>
            
        </div>
        <div class="grid gap-2" :class="{
            'grid-cols-1 md:grid-cols-2': folders.length,
            'grid-cols-1': ! folders.length,
        }">
            <div class="shadow bg-white rounded border-2 border-blue-400" >
                <div class="text-center p-4 text-white bg-blue-400 font-medium shadow">
                    Explorateur de fichiers
                </div>
                <div class="p-4 gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2" v-show="folders.length">
                    <button type="button"
                        @click="openFolder(folder)"
                        v-for="folder in folders"
                        :key="`folder_${folder.id}`"
                        class="text-left bg-white p-4 shadow-sm rounded hover:border-blue-200 border text-gray-700 w-full"
                        :class="{
                            'bg-blue-400': isSelectedFolder(folder),
                        }"
                    >
                        <div class="flex items-center justify-between">
                            <svg v-show="! isSelectedFolder(folder)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
                            </svg>
                            <svg v-show="isSelectedFolder(folder)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                            </svg>
                            <span
                                class="text-xs "
                                :class="{
                                    'text-white': isSelectedFolder(folder),
                                    'text-gray-500': ! isSelectedFolder(folder),
                                }"
                            >({{ getFilesInsideFolder(folder).length }} documents)</span>
                        </div>
                        <div class="truncate" :class="{
                            'text-white': isSelectedFolder(folder),
                            'text-gray-900': ! isSelectedFolder(folder),
                        }">
                            {{ folder.name }}
                        </div>
                    </button>
                </div>
                <hr class="my-6" v-show="folders.length && filesWithoutFolder.length">
                <div class="p-4 gap-4 grid " :class="gridClasses" v-show="filesWithoutFolder.length">
                    <file v-for="file in filesWithoutFolder" :key="`files_${file.id}`" :file="file" :editable="editable" @delete="fileToDelete = file" @edit="edit(file)" />
                </div>
            </div>
            <div ref="openedFolder" v-if="openedFolder && folders.length" class="shadow bg-white rounded border-2 border-blue-400">
                <div class="text-center p-4 text-blue-700 font-medium shadow">
                    {{ openedFolder.name }}
                </div>
                <div class="p-4 gap-4 grid" :class="gridClasses">
                    <file v-for="file in openedFolderFiles" :key="`files_${file.id}`" :file="file" :editable="editable" @delete="fileToDelete = file" @edit="edit(file)" />
                </div>
            </div>
        </div>

        <document-modal v-model="modalOpen"
            :file="modalFile"
            :filesTypes="filesTypes"
            :filesFolders="filesFolders"
            @added="fetchFiles"
            @modified="fetchFiles"
        />

        <document-delete-modal v-model="fileToDelete" @deleted="fetchFiles" />
    </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import MultiSelect from "@/components/Forms/MultiSelect";
import DocumentModal from "@/components/Files/DocumentModal";
import DocumentDeleteModal from "@/components/Files/DocumentDeleteModal";
import File from "@/components/Files/File";
import Auth from "@/mixins/Auth";

export default {
    mixins: [Auth],

    components: { MultiSelect, DocumentModal, DocumentDeleteModal, File },

    data() {
        return {
            filesTypes: [],
            filesFolders: [],
            selectedFilesTypes: [],
            allFiles: [],
            openedFolder: null,
            search: "",
            modalOpen: false,
            modalFile: null,

            fileToDelete: null,
        };
    },

    mounted() {
        this.fetchFiles();
        this.fetchFilesTypes();
        this.fetchFilesFolders();
    },

    computed: {
        ...mapGetters("auth", ["currentPromotion"]),

        folders() {
            return this.allFiles
                .map((file) => file.folder)
                .filter((value, index, self) => value && self.map(x => x && x.id).indexOf(value.id) == index); // Unique by ID
        },

        files() {
            return this.allFiles
                .filter((file) => {
                    if (this.selectedFilesTypes.length) {
                        let matchFilesTypes = this.selectedFilesTypes.some((fileTypeId) => {
                            return file.type.id === fileTypeId;
                        });
                        if (! matchFilesTypes) return false;
                    }

                    if (this.search) {
                        return this.search.split(" ").every((word) => {
                            return file.name.toLowerCase().includes(word.toLowerCase());
                        });
                    }

                    return true;
                });
        },

        filesTypesInMultiSelectFormat() {
            return this.filesTypes.map((fileType) => ({ value: fileType.id, label: fileType.name, title: fileType.name }));
        },

        openedFolderFiles() {
            if (! this.openedFolder) return [];

            return this.getFilesInsideFolder(this.openedFolder);
        },

        filesWithoutFolder() {
            return this.files.filter((file) => ! file.folder);
        },

        editable() {
            return this.isArchitect || this.isBroker || this.isPilot;
        },

        gridClasses() {
            // Dans le cas où nous n'avons pas de dossiers disponible (`folders.length`), l'interface
            // est sur une seule colonne donc avec plus de place (pas de dossier à afficher à droite)

            return {
                "grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2": this.folders.length,
                "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4": ! this.folders.length,
            };
        },
    },

    methods: {

        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true;
            } else {
                return false;
            }
        },

        async fetchFiles() {
            let response = await axios.get(`/files?promotion=${this.currentPromotion.id}`);
            this.allFiles = response.data;
        },

        async fetchFilesTypes() {
            let response = await axios.get("/files_types");
            this.filesTypes = response.data;
        },

        async fetchFilesFolders() {
            let response = await axios.get("/files_folders");
            this.filesFolders = response.data;
        },

        isSelectedFolder(folder) {
            if (! this.openedFolder) return false;

            return folder.id === this.openedFolder.id;
        },

        getFilesInsideFolder(folder) {
            return this.files.filter((file) => file.folder && file.folder.id === folder.id);
        },

        newFile() {
            this.modalFile = null;
            this.modalOpen = true;
        },

        edit(file) {
            this.modalFile = file;
            this.modalOpen = true;
        },

        openFolder(folder) {
            this.openedFolder = folder;
            if(this.isMobile())
                this.$nextTick(() => this.$refs.openedFolder.scrollIntoView());
        }
    },
};
</script>
