import axios from "axios";

export default {
    get(params) {
        return axios.get("applications", {
            params: params,
        });
    },
    markAsComplete(id, body) {
        return axios.put(`applications/${id}/markAsComplete`, body);
    },
    sendNotificationIncomplete(id, body) {
        return axios.post(
            `applications/sendNotificationIncomplete/${id}`,
            body
        );
    },
    getOnePublic(id) {
        return axios.get(`applications/public/${id}`);
    },
    completeRegister(id, data) {
        return axios.post(`applications/complete/${id}`, data);
    },
    createAction(promotionId, body) {
        return axios.post(
            `/promotions/${promotionId}/applications/actions`,
            body
        );
    },
    updateAction(promotionId, id, body) {
        return axios.put(
            `/promotions/${promotionId}/applications/actions/${id}`,
            body
        );
    },
    scheduleAgreement(id, body) {
        return axios.post(`applications/${id}/scheduleAgreement`, body);
    },
    planActOfPurchase(id, body) {
        return axios.post(`applications/${id}/planActOfPurchase`, body);
    },
    createPlanActOfPurchase(id, body) {
        return axios.post(`applications/${id}/createPlanActOfPurchase`, body);
    },
    reservationDepositPaid(id) {
        return axios.post(`applications/${id}/reservation-deposit-received`);
    },
    depositPaid(id) {
        return axios.post(`applications/${id}/deposit-received`);
    },
    clientSolvent(id) {
        return axios.post(`applications/${id}/client-solvent`);
    },
    clientNonSolvent(id) {
        return axios.post(`applications/${id}/client-non-solvent`);
    },
    confirmReceivedAgreementFunds(id) {
        return axios.post(
            `applications/${id}/confirm-received-agreement-funds`
        );
    },
    sendAct(id) {
        return axios.post(`applications/${id}/send-act`);
    },
    signAct(id) {
        return axios.post(`applications/${id}/sign-act`);
    },
    sendCallForFunds(id) {
        return axios.post(`applications/${id}/send-call-for-funds`);
    },
    download(params) {
        return axios.get("/applications/download", {params});
    },
};
