export default {
    saleEvolution: {
        chartData: {
            labels: ["Initial", "À jour"],
            datasets: [
                {
                    type : "bar",
                    label: "Commerces",
                    backgroundColor: "#38a169",
                    data: [],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                    maxBarThickness: 180,
                },
                {
                    type : "bar",
                    label: "Logements",
                    backgroundColor: "#2f855a",
                    data: [],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                    maxBarThickness: 180,
                },
            ],
        },
        options: {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            responsive: true,
            scales: {
                xAxes : [{
                    stacked : false,
                }],
                yAxes: [{
                    stacked: false,
                    ticks: {
                        beginAtZero: true,
                    },
                }],
            },
            plugins: {
                datalabels: {
                    font: {
                        size: 18,
                    },
                    color: "#fff",
                    display: function(context) {
                        return context.dataset.data[context.dataIndex] !== 0;
                    },
                    formatter: function(value){
                        return new Intl.NumberFormat().format(value);
                    },
                },
            },
        },
    },
    constructionEvolution: {
        chartData: {
            labels: ["Initial", "À jour"],
            datasets: [
                {
                    type : "bar",
                    label: "Prix/m³ garages",
                    backgroundColor: "#ef7d32",
                    data: [],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                    maxBarThickness: 180,
                },
                {
                    type : "bar",
                    label: "Prix/m³ commerces",
                    backgroundColor: "#dd6b20",
                    data: [],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                    maxBarThickness: 180,
                },
                {
                    type : "bar",
                    label: "Prix/m³ logements",
                    backgroundColor: "#c05621",
                    data: [],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                    maxBarThickness: 180,
                },
            ],
        },
        options: {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            responsive: true,
            scales: {
                xAxes : [{
                    stacked : false,
                }],
                yAxes: [{
                    stacked: false,
                    ticks: {
                        beginAtZero: true,
                    },
                }],
            },
            plugins: {
                datalabels: {
                    font: {
                        size: 18,
                    },
                    color: "#fff",
                    display: function(context) {
                        return context.dataset.data[context.dataIndex] !== 0;
                    },
                    formatter: function(value){
                        return new Intl.NumberFormat().format(value);
                    },
                },
            },
        },
    },
    marginEvolution: {
        chartData: {
            labels: ["Initial", "À jour"],
            datasets: [
                {
                    type : "bar",
                    label: "Marge",
                    backgroundColor: "#f6d973",
                    data: [],
                    borderColor: "rgb(90,90,90)",
                    borderWidth: 1,
                    maxBarThickness: 180,
                },
            ],
        },
        options: {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            responsive: true,
            scales: {
                xAxes : [{
                    stacked : false,
                }],
                yAxes: [{
                    stacked: false,
                    ticks: {
                        beginAtZero: true,
                    },
                }],
            },
            plugins: {
                datalabels: {
                    font: {
                        size: 18,
                    },
                    color: "#fff",
                    display: function(context) {
                        return context.dataset.data[context.dataIndex] !== 0;
                    },
                    formatter: function(value){
                        return new Intl.NumberFormat().format(value);
                    },
                },
            },
        },
    },
};